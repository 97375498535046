import { asClass, createContainer, asValue } from 'awilix';
import PlausibleWebAnalyticsService, { WebAnalyticsConfig, WebAnalyticsService } from 'services/WebAnalyticsService';

import { ComponentNotificationService, IWebSocketClientService, WebSocketClientService } from './services';

export type Container = {
  [Service.componentNotification]: ComponentNotificationService,
  [Service.websocketClient]: IWebSocketClientService,
  [Service.webAnalyticsConfig]: WebAnalyticsConfig,
  [Service.webAnalytics]: WebAnalyticsService,
};

// Creating IoC container
export class Service {
  static readonly componentNotification: unique symbol = Symbol('componentNotificationService');

  static readonly websocketClient: unique symbol = Symbol('websocketClientService');

  static readonly webAnalyticsConfig: unique symbol = Symbol('webAnalyticsConfig');

  static readonly webAnalytics: unique symbol = Symbol('webAnalyticsService');
}

export const container = createContainer();

container.register(Service.componentNotification, asClass(ComponentNotificationService).singleton());
container.register(Service.websocketClient, asClass(WebSocketClientService).singleton());
container.register({
  [Service.webAnalyticsConfig]: asValue({
    apiUrl: process.env.REACT_APP_WEB_ANALYTICS_API_URL,
    domain: process.env.REACT_APP_WEB_ANALYTICS_DOMAIN,
    trackLocalhost: process.env.REACT_APP_WEB_ANALYTICS_DEBUG === 'true',
  } as WebAnalyticsConfig),
});
container.register(Service.webAnalytics, asClass(PlausibleWebAnalyticsService).singleton());

export default container;
