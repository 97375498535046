import React, { useState, useCallback } from 'react';
import EligibilityResponsePopup from './EligibilityResponsePopup';
import { EligibilityApi } from '../../../../api';

interface Props {
  eligibilityId: string;
  children: React.ReactNode;
}

export const EligibilityResponsePopupContainer = ({ eligibilityId, children }: Props) => {
  const [eligibility, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  return (
    <EligibilityResponsePopup
      loading={loading}
      eligibility={eligibility}
      onResponseFetch={useCallback(async () => {
        setLoading(true);
        try {
          const response = await EligibilityApi.getEligibilityResponse(eligibilityId as string);
          setResponse(response);
        } finally {
          setLoading(false);
        }
      }, [eligibilityId])}
    >
      {children}
    </EligibilityResponsePopup>
  );
};

export default EligibilityResponsePopupContainer;
