import React from 'react';
import { Button, Modal } from 'custom-test-antd';
import { render as reactRender, unmount as reactUnmount } from 'rc-util/lib/React/render';
import { QuestionCircleOutlined } from '@ant-design/icons';

import './Confirm.pcss';

interface Props {
  title: string,
  visible: boolean,
  content: string,
  onCancel: Function,
  onOk: Function,
  onView: Function,
  close: (...args: any[]) => void;
}

const ConfirmDialog = ({
  title,
  content,
  close,
  onOk,
  onView,
  onCancel,
  ...other
}: Props) => {
  const onClose = close.bind(this, {
    triggerCancel: true,
  });
  return (
    <Modal
      {...other}
      className="Confirm-dialog"
      closable={false}
      title={(
        <>
          <QuestionCircleOutlined className="Confirm-icon" />
          {title}
        </>
      )}
      footer={[
        <Button
          key="1"
          onClick={() => {
            onCancel();
            onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="2"
          onClick={() => {
            onView();
            onClose();
          }}
        >
          View
        </Button>,
        <Button
          key="3"
          type="primary"
          onClick={() => {
            onOk();
            onClose();
          }}
        >
          Yes
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};

const destroyFns: Array<() => void> = [];

export function confirm(config: any) {
  const container = document.createDocumentFragment();
  // eslint-disable-next-line no-use-before-define
  let currentConfig = { ...config, close, visible: true } as any;

  function destroy(...args: any[]) {
    const triggerCancel = args.some((param) => param && param.triggerCancel);
    if (config.onCancel && triggerCancel) {
      config.onCancel(...args);
    }
    for (let i = 0; i < destroyFns.length; i += 1) {
      const fn = destroyFns[i];
      // eslint-disable-next-line no-use-before-define
      if (fn === close) {
        destroyFns.splice(i, 1);
        break;
      }
    }

    reactUnmount(container);
  }

  function render({ okText, cancelText, prefixCls: customizePrefixCls, ...props }: any) {
    /**
     * https://github.com/ant-design/ant-design/issues/23623
     *
     * Sync render blocks React event. Let's make this async.
     */
    setTimeout(() => {
      reactRender(
        <ConfirmDialog
          {...props}
        />,
        container,
      );
    });
  }

  function close(...args: any[]) {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: () => {
        if (typeof config.afterClose === 'function') {
          config.afterClose();
        }

        // @ts-ignore
        destroy.apply(this, args);
      },
    };
    render(currentConfig);
  }

  function update(configUpdate: any) {
    if (typeof configUpdate === 'function') {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }

  render(currentConfig);

  destroyFns.push(close);

  return {
    destroy: close,
    update,
  };
}

export default confirm;
