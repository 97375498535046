import produce from 'immer';
import { Customer } from '../../../../../api/financing/customer/financingCustomerTypes';
import { DealSummary } from '../../../../../api/financing/deals/financingDealsTypes';
import { PaginationValue } from '../../../transactions/pagination';
import { BranchData } from '../../../../../api/branch/branchTypes';

export interface ProposalContextState {
  proposal?: any;
  branches: BranchData[];
  proposalFetching: boolean,
  customer?: Customer;
  latestTransaction?: DealSummary;
  transactions: {
    items: DealSummary[],
    fetching: boolean,
    pagination: PaginationValue
  };
  customers: Customer[];
}

export interface InitState {
  proposal?: any,
}

export const initialState = (initValues?: InitState): ProposalContextState => ({
  proposal: initValues?.proposal ?? undefined,
  branches: [],
  customers: [],
  proposalFetching: false,
  transactions: {
    items: [],
    fetching: false,
    pagination: {
      pageSize: 3,
      current: 1,
      hasNextPage: false,
    },
  },
});

export const SET_PROPOSAL = 'SET_PROPOSAL';
export const setProposal = (proposal: any) => ({
  type: SET_PROPOSAL, payload: { proposal },
});

export const SET_PROPOSAL_FETCHING = 'SET_PROPOSAL_FETCHING';
export const setProposalFetching = (fetching: boolean) => ({
  type: SET_PROPOSAL_FETCHING, payload: { fetching },
});

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const setCustomer = (customer: any) => ({
  type: SET_CUSTOMER, payload: { customer },
});

export const SET_LATEST_TRANSACTION = 'SET_LATEST_TRANSACTION';
export const setLatestTransaction = (transaction: any) => ({
  type: SET_LATEST_TRANSACTION, payload: { transaction },
});

export const SET_TRANSACTIONS_ITEMS = 'SET_TRANSACTIONS_ITEMS';
export const setTransactionsItems = (items: any) => ({
  type: SET_TRANSACTIONS_ITEMS, payload: { items },
});

export const SET_TRANSACTIONS_PAGINATION = 'SET_TRANSACTIONS_PAGINATION';
export const setTransactionsPagination = (pagination: any) => ({
  type: SET_TRANSACTIONS_PAGINATION, payload: { pagination },
});

export const SET_TRANSACTIONS_FETCHING = 'SET_TRANSACTIONS_FETCHING';
export const setTransactionsFetching = (fetching: any) => ({
  type: SET_TRANSACTIONS_FETCHING, payload: { fetching },
});

export const SET_BRANCHES = 'SET_BRANCHES';
export const setBranches = (branches: BranchData[]) => ({
  type: SET_BRANCHES, payload: { branches },
});

export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const setCustomers = (customers: Customer[]) => ({
  type: SET_CUSTOMERS, payload: { customers },
});

const proposalReducer = (state: ProposalContextState, action: { type: string, payload: any }) => (
  produce(state, (draftState) => {
    switch (action.type) {
      case SET_PROPOSAL: {
        const { proposal } = (action as ReturnType<typeof setProposal>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.proposal = proposal;
        break;
      }
      case SET_PROPOSAL_FETCHING: {
        const { fetching } = (action as ReturnType<typeof setProposalFetching>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.proposalFetching = fetching;
        break;
      }
      case SET_LATEST_TRANSACTION: {
        const { transaction } = (action as ReturnType<typeof setLatestTransaction>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.latestTransaction = transaction;
        break;
      }
      case SET_CUSTOMER: {
        const { customer } = (action as ReturnType<typeof setCustomer>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.customer = customer;
        break;
      }
      case SET_TRANSACTIONS_ITEMS: {
        const { items } = (action as ReturnType<typeof setTransactionsItems>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.transactions.items = items;
        break;
      }
      case SET_TRANSACTIONS_PAGINATION: {
        const { pagination } = (action as ReturnType<typeof setTransactionsPagination>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.transactions.pagination = pagination;
        break;
      }
      case SET_TRANSACTIONS_FETCHING: {
        const { fetching } = (action as ReturnType<typeof setTransactionsFetching>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.transactions.fetching = fetching;
        break;
      }
      case SET_BRANCHES: {
        const { branches } = (action as ReturnType<typeof setBranches>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.branches = branches;
        break;
      }
      case SET_CUSTOMERS: {
        const { customers } = (action as ReturnType<typeof setCustomers>).payload;
        // eslint-disable-next-line no-param-reassign
        draftState.customers = customers;
        break;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  })
);

export default proposalReducer;
