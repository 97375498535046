import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Lock } from 'assets/lock.svg';

import './PasswordUpdated.pcss';

import { AuthCard } from '../auth-card';
import { publicRoutes } from '../../application/routes/routesConstants';

interface Props {
  email?: string,
}

export const PasswordUpdated: React.FC<Props> = ({ email }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => navigate(publicRoutes.signIn), 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);
  return (
    <AuthCard
      className="PasswordUpdated"
      header="Password Updated"
      headerText={(
        <>
          <div>
            You will be redirected to the Sign in page
          </div>
          <br />
          <div>
            We sent you an email
            {
              email
              && (
                <>
                  &nbsp;at&nbsp;
                  <span className="PasswordUpdated-email">{email}</span>
                </>
              )
            }
            &nbsp;informing of the password change
          </div>
        </>
      )}
    >
      <Lock />
    </AuthCard>
  );
};

export default PasswordUpdated;
