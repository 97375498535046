import React from 'react';
import { Formik } from 'formik';
import { Form, Button, Alert } from 'custom-test-antd';
import { Link } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';

import './SignUp.pcss';

import { InputWrapper, InputPasswordWrapper } from 'utils/form-helpers/antd-formik';
import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';
import { PasswordGuideTooltip } from '../password-guide-tooltip';

interface Props {
  onSubmit: any;
  initialValues: {};
  error?: {
    code: string,
    message: string,
  };
}

export const SignUp: React.FC<Props> = ({ onSubmit, error, initialValues }) => (
  <AuthCard
    className="SignUp"
    header="Create account"
    headerText="Please use your company email address and a strong password."
  >
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <InputWrapper
            name="organization_name"
            size="large"
            gray
            antFieldProps={{ label: 'Organization Name' }}
          />
          <InputWrapper
            antFieldProps={{ label: 'Full Name' }}
            name="name"
            size="large"
            gray
          />
          <InputWrapper
            antFieldProps={{ label: 'Email' }}
            name="email"
            size="large"
            gray
          />
          <InputWrapper
            antFieldProps={{ label: 'Phone' }}
            name="phone"
            size="large"
            gray
          />
          <div className="SignUp-passwordRow">
            <InputPasswordWrapper
              antFieldProps={{ label: 'Password', className: 'SignUp-password' }}
              name="password"
              size="large"
              gray
            />
            <Form.Item>
              <PasswordGuideTooltip>
                <QuestionCircleFilled className="SignUp-questionIcon" />
              </PasswordGuideTooltip>
            </Form.Item>
          </div>
          {
            error?.message
            && <Alert className="SignUp-error" message={error.message} type="error" />
          }
          <div className="SignUp-signInSection">
            <span>Already have an account?&nbsp;</span>
            <Link to={publicRoutes.signIn} className="SignUp-signIn">Sign In</Link>
          </div>
          <Button
            htmlType="submit"
            loading={isSubmitting}
            type="primary"
            onClick={() => handleSubmit()}
            size="large"
            className="SignUp-submitButton"
          >
            Create account
          </Button>
        </Form>
      )}
    </Formik>
  </AuthCard>
);

export default SignUp;
