import React, { useCallback, useState } from 'react';
import { notification } from 'custom-test-antd';
import { EligibilityApi, ApiError } from 'api';
import { ErrorMessage, Props as ErrorMessageProps } from './ErrorMessage';

interface Props {
  eligibility: ErrorMessageProps['eligibility'],
}

export const ErrorMessageContainer = ({ eligibility }: Props) => {
  const [loading, setLoading] = useState(false);
  return (
    <ErrorMessage
      eligibility={eligibility}
      loading={loading}
      onLogout={useCallback(async () => {
        setLoading(true);
        try {
          const result = await EligibilityApi.portalLogout(eligibility.id as string);
          notification.success({ message: result.message });
        } catch (error: any) {
          if (error instanceof ApiError && error.response?.status === 400) {
            const errorObject = await error.response.json();
            notification.error({ message: errorObject.message });
          }
        } finally {
          setLoading(false);
        }
      }, [eligibility.id])}
    />
  );
};

export default ErrorMessageContainer;
