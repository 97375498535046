import { Dispatch } from 'react';
import { ApiError, BranchApi } from 'api';
import { v4 as uuid } from 'uuid';
import { container, Service } from 'container';
import {
  addBranch,
  Facility,
  FacilityCheckStatus,
  OnboardFacilityContextState,
  setCurrentCheck,
  setError,
  setSubmitting,
  updateBranch,
} from './onboardFacilityReducer';
import { facilitiesSelector, portalSelector } from './onboardFacilitySelectors';
import type { WebAnalyticsService } from '../../../../services/WebAnalyticsService';
import { BranchCredentialsValidationTokenStatus } from '../../../../api/branch/branchTypes';

export const checkBranch = (
  branch: Omit<Facility, 'id' | 'status' | 'error' | 'portal'>,
  createBranchOnSuccess: boolean,
) => (
  async (dispatch: Dispatch<any>, getState: () => OnboardFacilityContextState) => {
    const state = getState();
    const portal = portalSelector(state);
    const id = uuid();
    const webAnalyticsService = container.resolve<WebAnalyticsService>(Service.webAnalytics);
    try {
      dispatch(addBranch({ ...branch, id, status: FacilityCheckStatus.Checking, portal }));
      dispatch(setCurrentCheck({ id }));
      const token = await BranchApi.checkBranch({ portal, createBranchOnSuccess, ...branch });
      if (token.checkResult.status === BranchCredentialsValidationTokenStatus.Valid) {
        dispatch(updateBranch({
          ...branch,
          id,
          status: FacilityCheckStatus.Valid,
          validationToken: token.token,
          portal,
        }));
      } else {
        webAnalyticsService.trackEvent('Onboarding_BranchForm', { props: { action: 'Fail' } });
        dispatch(updateBranch({
          ...branch,
          portal,
          id,
          status: FacilityCheckStatus.Error,
          validationToken: token.token,
          error: token.checkResult.message,
        }));
      }
    } catch (error: any) {
      webAnalyticsService.trackEvent('Onboarding_BranchForm', { props: { action: 'Fail' } });
      dispatch(updateBranch({ ...branch, id, status: FacilityCheckStatus.Error, error: error.message, portal }));
    }
  }
);

export const createBranches = (
  async (dispatch: Dispatch<any>, getState: () => OnboardFacilityContextState): Promise<boolean> => {
    try {
      dispatch(setSubmitting(true));
      const state = getState();
      const facilities = facilitiesSelector(state);

      if (facilities.length === 0) {
        dispatch(setError('Please add at least one facility'));
        return false;
      }

      for (let i = 0; i < facilities.length; i += 1) {
        const facility = facilities[i];
        if (facility.status !== FacilityCheckStatus.Valid) {
          dispatch(setError('Some of facilities is not valid'));
          return false;
        }
      }

      const haveDuplicates = facilities.some((facility, idx) => (
        facilities.findIndex((itFacility) => itFacility.branchId === facility.branchId) !== idx
      ));
      if (haveDuplicates) {
        dispatch(setError('Some of facilities exists in the list several times'));
        return false;
      }

      const validationTokens = facilities.map((facility) => facility.validationToken as string);
      try {
        await BranchApi.createBranchesFromTokens(validationTokens);
      } catch (error) {
        if (error instanceof ApiError && error.response.status === 422) {
          const responseJSON = await error.response.json();
          if (responseJSON.branch) {
            const branch = facilities.find((facility) => facility.branchId === responseJSON.branch.identifier);
            if (branch) {
              dispatch(updateBranch({ ...branch, status: FacilityCheckStatus.Error, error: responseJSON.message }));
            }
          }
          dispatch(setError(responseJSON.message));
          return false;
        }
        throw error;
      }
      return true;
    } finally {
      dispatch(setSubmitting(false));
    }
  }
);
