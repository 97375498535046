import React, { FC, useEffect, useState } from 'react';
import { useAuthState } from 'components/authentication/context/authContext';
import { isEligibilityPaidSelector } from 'components/authentication/context/authSelectors';
import { OrganizationApi } from 'api';
import { FullVersionLock } from './common/full-version-lock/FullVersionLock';

type Props = {
  children: React.ReactNode;
  className?: string;
  /*
  * A delay children component should be blocked with (if no full version access). In milliseconds
  */
  blockDelay?: number;
};

export const FullVersionChecker: FC<Props> = ({ children, className, blockDelay }: Props) => {
  const authState = useAuthState();
  const isEligibilityPaid = !!isEligibilityPaidSelector(authState);
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (isEligibilityPaid) {
      setShowChildren(true);
      return;
    }

    if (blockDelay) {
      setShowChildren(true);
      setTimeout(() => {
        setShowChildren(false);
      }, blockDelay);
    } else {
      setShowChildren(false);
    }
  }, [isEligibilityPaid, blockDelay]);

  const onSendUpgradeRequest = OrganizationApi.requestEligibilityUpgrade;

  return (
    <FullVersionLock
      className={className}
      hasFullVersionAccess={showChildren}
      animated={!!blockDelay}
      isRequestUpgradeAvailable
      onSendUpgradeRequest={onSendUpgradeRequest}
    >
      {children}
    </FullVersionLock>
  );
};

export default FullVersionChecker;
