import React from 'react';
import './NotFountPage.pcss';

export const NotFoundPage: React.FC = () => (
  <h2 className="NotFoundPage">
    Page Not Found
  </h2>
);

export default NotFoundPage;
