import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Spin, notification } from 'custom-test-antd';

import { submitValidate } from 'utils/form-helpers/antd-formik';
import EligibilityRequest from 'components/eligibility/eligibility-request/EligibilityRequest';
import EligibilityDefaultApi from 'api/eligibility-default/EligibilityDefaultApi';
import SinglePortalRequest from 'components/eligibility/eligibility-request/portal-request/singlePortalRequest';
import { useMount } from 'react-use';
import { BranchApi, PractitionerLicenseApi, ApiError } from '../../../api';
import { privateRoutes } from '../../application/routes/routesConstants';
import { BranchData } from '../../../api/branch/branchTypes';
import { PractitionerLicense } from '../../../api/practitioner-license/practitionerLicenseTypes';
import {
  EligibilityRequestProvider,
  useEligibilityRequestDispatch,
  useEligibilityRequestState,
} from '../../eligibility/eligibility-request/context/eligibilityRequestContext';
import {
  createPortalRequest,
  fetchEligibilityDefaultValues,
  setFormValues,
  updateFormConfig,
  updateInitialValues,
  validateCredentials,
} from '../../eligibility/eligibility-request/context/eligibilityRequestThunks';
import {
  formConfigSelector,
  initialValuesSelector,
  isInitialValuesLoadingSelector,
  touchedInputsSelector,
  validationErrorsSelector,
} from '../../eligibility/eligibility-request/context/eligibilityRequestSelectors';

const generalValidationSchema = Yup.object({
  branchId: Yup.string(),
  portal: Yup.string().required('Insurance is required'),
});

export const EligibilityDefaultCard = () => {
  const state = useEligibilityRequestState();
  const dispatch = useEligibilityRequestDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [branches, setBranches] = useState<BranchData[]>([]);
  const onFetchBranches = useCallback(async () => {
    const fetchedBranches = await BranchApi.getUserBranches();
    setBranches(fetchedBranches);
  }, []);
  const initialFormValues = initialValuesSelector(state);
  const isInitialValuesLoading = isInitialValuesLoadingSelector(state);
  const validationErrors = validationErrorsSelector(state);
  const touchedInputs = touchedInputsSelector(state);
  const formConfig = formConfigSelector(state);

  const [licenses, setLicenses] = useState<PractitionerLicense[]>([]);
  const onFetchLicenses = useCallback(async () => {
    const fetchedLicenses = await PractitionerLicenseApi.getList({}, {});
    setLicenses(fetchedLicenses);
  }, []);

  useMount(() => {
    dispatch(fetchEligibilityDefaultValues(id));
  });

  const onFormSubmit = useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (!submitValidate(values, generalValidationSchema, formikHelpers)) {
      return false;
    }

    try {
      const { portal } = values;
      const portalRequest = dispatch(createPortalRequest(portal)) as unknown as SinglePortalRequest;
      const valuesForSubmission = portalRequest.getValuesForSubmission(values, false);

      if (id) {
        await EligibilityDefaultApi.update(id, valuesForSubmission);
      } else {
        await EligibilityDefaultApi.create(valuesForSubmission);
      }

      navigate(privateRoutes.eligibilityDefaultList);
    } catch (error: any) {
      if (error instanceof ApiError && error.response.status === 409) {
        const response = await error.response.json();
        notification.error({
          message: 'Error',
          description: response.message,
        });

        return false;
      }

      notification.error({
        message: 'Error',
        description: 'Error during eligibility default save',
      });
    }
    return false;
  }, [id, navigate]);

  if (isInitialValuesLoading) {
    return <Spin spinning />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      initialTouched={touchedInputs}
      initialErrors={validationErrors}
      validateOnBlur={false}
      validate={async (values) => {
        const errors = await dispatch(validateCredentials(values)) as any;
        const { updated, values: newValues } = await dispatch(updateInitialValues(values, false)) as unknown as any;

        dispatch(setFormValues(updated ? newValues : values));
        dispatch(updateFormConfig);

        return errors;
      }}
      onSubmit={onFormSubmit}
    >
      {(formikProps) => (
        <EligibilityRequest
          config={formConfig}
          isInitialValueLoading={isInitialValuesLoading}
          formikProps={formikProps}
          branches={branches}
          onFetchBranches={onFetchBranches}
          licenses={licenses}
          onFetchLicenses={onFetchLicenses}
          selectBranchDisabled={!!id}
          selectPortalDisabled={!!id}
          submitBtnLabel="Save"
          eidDisabled
          portalSelectSingle
        />
      )}
    </Formik>
  );
};

export const EligibilityDefaultCardWrapper = () => (
  <EligibilityRequestProvider initState={{}}>
    <EligibilityDefaultCard />
  </EligibilityRequestProvider>
);

export default EligibilityDefaultCardWrapper;
