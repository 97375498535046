import React, { useCallback } from 'react';
import { Button, ButtonProps } from 'custom-test-antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import './Pagination.pcss';

export interface PaginationValue {
  current: number;
  pageSize: number;
  hasNextPage: boolean;
}

interface Props {
  className?: string;
  onChange: (pagination: PaginationValue) => void;
  pagination: PaginationValue,
}

export const Pagination = ({ className, pagination, onChange }: Props) => {
  const onPreviousClick = useCallback(() => {
    if (pagination.current > 1) {
      onChange({ ...pagination, current: pagination.current - 1 });
    }
  }, [pagination, onChange]);

  const onNextClick = useCallback(() => {
    if (pagination.hasNextPage) {
      onChange({ ...pagination, current: pagination.current + 1 });
    }
  }, [pagination, onChange]);

  const onPageClick = useCallback((e: any) => {
    const current = parseInt(e.target.getAttribute('data-page'), 10);
    onChange({ ...pagination, current });
  }, [pagination, onChange]);

  const { current, hasNextPage } = pagination;
  const buttons: Array<{
    page: number,
    type?: ButtonProps['type'],
    ghost?: boolean,
  }> = [];

  const showFirstPageWithSeparator = current >= 5;

  if (current > 1) {
    if (showFirstPageWithSeparator) {
      buttons.push({
        page: current - 1,
      });
    } else {
      for (let i = 1; i < current; i += 1) {
        buttons.push({
          page: i,
        });
      }
    }
  }
  buttons.push({
    page: current,
    type: 'primary',
    ghost: true,
  });
  if (hasNextPage) {
    buttons.push({
      page: current + 1,
    });
  }

  return (
    <div className={`Pagination ${className ?? ''}`}>
      <Button
        className="Pagination-button"
        onClick={onPreviousClick}
        icon={<LeftOutlined className="Pagination-arrow" />}
      />
      {
        showFirstPageWithSeparator
        && (
          <>
            <Button className="Pagination-button" data-page="1" onClick={onPageClick}>1</Button>
            <span className="Pagination-button transparent">•••</span>
          </>
        )
      }
      {
        buttons.map((button, index) => (
          <Button
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="Pagination-button"
            type={button.type}
            ghost={button.ghost}
            data-page={button.page}
            onClick={onPageClick}
          >
            {button.page}
          </Button>
        ))
      }
      {
        hasNextPage
        && (
          <span className="Pagination-button ellipsis">•••</span>
        )
      }
      <Button
        className="Pagination-button"
        onClick={onNextClick}
        icon={<RightOutlined className="Pagination-arrow" />}
      />
    </div>
  );
};

export default Pagination;
