import produce from 'immer';
import { BranchData } from '../../../api/branch/branchTypes';

export enum OnboardStep {
  ScheduleCall = 'scheduleCall',
  EnterBranch = 'enterBranch',
  Loading = 'loading',
}

export interface UserOnboardingContextState {
  branchRequired: boolean,
  callRequired: boolean,
  onboardStep?: OnboardStep,
  branches: BranchData[],
}

export interface InitState {
  branchRequired: boolean,
  callRequired: boolean,
}

export const initialState = (initState?: InitState): UserOnboardingContextState => ({
  branchRequired: initState?.branchRequired ?? false,
  callRequired: initState?.callRequired ?? false,
  onboardStep: OnboardStep.Loading,
  branches: [],
});

export const SET_ONBOARD_STEP = 'SET_ONBOARD_STEP';
export const setOnboardStep = (step?: OnboardStep) => ({
  type: SET_ONBOARD_STEP, payload: { step },
});

export const SET_BRANCHES = 'SET_BRANCHES';
export const setBranches = (branches: BranchData[]) => ({
  type: SET_BRANCHES, payload: { branches },
});

const userOnboardingReducer = (state: UserOnboardingContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_ONBOARD_STEP: {
      const { step } = (action as ReturnType<typeof setOnboardStep>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.onboardStep = step;
      break;
    }
    case SET_BRANCHES: {
      const { branches } = (action as ReturnType<typeof setBranches>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.branches = branches;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default userOnboardingReducer;
