export enum FileStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
}

export interface FileFilter {
  status?: FileStatus;
}

export interface FileListItem {
  id: string;
  status: FileStatus;
  file: {
    name: string;
  },
  createdAt: string;
  executeAt: string;
}

export interface BulkFile extends FileListItem {
  file: {
    name: string;
    content: string;
  };
}
