import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  sessionUserSelector,
  userProfileSelector,
  userRolePermissionsSelector,
} from 'components/authentication/context/authSelectors';
import { useAuthDispatch, useAuthState } from 'components/authentication/context/authContext';
import { signOut } from 'components/authentication/context/authThunks';
import { privateRoutes } from 'components/application/routes/routesConstants';
import { UserProfile } from 'api/user-profile/userProfileTypes';

import UserInfo from './UserInfo';
import { RbacRole } from '../../../../../services/rbac-manager/interfaces';

export const UserInfoContainer: React.FC = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  return (
    <UserInfo
      sessionUser={sessionUserSelector(state)}
      user={userProfileSelector(state) as UserProfile}
      role={userRolePermissionsSelector(state) as RbacRole}
      onLogOut={() => {
        dispatch(signOut);
      }}
      onSettings={() => {
        navigate(privateRoutes.accountSettings);
      }}
    />
  );
};

export default UserInfoContainer;
