import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FullVersionChecker } from 'components/full-version-checker';
import EligibilityResponse from './EligibilityResponse';
import { EligibilityApi } from '../../../api';

const BLOCK_FUNCTIONALITY_DELAY = 10000; // in ms

export const EligibilityResponseContainer = () => {
  const [eligibility, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const { eligibilityId } = useParams();
  return (
    <FullVersionChecker
      className="EligibilityResponse-upgradeFullVersionOverlay"
      blockDelay={BLOCK_FUNCTIONALITY_DELAY}
    >
      <EligibilityResponse
        loading={loading}
        eligibility={eligibility}
        onResponseFetch={useCallback(async () => {
          setLoading(true);
          try {
            const response = await EligibilityApi.getEligibilityResponse(eligibilityId as string);
            setResponse(response);
          } finally {
            setLoading(false);
          }
        }, [eligibilityId])}
      />
    </FullVersionChecker>
  );
};

export default EligibilityResponseContainer;
