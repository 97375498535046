import React, { FC, useState } from 'react';
import { notification } from 'custom-test-antd';
import UpgradeFullVersionWarning from '../warning/UpgradeFullVersionWarning';
import UpgradeFullVersionConfirmation from '../confirmation/UpgradeFullVersionConfirmation';

import './FullVersionLock.pcss';

type Props = {
  children: React.ReactNode;
  hasFullVersionAccess: boolean;
  isRequestUpgradeAvailable: boolean;
  onSendUpgradeRequest?: () => Promise<void>;
  requestUpgradeNotAvailableText?: string;
  className?: string;
  animated?: boolean;
};

export const FullVersionLock: FC<Props> = ({
  className,
  children,
  hasFullVersionAccess,
  isRequestUpgradeAvailable,
  onSendUpgradeRequest,
  requestUpgradeNotAvailableText,
  animated,
}: Props) => {
  const [isUpgradeRequested, setIsUpgradeRequested] = useState(false);

  if (hasFullVersionAccess) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const onGetCallback = async () => {
    if (isRequestUpgradeAvailable) {
      if (!onSendUpgradeRequest) {
        throw new Error('onSendUpgradeRequest should be set');
      }

      await onSendUpgradeRequest();
      setIsUpgradeRequested(true);
      return;
    }

    notification.warning({
      message: requestUpgradeNotAvailableText,
    });
  };

  return (
    <div className={`FullVersionLock ${className ?? ''}`}>
      <div className={`FullVersionLock-bluredContent ${animated ? 'animated' : ''}`}>
        {children}
      </div>
      <div className="FullVersionLock-overlay">
        <div className={`FullVersionLock-visibleContent ${animated ? 'animated' : ''}`}>
          { !isUpgradeRequested && <UpgradeFullVersionWarning onGetCallback={onGetCallback} />}
          { isUpgradeRequested && <UpgradeFullVersionConfirmation />}
        </div>
      </div>
    </div>
  );
};

export default FullVersionLock;
