import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'custom-test-antd';

import { useAuthState } from 'components/authentication/context/authContext';
import { userProfileSelector } from 'components/authentication/context/authSelectors';
import { privateRoutes } from '../application/routes/routesConstants';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';

import './EligibilityDefault.pcss';

enum EligibilityMenuKey {
  EligibilityDefaultList = 'eligibilityDefaultList',
  EligibilityDefaultNew = 'eligibilityDefaultNew',
}

const MenuRoutes: { [key: string]: string } = {
  [EligibilityMenuKey.EligibilityDefaultList]: privateRoutes.eligibilityDefaultList,
  [EligibilityMenuKey.EligibilityDefaultNew]: privateRoutes.eligibilityDefaultNew,
};

const Header = createPortal(PrivateLayout.titlePortalId);

export const Eligibility: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAuthState();
  const userProfile = userProfileSelector(authState);
  const menuItems = useMemo(() => {
    if (!userProfile) {
      return [];
    }

    const result = [
      { label: 'Overview', key: EligibilityMenuKey.EligibilityDefaultList },
      { label: 'New Eligibility Default', key: EligibilityMenuKey.EligibilityDefaultNew },
    ];

    return result;
  }, [userProfile]);

  let selectedKey: string = EligibilityMenuKey.EligibilityDefaultList;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header>Eligibility defaults</Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="Eligibility-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <Outlet />
        )}
      />
    </>
  );
};

export default Eligibility;
