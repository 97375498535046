import React, { useCallback } from 'react';

import './OnboardFacilityPage.pcss';

import { useUserOnboardingDispatch } from '../context/userOnboardingContext';
import { useAuthState } from '../../authentication/context/authContext';
import { userProfileSelector } from '../../authentication/context/authSelectors';
import { UserProfile } from '../../../api/user-profile/userProfileTypes';
import { AuthCard } from '../../authentication/auth-card';
import { OnboardFacilityProvider } from './context/onboardFacilityContext';
import { initOnboardStep } from '../context/userOnboardingThunks';
import { OnboardFacilityContainer } from './onboard-facility-form/OnboardFacilityContainer';

const OnboardFacilityPage = () => {
  const userOnboardingDispatch = useUserOnboardingDispatch();
  const authState = useAuthState();
  const user = userProfileSelector(authState) as UserProfile;

  return (
    <AuthCard
      className="OnboardFacilityPage card"
      header="Onboard your facilities"
      headerText="You can add your facilities using your eClaimLink, Shafafiya, Riayati credentials"
    >
      <OnboardFacilityProvider>
        <OnboardFacilityContainer
          onSubmit={useCallback(() => {
            userOnboardingDispatch(initOnboardStep(user));
          }, [userOnboardingDispatch, user])}
        />
      </OnboardFacilityProvider>
    </AuthCard>
  );
};

export default OnboardFacilityPage;
