import React from 'react';
import { FieldProps } from 'formik';
import {
  Input as _Input,
  PasswordProps as _PasswordProps,
  TextAreaProps as _TextAreaProps,
  InputRef,
  TextAreaRef,
  InputProps as _InputProps,
} from 'custom-test-antd';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { makeField } from './makeField';

type InputProps = FieldProps & _InputProps
type PasswordProps = FieldProps & _PasswordProps
type TextAreaProps = FieldProps & _TextAreaProps

export { _InputProps as InputProps };
export { _PasswordProps as PasswordProps };
export { _TextAreaProps as TextAreaProps };

type InputFieldProps = FormikFieldProps & _InputProps
type PasswordFieldProps = FormikFieldProps & _PasswordProps
type TextAreaFieldProps = FormikFieldProps & _TextAreaProps

const InternalInput = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: InputProps,
    ref: React.Ref<InputRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_Input
        name={name}
        ref={ref}
        value={value}
        onChange={(event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }}
        {...restProps}
      />
    );
  },
);

const InternalInputField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: InputFieldProps,
    ref: React.Ref<InputRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <InternalInput ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

const Password = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: PasswordProps,
    ref: React.Ref<InputRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_Input.Password
        name={name}
        ref={ref}
        value={value}
        onChange={(event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }}
        {...restProps}
      />
    );
  },
);

const PasswordFiled = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: PasswordFieldProps,
    ref: React.Ref<InputRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <Password ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

const TextArea = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: TextAreaProps,
    ref: React.Ref<TextAreaRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_Input.TextArea
        name={name}
        ref={ref}
        value={value}
        onChange={(event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }}
        {...restProps}
      />
    );
  },
);

const TextAreaField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: TextAreaFieldProps,
    ref: React.Ref<TextAreaRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <TextArea ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

export interface InputType
  extends React.ForwardRefExoticComponent<FieldProps & _InputProps & React.RefAttributes<InputRef>> {
  Password: typeof Password;
  TextArea: typeof TextArea;
}

export const Input = InternalInput as InputType;
Input.Password = Password;
Input.TextArea = TextArea;

export interface InputFieldType
  extends React.ForwardRefExoticComponent<InputFieldProps & _InputProps & React.RefAttributes<InputRef>> {
  Password: typeof PasswordFiled;
  TextArea: typeof TextAreaField;
}

export const InputField = InternalInputField as InputFieldType;
InputField.Password = PasswordFiled;
InputField.TextArea = TextAreaField;

export const InputWrapper = makeField<_InputProps>(Input);
export const InputPasswordWrapper = makeField<_PasswordProps>(Input.Password);
export const TextAreaWrapper = makeField<_TextAreaProps>(Input.TextArea);
