import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Button } from 'custom-test-antd';

import './LicenseNew.pcss';

import { InputWrapper } from '../../../utils/form-helpers/antd-formik';
import { Card } from '../../eligibility/card';

interface Props {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any;
}

export const LicenseNew = ({ onSubmit }: Props) => (
  <Card>
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form
          className="LicenseNew-form"
        >
          <InputWrapper
            size="large"
            gray
            antFieldProps={{ label: 'Name' }}
            name="name"
            placeholder="Name"
          />
          <InputWrapper
            size="large"
            gray
            antFieldProps={{ label: 'License' }}
            name="license"
            placeholder="License"
          />
          <div className="LicenseNew-buttonsSection">
            <Button
              size="large"
              htmlType="submit"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
              type="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </Card>
);

export default LicenseNew;
