import React from 'react';
import moment from 'moment-timezone';
import { Space } from 'custom-test-antd';
import { BulkUpload } from 'components/common/bulk-upload';
import { Form, Formik, FormikHelpers } from 'formik';
import { SelectField, SwitchField, DatePickerField } from 'utils/form-helpers/antd-formik';

import './EligibilityBulkRequest.pcss';

interface Props {
  className?: string;
  onTemplateDownload: () => void;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any,
  onBeforeFileChange?: () => boolean,
}

const timeValues: string[] = [];
for (let i = 0; i < 24; i += 1) {
  timeValues.push(`${i < 10 ? `0${i}` : i}:00`);
}

const EligibilityBulkRequest: React.FC<Props> = ({
  className,
  onTemplateDownload,
  onSubmit,
  onBeforeFileChange,
}) => (
  <Formik
    initialValues={{
      schedule: false,
      date: undefined,
      time: undefined,
      file: undefined,
    }}
    onSubmit={onSubmit}
  >
    {({ setFieldValue, handleSubmit, isSubmitting, values }) => (
      <Form className={className}>
        <div className="EligibilityBulkRequest-header">
          <Space>
            <span>Schedule</span>
            <SwitchField
              name="schedule"
              onChange={(schedule) => {
                if (schedule) {
                  setFieldValue('date', moment().add(1, 'day'));
                  setFieldValue('time', timeValues[1]);
                } else {
                  setFieldValue('date', undefined);
                  setFieldValue('time', undefined);
                }
              }}
            />
          </Space>
          <Space>
            <DatePickerField allowClear={false} name="date" disabled={!values.schedule} />
            <SelectField
              disabled={!values.schedule}
              name="time"
              placeholder="Time"
              className="EligibilityBulkRequest-timeSelector"
              showSearch
              allowClear={false}
            >
              {
                timeValues.map((value) => (
                  <SelectField.Option key={value} value={value}>
                    {value}
                  </SelectField.Option>
                ))
              }
            </SelectField>
          </Space>
        </div>
        <BulkUpload
          accept=".csv"
          customRequest={({ file }) => {
            setFieldValue('file', file);
            handleSubmit();
          }}
          loading={isSubmitting}
          onTemplateDownload={onTemplateDownload}
          uploadText={(
            <>
              Upload your bulk eligibility file
              <br />
              by drag-and-drop or&nbsp;
              <span className="EligibilityBulkRequest-link">click to select</span>
            </>
          )}
          noteText={(
            <>
              You can upload a .csv with your patient ID’s and other requirement’s. After
              <br />
              uploading the file will process your patients. This may take some time.
            </>
          )}
          beforeUpload={onBeforeFileChange}
        />
      </Form>
    )}
  </Formik>
);

export default EligibilityBulkRequest;
