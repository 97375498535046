import { commonFetch, ApiError } from '../../apiHelper';

export class FinancingProposalApi {
  static searchProposal = async (providerCodes: string[]) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/proposal/search`, {
      method: 'POST',
      body: JSON.stringify({
        providerCodes,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static updateProposal = async (id: any, state: string) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/proposal/proposal/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        state,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('obj', '{"path":"templates/"}');
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/document/create`, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static downloadFile = async (name: string) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/document/download`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        path: 'templates/',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.blob();
    }
    throw new ApiError(response);
  };

  static deleteFile = async (name: string) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/document/delete`, {
      method: 'PATCH',
      body: JSON.stringify({
        name,
        path: 'templates/',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return true;
    }
    throw new ApiError(response);
  };
}

export default FinancingProposalApi;
