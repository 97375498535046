import moment from 'moment-timezone';
import { Button, Modal, Space } from 'custom-test-antd';
import produce from 'immer';
import React from 'react';

import './ErrorModal.pcss';

import { Error as CheckError, ErrorDetails } from '../error-details';

export const showErrorModal = (errors: CheckError[], uploadedFile: any, onSkipError: any) => {
  const onDownloadInvalidRequests = async () => {
    const fr = new FileReader();
    fr.onload = () => {
      if (!fr.result) {
        return;
      }
      if (typeof fr.result !== 'string') {
        return;
      }
      const textRows = fr.result.split('\n');
      const resultText = [textRows[0]];
      errors.forEach((error: CheckError) => {
        resultText.push(textRows[error.row]);
      });
      const blob = new Blob([resultText.join('\n')]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `invalid-requests-${moment().format('YYYY-DD-MM-HH-mm')}.csv`;
      link.click();
      link.remove();
    };
    fr.readAsText(uploadedFile);
  };

  const modal = Modal.error({
    icon: null,
    className: 'el-bu-ErrorModal',
    title: 'Error Details',
    width: 1200,
    content: (
      <div>
        <ErrorDetails
          errors={errors}
        />
        <Space className="el-bu-ErrorModal-buttons">
          <Button
            key="skipError"
            onClick={() => {
              modal.destroy();
              onSkipError();
            }}
          >
            Skip errors
          </Button>
          <Button
            key="downloadInvalidRequests"
            onClick={() => onDownloadInvalidRequests()}
          >
            Download invalid requests
          </Button>
          <Button
            key="downloadReport"
            onClick={() => {
              const reportErrors = produce(errors, (draft: CheckError[]) => {
                for (let i = 0; i < draft.length; i += 1) {
                  // eslint-disable-next-line no-param-reassign
                  draft[i] = { ...draft[i], row: draft[i].row + 1 };
                }
              });
              const csvData = new Blob([JSON.stringify(reportErrors, null, '\t')], { type: 'application/json' });
              const fileUrl = URL.createObjectURL(csvData);
              const link = document.createElement('a');
              link.href = fileUrl;
              link.download = `errors-${moment().format('YYYY-DD-MM-HH-mm')}.json`;
              link.click();
              link.remove();
            }}
          >
            Download report
          </Button>
          <Button key="close" type="primary" onClick={() => modal.destroy()}>Close</Button>
        </Space>
      </div>
    ),
  });
};

export default showErrorModal;
