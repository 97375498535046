export enum ReportType {
  Csv = 'csv',
  Xlsx = 'xlsx',
}

export interface Benefit {
  label: string;
  value: string;
  group?: string;
}

export interface MaxConsul {
  label: string;
  value: string;
}

export enum EligibilityErrorType {
  SessionAlreadyInUse = 'sessionAlreadyInUse',
}

export enum EligibilityWebSocketEvent {
  UpdateResponse = 'updateEligibilityResponse',
  CreateRequest = 'createEligibilityRequest',
  CreateBulkUploads = 'createEligibilityBulkUploads',
}

export enum EligibilityRequestStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
  PortalError = 'portalError',
}

export interface EligibilityResponseStatus {
  message?: string;
  status: boolean;
}

export interface EligibilityResponse {
  status?: string;
  category?: string;
  areaOfCoverage: string;
  benefits: Benefit[];
  cardNo: string;
  dhaId: string;
  copay: string | Array<{ label: string; value: string }>;
  dob: string;
  payerId: string;
  patientId: string;
  transactionId: string,

  effectiveDate: string;
  emiratesId: string;
  gender: string;
  limit: string;
  maxConsul: MaxConsul | Array<any>;
  network: string;
  patientName: string;
  policyHolder: string;
  policyName: string;
  policyNo: string;

  mobNo: string;
  eligibilityStatus?: EligibilityResponseStatus;
  documents?: string;
  message?: string; // not used
  remarks: string;
  payerName?: string;
  billing?: string;
  deductable?: string;

  approvalRequirements?: Array<{ label: string, value: string }>;
  encounterType: string;
  denial: string; // non used
  referral?: Array<{ label: string, value: string }>;
  error: string; // non used

  privilegeClass?: string
  referenceNumber?: string
  payerCode?: string
  clinicianName?: string
  gatekeeper: string

  dependency?: string;
  roomType?: string;
  marital?: string;
  dohPackageNo?: string;
  authorizationId?: string;
  screenshot?: string;
}

export type EligibilityRequestPayload = {
  patientId: string;
  patientIdType?: string
  treatmentBasis: string
  serviceCategory: string
  consultationCategory: string
  clinicianLicense: string
  patientName: string
  countryCode: string
  mobileNumber: string
};

export interface Eligibility {
  id: string,
  status: EligibilityRequestStatus;
  error?: {
    message: string,
    type: EligibilityErrorType,
  };
  userId: string;
  createdAt: string;
  organizationId: number

  branchId: string;
  portal: string;

  response: EligibilityResponse[];
  user: {
    id: string,
    name: string,
  };

  payload: EligibilityRequestPayload;
}

export type EligibilityRequestDto = Omit<Eligibility,
  'response' | 'id' | 'status' | 'error' | 'createdAt' | 'userId' | 'organizationId' | 'user'>;

export interface EligibilityListItem {
  id: string;
  status: EligibilityRequestStatus;
  portal: string;

  payload: {
    patientId: string;
  };
  createdAt: string;

  response: EligibilityResponse[];
  user: {
    id: string;
    name: string;
  };
  error?: {
    message: string,
    type: EligibilityErrorType,
  };
}

export interface PortalLogout {
  message: string;
}

export interface UserUsageDashboardItem {
  userName: string;
  error: number;
  portalError: number;
  pending: number;
  success: number;
  total: number;
  axa: number;
  daman: number;
  nas: number;
  neuron: number;
  nextcare: number;
  almadallah: number;
  oman: number;
  metlife: number;
  msh: number;
  mednet: number;
  abnic: number;
  adnic: number;
  ngi: number;
  inayah: number;
}
