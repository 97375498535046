export const publicRoutes = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  verifyIntention: '/sign-up/verify/:verificationId',
  forgotPassword: '/forgot-password',
  updatePassword: 'auth/password-update/change-password/:token',
  registrationCompleted: '/registration-completed',
  passwordUpdated: '/password-updated',
  emailSent: '/email-sent',
  confirmEmailSent: '/confirm-email-sent',
};

export const privateRoutes = {
  home: '/',

  signUpOnboardFacility: '/sign-up/onboard-facility',
  signUpScheduleCall: '/sign-up/schedule-call',

  eligibilityList: '/eligibility',
  eligibilityResponse: '/eligibility/response/:eligibilityId',
  eligibilityRequest: '/eligibility/request',
  eligibilityBulkRequest: '/eligibility/bulk-request',

  practitionerLicensesList: '/practitioner-licenses',
  practitionerLicensesEdit: '/practitioner-licenses/:licenseId',
  practitionerLicensesNew: '/practitioner-licenses/new',
  practitionerLicensesBulkUpload: '/practitioner-licenses/bulk',

  eligibilityDefaultList: '/eligibility-defaults',
  eligibilityDefaultNew: '/eligibility-defaults/new',
  eligibilityDefaultEdit: '/eligibility-defaults/:id',

  eligibilityDashboard: '/eligibility-dashboard',

  adminClient: '/admin/clients',
  admin: '/admin',

  credentialList: '/credentials',
  credentialsForm: '/credentials/:credentialsId',
  credentialsNew: '/credentials/new',
  credentialsBulk: '/credentials/bulk',

  accountSettings: '/account',
  accountName: '/account/name',
  accountPhone: '/account/phone',
  accountEmail: '/account/email',
  accountPassword: '/account/password',

  capital: '/capital',
  capitalProposal: '/capital/proposal',
  capitalTransactions: '/capital/transactions',
  capitalGetStarted: '/capital/proposal/onboard-customer/:customerId/get-started',
  capitalProposalTutorial: '/capital/proposal/tutorial',
  capitalProposalSteps: '/capital/proposal/onboard-customer/:customerId/steps',
};
