import React from 'react';
import { Button, Dropdown, Popover, Space, Table, Tag } from 'custom-test-antd';
import { DownOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment-timezone';
import isEqual from 'lodash.isequal';
import type { PaginationProps } from 'antd';

import './EligibilityList.pcss';
import EligibilityListFilter from './filter/EligibilityListFilter';
import {
  EligibilityListItem,
  EligibilityRequestStatus,
  EligibilityWebSocketEvent,
  ReportType,
} from '../../../api/eligibility/eligibilityTypes';
import { EligibilityListContextState, initPagination, OrganizationUser } from './context/eligibilityListReducer';
import { BranchData } from '../../../api/branch/branchTypes';
import container, { Service } from '../../../container';
import { IWebSocketClientService } from '../../../services';
import { EligibilityResponsePopupContainer } from './eligibility-response-popup/EligibilityResponsePopupContainer';
import { ErrorMessageContainer } from '../eligibility-response/error-message';
import { Card } from '../card';

interface Props {
  onFetch: Function,
  onReportDownload: (reportType: ReportType) => Promise<void>,
  items: EligibilityListItem[],
  pagination: EligibilityListContextState['pagination'],
  onSetPagination: (pagination: EligibilityListContextState['pagination']) => void,
  filter: EligibilityListContextState['filter'],
  onSetFilter: (filter: EligibilityListContextState['filter']) => void,
  branches: BranchData[],
  users: OrganizationUser[],
  onItemUpdate: (item: EligibilityListItem) => void,
  onFetchBranches: () => void,
  onFetchUsers: () => void,
  onOpenEligibility: (id: string) => void,
  loading: boolean,
  showResponsePopup: boolean,
}

interface State {
  reportDownloading: boolean,
}

const showTotal: PaginationProps['showTotal'] = (total) => `TOTAL AMOUNT OF ENTRIES: ${total}`;

export class EligibilityList extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reportDownloading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onFilterApply = this.onFilterApply.bind(this);
    this.getList = this.getList.bind(this);
    this.updateEligibilityResponse = this.updateEligibilityResponse.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.getColumnsConfig = this.getColumnsConfig.bind(this);
  }

  componentDidMount() {
    const { onFetch, onFetchBranches, onFetchUsers } = this.props;
    const client = container.resolve(Service.websocketClient) as IWebSocketClientService;
    client.on(EligibilityWebSocketEvent.UpdateResponse, this.updateEligibilityResponse);
    client.on(EligibilityWebSocketEvent.CreateRequest, this.getList);
    client.on(EligibilityWebSocketEvent.CreateBulkUploads, this.getList);
    onFetch();
    onFetchBranches();
    onFetchUsers();
  }

  componentWillUnmount() {
    const client = container.resolve(Service.websocketClient) as IWebSocketClientService;
    client.off(EligibilityWebSocketEvent.UpdateResponse, this.updateEligibilityResponse);
    client.off(EligibilityWebSocketEvent.CreateRequest, this.getList);
    client.off(EligibilityWebSocketEvent.CreateBulkUploads, this.getList);
  }

  handleTableChange(pagination: TablePaginationConfig) {
    const { onSetPagination, onFetch } = this.props;
    onSetPagination(pagination as EligibilityListContextState['pagination']);
    onFetch();
  }

  onFilterApply(newFilter: EligibilityListContextState['filter']) {
    const { filter, onSetFilter, onSetPagination, onFetch } = this.props;
    if (!isEqual(newFilter, filter)) {
      onSetFilter(newFilter);
      onSetPagination(initPagination);
      onFetch();
    }
  }

  getColumnsConfig(): ColumnsType<EligibilityListItem> {
    return [
      {
        title: 'Information',
        dataIndex: ['response', 0, 'patientName'],
        key: 'name',
        render: (name, row) => {
          const { onOpenEligibility, showResponsePopup } = this.props;
          const nameRow = (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <Button className="EligibilityList-patientName" type="link" onClick={() => onOpenEligibility(row.id)}>
              {row?.response?.length === 1 ? name : 'View details'}
            </Button>
          );
          let firstRow;
          if (showResponsePopup && row?.response?.length === 1) {
            firstRow = (
              <EligibilityResponsePopupContainer
                eligibilityId={row.id}
              >
                {nameRow}
              </EligibilityResponsePopupContainer>
            );
          } else if (
            showResponsePopup
            && (row.status === EligibilityRequestStatus.Error || row.status === EligibilityRequestStatus.PortalError)
          ) {
            firstRow = (
              <Popover
                placement="right"
                content={(
                  <ErrorMessageContainer eligibility={row} />
                )}
              >
                {nameRow}
              </Popover>
            );
          } else {
            firstRow = nameRow;
          }
          return (
            <>
              {firstRow}
              {
                row?.payload?.patientId
                && (
                  <div className="EligibilityList-subLine">
                    <span className="EligibilityList-subLineLabel">Search:&nbsp;</span>
                    {row?.payload?.patientId}
                  </div>
                )
              }
            </>
          );
        },
      },
      {
        title: 'Member ID',
        dataIndex: ['response', 0, 'cardNo'],
        render: (memberId, row) => (
          <div>
            <span className="EligibilityList-firstLine">
              {row?.response?.length === 1 && memberId ? memberId : ' '}
            </span>
            {
              row?.response?.length === 1 && row?.response?.[0]?.dhaId
              && (
                <div className="EligibilityList-subLine">
                  <span className="EligibilityList-subLineLabel">DHA:&nbsp;</span>
                  {row?.response?.[0]?.dhaId}
                </div>
              )
            }
          </div>
        ),
      },
      {
        title: 'Insurance',
        dataIndex: 'portal',
        key: 'portal',
        render: (portal, row) => (
          <div>
            {portal}
            {
              row?.response?.length === 1 && row?.response?.[0]?.payerName
              && (
                <div className="EligibilityList-subLine">
                  <span className="EligibilityList-subLineLabel">Payer:&nbsp;</span>
                  {row?.response?.[0]?.payerName}
                </div>
              )
            }
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, row) => {
          let color: string | undefined;
          let icon: React.ReactNode | undefined;
          let text: string | undefined;
          let className: string | undefined;

          switch (status) {
            case EligibilityRequestStatus.Pending: {
              className = 'EligibilityList-pendingTag';
              icon = <LoadingOutlined />;
              text = 'Pending';
              break;
            }
            case EligibilityRequestStatus.Error: {
              color = 'error';
              text = 'Error';
              break;
            }
            case EligibilityRequestStatus.PortalError: {
              color = 'processing';
              text = 'Portal Notice';
              break;
            }
            case EligibilityRequestStatus.Success: {
              const eStatus = row?.response?.[0]?.eligibilityStatus?.status;
              if (row?.response?.length > 1) {
                color = 'warning';
                text = 'Ambiguity';
              } else if (eStatus) {
                color = 'success';
                text = 'Eligible';
              } else {
                color = 'error';
                text = 'Ineligible';
              }
              break;
            }
            default: {
              break;
            }
          }
          return (
            <div>
              <Tag size="large" icon={icon} color={color} borderless className={className}>{text}</Tag>
              {
                row?.response?.length === 1 && row?.response?.[0]?.effectiveDate
                && (
                  <div className="EligibilityList-subLine">
                    {row?.response?.[0]?.effectiveDate}
                  </div>
                )
              }
            </div>
          );
        },
      },
      {
        title: 'Entry',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date: string | undefined, row) => (
          <>
            {date ? moment(date).format('DD MMM YYYY HH:mm') : ''}
            {
              row?.user?.name
              && (
                <div className="EligibilityList-subLine">
                  {row?.user?.name}
                </div>
              )
            }
          </>
        ),
      },
      {
        title: '',
        width: 120,
        render: (_, row) => (
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => {
              const { onOpenEligibility } = this.props;
              onOpenEligibility(row.id);
            }}
          >
            View
          </Button>
        ),
      },
    ];
  }

  getList() {
    const { onFetch } = this.props;
    onFetch();
  }

  async downloadReport(reportType: ReportType) {
    const { onReportDownload } = this.props;
    this.setState({ reportDownloading: true });
    try {
      await onReportDownload(reportType);
    } finally {
      this.setState({ reportDownloading: false });
    }
  }

  updateEligibilityResponse(item: EligibilityListItem) {
    const { onItemUpdate } = this.props;
    onItemUpdate(item);
  }

  render() {
    const { items, pagination, filter, branches, users, onOpenEligibility, loading } = this.props;
    const { reportDownloading } = this.state;
    return (
      <Card className="EligibilityList">
        <div className="EligibilityList-actionPanel">
          <EligibilityListFilter
            className="EligibilityList-filter"
            filter={filter}
            onApply={this.onFilterApply}
            branches={branches}
            users={users}
          />
          <Dropdown
            menu={{
              items: [
                { label: 'CSV', key: ReportType.Csv },
                { label: 'XLSX', key: ReportType.Xlsx },
              ],
              onClick: ({ key }) => this.downloadReport(key as ReportType),
            }}
          >
            <Button loading={reportDownloading}>
              <Space>
                Download Report
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <Table
          loading={loading}
          className="EligibilityList-table"
          rowKey="id"
          rowClassName="EligibilityList-tableRow"
          columns={this.getColumnsConfig()}
          dataSource={items}
          pagination={{ ...pagination, showTotal }}
          onChange={this.handleTableChange}
          onRow={(record) => ({
            onDoubleClick: () => {
              onOpenEligibility(record.id);
            },
          })}
          rightAngleTopCorners
        />
      </Card>
    );
  }
}

export default EligibilityList;
