import { createSelector } from 'reselect';
import { OnboardFacilityContextState } from './onboardFacilityReducer';

export const facilitiesSelector = (state: OnboardFacilityContextState) => state.branches;
export const portalSelector = (state: OnboardFacilityContextState) => state.portal;
export const submittingSelector = (state: OnboardFacilityContextState) => state.submittingAllFacilities;
export const errorSelector = (state: OnboardFacilityContextState) => state.error;

export const currentCheckSelector = createSelector(
  (state: OnboardFacilityContextState) => state.currentCheck,
  facilitiesSelector,
  (currentCheck, facilities) => {
    if (currentCheck) {
      return facilities.find((facility) => facility.id === currentCheck.id);
    }
    return undefined;
  },
);
