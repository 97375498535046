import React from 'react';
import AcceptProposalSteps from './AcceptProposalSteps';
import { useProposalState } from '../proposal/context/context/proposalContext';
import { proposalSelector } from '../proposal/context/context/proposalSelectors';
import { ProposalState } from '../../../api/financing/proposal/financingProposalTypes';

export const calcCurrentStep = (state: string) => {
  switch (state) {
    case ProposalState.KYCDocumentUploading:
    case ProposalState.KYCDocumentUploaded:
      return 0;
    case ProposalState.EscrowAgreementGenerated:
      return 1;
    case ProposalState.AuthoriseDocumentsPrepared:
    case ProposalState.AuthoriseDocumentsUploading:
    case ProposalState.AuthoriseDocumentsUploaded:
      return 2;
    case ProposalState.WireTransferPrepared:
    case ProposalState.Completed:
      return 3;
    default:
      return -1;
  }
};

const AcceptProposalStepsContainer = () => {
  const state = useProposalState();
  const proposal = proposalSelector(state);
  return (
    <AcceptProposalSteps
      currentStep={calcCurrentStep(proposal?.state)}
    />
  );
};

export default AcceptProposalStepsContainer;
