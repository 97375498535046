import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, Table, Tag, Radio, Popover, Alert } from 'custom-test-antd';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { FacilitiesPortal } from 'api/branch/branchTypes';
import { InputPasswordWrapper, InputWrapper } from 'utils/form-helpers/antd-formik';

import './OnboardFacility.pcss';
import CheckModal from '../check-modal/CheckModal';
import { Facility, FacilityCheckStatus } from '../context/onboardFacilityReducer';

interface Props {
  isSubmitting: boolean
  portal: FacilitiesPortal;
  onPortalChange: (portal: FacilitiesPortal) => void;
  onSubmit: () => any;
  onAddBranch: (values: any, formikHelpers: FormikHelpers<any>) => Promise<void>;
  onBranchRemove: (branchId: string) => any;
  facilities: Facility[],
  currentCheck?: Facility,
  onModalClose: () => void;
  onModalOk: () => void;
  error?: string;
  onErrorClose: () => void;
  className?: string;
  createBranchOnSuccess: boolean;
}

const portalNames = {
  [FacilitiesPortal.eClaimLink]: 'eClaimLink',
  [FacilitiesPortal.Shafafiya]: 'Shafafiya',
  [FacilitiesPortal.Riayati]: 'Riayati',
};

export const OnboardFacility = ({
  onSubmit,
  facilities,
  portal,
  onPortalChange,
  onAddBranch,
  isSubmitting,
  onBranchRemove,
  currentCheck,
  onModalClose,
  onModalOk,
  error,
  onErrorClose,
  createBranchOnSuccess,
  className = '',
}: Props) => (
  <div className={className}>
    <Radio.Group
      value={portal}
      size="large"
      name="portal"
      buttonStyle="graySolid"
      onChange={(e) => onPortalChange(e.target.value)}
    >
      <Radio.Button value={FacilitiesPortal.eClaimLink}>
        {portalNames[FacilitiesPortal.eClaimLink]}
      </Radio.Button>
      <Radio.Button value={FacilitiesPortal.Shafafiya}>
        {portalNames[FacilitiesPortal.Shafafiya]}
      </Radio.Button>
      <Radio.Button value={FacilitiesPortal.Riayati}>
        {portalNames[FacilitiesPortal.Riayati]}
      </Radio.Button>
    </Radio.Group>
    <div>
      <Table
        rowKey="id"
        className="OnboardFacility-facilityTable"
        pagination={false}
        columns={[{
          title: 'PORTAL',
          dataIndex: 'portal',
          width: 120,
          render: (p: FacilitiesPortal) => (
            portalNames[p]
          ),
        }, {
          title: 'FACILITY NAME',
          dataIndex: 'name',
          width: 240,
        }, {
          title: 'USERNAME',
          dataIndex: 'login',
          width: 160,
        }, {
          title: 'STATUS',
          dataIndex: 'status',
          width: 170,
          align: 'center',
          render: (status: FacilityCheckStatus, row) => {
            let color: string | undefined;
            let text: string | undefined;
            let icon: React.ReactNode | undefined;
            if (status === FacilityCheckStatus.Checking) {
              color = 'processing';
              text = 'Checking';
              icon = <LoadingOutlined />;
            } else if (status === FacilityCheckStatus.Valid) {
              color = 'success';
              text = 'Valid';
            } else if (status === FacilityCheckStatus.Error) {
              return (
                <Popover
                  content={row.error}
                >
                  <Tag size="large" borderless icon={<ExclamationCircleFilled />} color="error">Error</Tag>
                </Popover>
              );
            }
            return <Tag size="large" borderless icon={icon} color={color}>{text}</Tag>;
          },
        }, {
          title: 'FACILITY ID',
          dataIndex: 'branchId',
          width: 210,
        }, {
          title: '',
          dataIndex: 'id',
          render: (id, row) => {
            if (createBranchOnSuccess && row.status === FacilityCheckStatus.Valid) {
              return null;
            }
            return (
              <Button
                className="OnboardFacility-deleteButton"
                danger
                type="text"
                onClick={() => {
                  onBranchRemove(id);
                }}
              >
                Delete
              </Button>
            );
          },
        }]}
        dataSource={facilities}
      />

      <Formik
        initialValues={{}}
        onSubmit={onAddBranch}
      >
        {({ handleSubmit }) => (
          <Form layout="inline" className="OnboardFacility-addFacilityForm">
            <InputWrapper
              antFieldProps={{ className: 'OnboardFacility-formField' }}
              placeholder="Facility Name"
              name="name"
              size="large"
              gray
            />
            <InputWrapper
              antFieldProps={{ className: 'OnboardFacility-formField' }}
              autoComplete="new-password"
              placeholder={portal === FacilitiesPortal.Riayati ? 'License' : 'Username'}
              name="login"
              size="large"
              gray
            />
            <InputPasswordWrapper
              antFieldProps={{ className: 'OnboardFacility-formField' }}
              autoComplete="new-password"
              placeholder={portal === FacilitiesPortal.Riayati ? 'API Token' : 'Password'}
              name="password"
              size="large"
              gray
            />
            <InputWrapper
              antFieldProps={{ className: 'OnboardFacility-formField' }}
              placeholder="Facility ID"
              name="branchId"
              size="large"
              gray
            />
            <Button
              size="large"
              type="primary"
              onClick={() => handleSubmit()}
            >
              {createBranchOnSuccess ? 'Add' : 'Check'}
            </Button>
            {
              currentCheck
              && (
                <CheckModal
                  status={currentCheck.status}
                  onModalClose={onModalClose}
                  onOk={onModalOk}
                  message={currentCheck.error}
                />
              )
            }
          </Form>
        )}
      </Formik>
      {
        error
        && (
          <Alert
            className="OnboardFacility-errorAlert"
            type="error"
            message={error}
            closable
            onClose={onErrorClose}
          />
        )
      }
    </div>
    {
      !createBranchOnSuccess
      && (
        <div className="OnboardFacility-submitSection">
          <div>I have added all facilities and I’ll like to move forward.</div>
          <Button
            htmlType="submit"
            loading={isSubmitting}
            type="link"
            onClick={() => onSubmit()}
            className="OnboardFacility-submitButton"
          >
            Save & Next Step
          </Button>
        </div>
      )
    }
  </div>
);

export default OnboardFacility;
