import React from 'react';
import { Table } from 'custom-test-antd';
import { ColumnsType } from 'antd/lib/table';

export interface Error {
  row: number,
  error: string | Array<{
    path: string,
    message: string
  }>

}

interface Props {
  errors: Error[];
}

const columns: ColumnsType<Error> = [{
  title: 'Row number',
  dataIndex: 'row',
  render: (row) => row + 1,
  width: 120,
}, {
  title: 'Error',
  dataIndex: 'error',
  render: (error: Error['error']) => {
    if (typeof error === 'string') {
      return error;
    }
    return error.map((e, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index}>
        {`${e.path}: ${e.message}`}
      </div>
    ));
  },
}];

export const ErrorDetails = ({ errors }: Props) => (
  <Table
    rowKey="row"
    columns={columns}
    dataSource={errors}
  />
);
export default ErrorDetails;
