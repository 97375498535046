import { Dispatch } from 'react';

import { BranchApi, EligibilityApi, OrganizationApi } from 'api';
import {
  EligibilityListContextState, setEligibilityListLoading, storeBranches,
  storeListItems, storePagination, storeUsers,
} from './eligibilityListReducer';
import { filterSelector, paginationSelector } from './eligibilityListSelectors';
import { ReportType } from '../../../../api/eligibility/eligibilityTypes';

export const getList = async (dispatch: Dispatch<any>, getState: () => EligibilityListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const pagination = paginationSelector(state);
  dispatch(setEligibilityListLoading(true));
  try {
    const result = await EligibilityApi.getList(filter, pagination);
    dispatch(storePagination({
      ...pagination,
      total: result.count,
    }));
    dispatch(storeListItems(result.data));
  } finally {
    dispatch(setEligibilityListLoading(false));
  }
};

export const downloadReport = (reportType: ReportType) => (
  async (dispatch: Dispatch<any>, getState: () => EligibilityListContextState) => {
    const state = getState();
    const filter = filterSelector(state);
    return EligibilityApi.getReport(filter, reportType);
  }
);

export const fetchUserBranches = async (dispatch: Dispatch<any>) => {
  const result = await BranchApi.getUserBranches();
  dispatch(storeBranches(result));
};

export const fetchOrganizationUsers = async (dispatch: Dispatch<any>) => {
  const result = await OrganizationApi.getOrganizationUsers();
  dispatch(storeUsers(result.data));
};
