import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import userOnboardingReducer, { UserOnboardingContextState, initialState, InitState } from './userOnboardingReducer';

const useThunkReducer = createReducer<any, UserOnboardingContextState>(thunk as any);

const UserOnboardingStateContext = React.createContext<UserOnboardingContextState>(initialState());
export const UserOnboardingDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode;
  initState: InitState;
}

const UserOnboardingProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(userOnboardingReducer, initialState(initState));

  return (
    <UserOnboardingStateContext.Provider value={state}>
      <UserOnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </UserOnboardingDispatchContext.Provider>
    </UserOnboardingStateContext.Provider>
  );
};

function useUserOnboardingState() {
  const context = React.useContext(UserOnboardingStateContext);
  if (context === undefined) {
    throw new Error('useUserOnboardingState must be used within a UserOnboardingStateContext');
  }
  return context;
}

function useUserOnboardingDispatch() {
  const context = React.useContext(UserOnboardingDispatchContext);
  if (context === undefined) {
    throw new Error('useUserOnboardingDispatch must be used within a UserOnboardingDispatchContext');
  }
  return context;
}

export {
  UserOnboardingProvider,
  useUserOnboardingState,
  useUserOnboardingDispatch,
};
