import React from 'react';
import { CascaderProps, Button, Form } from 'custom-test-antd';
import type { DefaultOptionType } from 'antd/es/cascader';
import { CascaderWrapper, SelectWrapper } from 'utils/form-helpers/antd-formik';
import { BranchApi } from 'api';
import { Formik, FormikHelpers } from 'formik';
import './LoginAsUser.pcss';

export interface Props {
  options: CascaderProps<any>['options'];
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any,
  branches: Awaited<ReturnType<typeof BranchApi.getAllBranch>>;
  onBranchChange: (branchId: number) => void;
}

const filter = (inputValue: string, path: DefaultOptionType[]) => (
  path.some(
    (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  )
);

export const LoginAsUser = ({ options, onSubmit, branches, onBranchChange }: Props) => (
  <Formik
    initialValues={{}}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, isSubmitting }) => (
      <div className="LoginAsUser">
        <Form layout="vertical">
          <SelectWrapper
            name="branchId"
            options={branches.map((branch) => ({
              value: branch.id,
              label: `[${branch.branchId}] ${branch.name}`,
            }))}
            gray
            placeholder="Select branch"
            allowClear
            optionFilterProp="label"
            onChange={onBranchChange}
            showSearch
          />
          <CascaderWrapper
            name="user"
            options={options}
            gray
            placeholder="Please select user"
            showSearch={{ filter }}
          />
          <div className="LoginAsUser-buttonSection">
            <Button
              onClick={() => handleSubmit()}
              type="primary"
              loading={isSubmitting}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    )}
  </Formik>
);

export default LoginAsUser;
