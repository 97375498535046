import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import { useAuthState } from 'components/authentication/context/authContext';
import { isEligibilityPaidSelector } from 'components/authentication/context/authSelectors';
import EligibilityList from './EligibilityList';
import { useEligibilityListDispatch, useEligibilityListState } from './context/eligibilityListContext';
import {
  branchesSelector,
  eligibilityListLoadingSelector,
  filterSelector,
  listItemSelector,
  paginationSelector,
  usersSelector,
} from './context/eligibilityListSelectors';
import { downloadReport, fetchOrganizationUsers, fetchUserBranches, getList } from './context/eligibilityListThunks';
import {
  EligibilityListContextState,
  storeFilter,
  storePagination,
  updateListItem,
} from './context/eligibilityListReducer';
import { privateRoutes } from '../../application/routes/routesConstants';
import { ReportType } from '../../../api/eligibility/eligibilityTypes';

export const EligibilityListContainer = () => {
  const state = useEligibilityListState();
  const dispatch = useEligibilityListDispatch();
  const navigate = useNavigate();
  const authState = useAuthState();
  const isEligibilityPaid = !!isEligibilityPaidSelector(authState);

  return (
    <EligibilityList
      loading={eligibilityListLoadingSelector(state)}
      branches={branchesSelector(state)}
      users={usersSelector(state)}
      pagination={paginationSelector(state)}
      filter={filterSelector(state)}
      items={listItemSelector(state)}
      onFetch={useCallback(() => dispatch(getList), [dispatch])}
      onItemUpdate={useCallback((item) => dispatch(updateListItem(item)), [dispatch])}
      onSetPagination={useCallback((pagination: EligibilityListContextState['pagination']) => {
        dispatch(storePagination(pagination));
      }, [dispatch])}
      onSetFilter={useCallback((filter: EligibilityListContextState['filter']) => {
        dispatch(storeFilter(filter));
      }, [dispatch])}
      onFetchBranches={useCallback(() => {
        dispatch(fetchUserBranches);
      }, [dispatch])}
      onFetchUsers={useCallback(() => {
        dispatch(fetchOrganizationUsers);
      }, [dispatch])}
      onOpenEligibility={useCallback((id) => {
        navigate(privateRoutes.eligibilityResponse.replace(':eligibilityId', id));
      }, [navigate])}
      onReportDownload={useCallback(async (reportType: ReportType) => {
        const data: any = await dispatch(downloadReport(reportType));
        let reportData;
        let extension;
        if (reportType === ReportType.Csv) {
          reportData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
          extension = 'csv';
        } else {
          reportData = new Blob([data], { type: 'text/xlsx;charset=utf-8;' });
          extension = 'xlsx';
        }
        const fileUrl = URL.createObjectURL(reportData);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `eligibility-report-${moment().format('YYYY-DD-MM-HH-mm')}.${extension}`;
        link.click();
        link.remove();
      }, [dispatch])}
      showResponsePopup={isEligibilityPaid}
    />
  );
};

export default EligibilityListContainer;
