import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthDispatch } from '../context/authContext';
import { IntentionCheck, IntentionStatus } from '../../../api/authentication/authenticationTypes';
import { authenticate } from '../context/authThunks';
import RegistrationSuccess from './RegistrationSuccess';
import RegistrationError from './RegistrationError';
import { privateRoutes } from '../../application/routes/routesConstants';

interface LocationState extends IntentionCheck {
  isFromVerificationLink?: boolean;
}

export const RegistrationCompletedContainer = () => {
  const { isFromVerificationLink, status, error } = useLocation().state as LocationState;
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  if (status === IntentionStatus.Completed) {
    return (
      <RegistrationSuccess
        showContinueOnPrevSessionMsg={isFromVerificationLink}
        onContinue={() => {
          dispatch(authenticate);
          navigate(privateRoutes.home, { replace: true });
        }}
      />
    );
  }
  return (
    <RegistrationError
      error={error}
    />
  );
};

export default RegistrationCompletedContainer;
