import * as Yup from 'yup';
import produce from 'immer';
import 'utils/form-helpers/yup-emirates-id';
import {
  AxaUserType,
  axaUserType,
} from './types';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';

const patientIdDelimiter = '|';

const initialValue = {
  patientIdType: AxaUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
};

export class AxaRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    const config: PortalConfig = {
      patientIdType: axaUserType,
      patientId: undefined,
      policyNo: false,
      membershipCode: false,
      emiratesId: true,
    };

    const { patientIdType } = values;
    switch (patientIdType) {
      case AxaUserType.EmiratesId:
      case undefined: {
        break;
      }
      case AxaUserType.PolicyNoAndMembershipCode: {
        config.patientId = undefined;
        config.emiratesId = false;
        config.policyNo = true;
        config.membershipCode = true;
        break;
      }
      default: {
        config.emiratesId = false;
        config.patientId = calcPatientId(patientIdType, axaUserType);
      }
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== AxaUserType.EmiratesId && patientIdType !== AxaUserType.PolicyNoAndMembershipCode
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === AxaUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
      policyNo: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === AxaUserType.PolicyNoAndMembershipCode
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      membershipCode: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === AxaUserType.PolicyNoAndMembershipCode
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
    });
  }

  getValuesForSubmission(values: any, enableValuesValidation: boolean = true) {
    const parentValues = super.getValuesForSubmission(values, enableValuesValidation);
    return produce(parentValues, (draft: any) => {
      if (values.patientIdType === AxaUserType.PolicyNoAndMembershipCode) {
        // eslint-disable-next-line no-param-reassign
        draft.patientId = `${values.policyNo}${patientIdDelimiter}${values.membershipCode}`;
        // eslint-disable-next-line no-param-reassign
        delete draft.policyNo;
        // eslint-disable-next-line no-param-reassign
        delete draft.membershipCode;
      }
      return draft;
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return AxaUserType.EmiratesId;
  }

  getValuesForForm(values: any) {
    const valuesForForm = super.getValuesForForm(values);

    if (valuesForForm.patientIdType === AxaUserType.PolicyNoAndMembershipCode && valuesForForm.patientId) {
      ([valuesForForm.policyNo, valuesForForm.membershipCode] = valuesForForm.patientId.split(patientIdDelimiter));

      delete valuesForForm.patientId;
    }

    return valuesForForm;
  }
}

export default AxaRequest;
