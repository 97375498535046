import React from 'react';
import { Avatar } from 'custom-test-antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import './PatientInfo.pcss';

import { EligibilityResponse } from '../../../../api/eligibility/eligibilityTypes';

interface Props {
  response: EligibilityResponse,
}

export const PatientInfo: React.FC<Props> = ({ response }) => {
  let dateString = '';
  if (response?.dob) {
    const dateOfBirth = moment(response.dob);
    const age = moment().diff(dateOfBirth, 'years');
    dateString = `${dateOfBirth.format('DD MMM YYYY')} • ${age}`;
  }
  //
  return (
    <div className="PatientInfo">
      <div className="PatientInfo-firstSection">
        <Avatar size={98} shape="square" icon={<UserOutlined />} />
        <div className="PatientInfo-infoSection">
          <div className="PatientInfo-patientName">{response?.patientName}</div>
          <div className="PatientInfo-patientInto">
            {`
            ${response?.gender ?? ''}
            ${response?.gender && response?.marital ? ' / ' : ''}
            ${response?.marital ? `${response.marital}` : ''}
            `}
          </div>
          <div className="PatientInfo-birthDate">{dateString}</div>
        </div>
        <div className="PatientInfo-infoSection">
          <div>
            <div className="PatientInfo-label">
              {`CONTACT${response?.authorizationId ? ' / AUTHORIZATION ID' : ''}`}
            </div>
            <div className="PatientInfo-value">{response?.mobNo}</div>
            {
              response?.authorizationId
              && <div className="PatientInfo-value">{response?.authorizationId}</div>
            }
          </div>
        </div>
      </div>
      <div className="PatientInfo-secondSection">
        <div className="PatientInfo-infoSection member">
          <div className="PatientInfo-label">MEMBER / EMIRATES ID</div>
          <div className="PatientInfo-value">{response?.cardNo}</div>
          <div className="PatientInfo-value">{response?.emiratesId}</div>
        </div>
        <div className="PatientInfo-infoSection">
          <div className="PatientInfo-label">DHA MEMBER / TRANSACTION ID</div>
          <div className="PatientInfo-value">{response?.dhaId}</div>
          <div className="PatientInfo-value">{response?.transactionId}</div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
