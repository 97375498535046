import React from 'react';
import { FormikHelpers, Formik } from 'formik';
import { Spin, Form, Button } from 'custom-test-antd';
import { InputWrapper, InputPasswordWrapper, SelectWrapper } from 'utils/form-helpers/antd-formik';
import { Credentials } from 'api/credentials/credentialsTypes';

import './CredentialsForm.pcss';

import { Card } from '../../eligibility/card';
import { BranchData } from '../../../api/branch/branchTypes';
import { InsurancePortal } from '../../../constants/dropdownOptions';
import { insurancePortalsCredentials } from '../constants/credentials';

interface Props {
  branches: BranchData[],
  onFetchBranches: () => void,
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any,
  credentials: Credentials
  onFetchCredentials: () => any,
  credentialsFetching: boolean,
}

export class CredentialsForm extends React.PureComponent<Props> {
  componentDidMount(): void {
    const { onFetchBranches, onFetchCredentials } = this.props;
    onFetchBranches();
    onFetchCredentials();
  }

  render() {
    const { branches, onSubmit, credentials, credentialsFetching } = this.props;

    return (
      <Card>
        <Formik
          initialValues={credentials}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit, values, resetForm, initialValues, isSubmitting }) => (
            <Spin spinning={isSubmitting || credentialsFetching}>
              <Form
                className="CredentialsNew-form"
              >
                <SelectWrapper
                  size="large"
                  gray
                  antFieldProps={{ label: 'Branch' }}
                  name="branchId"
                  placeholder="Select branch"
                  dropdownMatchSelectWidth={false}
                  disabled
                >
                  {
                    branches.map((branch) => (
                      <SelectWrapper.Option
                        key={branch.identifier}
                        value={branch.identifier}
                      >
                        {`[${branch.identifier}] ${branch.name}`}
                      </SelectWrapper.Option>
                    ))
                  }
                </SelectWrapper>
                <SelectWrapper
                  size="large"
                  gray
                  antFieldProps={{ label: 'Portal' }}
                  name="portal"
                  placeholder="Select portal"
                  disabled
                >
                  {
                    insurancePortalsCredentials.map((portal) => (
                      <SelectWrapper.Option
                        key={portal.value}
                        value={portal.value}
                        onChange={(portalValue: any) => {
                          resetForm({
                            values: {
                              ...initialValues,
                              portal: portalValue,
                              branchId: values.branchId,
                            },
                          });
                        }}
                      >
                        {portal.label}
                      </SelectWrapper.Option>
                    ))
                  }
                </SelectWrapper>
                <InputWrapper
                  size="large"
                  gray
                  antFieldProps={{ label: 'Username' }}
                  name="username"
                  placeholder="Username"
                />
                <InputPasswordWrapper
                  size="large"
                  gray
                  antFieldProps={{ label: 'Password' }}
                  name="password"
                  placeholder="Password"
                />
                {
                  values.portal === InsurancePortal.Oman
                  && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'License' }}
                      name="license"
                      placeholder="License"
                    />
                  )
                }
                <div className="CredentialsNew-buttonsSection">
                  <Button
                    size="large"
                    htmlType="submit"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                    type="primary"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Spin>
          )}
        </Formik>
      </Card>
    );
  }
}

export default CredentialsForm;
