import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum AdnicUserType {
  EmiratesId = '1',
  MemberId = '2',
  DhaId = '3',
}

export const adnicUserType: DropdownOptionString[] = [
  {
    value: AdnicUserType.EmiratesId,
    label: 'Emirates ID',
  },
  {
    value: AdnicUserType.MemberId,
    label: 'Member ID',
  },
  {
    value: AdnicUserType.DhaId,
    label: 'DHA ID',
  },
];

export const adnicCountryCode: DropdownOptionString[] = [
  { label: '50', value: '50' },
  { label: '52', value: '52' },
  { label: '54', value: '54' },
  { label: '55', value: '55' },
  { label: '56', value: '56' },
  { label: '57', value: '57' },
  { label: '58', value: '58' },
];

export const adnicVisitCategory: DropdownOptionString[] = [
  { label: 'First Visit', value: '1' },
  { label: 'Visit without referral', value: '2' },
  { label: 'Visit with referral', value: '3' },
];
