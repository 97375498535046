export interface BranchData {
  id: number;
  name: string;
  identifier: string;
  authority: string;
  authority_login: string;
}

export enum BranchCredentialsValidationTokenStatus {
  Valid = 'valid',
  Invalid = 'invalid',
}

export interface BranchCredentialsValidationToken {
  token: string;
  checkResult: {
    status: BranchCredentialsValidationTokenStatus;
    message?: string;
  };
}

export enum FacilitiesPortal {
  eClaimLink = 'ECLAIMLINK',
  Shafafiya = 'SHAFAFIYA',
  Riayati = 'RIAYATI',
}

export interface BranchApiDto {
  portal: FacilitiesPortal;
  login: string;
  password: string;
  branchId: string;
  name: string;
  createBranchOnSuccess: boolean;
}

export interface Branch {
  branchId: string;
  id: string;
  login: string;
  name: string;
  portal: FacilitiesPortal;
  password: string;
}
