import * as Yup from 'yup';
import { AnySchema, StringSchema as YupStringSchema } from 'yup';

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for password policy.
     */
    emiratesId(): YupStringSchema;
  }
}

const YUP_EMIRATES_ID_METHOD = 'emiratesId';

Yup.addMethod<AnySchema>(Yup.string, YUP_EMIRATES_ID_METHOD, function yupPassword() {
  // eslint-disable-next-line func-names
  return this.test(YUP_EMIRATES_ID_METHOD, function (value: string) {
    const regExp = /^\d{3}-\d{4}-\d{7}-\d$/;
    if (!regExp.test(value)) {
      return this.createError({
        message: 'Wrong format',
      });
    }
    return true;
  });
});
