import set from 'lodash.set';
import { FormikHelpers } from 'formik';
import { AnyObjectSchema, ValidationError } from 'yup';

const yupErrorsToFormikFormErrors = (errors: ValidationError) => {
  const formErrors = {};
  const formTouched = {};
  errors.inner.forEach((error) => {
    if (error.path) {
      set(formErrors, error.path, error.message);
      set(formTouched, error.path, true);
    }
  });
  return { formErrors, formTouched };
};

export const showErrors = <Values>(error: unknown, formikHelpers: FormikHelpers<Values>) => {
  if (error instanceof ValidationError) {
    const { formErrors, formTouched } = yupErrorsToFormikFormErrors(error);
    formikHelpers.setErrors(formErrors);
    formikHelpers.setTouched(formTouched, false);
    return;
  }
  throw error;
};

export const submitValidate = <Values>(
  values: Values, schema: AnyObjectSchema,
  formikHelpers: FormikHelpers<Values>,
) => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return true;
  } catch (error) {
    showErrors(error, formikHelpers);
  }
  return false;
};
