import { commonFetch, ApiError } from '../../apiHelper';
import { Customer } from './financingCustomerTypes';

export class FinancingCustomerApi {
  static getCustomers = async ({ providerCodes, searchText }: {
    providerCodes?: string[],
    searchText?: string
  }): Promise<Customer[]> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/customer/search`, {
      method: 'POST',
      body: JSON.stringify({ providerCodes, searchText }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };
}

export default FinancingCustomerApi;
