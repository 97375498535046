import React from 'react';
import { FormikHelpers, Formik } from 'formik';
import { Form, Button } from 'custom-test-antd';

import './AccountEmail.pcss';

import { InputWrapper } from '../../../../utils/form-helpers/antd-formik';
import { AccountLayoutCard } from '../account-layout-card';

interface Props {
  onCancel: () => void,
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any,
  initialValues: any
}

export const AccountEmail: React.FC<Props> = ({ onSubmit, initialValues, onCancel }) => (
  <AccountLayoutCard
    className="AccountEmail"
    title={(
      <AccountLayoutCard.Title
        title="Update Email"
        description="This email used as login into system"
      />
    )}
  >
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <InputWrapper
            size="large"
            antFieldProps={{ label: 'Email' }}
            gray
            name="email"
            placeholder="Email"
          />
          <div className="AccountEmail-buttons">
            <Button
              size="large"
              type="link"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </AccountLayoutCard>
);

export default AccountEmail;
