import React, { ComponentType } from 'react';

interface Props {
  LoadingComponent: ComponentType,
  AuthenticatedComponent: ComponentType,
  UnAuthenticatedComponent: ComponentType,
  isAuthenticating: boolean,
  isAuthenticated: boolean,
  validateUser: Function,
}

export class Authenticator extends React.PureComponent<Props> {
  componentDidMount() {
    const { validateUser } = this.props;
    validateUser();
  }

  render() {
    const {
      isAuthenticating,
      isAuthenticated,
      LoadingComponent,
      AuthenticatedComponent,
      UnAuthenticatedComponent,
    } = this.props;

    if (isAuthenticating) {
      return <LoadingComponent />;
    }

    if (isAuthenticated) {
      return <AuthenticatedComponent />;
    }

    return <UnAuthenticatedComponent />;
  }
}

export default Authenticator;
