import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'custom-test-antd';
import moment from 'moment-timezone';
import type { FormikHelpers } from 'formik';
import type { Moment } from 'moment-timezone';

import { EligibilityBulkFileApi, StaticApi, ApiError } from 'api';
import { useAuthState } from 'components/authentication/context/authContext';
import { isEligibilityPaidSelector } from 'components/authentication/context/authSelectors';
import { UpgradeFullVersionModal } from 'components/full-version-checker';
import { privateRoutes } from '../../../application/routes/routesConstants';
import EligibilityBulkRequest from './EligibilityBulkRequest';
import { showErrorModal } from './error-modal/ErrorModal';

interface Props {
  className?: string;
}

export const calExecuteAt = (date: Moment, time: number) => (
  date ? moment(`${date.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm').toISOString() : undefined
);

export const EligibilityBulkRequestContainer = ({ className }: Props) => {
  const navigate = useNavigate();
  const authState = useAuthState();
  const isEligibilityPaid = !!isEligibilityPaidSelector(authState);

  const [isUpgradeModalOpened, setIsUpgradeModalOpened] = useState(false);

  const onSuccess = useCallback(() => {
    notification.success({ message: 'Success', description: 'Successfully created bulk eligibility check' });
    navigate(privateRoutes.eligibilityList);
  }, [navigate]);

  const onError = useCallback(async (values: any, error: any, helpers: FormikHelpers<any>) => {
    const { file, date, time } = values;
    if (error instanceof ApiError && error.response.status === 400) {
      const response = await error.response.json();
      const { message } = response;
      if (Array.isArray(message)) {
        const onSkip = async () => {
          const { setSubmitting } = helpers;
          setSubmitting(true);
          try {
            await EligibilityBulkFileApi.bulkRequestCheck(file, true, calExecuteAt(date, time));
            onSuccess();
          } catch (requestError) {
            await onError(values, requestError as Error, helpers);
          } finally {
            setSubmitting(false);
          }
        };
        if (window.location.pathname !== privateRoutes.eligibilityBulkRequest) {
          return notification.error({
            message: 'Error',
            description: (
              <div>
                Error in bulk eligibility check
                <div className="EligibilityBulkRequest-errorDetails">
                  <Button
                    type="link"
                    onClick={() => showErrorModal(response.message, file, onSkip)}
                  >
                    Details
                  </Button>
                </div>
              </div>
            ),
          });
        }
        return showErrorModal(response.message, file, onSkip);
      }
    }

    return notification.error({
      message: 'Error',
      description: (
        <div>
          Error in bulk eligibility check
        </div>
      ),
    });
  }, [onSuccess]);

  const onBeforeFileChange = useCallback(() => {
    if (isEligibilityPaid) {
      return true;
    }

    setIsUpgradeModalOpened(true);
    return false;
  }, [isEligibilityPaid]);

  return (
    <>
      <UpgradeFullVersionModal open={isUpgradeModalOpened} onCancel={() => setIsUpgradeModalOpened(false)} />
      <EligibilityBulkRequest
        className={className}
        onSubmit={async (values, formikHelpers) => {
          // dispatch(setBulkEligibilityUploadLoading(true));
          const { file, date, time } = values;
          try {
            await EligibilityBulkFileApi.bulkRequestCheck(file, false, calExecuteAt(date, time));
            onSuccess();
          } catch (error) {
            await onError(values, error, formikHelpers);
          }
        }}
        onTemplateDownload={useCallback(async () => {
          try {
            const data = await StaticApi.getBulkEligibilityTemplate();
            const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            const fileUrl = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'bulk_eligibility_template.csv';
            link.click();
            link.remove();
          } catch (e) {
            notification.error({
              message: 'Error',
              description: 'Error getting bulk eligibility template',
            });
          }
        }, [])}
        onBeforeFileChange={onBeforeFileChange}
      />
    </>
  );
};

export default EligibilityBulkRequestContainer;
