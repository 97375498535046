import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import eligibilityRequestReducer, {
  EligibilityRequestContextState,
  initialState,
}
  from './eligibilityRequestReducer';

const useThunkReducer = createReducer<any, EligibilityRequestContextState>(thunk);

const EligibilityRequestStateContext = React.createContext<EligibilityRequestContextState>(initialState());
export const EligibilityRequestDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState: Partial<EligibilityRequestContextState>
}

const EligibilityRequestProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(eligibilityRequestReducer, initialState(initState));

  return (
    <EligibilityRequestStateContext.Provider value={state}>
      <EligibilityRequestDispatchContext.Provider value={dispatch}>
        {children}
      </EligibilityRequestDispatchContext.Provider>
    </EligibilityRequestStateContext.Provider>
  );
};

function useEligibilityRequestState() {
  const context = React.useContext(EligibilityRequestStateContext);
  if (context === undefined) {
    throw new Error('useEligibilityRequestState must be used within a EligibilityRequestStateContext');
  }
  return context;
}

function useEligibilityRequestDispatch() {
  const context = React.useContext(EligibilityRequestDispatchContext);
  if (context === undefined) {
    throw new Error('useEligibilityRequestDispatch must be used within a EligibilityRequestDispatchContext');
  }
  return context;
}

export {
  EligibilityRequestProvider,
  useEligibilityRequestState,
  useEligibilityRequestDispatch,
};
