import React from 'react';
import { Card, Steps } from 'custom-test-antd';
import CapitalStepsImage from 'assets/capital/capital-steps.png';

import './AcceptProposalSteps.pcss';
import { AuthorizeDocumentsStepContainer } from './authorize-documents-step';
import { SignDocumentsStep } from './sign-documents-step';
import { UploadDocumentsStepContainer } from './upload-documents-step';
import { StepHeader } from './step-header';
import { SuccessStepContainer } from './success-step';

interface Props {
  currentStep: number;
}

const AcceptProposalSteps = ({ currentStep }: Props) => {
  if (currentStep === 3) {
    return <SuccessStepContainer />;
  }
  return (
    <Card className="AcceptProposalSteps">
      <div className="AcceptProposalSteps-header">
        Complete the there simple steps
        <div className="AcceptProposalSteps-description">
          Description
        </div>
        <img src={CapitalStepsImage} alt="" className="AcceptProposalSteps-image" />
      </div>
      <div>
        <Steps direction="vertical" current={currentStep} className="AcceptProposalSteps-steps">
          <Steps.Step
            title={(
              <>
                {
                  currentStep === 0
                  && (
                    <>
                      <StepHeader
                        title="Upload your KYC documents"
                        subTitle="STEP 1 • 6 MINUTES"
                        description="Description"
                      />
                      <AuthorizeDocumentsStepContainer />
                    </>
                  )
                }
                {
                  currentStep > 0
                  && (
                    <StepHeader
                      title="Upload your KYC documents"
                      subTitle="STEP 1 • COMPLETED"
                    />
                  )
                }
              </>
            )}
          />
          <Steps.Step
            title={(
              <>
                {
                  currentStep < 1
                  && (
                    <StepHeader
                      title="Sign the escrow agreement"
                      subTitle="STEP 2 • 3 MINUTES"
                      description="Description"
                      inactive
                    />
                  )
                }
                {
                  currentStep === 1
                  && (
                    <>
                      <StepHeader
                        title="Sign the escrow agreement"
                        subTitle="STEP 2 • 3 MINUTES"
                        description="Description"
                      />
                      <SignDocumentsStep
                        docuSignLink="http://localhost:3002"
                      />
                    </>
                  )
                }
                {
                  currentStep > 1
                  && (
                    <StepHeader
                      title="Upload your KYC documents"
                      subTitle="STEP 2 • COMPLETED"
                    />
                  )
                }
              </>
            )}
          />
          <Steps.Step
            title={(
              <>
                {
                  currentStep < 2
                  && (
                    <StepHeader
                      title="Authorise documents with your letterhead"
                      subTitle="STEP 3 • 3 MINUTES"
                      description="Description"
                      inactive
                    />
                  )
                }
                {
                  currentStep === 2
                  && (
                    <>
                      <StepHeader
                        title="Authorise documents with your letterhead"
                        subTitle="STEP 3 • 3 MINUTES"
                        description="Description"
                      />
                      <UploadDocumentsStepContainer />
                    </>
                  )
                }
                {
                  currentStep > 2
                  && (
                    <StepHeader
                      title="Authorise documents with your letterhead"
                      subTitle="STEP 3 • 3 MINUTES"
                    />
                  )
                }
              </>
            )}
          />
        </Steps>
      </div>
    </Card>
  );
};

export default AcceptProposalSteps;
