import { commonFetch, encodeQueryFilter, ApiError } from '../apiHelper';
import { BulkFile, FileFilter, FileListItem } from './eligibilityBulkFileTypes';

export class EligibilityBulkFileApi {
  static bulkRequestCheck = async (file: any, skipInvalidRequests?: boolean, executeAt?: string) => {
    const formData = new FormData();
    formData.append('file', file);
    if (skipInvalidRequests) {
      formData.append('skipInvalidRequests', skipInvalidRequests.toString());
    }
    if (executeAt) {
      formData.append('executeAt', executeAt);
    }
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility-bulk-file/upload`, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      return response.blob();
    }
    throw new ApiError(response);
  };

  static getFileList = async (filter: FileFilter): Promise<FileListItem[]> => {
    const requestFilter: any = {};
    if (filter.status) {
      requestFilter.status = filter.status;
    }
    const response = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/eligibility-bulk-file${encodeQueryFilter(requestFilter)}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getFile = async (id: string): Promise<BulkFile> => {
    const response = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/eligibility-bulk-file/${id}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static updateFile = async (id: string, payload: { status: string }): Promise<BulkFile> => {
    const response = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/eligibility-bulk-file/${id}`,
      {
        body: JSON.stringify(payload),
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };
}

export default EligibilityBulkFileApi;
