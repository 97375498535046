import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CredentialsList from './CredentialsList';
import {
  useCredentialsListDispatch,
  useCredentialsListState,
} from './context/credentialsListContext';
import {
  listItemSelector,
  paginationSelector,
} from './context/credentialsListSelectors';
import { deleteCredentials, getList } from './context/credentialsListThunks';
import {
  CredentialsListContextState,
  initPagination,
  storeFilter,
  storePagination,
  updateListItem,
} from './context/credentialsListReducer';
import { privateRoutes } from '../../application/routes/routesConstants';

export const CredentialsListContainer = () => {
  const state = useCredentialsListState();
  const dispatch = useCredentialsListDispatch();
  const navigate = useNavigate();
  return (
    <CredentialsList
      pagination={paginationSelector(state)}
      items={listItemSelector(state)}
      onFetch={useCallback(() => dispatch(getList), [dispatch])}
      onItemUpdate={useCallback((item) => dispatch(updateListItem(item)), [dispatch])}
      onSetPagination={useCallback((pagination: CredentialsListContextState['pagination']) => {
        dispatch(storePagination(pagination));
      }, [dispatch])}
      onOpenCredentials={useCallback((id) => {
        navigate(privateRoutes.credentialsForm.replace(':credentialsId', id));
      }, [navigate])}
      onDelete={useCallback(async (id) => {
        dispatch(deleteCredentials(id));
      }, [dispatch])}
      onSearch={useCallback((search) => {
        dispatch(storeFilter(search ? { search } : {}));
        dispatch(storePagination(initPagination));
        dispatch(getList);
      }, [dispatch])}
    />
  );
};

export default CredentialsListContainer;
