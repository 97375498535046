import { Dispatch } from 'react';

import { CredentialsApi } from 'api';
import {
  CredentialsListContextState,
  storeListItems, storePagination,
} from './credentialsListReducer';
import { filterSelector, listItemSelector, paginationSelector } from './credentialsListSelectors';

export const getList = async (dispatch: Dispatch<any>, getState: () => CredentialsListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const pagination = paginationSelector(state);
  const result = await CredentialsApi.getList(filter, pagination);
  dispatch(storePagination({
    ...pagination,
    total: result.count,
  }));
  dispatch(storeListItems(result.data));
};

export const deleteCredentials = (id: string) => async (
  dispatch: Dispatch<any>,
  getState: () => CredentialsListContextState,
) => {
  const state = getState();
  const items = listItemSelector(state);
  const pagination = paginationSelector(state);
  await CredentialsApi.delete(id);
  if (items.length === 1 && pagination.current !== 1 && items.length === 1) {
    dispatch(storePagination({ ...pagination, current: pagination.current - 1 }));
  }
  dispatch(getList);
};
