import io, { Socket } from 'socket.io-client';
import { AuthEventType } from './ComponentNotificationService';
import { Service } from '../container';

export interface IWebSocketClientService {
  start(): void;

  close(): void;

  on(event: string, listener: (...args: any[]) => void): void;

  off(event: string, listener: (...args: any[]) => void): void;
}

export class WebSocketClientService implements IWebSocketClientService {
  websocketServer!: Socket;

  disconnected: boolean;

  constructor(container: any) {
    this.start = this.start.bind(this);
    this.connect = this.connect.bind(this);
    this.close = this.close.bind(this);
    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    this.emit = this.emit.bind(this);
    this.disconnected = false;

    container[Service.componentNotification].on(AuthEventType.UserAuthenticated, this.start);
    container[Service.componentNotification].on(AuthEventType.UserLogout, this.close);
  }

  start() {
    console.log('Starting Service.');
    this.connect();
  }

  close() {
    this.disconnected = true;
    this.websocketServer.close();
  }

  connect() {
    console.log('Connecting Service.');
    if (this.websocketServer) {
      this.websocketServer.connect();
      return;
    }
    let token = localStorage.getItem('accessToken');
    const options: Record<string, any> = {
      transports: ['websocket'],
    };
    if (token && process.env.REACT_APP_USE_AUTH_TOKEN === 'true') {
      options.auth = { token };
    }
    this.websocketServer = io(`${process.env.REACT_APP_BACKEND_URL}`, options);
    this.websocketServer.connect();

    this.websocketServer.on('connect', () => {
      token = localStorage.getItem('accessToken');
      this.disconnected = false;
      if (token && process.env.REACT_APP_USE_AUTH_TOKEN === 'true') {
        this.websocketServer.auth = { token };
      }
      this.websocketServer.emit('join');
    });
    this.websocketServer.on('disconnect', () => {
      if (!this.disconnected) {
        console.log('disconnect');
        setTimeout(this.connect, 3000);
      }
    });
    this.websocketServer.on('connect_error', (err: any) => {
      console.log(`connect_error due to :: ${err.message}`);
      setTimeout(this.connect, 3000);
    });
  }

  on(event: string, listener: (...args: any[]) => void) {
    if (this.websocketServer) {
      this.websocketServer.on(event, listener);
    } else {
      setTimeout(() => this.on(event, listener), 3000);
    }
  }

  off(event: string, listener: (...args: any[]) => void) {
    this.websocketServer?.off(event, listener);
  }

  emit(event: string, ...args: any[]) {
    this.websocketServer?.emit(event, ...args);
  }
}

export default WebSocketClientService;
