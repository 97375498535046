export interface CreateRegistrationIntentionData {
  name: string,
  email: string,
  password: string,
  phone: string,
}

export interface CreatedIntention {
  intentionId: string;
}

export enum IntentionStatus {
  Pending = 'pending',
  Failed = 'failed',
  Completed = 'completed',

}

export interface IntentionCheck {
  error?: string;
  status?: IntentionStatus;
  authToken?: {
    token: string;
  };
}

export interface AuthenticationUser {
  id: number;
  email: string;
  name: string;
}

export interface MeResult {
  currentUser: AuthenticationUser;
  sessionUser: AuthenticationUser;
}
