import React from 'react';
import moment from 'moment/moment';
import CircleRight from 'assets/capital/overlay-decorator/circle1.svg';
import CircleLeft from 'assets/capital/overlay-decorator/circle2.svg';

import './ProposalInfo.pcss';

interface Props {
  issuedDate: string,
  validDate: string,
  className?: string,
}

export const ProposalInfo = ({
  issuedDate,
  validDate,
  className,
}: Props) => (
  <div className={`ProposalInfo ${className ?? ''}`}>
    <img src={CircleLeft} alt="" className="ProposalInfo-circleLeft" />
    <img src={CircleRight} alt="" className="ProposalInfo-circleRight" />
    <div className="ProposalInfo-info">
      <div>
        Issued Date:&nbsp;
        {moment(issuedDate).format('DD MMM YYYY')}
      </div>
      <div>
        Valid thru:&nbsp;
        {moment(validDate).format('DD MMM YYYY')}
      </div>
    </div>
  </div>
);

export default ProposalInfo;
