import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import documentsUploaderReducer, {
  DocumentsUploaderContextState,
  initialState,
  InitState,
} from './documentsUploaderReducer';

const useThunkReducer = createReducer<any, DocumentsUploaderContextState>(thunk);

const DocumentsUploaderStateContext = React.createContext<DocumentsUploaderContextState>(initialState());
export const DocumentsUploaderDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState: InitState
}

const DocumentsUploaderProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(documentsUploaderReducer, initialState(initState));

  return (
    <DocumentsUploaderStateContext.Provider value={state}>
      <DocumentsUploaderDispatchContext.Provider value={dispatch}>
        {children}
      </DocumentsUploaderDispatchContext.Provider>
    </DocumentsUploaderStateContext.Provider>
  );
};

function useDocumentsUploaderState() {
  const context = React.useContext(DocumentsUploaderStateContext);
  if (context === undefined) {
    throw new Error('useDocumentsUploaderState must be used within a DocumentsUploaderStateContext');
  }
  return context;
}

function useDocumentsUploaderDispatch() {
  const context = React.useContext(DocumentsUploaderDispatchContext);
  if (context === undefined) {
    throw new Error('useDocumentsUploaderDispatch must be used within a DocumentsUploaderDispatchContext');
  }
  return context;
}

export {
  DocumentsUploaderProvider,
  useDocumentsUploaderState,
  useDocumentsUploaderDispatch,
};
