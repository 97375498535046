import { DropdownOptionString, InsurancePortal, InsurancePortalLabel } from '../../../constants/dropdownOptions';

export const insurancePortalsCredentials: DropdownOptionString[] = [
  { label: InsurancePortalLabel[InsurancePortal.Axa], value: InsurancePortal.Axa },
  { label: InsurancePortalLabel[InsurancePortal.Daman], value: InsurancePortal.Daman },
  { label: InsurancePortalLabel[InsurancePortal.Nas], value: InsurancePortal.Nas },
  { label: InsurancePortalLabel[InsurancePortal.Neuron], value: InsurancePortal.Neuron },
  { label: InsurancePortalLabel[InsurancePortal.Nextcare], value: InsurancePortal.Nextcare },
  { label: InsurancePortalLabel[InsurancePortal.Almadallah], value: InsurancePortal.Almadallah },
  { label: InsurancePortalLabel[InsurancePortal.Oman], value: InsurancePortal.Oman },
  { label: InsurancePortalLabel[InsurancePortal.Mednet], value: InsurancePortal.Mednet },
  { label: InsurancePortalLabel[InsurancePortal.Eclaim], value: InsurancePortal.Eclaim },
  { label: InsurancePortalLabel[InsurancePortal.Adnic], value: InsurancePortal.Adnic },
  { label: InsurancePortalLabel[InsurancePortal.Metlife], value: InsurancePortal.Metlife },
];

export const OTP_REQUIRED_ERROR = 'Required otp';
