import React from 'react';
import moment from 'moment-timezone';
import { ReactComponent as Klaim } from 'assets/klaim.svg';

import './AuthSider.pcss';

const AuthSider: React.FC = () => (
  <div className="AuthSider">
    <Klaim />
    <div className="AuthSider-textSection">
      <a
        className="AuthSider-text marginBottom"
        rel="noreferrer"
        target="_blank"
        href="https://klaim.ai/privacy-policy"
      >
        PRIVACY POLICY
      </a>
      <a
        className="AuthSider-text marginBottom"
        rel="noreferrer"
        target="_blank"
        href="https://klaim.ai/terms-of-service"
      >
        TERMS OF SERVICE
      </a>
      <span className="AuthSider-text small">
        {`Copyright © ${moment().format('YYYY')} Klaim \nAll rights reserved`}
      </span>
    </div>
  </div>
);

export default AuthSider;
