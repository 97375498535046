import React, { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import { PractitionerLicenseApi } from 'api';
import * as Yup from 'yup';

import { LicenseEdit } from './LicenseEdit';
import { submitValidate } from '../../../utils/form-helpers/antd-formik';
import { PractitionerLicense } from '../../../api/practitioner-license/practitionerLicenseTypes';
import { privateRoutes } from '../../application/routes/routesConstants';

const validationSchema = Yup.object({
  name: Yup.string().required(),
  license: Yup.string().required(),
});

export const LicenseEditContainer = () => {
  const navigate = useNavigate();
  const { licenseId } = useParams();
  const [license, setLicense] = useState<PractitionerLicense>();
  const [licenseFetching, setLicenseFetching] = useState(false);

  useMount(async () => {
    setLicenseFetching(true);
    try {
      if (licenseId) {
        const fetchedLicense = await PractitionerLicenseApi.getLicense(licenseId);
        setLicense(fetchedLicense);
      }
    } finally {
      setLicenseFetching(false);
    }
  });
  return (
    <LicenseEdit
      license={license}
      onSubmit={useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (!submitValidate(values, validationSchema, formikHelpers) || !licenseId) {
          return;
        }
        try {
          await PractitionerLicenseApi.update(licenseId, values);
          notification.success({
            message: 'Success',
            description: 'License updated successfully',
          });
          navigate(privateRoutes.practitionerLicensesList);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Error during license update',
          });
        }
      }, [navigate, licenseId])}
      licenseFetching={licenseFetching}
    />
  );
};

export default LicenseEditContainer;
