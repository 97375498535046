import { Dispatch } from 'react';
import { SelectProps } from 'antd';
import { DropdownOptionString, InsurancePortal } from '../../../../constants/dropdownOptions';
import AlmadallahRequest from './almadallah/almadallah';
import AxaRequest from './axa/axa';
import DamanRequest from './daman/daman';
import EclaimRequest from './eclaim/eclaim';
import NasRequest from './nas/nas';
import NeuronRequest from './neuron/neuron';
import NextcareRequest from './nexcare/nextcare';
import OmanRequest from './oman/oman';
import MednetRequest from './mednet/mednet';
import AdnicRequest from './adnic/adnic';
import MetlifeRequest from './metlife/metlife';
import MultipleRequest from './multiple-request/multipleRequest';
import { EligibilityRequestContextState } from '../context/eligibilityRequestReducer';

export interface DependentCode extends SelectProps {
  ['data-policy']?: string;
  ['data-certificate']?: string;
}

export interface FormConfig {
  patientIdType?: DropdownOptionString[],
  patientId?: {
    label: string,
    placeholder: string,
  },
  treatmentBases?: {
    portal: string,
    options: DropdownOptionString[],
    label: string,
  }[],
  clinicianLicense?: boolean,
  serviceCategory?: DropdownOptionString[],
  consultationCategory?: DropdownOptionString[],
  ultrasoundCategory?: DropdownOptionString[],
  patientName?: boolean,
  countryCode?: DropdownOptionString[],
  mobileNumber?: boolean,
  policyNo?: boolean, // axa
  membershipCode?: boolean, // axa
  emiratesId?: boolean,
  fetchAuthorizationId?: boolean,
  visitCategory?: DropdownOptionString[],
  payerType?: DropdownOptionString[], // daman
  portalPatientId?: boolean, // metlife
  policyNumber?: boolean, // metlife
  certificateNumber?: boolean, // metlife
  dependentCode?: DependentCode, // metlife
}

export interface IPortalRequest {
  getFormConfig: (values: any, extended?: boolean) => FormConfig;
  validate: (values: any) => any;
  getLastCheckId: (values: any) => Promise<string | undefined>;
  createRequest: (values: any) => Promise<any>;
  getInitialValues: (values: any, fromApi: boolean) => Promise<any>;
}

export interface IPortalRequestFactory {
  instantiate: (
    portals: InsurancePortal[],
    dispatch: Dispatch<any>,
    getState: () => EligibilityRequestContextState,
  ) => IPortalRequest;
}

export class PortalRequestFactory implements IPortalRequestFactory {
  // eslint-disable-next-line class-methods-use-this
  instantiate(
    portals: InsurancePortal[] | InsurancePortal,
    dispatch: Dispatch<any>,
    getState: () => EligibilityRequestContextState,
  ) {
    const portalMap: {
      [name: string]: {
        new(portal: InsurancePortal, d: Dispatch<any>, gS: () => EligibilityRequestContextState): IPortalRequest
      };
    } = {
      [InsurancePortal.Almadallah]: AlmadallahRequest,
      [InsurancePortal.Axa]: AxaRequest,
      [InsurancePortal.Daman]: DamanRequest,
      [InsurancePortal.Nas]: NasRequest,
      [InsurancePortal.Neuron]: NeuronRequest,
      [InsurancePortal.Nextcare]: NextcareRequest,
      [InsurancePortal.Oman]: OmanRequest,
      [InsurancePortal.Inayah]: EclaimRequest,
      [InsurancePortal.NGI]: EclaimRequest,
      [InsurancePortal.Abnic]: EclaimRequest,
      [InsurancePortal.MSH]: EclaimRequest,
      [InsurancePortal.Mednet]: MednetRequest,
      [InsurancePortal.Adnic]: AdnicRequest,
      [InsurancePortal.Metlife]: MetlifeRequest,
    };
    if (typeof portals === 'string') {
      return new portalMap[portals](portals, dispatch, getState);
    }
    if (portals.length > 1) {
      return new MultipleRequest(portals, dispatch, getState);
    }
    return new portalMap[portals[0]](portals[0], dispatch, getState);
  }
}
