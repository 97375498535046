import { BranchData } from '../branch/branchTypes';

export interface CredentialsListItem {
  id: string;
  username?: string;
  password?: string;
  portal?: string;
  license?: string;
  createdAt: Date;
  branch: BranchData;
  validationResponse?: {
    isValid: boolean;
  }
}

export interface CredentialsListResponse {
  data: CredentialsListItem[];
  count: number;
}

export interface Credentials {
  username?: string;
  password?: string;
  portal?: string;
  license?: string;
  branchId?: string;
}

export enum CredentialsWebSocketEvent {
  UpdateCredentials = 'updatePortalCredentials',
  CreateCredentials = 'createPortalCredentials',
}

export interface Otp {
  otp: string;
  requestId: string;
}
