import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { submitValidate } from 'utils/form-helpers/antd-formik';
import { useNavigate } from 'react-router-dom';

import SignIn from './SignIn';
import { useAuthDispatch } from '../context/authContext';
import { signIn } from '../context/authThunks';
import { privateRoutes } from '../../application/routes/routesConstants';

const signInSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().required('Required'),
});

export const SignInContainer: React.FC = () => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  return (
    <SignIn
      onSubmit={useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (!submitValidate(values, signInSchema, formikHelpers)) {
          return false;
        }
        try {
          await dispatch(signIn(values.email, values.password, values.rememberMe));
        } catch (error: any) {
          if (!error.ok) {
            formikHelpers.setErrors({ password: 'Email or password is wrong' });
            formikHelpers.setTouched({ password: true }, false);
          }
        }
        navigate(privateRoutes.home, { replace: true });
        return true;
      }, [dispatch, navigate])}
    />
  );
};

export default SignInContainer;
