import React from 'react';
import { Formik } from 'formik';
import { Form, Button } from 'custom-test-antd';
import { Link } from 'react-router-dom';

import './ForgotPassword.pcss';

import { InputWrapper } from 'utils/form-helpers/antd-formik';
import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';

interface Props {
  onSubmit: any;
}

export const ForgotPassword: React.FC<Props> = ({ onSubmit }) => (
  <AuthCard
    className="ForgotPassword"
    header="Update your password"
    headerText="Use a valid email address that has an account with Klaim so we can send you instructions"
  >
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <InputWrapper
            antFieldProps={{ label: 'Email' }}
            name="email"
            size="large"
            gray
          />
          <div className="ForgotPassword-backSection">
            <span>No need?&nbsp;</span>
            <Link to={publicRoutes.signIn} className="ForgotPassword-signIn">Take me back</Link>
          </div>
          <Button
            htmlType="submit"
            loading={isSubmitting}
            type="primary"
            onClick={() => handleSubmit()}
            size="large"
            className="ForgotPassword-submitButton"
          >
            Send me instructions
          </Button>
        </Form>
      )}
    </Formik>
  </AuthCard>
);

export default ForgotPassword;
