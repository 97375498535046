import React, { useState, useCallback } from 'react';
import { useMount } from 'react-use';

import { EligibilityBulkFileApi } from 'api';
import { FileListItem, FileStatus } from 'api/eligibility-bulk-file/eligibilityBulkFileTypes';
import FileList from './FileList';

interface Props {
  className?: string;
}

export const FileListContainer = ({ className }: Props) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FileListItem[]>([]);

  const loadFiles = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedFiles = await EligibilityBulkFileApi.getFileList({ status: FileStatus.Pending });
      setFiles(fetchedFiles);
    } finally {
      setLoading(false);
    }
  }, []);

  useMount(async () => {
    await loadFiles();
  });

  return (
    <FileList
      className={className}
      loading={loading}
      files={files}
      onDownload={async (id) => {
        setLoading(true);
        try {
          const bulkFile = await EligibilityBulkFileApi.getFile(id);
          const csvData = new Blob([atob(bulkFile.file.content)]);
          const fileUrl = URL.createObjectURL(csvData);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = bulkFile.file.name;
          link.click();
          link.remove();
        } finally {
          setLoading(false);
        }
      }}
      onDelete={async (id) => {
        await EligibilityBulkFileApi.updateFile(id, { status: FileStatus.Cancelled });
        await loadFiles();
      }}
    />
  );
};

export default FileListContainer;
