import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { AccountLayout } from 'components/account';
import { AccountSettingsContainer } from 'components/account/account-settings';
import { AccountEmailContainer } from 'components/account/account-settings/account-email';
import { AccountFullNameContainer } from 'components/account/account-settings/account-full-name';
import { AccountPasswordContainer } from 'components/account/account-settings/account-password';
import { AccountPhoneContainer } from 'components/account/account-settings/account-phone';
import { privateRoutes } from './routesConstants';

type Props = {
  showBackBtn?: boolean;
};

export const SettingsRoutes: FC<Props> = ({ showBackBtn }) => (
  <Route
    path={privateRoutes.accountSettings}
    element={<AccountLayout showBackBtn={showBackBtn} />}
  >
    <Route
      path={privateRoutes.accountName}
      element={<AccountFullNameContainer />}
    />
    <Route
      path={privateRoutes.accountPhone}
      element={<AccountPhoneContainer />}
    />
    <Route
      path={privateRoutes.accountEmail}
      element={<AccountEmailContainer />}
    />
    <Route
      path={privateRoutes.accountPassword}
      element={<AccountPasswordContainer />}
    />
    <Route
      path=""
      element={<AccountSettingsContainer />}
    />
  </Route>
);

export default SettingsRoutes;
