import React from 'react';
import sanitizeHtml from 'sanitize-html';

import './Gatekeeper.pcss';

interface Props {
  value: string;
}

const Gatekeeper = ({ value }: Props) => (
  <div>
    <div className="Gatekeeper-title">GATEKEEPER:</div>
    {/* eslint-disable-next-line react/no-danger */}
    <div className="Gatekeeper-value" dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }} />
  </div>
);

export default Gatekeeper;
