import React, { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import { PractitionerLicenseApi } from 'api';
import * as Yup from 'yup';

import { LicenseNew } from './LicenseNew';
import { submitValidate } from '../../../utils/form-helpers/antd-formik';
import { privateRoutes } from '../../application/routes/routesConstants';

const validationSchema = Yup.object({
  name: Yup.string().required(),
  license: Yup.string().required(),
});

export const LicenseEditContainer = () => {
  const navigate = useNavigate();

  return (
    <LicenseNew
      onSubmit={useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (!submitValidate(values, validationSchema, formikHelpers)) {
          return;
        }
        try {
          await PractitionerLicenseApi.create(values);
          notification.success({
            message: 'Success',
            description: 'License created successfully',
          });
          navigate(privateRoutes.practitionerLicensesList);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Error during license create',
          });
        }
      }, [navigate])}
    />
  );
};

export default LicenseEditContainer;
