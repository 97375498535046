import React from 'react';
import { Table, TableProps } from 'custom-test-antd';
import { ColumnsType } from 'antd/lib/table';

import './BenefitsTable.pcss';

import { Benefit } from '../../../../../api/eligibility/eligibilityTypes';

const columns: ColumnsType<Benefit> = [
  {
    title: 'BENEFITS',
    dataIndex: 'label',
    key: 'label',
    render: (label) => label.toLowerCase(),
  },
  {
    title: 'CoPay/Deductable',
    dataIndex: 'value',
    key: 'value',
  },
];

export const BenefitsTable: React.FC<TableProps<Benefit>> = (props) => (
  <Table
    {...props}
    rowKey="label"
    className="BenefitsTable"
    columns={columns}
  />
);

export default BenefitsTable;
