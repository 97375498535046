import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CapitalProposal from './CapitalProposal';
import { privateRoutes } from '../../application/routes/routesConstants';
import { useProposalDispatch, useProposalState } from './context/context/proposalContext';
import { customerSelector, proposalSelector } from './context/context/proposalSelectors';
import { updateProposalState } from './context/context/proposalThunks';
import { ProposalState } from '../../../api/financing/proposal/financingProposalTypes';

export const CapitalProposalContainer = () => {
  const navigate = useNavigate();
  const state = useProposalState();
  const dispatch = useProposalDispatch();
  const proposal = proposalSelector(state);
  const isWireTransferPrepared = proposal?.state === ProposalState.WireTransferPrepared;
  return (
    <CapitalProposal
      customer={customerSelector(state)}
      proposal={proposal}
      showSummaryTableRows
      showAcceptAction={proposal?.state === ProposalState.Created}
      showProposalProcess={[
        ProposalState.Accepted,
        ProposalState.KYCDocumentUploading,
        ProposalState.KYCDocumentUploaded,
        ProposalState.EscrowAgreementGenerated,
        ProposalState.AuthoriseDocumentsPrepared,
        ProposalState.AuthoriseDocumentsUploading,
        ProposalState.AuthoriseDocumentsUploaded,
      ].includes(proposal?.state)}
      showSignAction={isWireTransferPrepared}
      onAccept={useCallback((attachPdf) => {
        dispatch(updateProposalState(ProposalState.Accepted));
        // eslint-disable-next-line no-console
        console.log('attachPdf', attachPdf);
        navigate(privateRoutes.capitalGetStarted);
      }, [dispatch, navigate])}
      onSign={() => {
        // eslint-disable-next-line no-console
        console.log('sign');
      }}
    />
  );
};

export default CapitalProposalContainer;
