import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu } from 'custom-test-antd';
import backArrow from 'assets/back-arrow.svg';
import { privateRoutes } from '../application/routes/routesConstants';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';

import './AccountLayout.pcss';

enum AccountMenuKey {
  AccountSettings = 'AccountSettings',
}

const MenuRoutes: { [key: string]: string } = {
  [AccountMenuKey.AccountSettings]: privateRoutes.accountSettings,
};

const menuItems = [
  { label: 'Account Settings', key: AccountMenuKey.AccountSettings },
];

const Header = createPortal(PrivateLayout.titlePortalId);

type Props = {
  showBackBtn?: boolean;
};

export const AccountLayout: React.FC<Props> = ({ showBackBtn }) => {
  const navigate = useNavigate();
  const location = useLocation();

  let selectedKey: string = AccountMenuKey.AccountSettings;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header className="AccountLayout-header">
        <Button
          hidden={!showBackBtn}
          size="small"
          className="AccountLayout-backButton"
          type="primary"
          shape="circle"
          onClick={() => {
            navigate(-1);
          }}
          icon={<img src={backArrow} alt="back-arrow" />}
        />
        Settings
      </Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="AccountLayout-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <Outlet />
        )}
      />
    </>
  );
};

export default AccountLayout;
