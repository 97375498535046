import produce from 'immer';
import moment from 'moment-timezone';
import { CredentialsListItem } from '../../../../api/credentials/credentialsTypes';

export const initPagination = {
  pageSize: 10,
  current: 1,
  total: 0,
};

export interface CredentialsListContextState {
  listItems: CredentialsListItem[];
  filter: {
  };
  pagination: {
    pageSize: number,
    current: number,
    total: number,
  };
}

export interface InitState {
  filter?: CredentialsListContextState['filter'],
}

export const initialState = (initValues?: InitState): CredentialsListContextState => ({
  listItems: [],
  filter: initValues?.filter ?? {
    dates: [moment().subtract(1, 'month').startOf('day'), moment().startOf('day')],
  },
  pagination: initPagination,
});

export const STORE_LIST_ITEMS = 'STORE_LIST_ITEMS';
export const storeListItems = (items: CredentialsListItem[]) => ({
  type: STORE_LIST_ITEMS, payload: { items },
});

export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM';
export const updateListItem = (item: CredentialsListItem) => ({
  type: UPDATE_LIST_ITEM, payload: { item },
});

export const STORE_PAGINATION = 'STORE_PAGINATION';
export const storePagination = (pagination: CredentialsListContextState['pagination']) => ({
  type: STORE_PAGINATION, payload: { pagination },
});

export const STORE_FILTER = 'STORE_FILTER';
export const storeFilter = (filter: CredentialsListContextState['filter']) => ({
  type: STORE_FILTER, payload: { filter },
});

const credentialsListReducer = (state: CredentialsListContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case STORE_LIST_ITEMS: {
      // eslint-disable-next-line no-param-reassign
      draftState.listItems = action.payload.items;
      break;
    }
    case UPDATE_LIST_ITEM: {
      const index = draftState.listItems.findIndex((itCredentials) => itCredentials.id === action.payload.item.id);
      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        draftState.listItems[index] = action.payload.item;
      }
      break;
    }
    case STORE_PAGINATION: {
      // eslint-disable-next-line no-param-reassign
      draftState.pagination = action.payload.pagination;
      break;
    }
    case STORE_FILTER: {
      // eslint-disable-next-line no-param-reassign
      draftState.filter = action.payload.filter;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default credentialsListReducer;
