import React from 'react';

import './LoadingLayout.pcss';

interface Props {
  children: React.ReactNode,
  className?: string,
}

export const LoadingLayout: React.FC<Props> = ({ children, className }) => (
  <div className={`LoadingLayout ${className ?? ''}`}>{children}</div>
);

export default LoadingLayout;
