import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import GetStarted from './GetStarted';
import { useProposalState, useProposalDispatch } from '../proposal/context/context/proposalContext';
import { updateProposalState } from '../proposal/context/context/proposalThunks';
import { ProposalState } from '../../../api/financing/proposal/financingProposalTypes';
import { privateRoutes } from '../../application/routes/routesConstants';
import { proposalSelector } from '../proposal/context/context/proposalSelectors';

export const GetStartedContainer = () => {
  const navigate = useNavigate();
  const state = useProposalState();
  const dispatch = useProposalDispatch();
  const proposal = proposalSelector(state);
  return (
    <GetStarted
      showStart={proposal?.state === ProposalState.Accepted}
      showBack={proposal?.state !== ProposalState.Accepted}
      onStartProcess={useCallback(() => {
        dispatch(updateProposalState(ProposalState.KYCDocumentUploading));
        navigate(privateRoutes.capitalProposalSteps);
      }, [dispatch, navigate])}
      onBack={useCallback(() => {
        navigate(-1);
      }, [navigate])}
    />
  );
};

export default GetStartedContainer;
