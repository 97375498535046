import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import onboardFacilityReducer, { OnboardFacilityContextState, initialState } from './onboardFacilityReducer';

const useThunkReducer = createReducer<any, OnboardFacilityContextState>(thunk as any);

const OnboardFacilityStateContext = React.createContext<OnboardFacilityContextState>(initialState());
export const OnboardFacilityDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode;
}

const OnboardFacilityProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useThunkReducer(onboardFacilityReducer, initialState());

  return (
    <OnboardFacilityStateContext.Provider value={state}>
      <OnboardFacilityDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardFacilityDispatchContext.Provider>
    </OnboardFacilityStateContext.Provider>
  );
};

function useOnboardFacilityState() {
  const context = React.useContext(OnboardFacilityStateContext);
  if (context === undefined) {
    throw new Error('useOnboardFacilityState must be used within a OnboardFacilityStateContext');
  }
  return context;
}

function useOnboardFacilityDispatch() {
  const context = React.useContext(OnboardFacilityDispatchContext);
  if (context === undefined) {
    throw new Error('useOnboardFacilityDispatch must be used within a OnboardFacilityDispatchContext');
  }
  return context;
}

export {
  OnboardFacilityProvider,
  useOnboardFacilityState,
  useOnboardFacilityDispatch,
};
