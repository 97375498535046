import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { useMount } from 'react-use';

import { BranchApi, EligibilityApi } from 'api';
import Dashboard from './Dashboard';
import { UserUsageDashboardItem } from '../../../api/eligibility/eligibilityTypes';
import { BranchData } from '../../../api/branch/branchTypes';

export const DashboardContainer = () => {
  const [dates, setDates] = useState<[Moment, Moment]>([moment(), moment()]);
  const [selectedBranches, setSelectedBranches] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState<BranchData[]>([]);
  const [userData, setUserData] = useState<UserUsageDashboardItem[]>([]);
  const startDateString = dates[0].format('YYYY-MM-DD');
  const endDateString = dates[1].format('YYYY-MM-DD');
  useMount(async () => {
    const userBranches = await BranchApi.getUserBranches();
    setBranches(userBranches);
  });
  useEffect(() => {
    setLoading(true);

    EligibilityApi.getUserUsage(
      moment(startDateString).startOf('day'),
      moment(endDateString).endOf('day'),
      selectedBranches,
    ).then((result: any) => {
      setUserData(result);
    }).catch((error) => {
      console.error(error);
      setUserData([]);
    }).finally(() => {
      setLoading(false);
    });
  }, [startDateString, endDateString, selectedBranches]);
  return (
    <Dashboard
      branches={branches}
      selectedBranches={selectedBranches}
      onChangeDate={useCallback((selectedDates: any) => setDates(selectedDates), [])}
      onBranchesChange={useCallback((changedBranches: string[]) => setSelectedBranches(changedBranches), [])}
      dates={dates}
      loading={loading}
      userData={userData}
    />
  );
};

export default DashboardContainer;
