import React from 'react';
import { Table } from 'custom-test-antd';
import { EligibilityResponse } from '../../../../api/eligibility/eligibilityTypes';

interface Props {
  approvalRequirements: EligibilityResponse['approvalRequirements'];
}

export const ApprovalRequirements = ({ approvalRequirements }: Props) => {
  if (!Array.isArray((approvalRequirements))) {
    return null;
  }
  return (
    <Table
      rowKey="label"
      whiteHeader
      columns={[{
        title: 'Approval Requirements',
        dataIndex: 'label',
      }, {
        dataIndex: 'value',
      }]}
      dataSource={approvalRequirements}
    />
  );
};

export default ApprovalRequirements;
