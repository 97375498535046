import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { submitValidate } from 'utils/form-helpers/antd-formik';
import { useNavigate } from 'react-router-dom';
import { ApiError } from 'api';

import ForgotPassword from './ForgotPassword';
import { useAuthDispatch } from '../context/authContext';
import { forgotPassword } from '../context/authThunks';
import { publicRoutes } from '../../application/routes/routesConstants';

const signInSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
});

export const ForgotPasswordContainer = () => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  return (
    <ForgotPassword
      onSubmit={useCallback(async (values: any, formikBag: FormikHelpers<any>) => {
        if (!submitValidate(values, signInSchema, formikBag)) {
          return;
        }
        try {
          const result: any = await dispatch(forgotPassword(values.email));
          if (result.success) {
            navigate(publicRoutes.emailSent);
          }
        } catch (e: any) {
          if (e instanceof ApiError) {
            const error = await e.response.json();
            if (error?.error?.message) {
              formikBag.setFieldError('email', error?.error?.message);
              formikBag.setFieldTouched('email', true, false);
            }
          }
        }
      }, [dispatch, navigate])}
    />
  );
};

export default ForgotPasswordContainer;
