import React from 'react';
import { isMobile } from 'utils/mobileDetector';
import { usePrivateLayoutState } from '../private-layout/context/privateLayoutContext';
import { isMobileAdaptiveSelector } from '../private-layout/context/privateLayoutSelector';

import './MenuContentLayout.pcss';

interface Props {
  content: React.ReactNode,
  menu?: React.ReactElement | null,
}

export const MenuContentLayout: React.FC<Props> = ({ menu, content }) => {
  const layoutState = usePrivateLayoutState();
  const isMobileAdaptive = isMobileAdaptiveSelector(layoutState);

  return (
    <div className={`MenuContentLayout ${isMobileAdaptive && isMobile() ? 'mobile' : ''}`}>
      <div className="MenuContentLayout-menuSection">
        {
        menu
        && React.cloneElement(menu, {
          className: `MenuContentLayout-menu ${menu.props.className ?? ''}`,
          mode: 'horizontal',
        })
      }
      </div>
      <div className="MenuContentLayout-content">
        {content}
      </div>
    </div>
  );
};

export default MenuContentLayout;
