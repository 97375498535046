import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import {
  OmanUserType,
  omanUserType,
} from './types';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';

const initialValue = {
  patientIdType: OmanUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
};

export class OmanRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    const config: PortalConfig = {
      patientIdType: omanUserType,
      patientId: undefined,
      emiratesId: true,
    };

    const { patientIdType } = values;
    if (patientIdType && patientIdType !== OmanUserType.EmiratesId) {
      config.emiratesId = false;
      config.patientId = calcPatientId(patientIdType, omanUserType);
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== OmanUserType.EmiratesId
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === OmanUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return OmanUserType.EmiratesId;
  }
}

export default OmanRequest;
