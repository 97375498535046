import { ApiError, commonFetch } from '../apiHelper';
import {
  EligibilityDefaultGetResponse,
  EligibilityDefaultFindPayload,
  EligibilityDefaultSaveRequest,
} from './eligibilityDefaultTypes';

export class EligibilityDefaultApi {
  static search = async (payload?: EligibilityDefaultFindPayload): Promise<EligibilityDefaultGetResponse[]> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility-default/list`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getById = async (id: string): Promise<EligibilityDefaultGetResponse> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility-default/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static create = async (payload: EligibilityDefaultSaveRequest): Promise<string> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility-default`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static update = async (id: string, payload: EligibilityDefaultSaveRequest) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility-default/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return;
    }
    throw new ApiError(response);
  };

  static delete = async (id: string) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility-default/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      return;
    }
    throw new ApiError(response);
  };
}

export default EligibilityDefaultApi;
