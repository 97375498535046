import React from 'react';
import './Card.pcss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Card: React.FC<Props> = ({ children, className = '' }) => (
  <div className={`Card ${className}`}>
    {children}
  </div>
);

export default Card;
