let ToolkitOB: any = null;
let readerClass: any = null;
const self = this;
let patientIdCB: (e: any) => void;
let serviceInitializedCB: (e: any, isInitialized?: boolean) => void;
let isInitialized: boolean = false;
const isProduction = process.env.NODE_ENV === 'production';
const serviceUrl = '/static/eid-reader/';
// @ts-ignore
this.IsNfc = false;

const options: any = {
  jnlp_address: `${serviceUrl}lib/IDCardToolkitService.jnlp`,
  debugEnabled: !isProduction,
  agent_tls_enabled: false,
};
const IsSam = {
  sam_secure_messaging: true,
};
options.toolkitConfig = 'vg_connection_timeout = 60 \n';
options.toolkitConfig += 'log_level = "INFO" \n';
options.toolkitConfig += 'log_performance_time = true \n';
options.toolkitConfig += 'read_publicdata_offline = true \n';

/**
 * Error handler call back function.
 * This function is executed if any error occurred in the web socket communication.
 * This function is passed as a error call back function while initializing the web socket.
 *
 * @param err error details
 */
const errorHandlerCB = (err: any) => {
  serviceInitializedCB(false);
  isInitialized = false;
  readerClass = null;
  ToolkitOB = null;
  if (err !== null) {
    alert(`errorHandler ERROR : ${err}`);
  }
};
/**
 * Close handler call back function.
 * This function is executed when web socket connection is closed.
 * This function is passed as a close call back function while initializing the web socket.
 *
 * @param response response details
 */
const closeHandlerCB = (response: any) => {
  ToolkitOB = null;
  readerClass = null;
  // eslint-disable-next-line eqeqeq
  if (response !== null && undefined == response) { /* empty */
  }
  // eslint-disable-next-line no-use-before-define
  serviceInitializedCB(false);
  isInitialized = false;
};
/**
 * Close handler call back function.
 * This function is executed when web socket connection is closed.
 * This function is passed as a close call back function while initializing the web socket.
 *
 * @param response response details
 */
/**
 * open handler call back function.
 * This function is executed when web socket connection is opened/established successfully.
 * This function is passed as a onOpen call back function while initializing the web socket.
 *
 */
const onOpenHandlerCB = (response: any, error: any) => {
  serviceInitializedCB(false);
  isInitialized = false;
  if (error === null) {
    /**
     * call the list reader function and pass listReaderCB to be executed
     * after the response is received from server
     */
    if (IsSam.sam_secure_messaging) {
      // eslint-disable-next-line no-use-before-define
      ToolkitOB.getReaderWithEmiratesId(listReaderCB);
    } else {
      // eslint-disable-next-line no-use-before-define
      ToolkitOB.listReaders(listReaderCB);
    }
  } else {
    ToolkitOB = null;
  }
};
/**
 * listReader handler call back function.
 * This function is executed when response is received from server for listReader request.
 * This function is passed as a listReader call back function while sending listReader request.
 *
 * @param response describing response from server.
 *  response object has one field 'data' which contains a string in the json format.
 *  This string can be converted to json object by using JSON.parse(response.data);
 *
 * @param error
 */
// eslint-disable-next-line consistent-return
const listReaderCB = (response: any, error: any) => {
  if (error !== null) {
    if (Object.keys(error).length) {
      alert(error.message || error.description);
    }
    ToolkitOB = null;
    serviceInitializedCB(false);
    isInitialized = false;
  } else {
    const readerList = response;
    if (IsSam.sam_secure_messaging) {
      readerClass = readerList;
    } else if (readerList && readerList.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      readerClass = readerList[0];
    } else {
      alert('No readers found');
    }
    /**
     * call the connect reader function and pass connectReaderCB to be executed
     * after the response is received from server
     */
    // eslint-disable-next-line no-use-before-define
    const ret = readerClass.connect(connectReaderCB);
    if (ret !== '') {
      serviceInitializedCB(true);
    }
  }
};
/**
 * This function is used to get Interface of the reader
 */
const getInterfaceCB = (response: any, error: any) => {
  if (error !== null) {
    if (Object.keys(error).length) {
      alert(`${error.code} : ${error.message}`);
    }
    ToolkitOB = null;
    return;
  }
  if (response === 2) {
    // @ts-ignore
    self.IsNfc = true;
    alert('Initialize Success. First Set NFC Parameters.');
  } else {
    // @ts-ignore
    self.IsNfc = false;
  }
  serviceInitializedCB(false, true);
  isInitialized = true;
};
/**
 * connectReader handler call back function.
 * This function is executed when response is received from server for connectReader request.
 * This function is passed as a connectReader call back function while sending connectReader request.
 *
 * @param response describing response from server.
 *  response object has one field 'data' which contains a string in the json format.
 *  This string can be converted to json object by using JSON.parse(response.data);
 *
 * @param error
 */
const connectReaderCB = (response: any, error: any) => {
  if (error !== null) {
    if (Object.keys(error).length) {
      alert(`${error.code} : ${error.message}`);
    }
    ToolkitOB = null;
    serviceInitializedCB(false);
    isInitialized = false;
    return;
  }

  readerClass.getInterfaceType(getInterfaceCB);
};
/**
 * This function is used to initialize the PublicDataWebComponent
 */
export function Initialize(callback: any) {
  serviceInitializedCB = callback || function () {};
  serviceInitializedCB(true);
  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    ToolkitOB = new Toolkit(
      onOpenHandlerCB, /* reference to onOpen call back function */
      closeHandlerCB, /* reference to onClose call back function */
      errorHandlerCB, /* reference to onError call back function */
      options, /* options */
      // callback,
    );
  } catch (e) {
    serviceInitializedCB(false);
    isInitialized = false;
    alert(`Webcomponent Initialization Failed, Details: ${e}`);
  }
}

/**
 * This function is used to read the public data from first reader
 * found.
 */
export function ReadCardData(callback: any) {
  if (readerClass === null || undefined === readerClass) {
    alert('ERROR : Reader is not initialized.');
    return;
  }
  patientIdCB = callback;
  // eslint-disable-next-line no-use-before-define
  const randomStr = generateRandomString(40);
  const requestId = btoa(randomStr);
  /**
   * call the read public data function and pass readPublicDataCB to be executed
   * after the response is received from server
   */
  let address = true;
  // @ts-ignore
  if (self.IsNfc) {
    address = false;
  }
  readerClass.readPublicData(
    requestId,
    true,
    true,
    true,
    true,
    address,
    // eslint-disable-next-line no-use-before-define
    readPublicDataCB,
  );
}

function formatIdNumber(cardNumber: string): string {
  return `${cardNumber.substring(0, 3)}-${
    cardNumber.substring(3, 7)}-${cardNumber.substring(7, 14)}-${cardNumber.substring(14, 15)}`;
}

/**
 * readPublicData handler call back function.
 * This function is executed when response is received from server for readPublicData request.
 * This function is passed as a readPublicData call back function while sending readPublicData request.
 *
 * @param response describing response from server.
 *  response object has one field 'data' which contains a string in the json format.
 *  This string can be converted to json object by using JSON.parse(response.data);
 *
 * @param error
 */
const readPublicDataCB = (response: any, error: any) => {
  if (error === null) {
    if ('iDNumber' in response) {
      let cardNumber = response.iDNumber;
      if (cardNumber.length === 15) {
        cardNumber = formatIdNumber(cardNumber);
      }
      patientIdCB(cardNumber);
    }
  } else if (Object.keys(error).length) {
    patientIdCB(undefined);
    alert(error.message);
  }
};
const generateRandomString = (length: any): string => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const EidServiceStatus = (cb: any) => {
  if (isInitialized) {
    Initialize(cb);
  }
};
