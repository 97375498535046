import React, { ComponentType, useCallback } from 'react';
import Authenticator from './Authenticator';
import { useAuthState, useAuthDispatch } from '../../authentication/context/authContext';
import { isAuthenticatedSelector, isAuthenticatingSelector } from '../../authentication/context/authSelectors';
import { authenticate } from '../../authentication/context/authThunks';

interface Props {
  LoadingComponent: ComponentType,
  AuthenticatedComponent: ComponentType,
  UnAuthenticatedComponent: ComponentType,
}

export const AuthenticatorContainer: React.FC<Props> = (props) => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();

  return (
    <Authenticator
      {...props}
      isAuthenticating={isAuthenticatingSelector(state)}
      isAuthenticated={isAuthenticatedSelector(state)}
      validateUser={useCallback(() => {
        dispatch(authenticate);
      }, [dispatch])}
    />
  );
};

export default AuthenticatorContainer;
