import React from 'react';
import { Button, Steps } from 'custom-test-antd';
import { InlineWidget } from 'react-calendly';
import { AuthCard } from 'components/authentication/auth-card';

import './CallScheduler.pcss';

interface Props {
  name: string,
  email: string,
  calendyUrl: string,
  onSkip: () => void,
}

export const CallScheduler = ({ name, email, calendyUrl, onSkip }: Props) => (
  <AuthCard className="CallScheduler">
    <div className="CallScheduler-stepper">
      <div className="CallScheduler-header">One last step!</div>
      <div className="CallScheduler-text">Thank you for creating an account and onboarding your facilities.</div>
      <Steps
        direction="vertical"
        current={2}
        className="CallScheduler-steps"
        items={[
          {
            title: 'Account created',
          },
          {
            title: 'Onboarded Facility',
          },
          {
            title: 'Schedule a call',
            description: 'Choose a time with our professional RCM team to take things forward.',
          },
        ]}
      />
    </div>
    <InlineWidget
      prefill={{ name, email }}
      pageSettings={{ hideEventTypeDetails: true }}
      url={calendyUrl}
    />
    <Button className="CallScheduler-skip" size="large" type="default" onClick={onSkip}>
      Skip
    </Button>
  </AuthCard>
);

export default CallScheduler;
