import { Dispatch } from 'react';
import isEqual from 'lodash.isequal';

import EligibilityDefaultApi from 'api/eligibility-default/EligibilityDefaultApi';
import CredentialsApi from 'api/credentials/CredentialsApi';
import {
  setFormConfig as setFormConfigAction,
  EligibilityRequestContextState,
  setFormValues as setFormValuesAction,
  setIsInitialValuesLoading,
  setInitialFormValues,
  setValidationErrors,
  setTouchedInputs,
} from './eligibilityRequestReducer';
import { IPortalRequest, PortalRequestFactory } from '../portal-request/portalRequest';
import {
  previousValuesSelector,
  validationErrorsSelector,
  valuesSelector,
} from './eligibilityRequestSelectors';
import { EclaimPortals, InsurancePortal, InsurancePortalLabel } from '../../../../constants/dropdownOptions';
import SinglePortalRequest from '../portal-request/singlePortalRequest';

const validatePortalCredentials = async (values: any) => {
  const { branchId, portal } = values;
  if (!portal || !portal.length) {
    return undefined;
  }
  const formattedPortal = Array.isArray(portal) ? portal : [portal];
  try {
    const credentialPortals = formattedPortal.map((itPortal: InsurancePortal) => {
      if (EclaimPortals.includes(itPortal)) {
        return InsurancePortal.Eclaim;
      }
      return itPortal;
    });
    const credentialsResponse = await CredentialsApi.getList({
      branchId,
      portal: credentialPortals,
    }, { pageSize: credentialPortals.length, current: 1 });
    const credentials = credentialsResponse.data;
    const portalsWithoutCredentials: InsurancePortal[] = formattedPortal.filter((itPortal: InsurancePortal) => {
      const portalCredentials = credentials.find((c) => {
        if (EclaimPortals.includes(itPortal)) {
          return c.portal === InsurancePortal.Eclaim;
        }
        return c.portal === itPortal;
      });
      return !(
        portalCredentials
        && portalCredentials.validationResponse && portalCredentials.validationResponse.isValid
      );
    });
    if (portalsWithoutCredentials.length) {
      return `No credentials for ${portalsWithoutCredentials
        .map((p) => InsurancePortalLabel[p])
        .join(', ')}`;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return undefined;
};

const setFormConfig = (values: any, dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) => {
  const { portal } = values;
  if (!portal || !portal.length) {
    dispatch(setFormConfigAction(undefined));
    return;
  }

  const factory = new PortalRequestFactory();
  const portalRequest = factory.instantiate(portal, dispatch, getState) as IPortalRequest;
  const config = portalRequest.getFormConfig(values, true);
  dispatch(setFormConfigAction(config));
};

export const updateFormConfig = async (dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) => {
  const state = getState();
  const values = valuesSelector(state);
  const previousValues = previousValuesSelector(state);
  if (isEqual(values, previousValues)) {
    return;
  }

  setFormConfig(values, dispatch, getState);
};

export const setFormValues = (
  values: any,
) => async (dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) => {
  const state = getState();
  const currentValues = valuesSelector(state);
  if (isEqual(values, currentValues)) {
    return;
  }
  dispatch(setFormValuesAction(values));
};

export const createPortalRequest = (
  portal: InsurancePortal[] | InsurancePortal,
) => (dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) => {
  const factory = new PortalRequestFactory();
  return factory.instantiate(portal, dispatch, getState) as IPortalRequest;
};

export const validateCredentials = (values: any) => async (
  dispatch: Dispatch<any>,
  getState: () => EligibilityRequestContextState,
) => {
  const state = getState();
  const { portal, branchId } = values;
  const { portal: currentPortal, branchId: currentBranchId } = valuesSelector(state);
  if (portal === currentPortal && branchId === currentBranchId) {
    return validationErrorsSelector(state);
  }

  let error: any = {};
  let touched: any = {};
  const portalError = await validatePortalCredentials({ ...values, branchId });
  if (portalError) {
    error = { portal: portalError };
    touched = { portal: true };
  }

  dispatch(setValidationErrors(error));
  dispatch(setTouchedInputs(touched));

  return error;
};

export const updateInitialValues = (
  values: any,
  fromApi: boolean,
) => async (dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) => {
  const state = getState();
  const { portal, branchId } = values;
  const { portal: currentPortal, branchId: currentBranchId } = valuesSelector(state);
  if (portal === currentPortal && branchId === currentBranchId) {
    return { updated: false };
  }

  dispatch(setIsInitialValuesLoading(true));

  try {
    let portalInitValues = {};
    if (portal && portal.length) {
      const portalRequest = dispatch(createPortalRequest(portal)) as unknown as IPortalRequest;
      portalInitValues = await portalRequest.getInitialValues({ branchId }, fromApi);
    }

    const newValues = {
      ...portalInitValues,
      portal,
      branchId,
    };
    dispatch(setInitialFormValues(newValues));

    return { updated: true, values: newValues };
  } catch (err) {
    return { updated: false };
  } finally {
    dispatch(setIsInitialValuesLoading(false));
  }
};

export const fetchEligibilityDefaultValues = (
  eligibilityDefaultId?: string,
) => async (dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) => {
  try {
    if (eligibilityDefaultId) {
      dispatch(setIsInitialValuesLoading(true));
      const eligibilityDefault = await EligibilityDefaultApi.getById(eligibilityDefaultId);
      const eligibilityDefaultValues = {
        ...eligibilityDefault.payload,
        branchId: eligibilityDefault.branchId,
        portal: eligibilityDefault.portal,
      };

      const { portal } = eligibilityDefault;
      const portalRequest = dispatch(
        createPortalRequest(portal as InsurancePortal),
      ) as unknown as SinglePortalRequest;
      const valuesForForm = portalRequest.getValuesForForm(eligibilityDefaultValues);

      dispatch(setInitialFormValues(valuesForForm));
      dispatch(setFormValuesAction(valuesForForm));

      setFormConfig(valuesForForm, dispatch, getState);
    }
  } finally {
    dispatch(setIsInitialValuesLoading(false));
  }
};
