import React from 'react';
import { Card, CardProps } from 'custom-test-antd';
import { isMobile } from '../../../../utils/mobileDetector';

import './AccountLayoutCard.pcss';

const AccountLayoutCardInternal: React.FC<CardProps> = ({ children, className, ...other }) => (
  <Card
    className={`AccountLayoutCard ${className ?? ''} ${isMobile() ? 'mobile' : ''}`}
    {...other}
  >
    {children}
  </Card>
);

interface TitleProps {
  title?: React.ReactNode,
  titleId?: string,
  description?: React.ReactNode,
}

const Title: React.FC<TitleProps> = ({ title, description, titleId }) => {
  const titleProps: { id?: string } = {};
  if (titleId) {
    titleProps.id = titleId;
  }
  return (
    <>
      {
        title
        && <div {...titleProps}>{title}</div>
      }
      {
        description
        && <div className="AccountLayoutCard-description">{description}</div>
      }
    </>
  );
};

type AccountLayoutCardTypeInternal = typeof AccountLayoutCardInternal;

export interface AccountLayoutCardType extends AccountLayoutCardTypeInternal {
  Title: typeof Title;
}

export const AccountLayoutCard = AccountLayoutCardInternal as AccountLayoutCardType;
AccountLayoutCard.Title = Title;

export default AccountLayoutCard;
