import React from 'react';
import { FieldProps } from 'formik';
import { InputRef } from 'custom-test-antd';
import {
  MaskedInput as _MaskedInput,
  MaskedInputProps as _MaskedInputProps,
} from '../../fields/MaskedInput';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { makeField } from './makeField';

type MaskedInputProps = FieldProps & _MaskedInputProps;

export { _MaskedInputProps as MaskedInputProps };

type MaskedInputFieldProps = FormikFieldProps & _MaskedInputProps;

const MaskedInput = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      onBlur: _onBlur,
      ...restProps
    }: MaskedInputProps,
    ref: React.Ref<InputRef>,
  ) => {
    const {
      value, onChange, onBlur, name,
    } = field;
    return (
      <_MaskedInput
        name={name}
        ref={ref}
        value={value}
        onChange={(event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }}
        onBlur={(event) => {
          onBlur(event);
          if (_onBlur) {
            _onBlur(event);
          }
        }}
        {...restProps}
      />
    );
  },
);

export const MaskedInputField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: MaskedInputFieldProps,
    ref: React.Ref<InputRef>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <MaskedInput ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

export const MaskedInputWrapper = makeField<_MaskedInputProps>(MaskedInput);
