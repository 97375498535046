import React from 'react';
import { Card } from 'custom-test-antd';

import './CapitalProposal.pcss';

import { AcceptProposal } from './accept-proposal';
import { SignProposal } from './sign-proposal';
import { ProposalInfo } from './proposal-info';
import { ProposalDetailTable } from './proposal-detail-table';
import { ClientInfo } from './client-info';
import { ProposalProcessContainer } from '../overview/proposal-process';
import { Customer } from '../../../api/financing/customer/financingCustomerTypes';

interface Props {
  customer?: Customer;
  onAccept: (attachPdf: boolean) => void;
  onSign: () => void;
  showSummaryTableRows: boolean;
  showAcceptAction: boolean;
  showSignAction: boolean;
  showProposalProcess: boolean;
  proposal: any;
}

export const CapitalProposal = ({
  onAccept,
  showSummaryTableRows,
  showAcceptAction,
  showSignAction,
  showProposalProcess,
  onSign,
  proposal,
  customer,
}: Props) => {
  if (!proposal) {
    return null;
  }

  return (
    <div className="CapitalProposal">
      <Card className="CapitalProposal-infoCard">
        <ClientInfo
          customer={customer}
          className="CapitalProposal-client"
        />
        <ProposalInfo
          className="CapitalProposal-proposal"
          issuedDate={proposal.issuedDate}
          validDate={proposal.validDate}
        />
        <div>
          <div className="CapitalProposal-detailsHeader">
            Proposal details
          </div>
          <ProposalDetailTable
            dataSource={proposal.details}
            showSummaryTableRows={showSummaryTableRows}
            totalClaims={proposal.totalClaims}
            totalNetAmount={proposal.totalNetAmount}
            totalDiscount={proposal.totalDiscount}
            totalPurchasePrice={proposal.totalAmount}
          />
        </div>
      </Card>
      {
        showProposalProcess
        && (
          <ProposalProcessContainer
            className="CapitalProposal-actionCard"
          />
        )
      }
      {
        showAcceptAction
        && (
          <AcceptProposal
            className="CapitalProposal-actionCard"
            totalAmount={proposal.totalAmount}
            onAccept={onAccept}
          />
        )
      }
      {
        showSignAction
        && (
          <SignProposal
            className="CapitalProposal-actionCard"
            totalAmount={proposal.totalAmount}
            onSign={onSign}
          />
        )
      }
    </div>
  );
};

export default CapitalProposal;
