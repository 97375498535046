import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

import { showErrors, submitValidate } from 'utils/form-helpers/antd-formik';
import 'utils/form-helpers/yup-password';
import 'utils/form-helpers/yup-phone';
import { ApiError } from 'api';

import SignUp from './SignUp';
import { useAuthDispatch } from '../context/authContext';
import { publicRoutes } from '../../application/routes/routesConstants';
import { createRegistrationIntention } from '../context/authThunks';
import { CreatedIntention } from '../../../api/authentication/authenticationTypes';

const signInSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().password().required('Required'),
  phone: Yup.string().required('Phone is required').phone(undefined, false, 'Invalid phone'),
  organization_name: Yup.string().required('Required'),
});

export const SignUpContainer = () => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  return (
    <SignUp
      initialValues={{}}
      onSubmit={useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (!submitValidate(values, signInSchema, formikHelpers)) {
          return;
        }
        try {
          const result = (await dispatch(createRegistrationIntention(values))) as unknown as CreatedIntention;
          navigate(publicRoutes.confirmEmailSent, { state: { email: values.email, intentionId: result.intentionId } });
        } catch (error: any) {
          if (error instanceof ApiError && error.response.status === 422) {
            const errorObject = await error.response.json();
            if (errorObject.type === 'Error') {
              formikHelpers.setErrors(errorObject.error);
              formikHelpers.setTouched({ email: true }, false);
            } else if (errorObject.type === 'ValidationError') {
              showErrors(new Yup.ValidationError(errorObject.error), formikHelpers);
            }
          }
        }
      }, [dispatch, navigate])}
    />
  );
};

export default SignUpContainer;
