import type { Moment } from 'moment-timezone';
import { ApiError, commonFetch, encodeQueryFilter } from '../apiHelper';
import {
  Eligibility,
  EligibilityRequestDto,
  PortalLogout,
  ReportType,
  UserUsageDashboardItem,
} from './eligibilityTypes';

interface EligibilityFilter {
  search?: string,
  dates?: Moment[],
  status?: string[],
  portal?: string[],
  branchId?: string[],
  patientId?: string,
  patientIdType?: string,
  userId?: string[],
}

export const eligibilityFilterToApiFilter = (filter: EligibilityFilter) => {
  const requestFilter: any = {};
  if (filter.search) {
    requestFilter.search = filter.search;
  }
  if (filter.status) {
    requestFilter.status = filter.status;
  }
  if (filter.portal) {
    requestFilter.portal = filter.portal;
  }
  if (filter.branchId) {
    requestFilter.branchId = filter.branchId;
  }
  if (filter.userId) {
    requestFilter.userId = filter.userId;
  }
  if (filter.patientIdType) {
    requestFilter.patientIdType = filter.patientIdType;
  }
  if (filter.patientId) {
    requestFilter.patientId = filter.patientId;
  }
  if (filter.dates) {
    if (filter.dates?.[0]) {
      requestFilter.startDate = filter.dates[0].toISOString();
    }
    if (filter.dates?.[1]) {
      requestFilter.endDate = filter.dates[1].toISOString();
    }
  }

  return requestFilter;
};

export class EligibilityApi {
  static createEligibilityRequest = async (payload: EligibilityRequestDto) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static createMultiPortalRequest = async (payload: EligibilityRequestDto[]) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility/multi-portal`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getEligibilityResponse = async (id: string) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility/${id}`, {
      method: 'GET',
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static portalLogout = async (id: string): Promise<PortalLogout> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/eligibility/${id}/portal-logout`, {
      method: 'POST',
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getMetlifeDependentCodes = async (
    branchId: string,
    policyNumber: string,
    certificateNumber: string,
  ): Promise<{ label: string, value: string }[]> => {
    const filter = encodeQueryFilter({ policyNumber, certificateNumber, branchId });
    const response = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/eligibility/metlife/dependent-codes${filter}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getList = async (filter: EligibilityFilter, pagination: {
    pageSize: number,
    current: number,
  }): Promise<{ data: Eligibility[], count: number }> => {
    let requestFilter: any = {
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
    };
    const apiFilter = eligibilityFilterToApiFilter(filter);
    requestFilter = { ...requestFilter, ...apiFilter };
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/eligibility${encodeQueryFilter(requestFilter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getReport = async (filter: EligibilityFilter, reportType: ReportType) => {
    const apiFilter = eligibilityFilterToApiFilter(filter);
    let requestURL;
    if (reportType === ReportType.Csv) {
      requestURL = `${process.env.REACT_APP_BACKEND_URL}/eligibility/csv${encodeQueryFilter(apiFilter)}`;
    } else {
      requestURL = `${process.env.REACT_APP_BACKEND_URL}/eligibility/xlsx${encodeQueryFilter(apiFilter)}`;
    }
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.blob();
    }
    throw new ApiError(backendResponse);
  };

  static getUserUsage = async (
    startDate: Moment,
    endDate: Moment,
    branches?: string[],
  ): Promise<UserUsageDashboardItem[]> => {
    const filter: any = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    if (branches) {
      filter.branchIds = branches;
    }
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/eligibility/user-usage${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default EligibilityApi;
