import * as Yup from 'yup';
import produce from 'immer';
import 'utils/form-helpers/yup-emirates-id';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';
import { adnicCountryCode, AdnicUserType, adnicUserType, adnicVisitCategory } from './types';

const initialValue = {
  patientIdType: AdnicUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
  fetchAuthorizationId: false,
};

export class AdnicRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any, extended?: boolean): PortalConfig {
    const config: PortalConfig = {
      patientIdType: adnicUserType,
      patientId: undefined,
      emiratesId: true,
    };

    const { patientIdType, fetchAuthorizationId } = values;
    if (patientIdType && patientIdType !== AdnicUserType.EmiratesId) {
      config.emiratesId = false;
      config.patientId = calcPatientId(patientIdType, adnicUserType);
    }
    if (extended) {
      config.fetchAuthorizationId = true;
      config.countryCode = undefined;
      config.mobileNumber = false;
      config.visitCategory = undefined;
      config.clinicianLicense = false;

      if (fetchAuthorizationId) {
        config.countryCode = adnicCountryCode;
        config.mobileNumber = true;
        config.visitCategory = adnicVisitCategory;
        config.clinicianLicense = true;
      }
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== AdnicUserType.EmiratesId
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === AdnicUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
      fetchAuthorizationId: Yup.boolean().required('Required'),
      countryCode: Yup.string().when('fetchAuthorizationId', {
        is: true,
        then: (schema) => schema.required('Country code is required'),
        otherwise: (schema) => schema,
      }),
      mobileNumber: Yup.string().when('fetchAuthorizationId', {
        is: true,
        then: (schema) => schema.required('Mobile number is required'),
        otherwise: (schema) => schema,
      }),
      visitCategory: Yup.string().when('fetchAuthorizationId', {
        is: true,
        then: (schema) => schema.required('Visit category is required'),
        otherwise: (schema) => schema,
      }),
      clinicianLicense: Yup.string().when('fetchAuthorizationId', {
        is: true,
        then: (schema) => schema.required('Licensee is required'),
        otherwise: (schema) => schema,
      }),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return AdnicUserType.EmiratesId;
  }

  getValuesForSubmission(values: any, enableValuesValidation: boolean = true) {
    const parentValues = super.getValuesForSubmission(values, enableValuesValidation);
    return produce(parentValues, (draft: any) => {
      if (!values.fetchAuthorizationId) {
        // eslint-disable-next-line no-param-reassign
        delete draft.countryCode;
        // eslint-disable-next-line no-param-reassign
        delete draft.mobileNumber;
        // eslint-disable-next-line no-param-reassign
        delete draft.visitCategory;
        // eslint-disable-next-line no-param-reassign
        delete draft.clinicianLicense;
      }
      return draft;
    });
  }
}

export default AdnicRequest;
