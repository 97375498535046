import React from 'react';
import { Button } from 'custom-test-antd';

import './SignDocumentsStep.pcss';

interface Props {
  docuSignLink: string;
}

export const SignDocumentsStep = ({ docuSignLink }: Props) => (
  <div className="SignDocumentsStep">
    <Button
      size="large"
      type="primary"
      onClick={() => {
        window.open(docuSignLink, '_blank');
      }}
    >
      Continue to&nbsp;
      <span className="SignDocumentsStep-docuSign">DocuSign</span>
    </Button>
  </div>
);

export default SignDocumentsStep;
