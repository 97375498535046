import { Dispatch } from 'react';
import { BranchApi, OrganizationApi } from 'api';
import { RbacRoleKeys } from 'constants/rbac';
import {
  setOnboardStep,
  OnboardStep,
  UserOnboardingContextState,
  setBranches,
} from './userOnboardingReducer';
import { branchRequiredSelector, callRequiredSelector } from './userOnboardingSelectors';
import { UserProfile } from '../../../api/user-profile/userProfileTypes';

// eslint-disable-next-line import/prefer-default-export
export const initOnboardStep = (user: UserProfile) => (
  async (dispatch: Dispatch<any>, getState: () => UserOnboardingContextState) => {
    if ([RbacRoleKeys.SystemAdmin, RbacRoleKeys.SystemUser].includes(user.rbac_role_id)) {
      const branches = await BranchApi.getUserBranches();
      dispatch(setBranches(branches));
      dispatch(setOnboardStep(undefined));
      return;
    }
    const state = getState();
    const branchRequired = branchRequiredSelector(state);
    const callRequired = callRequiredSelector(state);
    if (branchRequired) {
      dispatch(setOnboardStep(OnboardStep.Loading));
      try {
        const { count } = await OrganizationApi.getOrganizationBranchesCount(user.organization.id);
        if (count) {
          const branches = await BranchApi.getUserBranches();
          dispatch(setBranches(branches));
        } else {
          dispatch(setOnboardStep(OnboardStep.EnterBranch));
          return;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(setOnboardStep(OnboardStep.EnterBranch));
        return;
      }
    }
    if (callRequired) {
      dispatch(setOnboardStep(OnboardStep.Loading));
      try {
        const access = user.organization.is_sales_call_skipped;
        if (access) {
          dispatch(setOnboardStep(undefined));
        } else {
          dispatch(setOnboardStep(OnboardStep.ScheduleCall));
          return;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(setOnboardStep(OnboardStep.ScheduleCall));
      }
    }
  }
);
