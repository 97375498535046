import React from 'react';
import { Button, Spin } from 'custom-test-antd';
import { ErrorMessageText } from './error-message-text';
import './ErrorMessage.pcss';
import { InsurancePortal, InsurancePortalLabel } from '../../../../constants/dropdownOptions';
import {
  Eligibility,
  EligibilityErrorType,
  EligibilityRequestStatus,
} from '../../../../api/eligibility/eligibilityTypes';

export interface Props {
  eligibility: Pick<Eligibility, 'id' | 'error' | 'portal' | 'status'>;
  loading: boolean;
  onLogout: () => void;
}

export const ErrorMessage = ({ eligibility, loading, onLogout }: Props) => {
  if (!eligibility.error) {
    return <ErrorMessageText error="Something went wrong." />;
  }
  if (
    eligibility.portal === InsurancePortal.Adnic
    && eligibility.error.type === EligibilityErrorType.SessionAlreadyInUse
  ) {
    return (
      <Spin spinning={loading} wrapperClassName="ErrorMessage-spin">
        <ErrorMessageText error={eligibility.error.message}>
          <Button type="link" onClick={onLogout}>Logout</Button>
        </ErrorMessageText>
      </Spin>
    );
  }
  const message = typeof eligibility.error?.message === 'string'
    ? eligibility.error.message
    : JSON.stringify(eligibility.error);

  return (
    <div>
      {
        eligibility.status === EligibilityRequestStatus.PortalError
        && <div>{`${InsurancePortalLabel[eligibility.portal as InsurancePortal]} response:`}</div>
      }
      <ErrorMessageText error={message} />
    </div>
  );
};

export default ErrorMessage;
