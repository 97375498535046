import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Spin, Form, Button } from 'custom-test-antd';

import './LicenseEdit.pcss';

import { PractitionerLicense } from 'api/practitioner-license/practitionerLicenseTypes';
import { InputWrapper } from '../../../utils/form-helpers/antd-formik';
import { Card } from '../../eligibility/card';

interface Props {
  license?: PractitionerLicense;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any;
  licenseFetching: boolean;
}

export const LicenseEdit = ({ license, onSubmit, licenseFetching }: Props) => (
  <Card>
    <Formik
      initialValues={license ?? {}}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <Spin spinning={isSubmitting || licenseFetching}>
          <Form
            className="LicenseEdit-form"
          >
            <InputWrapper
              size="large"
              gray
              antFieldProps={{ label: 'Name' }}
              name="name"
              placeholder="Name"
            />
            <InputWrapper
              size="large"
              gray
              antFieldProps={{ label: 'License' }}
              name="license"
              placeholder="License"
            />
            <div className="LicenseEdit-buttonsSection">
              <Button
                size="large"
                htmlType="submit"
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                type="primary"
              >
                Save
              </Button>
            </div>
          </Form>
        </Spin>
      )}
    </Formik>
  </Card>
);

export default LicenseEdit;
