import React from 'react';
import { Card, Anchor, Affix, Button } from 'custom-test-antd';

import './AccountSettings.pcss';
import { isMobile } from '../../../utils/mobileDetector';
import SettingsCell from './settings-cell/SettingsCell';
import SecurityCell from './security-cell/SecurityCell';
import { AccountLayoutCard } from './account-layout-card';
import { UserProfile } from '../../../api/user-profile/userProfileTypes';
import { RbacRoleKeys } from '../../../constants/rbac';
import { LoginAsUserContainer } from './login-as-user';

interface Props {
  onFullNameEdit: () => void,
  onPhoneEdit: () => void,
  onEmailEdit: () => void,
  onChangePassword: () => void,
  user: UserProfile,
}

interface State {
  affixWidth: number;
}

export class AccountSettings extends React.PureComponent<Props, State> {
  affixRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      affixWidth: 0,
    };
    this.affixRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ affixWidth: this.affixRef.current.placeholderNode.clientWidth });
  }

  render() {
    const { onFullNameEdit, onPhoneEdit, onChangePassword, onEmailEdit, user } = this.props;
    const { affixWidth } = this.state;
    return (
      <div className="AccountSettings">
        <Affix
          style={{
            display: isMobile() ? 'none' : 'block',
            left: `-${affixWidth + 24}px`,
          }}
          className="AccountSettings-affix"
          offsetTop={20}
          ref={this.affixRef}
        >
          <Card
            className="AccountSettings-anchorCard"
          >
            <Anchor>
              <Anchor.Link href="#profile-info" title="Profile info" />
              <Anchor.Link href="#contact-info" title="Contact info" />
              <Anchor.Link href="#security-info" title="Security & Login" />
              {
                user!.rbac_role_id === RbacRoleKeys.SystemAdmin
                && (
                  <Anchor.Link href="#login-as-user" title="Login as user" />
                )
              }
            </Anchor>
          </Card>
        </Affix>
        <AccountLayoutCard
          className="AccountSettings-card"
          title={
            <AccountLayoutCard.Title title="Profile info" titleId="profile-info" />
          }
        >
          <SettingsCell
            className="AccountSettings-infoCell"
            label="Full Name"
            value={user.name}
            onEdit={onFullNameEdit}
          />
        </AccountLayoutCard>
        <AccountLayoutCard
          className="AccountSettings-card"
          title={
            <AccountLayoutCard.Title title="Contact info" titleId="contact-info" />
          }
        >
          <SettingsCell
            className="AccountSettings-infoCell"
            label="Email"
            value={user.email}
            onEdit={onEmailEdit}
          />
          <SettingsCell
            className="AccountSettings-infoCell"
            label="Phone"
            value={user.phone}
            onEdit={onPhoneEdit}
          />
        </AccountLayoutCard>
        <AccountLayoutCard
          className="AccountSettings-card"
          title={
            <AccountLayoutCard.Title title="Security & Login" titleId="security-info" />
          }
        >
          <SecurityCell
            className="AccountSettings-securityCell"
            title="Password"
            description="Changing your password will sign you out everywhere except
            for devices you use to verify it’s you when signing in."
          >
            <Button
              className="AccountSettings-changePasswordButton"
              type="link"
              onClick={onChangePassword}
            >
              Change password
            </Button>
          </SecurityCell>
        </AccountLayoutCard>
        {
          user!.rbac_role_id === RbacRoleKeys.SystemAdmin
          && (
            <AccountLayoutCard
              className="AccountSettings-card"
              title={
                <AccountLayoutCard.Title title="Login as user" titleId="login-as-user" />
              }
            >
              <LoginAsUserContainer />
            </AccountLayoutCard>
          )
        }
      </div>
    );
  }
}

export default AccountSettings;
