import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import { FormikHelpers } from 'formik';

import { submitValidate } from 'utils/form-helpers/antd-formik';

import CredentialsForm from './CredentialsForm';
import { BranchApi, CredentialsApi, ApiError } from '../../../api';
import { privateRoutes } from '../../application/routes/routesConstants';
import { BranchData } from '../../../api/branch/branchTypes';
import getValidationSchema from '../validation-schemas';
import { OTP_REQUIRED_ERROR } from '../constants/credentials';
import showOtpModal, { ModalInstance } from '../otp-form/OtpFormModal';

export const CredentialsFormContainer = () => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<BranchData[]>([]);
  const [credentials, setCredentials] = useState({});
  const [credentialsFetching, setCredentialsFetching] = useState(false);
  const otpRequired = useRef(false);
  const otpRequestId = useRef('');
  const otpModal = useRef<ModalInstance | undefined>(undefined);
  const { credentialsId } = useParams();
  const onCredentialsSubmit = useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (!submitValidate(values, getValidationSchema(otpRequired.current), formikHelpers) || !credentialsId) {
      return false;
    }
    try {
      const otp = values.otp
        ? {
          otp: values.otp,
          requestId: otpRequestId.current,
        }
        : undefined;
      await CredentialsApi.updateCredentials(credentialsId, values, otp);

      notification.success({
        message: 'Success',
        description: 'Credentials updated successfully',
      });
      navigate(privateRoutes.credentialList);
      return true;
    } catch (error: any) {
      let errorMessage = error.toString();
      if (error instanceof ApiError && error.response?.status === 400) {
        const errorObject = await error.response.json();
        errorMessage = errorObject.message;

        if (errorMessage === OTP_REQUIRED_ERROR) {
          otpRequestId.current = errorObject.requestId;

          if (!otpRequired.current) {
            otpRequired.current = true;
            otpModal.current = showOtpModal({
              onSubmit: async (otpValues: any, modalFormikHelpers: FormikHelpers<any>) => {
                const submitted = await onCredentialsSubmit({ ...values, ...otpValues }, modalFormikHelpers);
                if (submitted) {
                  otpModal.current?.destroy();
                }
              },
              onClose: () => {
                otpRequired.current = false;
              },
            });
          }

          return false;
        }
      }

      notification.error({
        message: 'Error',
        description: errorMessage,
      });
      return false;
    }
  }, [navigate, credentialsId]);
  return (
    <CredentialsForm
      branches={branches}
      onFetchBranches={useCallback(async () => {
        const fetchedBranches = await BranchApi.getUserBranches();
        setBranches(fetchedBranches);
      }, [])}
      credentials={credentials}
      credentialsFetching={credentialsFetching}
      onFetchCredentials={useCallback(async () => {
        if (!credentialsId) {
          return;
        }
        setCredentialsFetching(true);
        try {
          const fetchedCredentials = await CredentialsApi.getCredentials(credentialsId);
          setCredentials(fetchedCredentials);
        } finally {
          setCredentialsFetching(false);
        }
      }, [credentialsId])}
      onSubmit={onCredentialsSubmit}
    />
  );
};

export default CredentialsFormContainer;
