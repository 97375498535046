import React, { useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Formik, Form } from 'formik';
import { Button, Popover, Input, Badge } from 'custom-test-antd';
import { SelectField, DatePickerField } from 'utils/form-helpers/antd-formik';
import { DropdownOptionString, InsurancePortal, InsurancePortalLabel } from '../../../../constants/dropdownOptions';

import './EligibilityListFilter.pcss';
import { EligibilityListContextState, OrganizationUser } from '../context/eligibilityListReducer';
import { BranchData } from '../../../../api/branch/branchTypes';

interface Props {
  className?: string,
  filter: EligibilityListContextState['filter'],
  onApply: (filter: EligibilityListContextState['filter']) => void,
  branches: BranchData[],
  users: OrganizationUser[],
}

const eligibilityStatues: DropdownOptionString[] = [
  { label: 'Pending', value: 'pending' },
  { label: 'Eligible', value: 'eligible' },
  { label: 'Not Eligible', value: 'notEligible' },
  { label: 'Portal Notice', value: 'portalError' },
  { label: 'Error', value: 'error' },
];

export const insurancePortals: DropdownOptionString[] = [
  { label: InsurancePortalLabel[InsurancePortal.Axa], value: InsurancePortal.Axa },
  { label: InsurancePortalLabel[InsurancePortal.Daman], value: InsurancePortal.Daman },
  { label: InsurancePortalLabel[InsurancePortal.Nas], value: InsurancePortal.Nas },
  { label: InsurancePortalLabel[InsurancePortal.Neuron], value: InsurancePortal.Neuron },
  { label: InsurancePortalLabel[InsurancePortal.Nextcare], value: InsurancePortal.Nextcare },
  { label: InsurancePortalLabel[InsurancePortal.Almadallah], value: InsurancePortal.Almadallah },
  { label: InsurancePortalLabel[InsurancePortal.Oman], value: InsurancePortal.Oman },
  { label: InsurancePortalLabel[InsurancePortal.Mednet], value: InsurancePortal.Mednet },
  { label: InsurancePortalLabel[InsurancePortal.Adnic], value: InsurancePortal.Adnic },
  { label: InsurancePortalLabel[InsurancePortal.Metlife], value: InsurancePortal.Metlife },
];

export const eclaimInsurancePortals: DropdownOptionString[] = [
  { label: InsurancePortalLabel[InsurancePortal.Abnic], value: InsurancePortal.Abnic },
  { label: InsurancePortalLabel[InsurancePortal.Inayah], value: InsurancePortal.Inayah },
  { label: InsurancePortalLabel[InsurancePortal.MSH], value: InsurancePortal.MSH },
  { label: InsurancePortalLabel[InsurancePortal.NGI], value: InsurancePortal.NGI },
];

export const EligibilityListFilter: React.FC<Props> = ({ className, filter, onApply, branches, users }) => {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const filterButtonFormValues = { ...filter, search: undefined };
  const filterButtonIsActive = Object.entries(filterButtonFormValues).reduce((acc, [, value]) => {
    if (value && Array.isArray(value)) {
      return acc || value.some((v: any) => !!v);
    }
    return acc;
  }, false);

  return (
    <div className={className}>
      <Input.Search
        className="EligibilityListFilter-search"
        placeholder="Search"
        onSearch={(search) => onApply({ ...filter, search })}
        size="large"
        gray
      />
      <Popover
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
        placement="bottomLeft"
        content={(
          <Formik
            initialValues={filterButtonFormValues}
            onSubmit={(values) => {
              onApply({ ...values, search: filter.search });
              hide();
            }}
          >
            {() => (
              <Form>
                <div className="EligibilityListFilter-popupContent">
                  <div className="EligibilityListFilter-filterItem">
                    <div className="EligibilityListFilter-label">Status</div>
                    <SelectField gray name="status" mode="multiple" placeholder="Status">
                      {eligibilityStatues.map((status) => (
                        <SelectField.Option key={status.value} value={status.value}>{status.label}</SelectField.Option>
                      ))}
                    </SelectField>
                  </div>
                  <div className="EligibilityListFilter-filterItem">
                    <div className="EligibilityListFilter-label">Portal</div>
                    <SelectField gray name="portal" mode="multiple" placeholder="Portal">
                      <SelectField.OptGroup label="Common">
                        {insurancePortals.map((portal) => (
                          <SelectField.Option
                            key={portal.value}
                            value={portal.value}
                          >
                            {portal.label}
                          </SelectField.Option>
                        ))}
                      </SelectField.OptGroup>
                      <SelectField.OptGroup label="Eclaim">
                        {eclaimInsurancePortals.map((portal) => (
                          <SelectField.Option
                            key={portal.value}
                            value={portal.value}
                          >
                            {portal.label}
                          </SelectField.Option>
                        ))}
                      </SelectField.OptGroup>
                    </SelectField>
                  </div>
                  <div className="EligibilityListFilter-filterItem">
                    <div className="EligibilityListFilter-label">Dates</div>
                    <DatePickerField.RangePicker gray name="dates" />
                  </div>
                  <div className="EligibilityListFilter-filterItem">
                    <div className="EligibilityListFilter-label">Branch</div>
                    <SelectField
                      gray
                      name="branchId"
                      mode="multiple"
                      placeholder="Branch"
                      dropdownMatchSelectWidth={false}
                      optionFilterProp="label"
                    >
                      {
                        branches.map((branch) => (
                          <SelectField.Option
                            key={branch.identifier}
                            value={branch.identifier}
                            label={`${branch.identifier} ${branch.name}`}
                          >
                            {`[${branch.identifier}] ${branch.name}`}
                          </SelectField.Option>
                        ))
                      }
                    </SelectField>
                  </div>
                  <div className="EligibilityListFilter-filterItem">
                    <div className="EligibilityListFilter-label">User</div>
                    <SelectField
                      gray
                      name="userId"
                      mode="multiple"
                      placeholder="Branch"
                      dropdownMatchSelectWidth={false}
                      optionFilterProp="label"
                    >
                      {
                        users.map((user) => (
                          <SelectField.Option key={user.id} value={user.id} label={user.name}>
                            {user.name}
                          </SelectField.Option>
                        ))
                      }
                    </SelectField>
                  </div>
                  <div className="EligibilityListFilter-buttonSection">
                    <Button htmlType="submit" type="gray">Apply</Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      >
        <Badge dot={filterButtonIsActive}>
          <Button
            icon={<FilterOutlined />}
            className="EligibilityListFilter-filterButton"
            type="gray"
            size="large"
          />
        </Badge>
      </Popover>
    </div>

  );
};
export default EligibilityListFilter;
