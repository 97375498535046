import React from 'react';

interface Props {
  children: React.ReactNode,
}

const HyperlinkText = ({ children, ...other }: Props) => {
  if (typeof children === 'string') {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return (
      <span {...other}>
        {children.split(urlRegex).map((subString, index) => {
          if (subString.match(urlRegex)) {
            // eslint-disable-next-line react/no-array-index-key
            return (<a target="_blank" rel="noreferrer" href={subString} key={index}>{subString}</a>);
          }
          return subString;
        })}
      </span>
    );
  }
  return (
    <span {...other}>{children}</span>
  );
};

export default HyperlinkText;
