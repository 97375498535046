import React from 'react';
import { Tabs, Spin, Popover, Divider } from 'custom-test-antd';

import './EligibilityResponsePopup.pcss';

import { Eligibility } from '../../../../api/eligibility/eligibilityTypes';
import { Benefits } from '../../eligibility-response/benefits';
import { Note } from '../../eligibility-response/note';

interface Props {
  loading: boolean;
  eligibility?: Eligibility;
  onResponseFetch: () => void;
  children: React.ReactNode;
}

class EligibilityResponsePopupPopup extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.renderResponseForm = this.renderResponseForm.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  onVisibilityChange(visible: boolean) {
    if (visible) {
      const { onResponseFetch } = this.props;
      onResponseFetch();
    }
  }

  renderResponseForm(index: number) {
    const { eligibility } = this.props;

    if (!eligibility) {
      return null;
    }

    return (
      <div>
        <span className="EligibilityResponsePopup-label">NETWORK</span>
        <span className="EligibilityResponsePopup-value">{eligibility.response?.[index]?.network}</span>
        {
          (
            (eligibility.response?.[index]?.copay && eligibility.response?.[index]?.copay.length !== 0)
            || eligibility.response?.[index]?.maxConsul
            || eligibility.response?.[index]?.deductable
          )
          && (
            <Note
              className="EligibilityResponsePopup-note"
              note={eligibility.response?.[index].copay}
              maxConsul={eligibility.response?.[index]?.maxConsul}
              deductable={eligibility.response?.[index]?.deductable}
            />
          )
        }
        <Divider />
        <Benefits tableSize="small" benefits={eligibility.response?.[index].benefits} />
      </div>
    );
  }

  render() {
    const { eligibility, loading, children } = this.props;

    let content;
    if (!eligibility) {
      content = (
        <Spin spinning wrapperClassName="EligibilityResponse-spin" tip="Loading..." />
      );
    } else if (eligibility?.response?.length > 1) {
      content = (
        <Spin spinning={loading} wrapperClassName="EligibilityResponsePopup-spin">
          <Tabs className="EligibilityResponsePopup-tabs">
            {
              eligibility?.response.map((r, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tabs.TabPane tab={r.patientName || '-'} key={i}>
                  {this.renderResponseForm(i)}
                </Tabs.TabPane>
              ))
            }
          </Tabs>
        </Spin>
      );
    } else {
      content = (
        <Spin wrapperClassName="EligibilityResponsePopup-spin" spinning={loading}>
          {this.renderResponseForm(0)}
        </Spin>
      );
    }

    return (
      <Popover
        content={content}
        onOpenChange={this.onVisibilityChange}
        placement="right"
        overlayClassName="EligibilityResponsePopup-overlay"
      >
        {children}
      </Popover>
    );
  }
}

export default EligibilityResponsePopupPopup;
