import produce from 'immer';
import { FacilitiesPortal } from '../../../../api/branch/branchTypes';

export enum FacilityCheckStatus {
  Valid = 'valid',
  Error = 'error',
  Checking = 'checking',
}

export interface Facility {
  name: string;
  login: string;
  status: FacilityCheckStatus;
  password: string;
  branchId: string;
  id: string;
  error?: any;
  validationToken?: string;
  portal: FacilitiesPortal;
}

export interface OnboardFacilityContextState {
  branches: Facility[];
  portal: FacilitiesPortal;
  submittingAllFacilities: boolean;
  currentCheck: {
    id: string;
  } | undefined;
  error?: string;
}

export const initialState = (initState?: any): OnboardFacilityContextState => ({
  branches: [],
  portal: FacilitiesPortal.eClaimLink,
  submittingAllFacilities: false,
  currentCheck: undefined,
  error: undefined,
});

export const SET_BRANCHES = 'SET_BRANCHES';
export const setBranches = (branches: Facility[]) => ({
  type: SET_BRANCHES, payload: { branches },
});

export const ADD_BRANCH = 'ADD_BRANCH';
export const addBranch = (branch: Facility) => ({
  type: ADD_BRANCH, payload: { branch },
});

export const REMOVE_BRANCH = 'REMOVE_BRANCH';
export const removeBranch = (branchId: string) => ({
  type: REMOVE_BRANCH, payload: { branchId },
});

export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const updateBranch = (branch: Facility) => ({
  type: UPDATE_BRANCH, payload: { branch },
});

export const SET_PORTAL = 'SET_PORTAL';
export const setPortal = (portal: FacilitiesPortal) => ({
  type: SET_PORTAL, payload: { portal },
});

export const SET_SUBMITTING_ALL_FACILITIES = 'SET_SUBMITTING_ALL_FACILITIES';
export const setSubmitting = (submitting: boolean) => ({
  type: SET_SUBMITTING_ALL_FACILITIES, payload: { submitting },
});

export const SET_CURRENT_CHECK = 'SET_CURRENT_CHECK';
export const setCurrentCheck = (check: OnboardFacilityContextState['currentCheck']) => ({
  type: SET_CURRENT_CHECK, payload: { check },
});

export const SET_ERROR = 'SET_ERROR';
export const setError = (error?: string) => ({
  type: SET_ERROR, payload: { error },
});

const onboardFacilityReducer = (state: OnboardFacilityContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_ERROR: {
      const { error } = (action as ReturnType<typeof setError>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.error = error;
      break;
    }
    case SET_CURRENT_CHECK: {
      const { check } = (action as ReturnType<typeof setCurrentCheck>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.currentCheck = check;
      break;
    }
    case SET_PORTAL: {
      const { portal } = (action as ReturnType<typeof setPortal>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.portal = portal;
      break;
    }
    case SET_SUBMITTING_ALL_FACILITIES: {
      const { submitting } = (action as ReturnType<typeof setSubmitting>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.submittingAllFacilities = submitting;
      break;
    }
    case REMOVE_BRANCH: {
      const { branchId } = (action as ReturnType<typeof removeBranch>).payload;
      const index = draftState.branches.findIndex((draftBranch) => draftBranch.id === branchId);
      if (index !== -1) {
        draftState.branches.splice(index, 1);
      }
      break;
    }
    case SET_BRANCHES: {
      const { branches } = (action as ReturnType<typeof setBranches>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.branches = branches;
      break;
    }
    case ADD_BRANCH: {
      const { branch } = (action as ReturnType<typeof addBranch>).payload;
      draftState.branches.push(branch);
      break;
    }
    case UPDATE_BRANCH: {
      const { branch } = (action as ReturnType<typeof updateBranch>).payload;
      const index = draftState.branches.findIndex((draftBranch) => draftBranch.id === branch.id);
      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        draftState.branches[index] = branch;
      }
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default onboardFacilityReducer;
