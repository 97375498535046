import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { submitValidate } from 'utils/form-helpers/antd-formik';
import 'utils/form-helpers/yup-password';
import { ApiError } from 'api';

import UpdatePassword from './UpdatePassword';
import { useAuthDispatch } from '../context/authContext';
import { changePassword } from '../context/authThunks';
import { publicRoutes } from '../../application/routes/routesConstants';

const signInSchema = Yup.object({
  password: Yup.string().password().required('Required'),
});

export const UpdatePasswordContainer = () => {
  const { token } = useParams();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  return (
    <UpdatePassword
      onSubmit={useCallback(async (values: any, formikBag: FormikHelpers<any>) => {
        if (!submitValidate(values, signInSchema, formikBag)) {
          return;
        }
        if (token) {
          try {
            const result: any = await dispatch(changePassword(values.password, token));
            if (result) {
              navigate(publicRoutes.passwordUpdated);
            }
          } catch (e: any) {
            if (e instanceof ApiError) {
              const error = await e.response.json();
              if (error.message) {
                formikBag.setFieldError('password', error.message);
                formikBag.setFieldTouched('password', true, false);
              }
            }
          }
        }
      }, [dispatch, navigate, token])}
    />
  );
};

export default UpdatePasswordContainer;
