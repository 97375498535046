import React from 'react';
import './StepHeader.pcss';

interface Props {
  subTitle: string,
  title: string,
  description?: string;
  inactive?: boolean;
}

export const StepHeader = ({ subTitle, title, description, inactive = false }: Props) => (
  <div className={`StepHeader ${inactive ? 'inactive' : ''}`}>
    <div className="StepHeader-subTitle">{subTitle}</div>
    <div className="StepHeader-title">{title}</div>
    {
      description
      && <div className="StepHeader-description">{description}</div>
    }
  </div>
);

export default StepHeader;
