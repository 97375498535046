import React from 'react';
import { notification } from 'custom-test-antd';

import {
  DocumentsUploaderProvider,
  useDocumentsUploaderDispatch,
  useDocumentsUploaderState,
} from './context/documentsUploaderContext';
import { DocumentsUploader } from './DocumentsUploader';
import { addFile, updateFile, removeFile, UploadFile } from './context/documentsUploaderReducer';
import { fileListSelector } from './context/documentsUploaderSelectors';

interface Props {
  onDelete: (fileName: string) => Promise<void>;
  onTypeChange: (id: string, type: string) => Promise<void>;
  onFileUpload: (file: string | Blob) => Promise<any>;
  onFileDownload: (fileName: string) => Promise<any>;
  className?: string,
  disabled?: boolean,
}

export const DocumentsUploaderContainer = ({
  onDelete, onTypeChange, onFileUpload, onFileDownload, className, disabled = false,
}: Props) => {
  const state = useDocumentsUploaderState();
  const dispatch = useDocumentsUploaderDispatch();
  const fileList = fileListSelector(state);
  return (
    <DocumentsUploader
      disabled={disabled}
      className={className}
      fileList={fileList}
      onFileDownload={onFileDownload}
      onDraggerChange={({ file }) => {
        const index = fileList.findIndex((stateFile) => stateFile.id === file.uid);
        if (index !== -1) {
          const updatedFile: Partial<UploadFile> = {
            status: file.status,
          };
          if (file.status === 'done') {
            updatedFile.id = file.response.id;
            updatedFile.uploadDate = file.response.timeCreated;
          }
          dispatch(updateFile(file.uid, updatedFile));
        } else {
          dispatch(addFile({
            id: file.uid,
            status: file.status,
            name: file.name,
          }));
        }
      }}
      customRequest={async ({ onSuccess, onError, file }) => {
        try {
          const response = await onFileUpload(file);
          if (onSuccess) {
            onSuccess(response);
          }
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      }}
      onDelete={async (fileId: string) => {
        dispatch(updateFile(fileId, { status: 'uploading' }));
        try {
          const existingFile = fileList.find((file) => file.id === fileId);
          if (existingFile) {
            await onDelete(existingFile.name);
          }
          dispatch(removeFile(fileId));
        } catch (error) {
          dispatch(updateFile(fileId, { status: 'done' }));
          notification.error({
            message: 'Internal error was occurred', description: 'Please contact technical support',
          });
        }
      }}
      onTypeChange={(fileId: string, type: string) => {
        const previousType = fileList.find((f) => f.id === fileId)?.type;
        dispatch(updateFile(fileId, { status: 'uploading', type }));
        try {
          onTypeChange(fileId, type);
          dispatch(updateFile(fileId, { status: 'done' }));
        } catch (error) {
          dispatch(updateFile(fileId, { status: 'done', type: previousType }));
        }
      }}
    />
  );
};

export interface WrapperProps extends Props {
  fileList?: UploadFile[],
}

export const DocumentsUploaderWrapper = ({ fileList, ...other }: WrapperProps) => (
  <DocumentsUploaderProvider initState={{ fileList }}>
    <DocumentsUploaderContainer {...other} />
  </DocumentsUploaderProvider>
);

export default DocumentsUploaderWrapper;
