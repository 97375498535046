import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SuccessStep } from './SuccessStep';
import { privateRoutes } from '../../../application/routes/routesConstants';

export const SuccessStepContainer = () => {
  const navigate = useNavigate();
  return (
    <SuccessStep
      onGoToProposal={() => {
        navigate(privateRoutes.capitalProposal);
      }}
    />
  );
};

export default SuccessStepContainer;
