import { Menu as _Menu, Avatar } from 'custom-test-antd';
import type { MenuProps } from 'custom-test-antd';
import React from 'react';

import './Menu.pcss';

interface MenuTextLabelProps {
  label: string,
}

export const MenuTextLabel = ({ label }: MenuTextLabelProps) => (
  <div className="MenuTextLabel">
    <div className="MenuTextLabel-label">{label}</div>
  </div>
);

interface MenuLabelProps {
  label: string;
  description?: string;
  src?: React.ReactNode;
}

export const MenuLabel = ({ label, description, src }: MenuLabelProps) => (
  <div className="MenuLabel">
    <Avatar size={38} className="MenuLabel-icon" shape="square" src={src} />
    <span className="MenuLabel-titleSection">
      <div className="MenuLabel-label">{label}</div>
      <div className="MenuLabel-description">{description}</div>
    </span>
  </div>
);

interface Props {
  onClick?: MenuProps['onClick'];
  items: MenuProps['items'];
  selectedKey?: string;
  className?: string;
}

export const Menu: React.FC<Props> = ({ onClick, items, selectedKey, className }) => (
  <_Menu
    className={`Menu ${className ?? ''}`}
    onClick={onClick}
    selectedKeys={selectedKey ? [selectedKey] : undefined}
    items={items}
  />
);

export default Menu;
