import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { notification } from 'custom-test-antd';

import { showErrors, submitValidate } from 'utils/form-helpers/antd-formik';

import { UpgradeFullVersionModal } from 'components/full-version-checker';
import EligibilityRequest from './EligibilityRequest';
import { confirm } from './confirm';
import { BranchApi, PractitionerLicenseApi, ApiError } from '../../../api';
import { privateRoutes } from '../../application/routes/routesConstants';
import { BranchData } from '../../../api/branch/branchTypes';
import { IPortalRequest } from './portal-request/portalRequest';
import { PractitionerLicense } from '../../../api/practitioner-license/practitionerLicenseTypes';
import {
  EligibilityRequestProvider,
  useEligibilityRequestDispatch,
  useEligibilityRequestState,
} from './context/eligibilityRequestContext';
import {
  createPortalRequest,
  setFormValues,
  updateFormConfig,
  updateInitialValues,
  validateCredentials,
} from './context/eligibilityRequestThunks';
import {
  formConfigSelector,
  initialValuesSelector,
  isInitialValuesLoadingSelector,
  touchedInputsSelector,
  validationErrorsSelector,
} from './context/eligibilityRequestSelectors';

const generalValidationSchema = Yup.object({
  branchId: Yup.string(),
  portal: Yup.array().of(Yup.string()).min(1, 'Insurance is required').required('Insurance is required'),
});

const confirmRequest = (eligibilityId: string, onView: (id: string) => void) => (
  new Promise((resolve) => {
    confirm({
      title: 'Re-execute eligibility request?',
      content: 'Eligibility request was performed within 24 hours for the same patient',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      onView: () => {
        onView(eligibilityId);
        resolve(false);
      },
    });
  })
);

export const EligibilityRequestContainer = () => {
  const state = useEligibilityRequestState();
  const dispatch = useEligibilityRequestDispatch();
  const navigate = useNavigate();
  const [branches, setBranches] = useState<BranchData[]>([]);
  const onFetchBranches = useCallback(async () => {
    const fetchedBranches = await BranchApi.getUserBranches();
    setBranches(fetchedBranches);
  }, []);
  const initialFormValues = initialValuesSelector(state);
  const isInitialValuesLoading = isInitialValuesLoadingSelector(state);
  const validationErrors = validationErrorsSelector(state);
  const touchedInputs = touchedInputsSelector(state);
  const formConfig = formConfigSelector(state);

  const [licenses, setLicenses] = useState<PractitionerLicense[]>([]);
  const onFetchLicenses = useCallback(async () => {
    const fetchedLicenses = await PractitionerLicenseApi.getList({}, {});
    setLicenses(fetchedLicenses);
  }, []);

  const onRedirectToEligibilityResponse = useCallback((id: string) => {
    navigate(privateRoutes.eligibilityResponse.replace(':eligibilityId', id));
  }, [navigate]);

  const [isUpgradeModalOpened, setIsUpgradeModalOpened] = useState(false);
  const [nextCheckAt, setNextCheckAt] = useState(new Date());

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      initialTouched={touchedInputs}
      initialErrors={validationErrors}
      validateOnBlur={false}
      validate={async (values: any) => {
        const errors = await dispatch(validateCredentials(values)) as any;
        const { updated, values: newValues } = await dispatch(updateInitialValues(values, true)) as unknown as any;

        dispatch(setFormValues(updated ? newValues : values));
        dispatch(updateFormConfig);

        return errors;
      }}
      onSubmit={useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (!submitValidate(values, generalValidationSchema, formikHelpers)) {
          return false;
        }
        const { portal } = values;
        const portalRequest = dispatch(createPortalRequest(portal)) as unknown as IPortalRequest;
        try {
          portalRequest.validate(values);
        } catch (error) {
          showErrors(error, formikHelpers);
          return false;
        }

        try {
          const lastCheckId = await portalRequest.getLastCheckId(values);
          if (lastCheckId) {
            const confirmation = await confirmRequest(
              lastCheckId,
              onRedirectToEligibilityResponse,
            );
            if (!confirmation) {
              return true;
            }
          }
          const request = await portalRequest.createRequest(values);
          navigate(privateRoutes.eligibilityList);
          return request;
        } catch (error: any) {
          let description;
          if (error instanceof ApiError) {
            const response = await error.response.json();

            if (error.response.status === 403 && response.nextCheckAt) {
              setNextCheckAt(new Date(response.nextCheckAt));
              setIsUpgradeModalOpened(true);

              return false;
            }
            if (response.message) {
              description = response.message;
            }
          }
          notification.error({
            message: 'Error',
            description,
          });
        }
        return false;
      }, [onRedirectToEligibilityResponse, navigate])}
    >
      {(formikProps) => (
        <>
          <UpgradeFullVersionModal
            actionAvailableFrom={nextCheckAt}
            actionName="Check Eligibility"
            onActionClick={() => {
              formikProps.submitForm();
              setIsUpgradeModalOpened(false);
            }}
            open={isUpgradeModalOpened}
            onCancel={() => setIsUpgradeModalOpened(false)}
          />
          <EligibilityRequest
            config={formConfig}
            isInitialValueLoading={isInitialValuesLoading}
            formikProps={formikProps}
            branches={branches}
            onFetchBranches={onFetchBranches}
            licenses={licenses}
            onFetchLicenses={onFetchLicenses}
          />
        </>
      )}
    </Formik>
  );
};

export const EligibilityRequestWrapper = () => (
  <EligibilityRequestProvider initState={{}}>
    <EligibilityRequestContainer />
  </EligibilityRequestProvider>
);

export default EligibilityRequestWrapper;
