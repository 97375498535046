import React from 'react';

import EmailSent from './EmailSent';
import { useAuthState } from '../context/authContext';
import { forgotPasswordEmailSelector } from '../context/authSelectors';

export const EmailSentContainer: React.FC = () => {
  const state = useAuthState();
  return (
    <EmailSent
      email={forgotPasswordEmailSelector(state)}
    />
  );
};

export default EmailSentContainer;
