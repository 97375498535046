import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';
import { MednetUserType, mednetUserType } from './types';

const initialValue = {
  patientIdType: MednetUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
};
export class MednetRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    const config: PortalConfig = {
      patientIdType: mednetUserType,
      patientId: undefined,
      mobileNumber: true,
      emiratesId: true,
    };

    const { patientIdType } = values;
    if (patientIdType && patientIdType !== MednetUserType.EmiratesId) {
      config.emiratesId = false;
      config.patientId = calcPatientId(patientIdType, mednetUserType);
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== MednetUserType.EmiratesId
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === MednetUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
      mobileNumber: Yup.string().required('Mobile number is required'),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return MednetUserType.EmiratesId;
  }
}

export default MednetRequest;
