import React from 'react';
import moment from 'moment-timezone';

import { ReactComponent as Dollar } from 'assets/dollar.svg';

import './Transaction.pcss';
import { InsuranceRow } from '../insurance-row';
import { formatClaims, formatPrice } from '../utills/numberFormats';

interface Props {
  date: string,
  reference: string,
  showClaims?: boolean,
  showNetAmount?: boolean,
  detail: Array<{
    portal: string,
    purchasePrice: number,
    claims?: number,
    netAmount?: number,
  }>
}

export const Transaction = ({ date, detail, reference, showClaims = false, showNetAmount = false }: Props) => {
  const summary = detail.reduce((acc, item) => ({
    purchasePrice: acc.purchasePrice + item.purchasePrice,
    claims: acc.claims + (item.claims ?? 0),
    netAmount: acc.netAmount + (item.netAmount ?? 0),
  }), {
    purchasePrice: 0,
    claims: 0,
    netAmount: 0,
  });

  return (
    <table className="Transaction">
      <thead>
        <tr className="Transaction-header">
          <td>{reference}</td>
          {
            showClaims
            && <td />
          }
          {
            showNetAmount
            && <td />
          }
          <td>{moment(date).format('DD MMMM, YY')}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="Transaction-purchase">
            <Dollar className="Transaction-dollarIcon" />
            Purchase
          </td>
          {
            showClaims
            && <td>{formatClaims(summary.claims)}</td>
          }
          {
            showNetAmount
            && <td>{formatPrice(summary.netAmount)}</td>
          }
          <td>{formatPrice(summary.purchasePrice)}</td>
        </tr>
        {
          detail.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              <td className="Transaction-insurer"><InsuranceRow insurer={item.portal} /></td>
              {
                showClaims
                && <td>{formatClaims(item.claims)}</td>
              }
              {
                showNetAmount
                && <td>{formatPrice(item.netAmount)}</td>
              }
              <td>{formatPrice(item.purchasePrice)}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default Transaction;
