import React from 'react';
import { Link } from 'react-router-dom';
import LetterError from 'assets/letter-error.svg';

import './RegistrationCompleted.pcss';

import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';

interface Props {
  error?: string;
}

export const RegistrationError: React.FC<Props> = ({ error }) => (
  <AuthCard
    className="ConfirmEmailSent"
    header="Registration Failed"
    headerText={(
      <div>
        {error}
        <br />
        <br />
        Please consider creating a new account from the registration screen. If you are facing any issues
        contact our support team for assistance
      </div>
    )}
  >

    <img className="RegistrationCompleted-icon" src={LetterError} alt="" />
    <div className="RegistrationCompleted-backSection">
      I want to&nbsp;
      <Link to={publicRoutes.signUp} className="RegistrationCompleted-signIn">Create a new account</Link>
    </div>
  </AuthCard>
);

export default RegistrationError;
