import produce from 'immer';
import { UploadFileStatus } from 'antd/es/upload/interface';

export interface UploadFile {
  id: string,
  name: string,
  status?: UploadFileStatus,
  url?: string,
  type?: string,
  uploadDate?: string,
}

export interface DocumentsUploaderContextState {
  fileList: UploadFile[];
}

export interface InitState {
  fileList?: UploadFile[],
}

export const initialState = (initValues?: InitState): DocumentsUploaderContextState => ({
  fileList: initValues?.fileList ?? [],
});

export const UPDATE_FILE = 'APPLY_FILE_LIST_CHANGE';
export const updateFile = (id: string, file: Partial<UploadFile>) => ({
  type: UPDATE_FILE, payload: { id, file },
});

export const ADD_FILE = 'ADD_FILE';
export const addFile = (file: UploadFile) => ({
  type: ADD_FILE, payload: { file },
});

export const REMOVE_FILE = 'REMOVE_FILE';
export const removeFile = (id: string) => ({
  type: REMOVE_FILE, payload: { id },
});

const documentsUploaderReducer = (state: DocumentsUploaderContextState, action: { type: string, payload: any }) => (
  produce(state, (draftState) => {
    switch (action.type) {
      case ADD_FILE: {
        const { file } = (action as ReturnType<typeof addFile>).payload;
        draftState.fileList.push(file);
        break;
      }
      case UPDATE_FILE: {
        const { id, file } = (action as ReturnType<typeof updateFile>).payload;
        const index = draftState.fileList.findIndex((stateFile) => stateFile.id === id);
        if (index !== -1) {
          // eslint-disable-next-line no-param-reassign
          draftState.fileList[index] = {
            ...draftState.fileList[index],
            ...file,
          };
        }
        break;
      }
      case REMOVE_FILE: {
        const { id } = (action as ReturnType<typeof removeFile>).payload;
        const index = draftState.fileList.findIndex((stateFile) => stateFile.id === id);
        if (index !== -1) {
          // eslint-disable-next-line no-param-reassign
          draftState.fileList.splice(index, 1);
        }
        break;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  })
);

export default documentsUploaderReducer;
