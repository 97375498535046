import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CapitalOverview from './CapitalOverview';
import { useProposalDispatch, useProposalState } from '../proposal/context/context/proposalContext';
import {
  latestTransactionSelector,
  proposalFetchingSelector,
  proposalSelector,
} from '../proposal/context/context/proposalSelectors';
import { privateRoutes } from '../../application/routes/routesConstants';
import { fetchLatestTransaction } from '../proposal/context/context/proposalThunks';

export const CapitalOverviewContainer = () => {
  const navigate = useNavigate();
  const state = useProposalState();
  const dispatch = useProposalDispatch();
  const proposal = proposalSelector(state);
  const proposalFetching = proposalFetchingSelector(state);
  return (
    <CapitalOverview
      proposal={proposal}
      loading={proposalFetching}
      latestTransaction={latestTransactionSelector(state)}
      onView={useCallback(() => {
        navigate(privateRoutes.capitalProposal);
      }, [navigate])}
      onFetchLatestTransaction={useCallback(() => {
        dispatch(fetchLatestTransaction());
      }, [dispatch])}
    />
  );
};

export default CapitalOverviewContainer;
