import React from 'react';
import { Card, Steps, Divider, Button } from 'custom-test-antd';

import './ProposalProcess.pcss';

interface Props {
  className?: string;
  step: number;
  onContinue?: () => void;
  onViewTutorial?: () => void;
}

export const ProposalProcess = ({ className, step, onContinue, onViewTutorial }: Props) => (
  <Card className={`ProposalProcess ${className}`}>
    <div className="ProposalProcess-header">
      Process&nbsp;
      {step > 0 ? step : 0}
      &nbsp;of 3
      <Button type="link" onClick={onViewTutorial}>View tutorial</Button>
    </div>
    <Steps direction="vertical" current={step} className="ProposalProcess-steps">
      <Steps.Step title="Upload your KYC documents" description="Description" />
      <Steps.Step title="Sign the escrow agreement" description="Description" />
      <Steps.Step title="Authorise documents with letterhead" description="Description" />
    </Steps>
    <Divider className="ProposalProcess-divider" />
    <Button
      onClick={onContinue}
      className="ProposalProcess-button"
      size="large"
    >
      Continue the with procedure
    </Button>
  </Card>
);

export default ProposalProcess;
