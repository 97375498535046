import React from 'react';
import { Card, Button } from 'custom-test-antd';

import './GetStarted.pcss';

interface Props {
  onStartProcess: () => void;
  onBack: () => void;
  showStart: boolean;
  showBack: boolean;
}

export const GetStarted = ({ onStartProcess, onBack, showStart, showBack }: Props) => (
  <Card className="GetStarted">
    <div className="GetStarted-header">
      <div>
        Lets gets you started with Klaim Kapital
      </div>
      <div className="GetStarted-headerDescription">
        Description
      </div>
    </div>
    <video controls className="GetStarted-video">
      <track kind="captions" />
      <source
        src="123"
        type="video/mp4"
      />
      Sorry, your browser doesn&apos;t support embedded videos.
    </video>
    <div className="GetStarted-footer">
      {
        showStart
        && (
          <>
            <Button
              className="GetStarted-startButton"
              type="primary"
              size="large"
              onClick={onStartProcess}
            >
              Start your KYC process
            </Button>
            <div>Description</div>
          </>
        )
      }
      {
        showBack
        && (
          <Button
            className="GetStarted-backButton"
            type="primary"
            size="large"
            onClick={onBack}
          >
            Take me back
          </Button>
        )
      }
    </div>
  </Card>
);

export default GetStarted;
