export const formatDiscount = (discount?: number) => {
  if (discount === undefined || discount === null) {
    return '';
  }
  return `${discount}%`;
};

export const formatPrice = (price?: number, showCurrency: boolean = true) => {
  if (price === undefined || price === null) {
    return '';
  }
  const numberString = price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${showCurrency ? 'AED ' : ''}${numberString}`;
};

export const formatClaims = (claims?: number) => {
  if (claims === undefined || claims === null) {
    return '';
  }
  return claims.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
