import React, { useCallback } from 'react';
import { AuthorizeDocumentsStep } from './AuthorizeDocumentsStep';
import { FinancingProposalApi } from '../../../../api';
import { useProposalDispatch, useProposalState } from '../../proposal/context/context/proposalContext';
import { updateProposalState } from '../../proposal/context/context/proposalThunks';
import { ProposalState } from '../../../../api/financing/proposal/financingProposalTypes';
import { proposalSelector } from '../../proposal/context/context/proposalSelectors';

export const AuthorizeDocumentsStepContainer = () => {
  const state = useProposalState();
  const dispatch = useProposalDispatch();

  const proposal = proposalSelector(state);
  return (
    <AuthorizeDocumentsStep
      proposalState={proposal?.state}
      onDelete={useCallback(async (fileName) => {
        await FinancingProposalApi.deleteFile(fileName);
      }, [])}
      onFileUpload={async (file) => FinancingProposalApi.uploadFile(file)}
      onTypeChange={useCallback(async (id, type) => {
        // eslint-disable-next-line no-console
        console.log(id, type);
      }, [])}
      onFileDownload={useCallback(async (fileName) => {
        const data = await FinancingProposalApi.downloadFile(fileName);
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const fileUrl = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();
        link.remove();
      }, [])}
      onValidateButtonClick={useCallback(() => {
        dispatch(updateProposalState(ProposalState.KYCDocumentUploaded));
      }, [dispatch])}
      onChangeButtonClick={useCallback(() => {
        dispatch(updateProposalState(ProposalState.KYCDocumentUploading));
      }, [dispatch])}
    />
  );
};

export default AuthorizeDocumentsStepContainer;
