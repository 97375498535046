import React, { useEffect } from 'react';
import { Spin } from 'custom-test-antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthDispatch } from '../context/authContext';
import { executeRegistrationIntention } from '../context/authThunks';
import { IntentionCheck } from '../../../api/authentication/authenticationTypes';
import { publicRoutes } from '../../application/routes/routesConstants';

export const VerifyIntention = () => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const { verificationId } = useParams();
  useEffect(() => {
    if (!verificationId) {
      return;
    }
    const executeIntention = async () => {
      const result = (await dispatch(executeRegistrationIntention(verificationId))) as unknown as IntentionCheck;
      navigate(publicRoutes.registrationCompleted, { state: { ...result, isFromVerificationLink: true } });
    };
    executeIntention().catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }, [dispatch, navigate, verificationId]);
  return (<Spin spinning />);
};

export default VerifyIntention;
