import React from 'react';
import { Card, Progress, Divider, Button } from 'custom-test-antd';
import moment from 'moment-timezone';

import './ProposalOverview.pcss';
import { InsuranceRow } from '../../insurance-row';
import { formatDiscount, formatPrice } from '../../utills/numberFormats';

interface Props {
  issuedDate: string;
  purchaseOffer: number;
  netAmount: number;
  discount: number;
  onView?: () => void;
  insurers: string[];
  className?: string;
}

export const ProposalOverview = ({
  issuedDate,
  purchaseOffer,
  netAmount,
  discount,
  onView,
  insurers,
  className,
}: Props) => (
  <Card className={`ProposalOverview ${className ?? ''}`}>
    <div className="ProposalOverview-header">
      Proposal
    </div>
    <div className="ProposalOverview-date">
      {moment(issuedDate).format('DD MMM YYYY')}
    </div>
    <Progress
      type="circle"
      className="ProposalOverview-offerProgress"
      percent={100}
      format={() => (
        <div className="ProposalOverview-offerText">
          PURCHASE OFFER
          <div className="ProposalOverview-offerPrice">
            {formatPrice(purchaseOffer)}
          </div>
        </div>
      )}
    />
    <div className="ProposalOverview-priceSection">
      <div className="ProposalOverview-priceCompare">
        <div className="ProposalOverview-priceSummary">
          <div className="ProposalOverview-amountHeader">
            NET AMOUNT
          </div>
          <div className="ProposalOverview-priceAmount">
            {formatPrice(netAmount)}
          </div>
        </div>
        <Divider type="vertical" className="ProposalOverview-priceDelimiter" />
        <div className="ProposalOverview-priceSummary">
          <div className="ProposalOverview-discountHeader">
            DISCOUNT
          </div>
          <div className="ProposalOverview-priceDiscount">
            {formatDiscount(discount)}
          </div>
        </div>
      </div>
      <Divider className="ProposalOverview-insurersDelimiter" />
      <div className="ProposalOverview-insurersSection">
        {insurers.map((insurer, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <InsuranceRow key={index} insurer={insurer} />
        ))}
      </div>
    </div>
    <Button
      size="large"
      className="ProposalOverview-priceButton"
      onClick={onView}
    >
      View prices
    </Button>
  </Card>
);

export default ProposalOverview;
