import React from 'react';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Spin } from 'custom-test-antd';

import './CheckModal.pcss';
import { FacilityCheckStatus } from '../context/onboardFacilityReducer';

interface Props {
  onOk: () => void;
  onModalClose: () => void;
  status: FacilityCheckStatus;
  message?: React.ReactNode;
}

const CheckModal: React.FC<Props> = ({ message, status, onModalClose, onOk }) => (
  <Modal
    open
    title={status === FacilityCheckStatus.Checking ? 'Checking Credentials' : 'Credentials Check Response'}
    onOk={onModalClose}
    onCancel={onModalClose}
    footer={(
      <Button type="primary" onClick={onOk} disabled={status === FacilityCheckStatus.Checking}>
        OK
      </Button>
    )}
  >
    <Space direction="vertical" align="center" className="of-CheckModal-content">
      {status === FacilityCheckStatus.Checking && (
        <>
          <Spin />
          <div className="of-CheckModal-loadingText">
            This process can run in the background. Close this popup if you want to continue using the system
          </div>
        </>
      )}
      {
        status === FacilityCheckStatus.Valid
        && (
          <>
            <CheckCircleOutlined className="of-CheckModal-successIcon" />
            <div>Credentials is valid</div>
          </>
        )
      }
      {
        status === FacilityCheckStatus.Error
        && (
          <>
            <ExclamationCircleOutlined className="of-CheckModal-errorIcon" />
            <div>
              {message}
            </div>
          </>
        )
      }
    </Space>
  </Modal>
);

export default CheckModal;
