import React from 'react';
import { Menu, Popover, Table, Empty } from 'custom-test-antd';
import moment from 'moment-timezone';
import { DeleteOutlined, DownloadOutlined, MoreOutlined } from '@ant-design/icons';
import { FileListItem } from 'api/eligibility-bulk-file/eligibilityBulkFileTypes';

import './FileList.pcss';

interface Props {
  loading: boolean;
  files: FileListItem[];
  onDownload: (id: string) => void;
  onDelete: (id: string) => void;
  className?: string;
}

const FileList: React.FC<Props> = ({
  loading,
  files,
  onDelete,
  onDownload,
  className,
}) => (
  <Table
    className={className}
    columns={[{
      title: 'Scheduled item',
      dataIndex: ['file', 'name'],
    }, {
      title: 'Execute At',
      dataIndex: 'executeAt',
      render: (executeAt) => (executeAt ? moment(executeAt).format('DD MMM YYYY HH:mm') : ''),
    }, {
      title: '',
      dataIndex: 'id',
      width: 14,
      render: (_, row) => (
        <Popover
          overlayClassName="FileList-moreActions"
          content={(
            <Menu
              className="FileList-menu"
              items={[
                {
                  label: 'Download',
                  key: 'download',
                  icon: <DownloadOutlined />,
                  onClick: () => {
                    onDownload(row.id);
                  },
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    onDelete(row.id);
                  },
                },
              ]}
            />
          )}
        >
          <MoreOutlined />
        </Popover>
      ),
    }]}
    whiteHeader
    rowKey="id"
    loading={loading}
    dataSource={files}
    pagination={false}
    locale={{ emptyText: <Empty description="No postponed files" image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
  />
);

export default FileList;
