import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum OmanUserType {
  EmiratesId = '1',
  MemberId = '2',
}

export const omanUserType: DropdownOptionString[] = [
  {
    value: OmanUserType.EmiratesId,
    label: 'Emirates ID',
  },
  { value: OmanUserType.MemberId, label: 'Member ID' },
];
