import { Dispatch } from 'react';
import produce from 'immer';
import moment from 'moment/moment';
import { AnyObjectSchema } from 'yup';
import EligibilityDefaultApi from 'api/eligibility-default/EligibilityDefaultApi';
import { FormConfig, IPortalRequest } from './portalRequest';
import { DropdownOptionString, InsurancePortal } from '../../../../constants/dropdownOptions';
import { EligibilityApi } from '../../../../api';
import { EligibilityRequestContextState } from '../context/eligibilityRequestReducer';

export interface PortalConfig extends Omit<FormConfig, 'treatmentBases'> {
  treatmentBasis?: DropdownOptionString[],
}

export abstract class SinglePortalRequest implements IPortalRequest {
  protected readonly portal: InsurancePortal;

  protected readonly dispatch: Dispatch<any>;

  protected readonly getState: () => EligibilityRequestContextState;

  constructor(portal: InsurancePortal, dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) {
    this.portal = portal;
    this.dispatch = dispatch;
    this.getState = getState;
  }

  getValuesForForm(values: any): any {
    const newValues = {
      ...values,
    };
    if (values.patientIdType === this.getEmiratesIdPatientType() && values.patientId) {
      newValues.emiratesId = values.patientId;
      delete newValues.patientId;
    }

    return newValues;
  }

  getValuesForSubmission(values: any, enableValuesValidation: boolean = true): any {
    return produce(values, (draft: any) => {
      const schema = this.getValidationSchema();

      if (enableValuesValidation) {
        // eslint-disable-next-line no-param-reassign
        draft = schema.validateSync(values, { stripUnknown: true });
      }

      const config = this.getFormConfig(values, true);
      if (config.emiratesId) {
        // eslint-disable-next-line no-param-reassign
        draft.patientId = values.emiratesId;
        // eslint-disable-next-line no-param-reassign
        delete draft.emiratesId;
      } else if (draft.emiratesId) {
        // eslint-disable-next-line no-param-reassign
        delete draft.emiratesId;
      }
      // eslint-disable-next-line no-param-reassign
      draft.portal = this.portal;
      // eslint-disable-next-line no-param-reassign
      draft.branchId = values.branchId;

      const treatmentBasis = values[`treatmentBasis-${this.portal}`];
      if (treatmentBasis) {
        // eslint-disable-next-line no-param-reassign
        draft.treatmentBasis = treatmentBasis;
        // eslint-disable-next-line no-param-reassign
        delete draft[`treatmentBasis-${this.portal}`];
      }
      return draft;
    });
  }

  getFormConfig(values: any, extended?: boolean): FormConfig {
    const portalConfig = this.getPortalConfig(values, extended);
    const result: FormConfig = portalConfig;
    if (portalConfig.treatmentBasis) {
      result.treatmentBases = [{
        portal: this.portal,
        options: portalConfig.treatmentBasis,
        label: 'Treatment Basis',
      }];
    }
    return result;
  }

  validate(values: any) {
    const schema = this.getValidationSchema();
    return schema.validateSync(values, { abortEarly: false });
  }

  async getLastCheckId(values: any) {
    const submitValues = this.getValuesForSubmission(values);
    const existingEligibility = await EligibilityApi.getList({
      patientId: submitValues.patientId,
      patientIdType: submitValues.patientIdType,
      portal: [submitValues.portal],
      dates: [moment().subtract(1, 'day')],
    }, {
      pageSize: 1,
      current: 1,
    });
    return existingEligibility?.data?.[0]?.id;
  }

  async createRequest(values: any) {
    const submitValues = this.getValuesForSubmission(values);
    return EligibilityApi.createEligibilityRequest(submitValues);
  }

  abstract getPortalConfig(values: any, extended?: boolean, dispatch?: Dispatch<any>): PortalConfig;

  // abstract getInitialValues(): any;

  abstract getValidationSchema(): AnyObjectSchema;

  abstract getEmiratesIdPatientType(): string | undefined;

  protected async getRemoteInitialValues(values: any): Promise<any> {
    const { branchId } = values;
    const response = await EligibilityDefaultApi.search({ branchId, portals: [this.portal] });
    const defaultValues = response?.[0]?.payload;
    const defaultValuesForForm = defaultValues ? this.getValuesForForm(defaultValues) : undefined;

    return defaultValuesForForm;
  }

  /**
   * Get initial values for component form
   * @param  {any} values - search payload
   * @param  {boolean} fromApi - if true then get values from api
   * @returns Promise
   */
  abstract getInitialValues(values: any, fromApi: boolean): Promise<any>;
}

export default SinglePortalRequest;
