import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import * as Yup from 'yup';

import { submitValidate } from 'utils/form-helpers/antd-formik';

import { AccountFullName } from './AccountFullName';
import { useAuthDispatch, useAuthState } from '../../../authentication/context/authContext';
import { userProfileSelector } from '../../../authentication/context/authSelectors';
import { UserProfileApi } from '../../../../api';
import { privateRoutes } from '../../../application/routes/routesConstants';
import { UserProfile } from '../../../../api/user-profile/userProfileTypes';
import { fetchUserProfile } from '../../../authentication/context/authThunks';

const validationSchema = Yup.object({
  fullName: Yup.string().required('Full name is required'),
});

export const AccountFullNameContainer = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userProfileSelector(state) as UserProfile;
  const navigate = useNavigate();
  return (
    <AccountFullName
      initialValues={{ fullName: user.name }}
      onSubmit={useCallback(async (values, formikHelpers) => {
        if (!submitValidate(values, validationSchema, formikHelpers)) {
          return false;
        }
        try {
          await UserProfileApi.updateProfile({
            name: values.fullName,
            email: user.email,
            phone: user.phone,
          });
          await dispatch(fetchUserProfile);
          navigate(privateRoutes.accountSettings);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Internal server error',
          });
          return false;
        }
        return true;
      }, [dispatch, navigate, user])}
      onCancel={useCallback(() => {
        navigate(-1);
      }, [navigate])}
    />
  );
};

export default AccountFullNameContainer;
