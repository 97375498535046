import React from 'react';

import './AuthCard.pcss';
import { isMobile } from '../../../utils/mobileDetector';

interface Props {
  className?: string;
  header?: React.ReactNode;
  headerText?: React.ReactNode;
  children: React.ReactNode;
}

export const AuthCard: React.FC<Props> = ({ className, header, headerText, children }) => (
  <div className={`AuthCard ${className ?? ''} ${isMobile() ? 'mobile' : ''}`}>
    {
      header
      && <span className="AuthCard-header">{header}</span>
    }
    {
      headerText
      && <span className="AuthCard-headerText">{headerText}</span>
    }
    {children}
  </div>
);

export default AuthCard;
