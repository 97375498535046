import React from 'react';
import { Tooltip, TooltipProps } from 'custom-test-antd';

import './PasswordGuideTooltip.pcss';

export const PasswordGuideTooltip: React.FC<Omit<TooltipProps, 'title'>> = ({
  overlayClassName, ...other
}) => (
  <Tooltip
    {...other}
    overlayClassName="PasswordGuideTooltip-passwordTooltipOverlay"
    title={(
      <div>
        <span className="PasswordGuideTooltip-passwordTooltipHeader">Password guidelines:</span>
        <ul className="PasswordGuideTooltip-passwordTooltipList">
          <li>Maintain an 8-character minimum length</li>
          <li>Use Uppercase characters and lowercase characters</li>
          <li>Mix letters and numbers</li>
          <li>Include at least one special character, e.g., @ $ ! % * # ? & ^ _ -</li>
          <li>Don’t use spaces in your password</li>
        </ul>
      </div>
    )}
  />
);

export default PasswordGuideTooltip;
