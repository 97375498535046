import React, { useCallback } from 'react';
import CapitalTransactions from './CapitalTransactions';
import { fetchTransactions } from '../proposal/context/context/proposalThunks';
import { useProposalDispatch, useProposalState } from '../proposal/context/context/proposalContext';
import { transactionsSelector } from '../proposal/context/context/proposalSelectors';
import { PaginationValue } from './pagination';
import { setTransactionsPagination } from '../proposal/context/context/proposalReducer';

const CapitalTransactionsContainer = () => {
  const state = useProposalState();
  const dispatch = useProposalDispatch();
  const transactions = transactionsSelector(state);
  return (
    <CapitalTransactions
      transactions={transactions.items}
      loading={transactions.fetching}
      pagination={transactions.pagination}
      onFetch={useCallback(() => {
        dispatch(fetchTransactions());
      }, [dispatch])}
      onPaginationChange={(pagination: PaginationValue) => {
        dispatch(setTransactionsPagination(pagination));
        dispatch(fetchTransactions());
      }}
    />
  );
};

export default CapitalTransactionsContainer;
