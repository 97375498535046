import React from 'react';
import * as Yup from 'yup';
import { FacilitiesPortal } from 'api/branch/branchTypes';

import { submitValidate } from 'utils/form-helpers/antd-formik';
import { container, Service } from 'container';
import WebAnalyticsService from 'services/WebAnalyticsService';
import OnboardFacility from './OnboardFacility';
import {
  useOnboardFacilityDispatch,
  useOnboardFacilityState,
} from '../context/onboardFacilityContext';
import { checkBranch, createBranches } from '../context/onboardFacilityThunks';
import {
  currentCheckSelector,
  errorSelector,
  facilitiesSelector,
  portalSelector,
  submittingSelector,
} from '../context/onboardFacilitySelectors';
import {
  removeBranch,
  setCurrentCheck, setError,
  setPortal,
} from '../context/onboardFacilityReducer';

const FACILITY_ID_PREFIXES_ECLAIM_LINK: string[] = [
  '^CL-BR-\\d+-\\d+$',
  '^CL-MC-\\d+-\\d+$',
  '^CL-PP-\\d+-\\d+$',
  '^DHA-F-\\d+$',
  '^DHCC-F-\\d+$',
  '^DHCC-PH-\\d+$',
  '^MOH-F-\\d+$',
  '^MOH\\d+$',
  '^MOHM\\d+$',
];

const FACILITY_ID_PREFIXES_SHAFAFIYA: string[] = [
  '^MF\\d+$',
  '^PF\\d+$',
];

const FACILITY_ID_PREFIXES_RIAYATI: string[] = [
  '^\\d+$',
];

export const FACILITY_ID_PREFIXES: any = {
  [FacilitiesPortal.eClaimLink]: FACILITY_ID_PREFIXES_ECLAIM_LINK,
  [FacilitiesPortal.Shafafiya]: FACILITY_ID_PREFIXES_SHAFAFIYA,
  [FacilitiesPortal.Riayati]: FACILITY_ID_PREFIXES_RIAYATI,
};

export const creanteOnboardFacilitiesSchema = (portal: FacilitiesPortal) => Yup.object({
  name: Yup.string().required('Required'),
  login: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  branchId: Yup.string()
    .matches(
      new RegExp(FACILITY_ID_PREFIXES[portal].join('|')),
      'Invalid Facility ID',
    )
    .required('Required'),
});

interface Props {
  onSubmit: () => void;
  className?: string;
  createBranchOnSuccess?: boolean;
}

export const OnboardFacilityContainer = ({ onSubmit, className, createBranchOnSuccess = false }: Props) => {
  const dispatch = useOnboardFacilityDispatch();
  const state = useOnboardFacilityState();
  const webAnalyticsService = container.resolve<WebAnalyticsService>(Service.webAnalytics);

  webAnalyticsService.trackEvent('Onboarding_BranchForm', { props: { action: 'Enter' } });
  const portal = portalSelector(state);
  const facilities = facilitiesSelector(state);
  const isSubmitting = submittingSelector(state);
  const currentCheck = currentCheckSelector(state);
  const error = errorSelector(state);

  return (
    <OnboardFacility
      createBranchOnSuccess={createBranchOnSuccess}
      className={className}
      error={error}
      onErrorClose={() => {
        dispatch(setError(undefined));
      }}
      onModalClose={() => {
        dispatch(setCurrentCheck(undefined));
      }}
      onModalOk={() => {
        dispatch(setCurrentCheck(undefined));
      }}
      currentCheck={currentCheck}
      isSubmitting={isSubmitting}
      facilities={facilities}
      portal={portal}
      onPortalChange={(changedPortal: FacilitiesPortal) => {
        dispatch(setPortal(changedPortal));
      }}
      onBranchRemove={(branchId) => {
        dispatch(removeBranch(branchId));
      }}
      onAddBranch={async (values: any, formikHelpers) => {
        const validationSchema = creanteOnboardFacilitiesSchema(portal);
        if (!submitValidate(values, validationSchema, formikHelpers)) {
          return;
        }
        formikHelpers.resetForm({});
        dispatch(checkBranch(values, createBranchOnSuccess)) as any;
      }}
      onSubmit={async () => {
        const success: boolean = await dispatch(createBranches) as any;
        if (success) {
          onSubmit();
        }
      }}
    />
  );
};

export default OnboardFacilityContainer;
