import React, { FC, PropsWithChildren, createContext } from 'react';

export interface PrivateLayoutContextState {
  showMenu: boolean;
  isMobileAdaptive: boolean;
}

const initialState: PrivateLayoutContextState = {
  showMenu: true,
  isMobileAdaptive: false,
};

const PrivateLayoutContext = createContext<PrivateLayoutContextState>(initialState);

type Props = {
  state?: PrivateLayoutContextState;
};

const PrivateLayoutProvider: FC<PropsWithChildren<Props>> = ({
  children,
  state = initialState,
}) => (
  <PrivateLayoutContext.Provider value={state}>
    {children}
  </PrivateLayoutContext.Provider>
);

function usePrivateLayoutState() {
  const context = React.useContext(PrivateLayoutContext);
  if (context === undefined) {
    throw new Error('usePrivateLayoutState must be used within a PrivateLayoutContext');
  }
  return context;
}

export { PrivateLayoutProvider, usePrivateLayoutState };
