import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum NextcareTreatmentType {
  InPatient = '1',
  OutPatient = '2',
  Dental = '7',
  Psychiatry = '8',
  Optical = '15',
  TravelInsurance = '16',
  EmergencyRoomService = '1.10',
  ChronicOut = '2.10',
  Maternity = '2.20',
}

export const nextcareTreatmentType: DropdownOptionString[] = [
  { value: NextcareTreatmentType.InPatient, label: 'In-Patient' },
  { value: NextcareTreatmentType.OutPatient, label: 'Out-Patient' },
  { value: NextcareTreatmentType.Dental, label: 'Dental' },
  { value: NextcareTreatmentType.Psychiatry, label: 'Psychiatry' },
  { value: NextcareTreatmentType.Optical, label: 'Optical' },
  { value: NextcareTreatmentType.TravelInsurance, label: 'Travel Insurance' },
  { value: NextcareTreatmentType.ChronicOut, label: 'Chronic Out' },
  { value: NextcareTreatmentType.EmergencyRoomService, label: 'Emergency Room Services' },
  { value: NextcareTreatmentType.Maternity, label: 'Maternity' },
];

export enum NextcareUserType {
  RegulatorMemberId = '-3',
  EmiratesId = '-1',
  PinNo = '0',
  Passport = '1',
  IdentityCard = '2',
  SocialSecurity = '3',
  MilitaryId = '4',
  DrivingLicense = '5',
  Others = '6',
  WorkPermit = '7',
  ResidencyCardIqama = '8',
  EntryVisa = '9',
  CardNo = '10',
}

export const nextcareUserType: DropdownOptionString[] = [
  { value: NextcareUserType.RegulatorMemberId, label: 'Regulator Member ID' },
  { value: NextcareUserType.EmiratesId, label: 'Emirates ID' },
  { value: NextcareUserType.PinNo, label: 'Pin #' },
  { value: NextcareUserType.Passport, label: 'Passport' },
  { value: NextcareUserType.IdentityCard, label: 'Identity Card' },
  { value: NextcareUserType.SocialSecurity, label: 'Social Security' },
  { value: NextcareUserType.MilitaryId, label: 'Military ID' },
  { value: NextcareUserType.DrivingLicense, label: 'Driving License' },
  { value: NextcareUserType.Others, label: 'Others' },
  { value: NextcareUserType.WorkPermit, label: 'Work Permit' },
  { value: NextcareUserType.ResidencyCardIqama, label: 'Residency Card (Iqama)' },
  { value: NextcareUserType.EntryVisa, label: 'Entry Visa' },
  { value: NextcareUserType.CardNo, label: 'Card No' },
];
