import produce from 'immer';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { EligibilityListItem } from '../../../../api/eligibility/eligibilityTypes';
import { BranchData } from '../../../../api/branch/branchTypes';

export const initPagination = {
  pageSize: 10,
  current: 1,
  total: 0,
};

export interface OrganizationUser {
  id: string,
  name: string,
}

export interface EligibilityListContextState {
  listItems: EligibilityListItem[];
  loading: boolean;
  filter: {
    search?: string;
    status?: string[];
    portal?: string[];
    branchId?: string[];
    dates?: Moment[];
  };
  pagination: {
    pageSize: number,
    current: number,
    total: number,
  };
  branches: BranchData[];
  users: OrganizationUser[];
  bulkUploadLoading: boolean;
}

export interface InitState {
  filter?: EligibilityListContextState['filter'],
}

export const initialState = (initValues?: InitState): EligibilityListContextState => ({
  listItems: [],
  loading: false,
  filter: initValues?.filter ?? {
    dates: [moment().subtract(1, 'month').startOf('day'), moment().startOf('day')],
  },
  pagination: initPagination,
  branches: [],
  users: [],
  bulkUploadLoading: false,
});

export const STORE_LIST_ITEMS = 'STORE_LIST_ITEMS';
export const storeListItems = (items: EligibilityListItem[]) => ({
  type: STORE_LIST_ITEMS, payload: { items },
});

export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM';
export const updateListItem = (item: EligibilityListItem) => ({
  type: UPDATE_LIST_ITEM, payload: { item },
});

export const STORE_PAGINATION = 'STORE_PAGINATION';
export const storePagination = (pagination: EligibilityListContextState['pagination']) => ({
  type: STORE_PAGINATION, payload: { pagination },
});

export const STORE_FILTER = 'STORE_FILTER';
export const storeFilter = (filter: EligibilityListContextState['filter']) => ({
  type: STORE_FILTER, payload: { filter },
});

export const STORE_BRANCHES = 'STORE_BRANCHES';
export const storeBranches = (branches: BranchData[]) => ({
  type: STORE_BRANCHES, payload: { branches },
});

export const STORE_USERS = 'STORE_USERS';
export const storeUsers = (users: OrganizationUser[]) => ({
  type: STORE_USERS, payload: { users },
});

export const SET_ELIGIBILITY_LIST_LOADING = 'SET_ELIGIBILITY_LIST_LOADING';
export const setEligibilityListLoading = (loading: boolean) => ({
  type: SET_ELIGIBILITY_LIST_LOADING, payload: { loading },
});

export const SET_BULK_ELIGIBILITY_UPLOAD_LOADING = 'SET_BULK_ELIGIBILITY_UPLOAD_LOADING';
export const setBulkEligibilityUploadLoading = (loading: boolean) => ({
  type: SET_BULK_ELIGIBILITY_UPLOAD_LOADING, payload: { loading },
});

const eligibilityListReducer = (state: EligibilityListContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_BULK_ELIGIBILITY_UPLOAD_LOADING: {
      const { loading } = (action as ReturnType<typeof setBulkEligibilityUploadLoading>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.bulkUploadLoading = loading;
      break;
    }
    case STORE_LIST_ITEMS: {
      // eslint-disable-next-line no-param-reassign
      draftState.listItems = action.payload.items;
      break;
    }
    case SET_ELIGIBILITY_LIST_LOADING: {
      // eslint-disable-next-line no-param-reassign
      draftState.loading = action.payload.loading;
      break;
    }
    case UPDATE_LIST_ITEM: {
      const index = draftState.listItems.findIndex((itEligibility) => itEligibility.id === action.payload.item.id);
      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        draftState.listItems[index] = action.payload.item;
      }
      break;
    }
    case STORE_PAGINATION: {
      // eslint-disable-next-line no-param-reassign
      draftState.pagination = action.payload.pagination;
      break;
    }
    case STORE_FILTER: {
      // eslint-disable-next-line no-param-reassign
      draftState.filter = action.payload.filter;
      break;
    }
    case STORE_BRANCHES: {
      // eslint-disable-next-line no-param-reassign
      draftState.branches = action.payload.branches;
      break;
    }
    case STORE_USERS: {
      // eslint-disable-next-line no-param-reassign
      draftState.users = action.payload.users;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default eligibilityListReducer;
