import { commonFetch, ApiError } from '../../apiHelper';
import { Deal, DealSummary } from './financingDealsTypes';

export class FinancingDealsApi {
  static fetchDeals = async (customerIds: string[], limit?: number, offset?: number): Promise<Deal[]> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/deal/search`, {
      method: 'POST',
      body: JSON.stringify({ customerIds, limit, offset }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static fetchDealSummary = async (dealId: string): Promise<DealSummary> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/financing/api/deal/dealSummary/${dealId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };
}

export default FinancingDealsApi;
