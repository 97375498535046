import * as Yup from 'yup';
import { AnySchema, StringSchema as YupStringSchema } from 'yup';

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for password policy.
     */
    password(): YupStringSchema;
  }
}

const YUP_PASSWORD_METHOD = 'password';

Yup.addMethod<AnySchema>(Yup.string, YUP_PASSWORD_METHOD, function yupPassword() {
  // eslint-disable-next-line func-names
  return this.test(YUP_PASSWORD_METHOD, function (value: string) {
    const regExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])(?=.*[@$!%*#?&^_-])[A-Za-z\d@$!%*#?&^_-]{8,}$/;
    if (!regExp.test(value)) {
      return this.createError({
        message: 'Password must contain 8 characters above and at least one uppercase, '
          + 'one number and one special case character, with no space',
      });
    }
    return true;
  });
});
