import React from 'react';
import { Button, Card } from 'custom-test-antd';
import SuccessImage from 'assets/capital/success-document.png';

import './SuccessStep.pcss';

interface Props {
  onGoToProposal: () => void,
}

export const SuccessStep = ({ onGoToProposal }: Props) => (
  <Card className="SuccessStep">
    <div className="SuccessStep-header">
      We are good to go!
    </div>
    <div className="SuccessStep-description">
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
      dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
      kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    </div>
    <img className="SuccessStep-image" src={SuccessImage} alt="" />
    <Button onClick={onGoToProposal} size="large" type="primary">Take me to my proposal</Button>
  </Card>
);

export default SuccessStep;
