import React, { useCallback } from 'react';
import { notification } from 'custom-test-antd';
import type { UploadProps } from 'custom-test-antd';
import { useNavigate } from 'react-router-dom';
import { BulkUpload } from 'components/common/bulk-upload';

import './CredentialsBulk.pcss';

import { CredentialsApi, StaticApi } from '../../../api';
import { privateRoutes } from '../../application/routes/routesConstants';

interface Props {
}

const CredentialsBulk: React.FC<Props> = () => {
  const navigate = useNavigate();
  const customRequest: UploadProps['customRequest'] = async ({ onSuccess, onError, file }) => {
    try {
      const response = await CredentialsApi.bulkAdd(file);
      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      if (onError) {
        onError(error as Error);
      }
    }
  };

  return (
    <BulkUpload
      accept=".csv"
      customRequest={useCallback(customRequest, [])}
      onSuccess={useCallback((response: any) => {
        const { error, success } = response;
        const hasError = error.length > 0;
        const hasSuccess = success.length > 0;
        if (!hasError) {
          notification.success({ message: 'Success', description: 'Successfully uploaded credentials' });
          navigate(privateRoutes.credentialList);
        } else if (!hasSuccess) {
          notification.error({ message: 'Error', description: 'All credentials were not uploaded' });
        } else {
          notification.error({
            message: 'Error',
            description: (
              <span>
                {`${success.length} of ${success.length + error.length} were successfully uploaded.`}
                <br />
                {`Error with credentials for portals: ${
                  error.map((credential: { portal?: string }) => credential?.portal).join(', ')
                }`}
              </span>
            ),
          });
        }
      }, [navigate])}
      onError={useCallback(() => {
        notification.error({
          message: 'Error',
          description: 'Error in bulk credentials upload.',
        });
      }, [])}
      onTemplateDownload={useCallback(async () => {
        try {
          const data = await StaticApi.getBulkPortalCredentialsTemplate();
          const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
          const fileUrl = URL.createObjectURL(csvData);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = 'bulk_credentials_template.csv';
          link.click();
          link.remove();
        } catch (e) {
          notification.error({
            message: 'Error',
            description: 'Error getting credentials template',
          });
        }
      }, [])}
      uploadText={(
        <>
          Upload your bulk portals credentials file
          <br />
          by drag-and-drop or&nbsp;
          <span className="CredentialsBulk-link">click to select</span>
        </>
      )}
      noteText={(
        <>
          You can upload a .csv with your portal credentials.
        </>
      )}
    />
  );
};

export default CredentialsBulk;
