import React from 'react';
import { Button } from 'custom-test-antd';

import './UploadDocumentsStep.pcss';
import { DocumentsUploaderWrapper, WrapperProps } from '../documents-uploader';
import { CalendyButton } from '../../../common/calendy/button';
import { ProposalState } from '../../../../api/financing/proposal/financingProposalTypes';

interface Props {
  onDelete: WrapperProps['onDelete'];
  onTypeChange: WrapperProps['onTypeChange'];
  onFileUpload: WrapperProps['onFileUpload'];
  onFileDownload: WrapperProps['onFileDownload'];
  onValidateButtonClick: () => void;
  onChangeButtonClick: () => void;
  proposalState?: ProposalState,
  onDownloadAll: () => void;
}

export const UploadDocumentsStep = ({
  onDelete,
  onTypeChange,
  onFileUpload,
  onFileDownload,
  onValidateButtonClick,
  onChangeButtonClick,
  proposalState,
  onDownloadAll,
}: Props) => {
  const documents = [{
    id: '1',
    name: '1.txt',
  }, {
    id: '2',
    name: '2.txt',
  }];
  return (
    <div className="UploadDocumentsStep">
      <div className="UploadDocumentsStep-downloadedDocument">
        {documents.map((document) => (
          <div key={document.id}>
            <div className="UploadDocumentsStep-downloadedDocumentDot" />
            <div className="UploadDocumentsStep-downloadedDocumentText">{document.name}</div>
          </div>
        ))}
      </div>
      <div className="UploadDocumentsStep-downloadAll">
        <span>Click here to&nbsp;</span>
        <Button
          className="UploadDocumentsStep-downloadAllLink"
          type="link"
          onClick={onDownloadAll}
        >
          Download all documents
        </Button>
      </div>
      {
        (
          proposalState === ProposalState.AuthoriseDocumentsUploading
          || proposalState === ProposalState.AuthoriseDocumentsUploaded
        )
        && (
          <DocumentsUploaderWrapper
            disabled={proposalState !== ProposalState.AuthoriseDocumentsUploading}
            className="UploadDocumentsStep-fileUploader"
            onDelete={onDelete}
            onTypeChange={onTypeChange}
            onFileUpload={onFileUpload}
            onFileDownload={onFileDownload}
            fileList={[{
              id: '1',
              name: 'test.txt',
              uploadDate: '2022-03-12',
              status: 'done',
            }]}
          />
        )
      }
      <div className="UploadDocumentsStep-help">
        {
          proposalState === ProposalState.AuthoriseDocumentsUploading
          && <Button onClick={onValidateButtonClick} size="large" type="primary">Validate documents</Button>
        }
        {
          proposalState === ProposalState.AuthoriseDocumentsUploaded
          && <Button onClick={onChangeButtonClick} size="large">Change documents</Button>
        }
        <span className="AuthorizeDocumentsStep-buttonSpace" />
        <span className="UploadDocumentsStep-helpText">Having trouble with something?</span>
        <CalendyButton
          size="large"
          url={process.env.REACT_APP_CAPITAL_SUPPORT_CALENDY_URL}
        >
          Contact support
        </CalendyButton>
      </div>
    </div>
  );
};

export default UploadDocumentsStep;
