import React from 'react';

import './SecurityCell.pcss';

interface Props {
  className?: string;
  title: string;
  description: string;
  children: React.ReactNode;
}

export const SecurityCell: React.FC<Props> = ({ className, title, description, children }) => (
  <div className={`SecurityCell ${className ?? ''}`}>
    <div className="SecurityCell-header">
      <div className="SecurityCell-title">{title}</div>
      <div className="SecurityCell-description">{description}</div>
    </div>
    {children}
  </div>
);

export default SecurityCell;
