/* eslint-disable class-methods-use-this */
import { RbacRuleFactory } from 'services/rbac-manager/interfaces';

// eslint-disable-next-line import/prefer-default-export
export class RbacLightRuleFactory implements RbacRuleFactory {
  createRule(_rule_: string) {
    return {
      execute: (_payload_: any) => true, // Keeps light check without rules
    };
  }
}
