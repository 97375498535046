import React, { useState, useMemo, useCallback } from 'react';
import { useMount } from 'react-use';
import { useNavigate, generatePath } from 'react-router-dom';
import { PractitionerLicenseApi } from 'api';
import { PractitionerLicense } from 'api/practitioner-license/practitionerLicenseTypes';
import { privateRoutes } from 'components/application/routes/routesConstants';

import { LicenseList } from './LicenseList';

export const LicenseListContainer = () => {
  const [licenses, setLicenses] = useState<PractitionerLicense[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>();
  const navigate = useNavigate();

  const fetchLicenses = useCallback(async () => {
    setLoading(true);
    try {
      const result = await PractitionerLicenseApi.getList({}, {});
      setLicenses(result);
    } finally {
      setLoading(false);
    }
  }, []);

  useMount(async () => {
    await fetchLicenses();
  });

  const listLicenses = useMemo(() => (
    licenses.filter(({ license, name }) => {
      if (search) {
        return `${license} - ${name}`.toString().toUpperCase().indexOf(search.toUpperCase()) !== -1;
      }
      return true;
    })
  ), [search, licenses]);

  return (
    <LicenseList
      licenses={listLicenses}
      loading={loading}
      onOpenLicense={(licenseId) => {
        navigate(generatePath(`${privateRoutes.practitionerLicensesEdit}`, { licenseId }));
      }}
      onDelete={async (licenseId) => {
        setLoading(true);
        try {
          await PractitionerLicenseApi.delete(licenseId);
          await fetchLicenses();
        } finally {
          setLoading(false);
        }
      }}
      onSearch={(userSearch) => {
        setSearch(userSearch);
      }}
    />
  );
};

export default LicenseListContainer;
