import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import {
  nextcareTreatmentType,
  NextcareUserType,
  nextcareUserType,
} from './types';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';

const initialValue = {
  patientIdType: NextcareUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
};

export class NextcareRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    const config: PortalConfig = {
      patientIdType: nextcareUserType,
      patientId: undefined,
      treatmentBasis: nextcareTreatmentType,
      emiratesId: true,
    };

    const { patientIdType } = values;
    if (patientIdType && patientIdType !== NextcareUserType.EmiratesId) {
      config.emiratesId = false;
      config.patientId = calcPatientId(patientIdType, nextcareUserType);
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== NextcareUserType.EmiratesId
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === NextcareUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
      [`treatmentBasis-${this.portal}`]: Yup.string().required('Treatment basis is required'),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return NextcareUserType.EmiratesId;
  }

  getValuesForForm(values: any) {
    const valuesForForm = super.getValuesForForm(values);

    if (valuesForForm.treatmentBasis) {
      // eslint-disable-next-line no-param-reassign
      valuesForForm[`treatmentBasis-${this.portal}`] = valuesForForm.treatmentBasis;
      // eslint-disable-next-line no-param-reassign
      delete valuesForForm.treatmentBasis;
    }

    return valuesForForm;
  }
}

export default NextcareRequest;
