import React from 'react';
import { Button, Card } from 'custom-test-antd';

import './SignProposal.pcss';

import { formatPrice } from '../../utills/numberFormats';

interface Props {
  totalAmount: number;
  onSign: () => void;
  className?: string;
}

export const SignProposal = ({ onSign, totalAmount, className }: Props) => (
  <Card className={`SignProposal ${className ?? ''}`}>
    <span>Proposal Amount&nbsp;</span>
    <span className="SignProposal-currency">(AED)</span>
    <div>
      <span className="SignProposal-amount">{formatPrice(totalAmount, false)}</span>
    </div>
    <Button
      className="SignProposal-button"
      size="large"
      type="primary"
      onClick={onSign}
    >
      Continue to&nbsp;
      <span className="SignProposal-docuSign">DocuSign</span>
    </Button>
  </Card>
);

export default SignProposal;
