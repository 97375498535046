export const RbacRoleKeys = {
  SystemAdmin: 1,
  SystemUser: 2,
  OrganizationAdmin: 3,
  OrganizationInsuranceManager: 4,
  OrganizationUser: 5,
};

export const RbacPermissionKeys = {
  DownloadCheckReport: 2,
  ListPortalCredentials: 3,
  ReadPortalCredentials: 4,
  CreatePortalCredentials: 5,
  UpdatePortalCredentials: 6,
  DeletePortalCredentials: 7,
  ListUsers: 8,
  ReadUser: 9,
  CreateUser: 10,
  UpdateUser: 11,
  DeleteUser: 12,
  ListBranches: 13,
  ReadBranch: 14,
  CreateBranch: 15,
  UpdateBranch: 16,
  DeleteBranch: 17,
  ManagePractionerLicense: 18,
  ListEligibilityCheck: 19,
  CreateEligibilityCheck: 20,
  ReadEligibilityCheck: 21,
  ViewEligibilityUsage: 22,
};
