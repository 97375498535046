import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Letter } from 'assets/letter.svg';

import './EmailSent.pcss';

import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';

interface Props {
  email?: string;
}

export const EmailSent: React.FC<Props> = ({ email }) => (
  <AuthCard
    className="EmailSent"
    header="Email has been sent"
    headerText={(
      <>
        <div>
          Instructions for resetting your password have been sent to&nbsp;
          <span className="EmailSent-email">{email}</span>
        </div>
        <br />
        <div>
          You will receive an email within 1 minute. Make sure to check your spam folder too
        </div>
      </>
    )}
  >
    <Letter className="EmailSent-icon" />
    <div className="EmailSent-backSection">
      <span>No email?&nbsp;</span>
      <Link to={publicRoutes.signIn} className="EmailSent-signIn">Take me back</Link>
    </div>
  </AuthCard>
);

export default EmailSent;
