import React from 'react';
import { Avatar } from 'custom-test-antd';
import { UserOutlined } from '@ant-design/icons';

import './ClientInfo.pcss';
import { Customer } from '../../../../api/financing/customer/financingCustomerTypes';

interface Props {
  customer?: Customer;
  className?: string;
}

export const ClientInfo = ({ customer, className }: Props) => (
  <div className={`ClientInfo ${className ?? ''}`}>
    <Avatar className="ClientInfo-avatar" icon={<UserOutlined />} size={68} />
    <div className="ClientInfo-name">
      <div>{customer?.name}</div>
    </div>
    <div className="ClientInfo-address">
      {customer?.address}
    </div>
  </div>
);

export default ClientInfo;
