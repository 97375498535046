import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'custom-test-antd';

import './Admin.pcss';

import { RbacRoleKeys } from 'constants/rbac';
import { privateRoutes } from '../application/routes/routesConstants';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';
import { useAuthState } from '../authentication/context/authContext';
import { userProfileSelector } from '../authentication/context/authSelectors';
import { UserProfile } from '../../api/user-profile/userProfileTypes';
import { NotFoundPage } from '../not-found';

enum AdminMenuKey {
  Clients = 'clients',
}

const MenuRoutes: { [key: string]: string } = {
  [AdminMenuKey.Clients]: privateRoutes.adminClient,
};

const menuItems = [
  { label: 'Client', key: AdminMenuKey.Clients },
];

const Header = createPortal(PrivateLayout.titlePortalId);

export const Admin: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = useAuthState();
  const user = userProfileSelector(state) as UserProfile;
  if (![RbacRoleKeys.SystemAdmin, RbacRoleKeys.SystemUser].includes(user.rbac_role_id)) {
    return <NotFoundPage />;
  }

  let selectedKey: string = AdminMenuKey.Clients;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header>Admin</Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="Admin-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <Outlet />
        )}
      />
    </>
  );
};

export default Admin;
