import React from 'react';

import './CapitalOverview.pcss';
import { Spin, Empty } from 'custom-test-antd';
import { useMount } from 'react-use';

import { ProposalOverview } from './proposal-overview';
import { ProposalProcessContainer } from './proposal-process';
import { LastTransaction } from './last-transaction';
import { DealSummary } from '../../../api/financing/deals/financingDealsTypes';

interface Props {
  proposal: any;
  onView: () => void;
  onFetchLatestTransaction: () => void;
  latestTransaction?: DealSummary;
  loading: boolean;
}

export const CapitalOverview = ({ proposal, onView, onFetchLatestTransaction, latestTransaction, loading }: Props) => {
  useMount(() => {
    onFetchLatestTransaction();
  });

  if (loading) {
    return <Spin />;
  }

  if (!proposal) {
    return <Empty description="No proposal" />;
  }

  return (
    <div className="CapitalOverview">
      <ProposalOverview
        issuedDate={proposal.issuedDate}
        purchaseOffer={proposal.totalAmount}
        netAmount={proposal.totalNetAmount}
        discount={proposal.totalDiscount}
        insurers={proposal.details.map((detail: any) => detail.portal)}
        onView={onView}
      />
      <div>
        <ProposalProcessContainer className="CapitalOverview-proposalProcess" />
        {
          latestTransaction
          && (
            <LastTransaction
              reference={latestTransaction.deal.reference}
              date={latestTransaction.deal.date}
              detail={latestTransaction.insurers.map((insurer) => ({
                portal: insurer.insurer.shortName.toLowerCase(),
                purchasePrice: insurer.purchasePrice,
              }))}
            />
          )
        }
      </div>
    </div>
  );
};

export default CapitalOverview;
