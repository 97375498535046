import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import './index.pcss';
import './index.less';

import Application from './components/application/Application';

moment.tz.setDefault('Asia/Dubai');

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render((
  <BrowserRouter>
    <Application />
  </BrowserRouter>
));
