import React from 'react';
import { Layout, Spin } from 'custom-test-antd';
import { Outlet } from 'react-router-dom';
import { isMobile } from 'utils/mobileDetector';
import moment from 'moment/moment';
import Klaim from 'assets/klaim.svg';
import AuthSider from './auth-sider/AuthSider';

import './AuthLayout.pcss';
import { LoadingLayout } from '../loading-layout';

export const AuthLayout: React.FC = () => {
  if (isMobile()) {
    return (
      <Layout className="AuthLayout mobile">
        <img src={Klaim} alt="" className="AuthLayout-klaimImg mobile" />
        <React.Suspense
          fallback={<LoadingLayout><Spin spinning tip="Loading..." /></LoadingLayout>}
        >
          <Outlet />
        </React.Suspense>
        <Layout.Footer className="AuthLayout-footer mobile">
          <div className="AuthLayout-police mobile">
            <a
              className="AuthLayout-link mobile"
              rel="noreferrer"
              target="_blank"
              href="https://klaim.ai/privacy-policy"
            >
              PRIVACY POLICY
            </a>
            <a
              className="AuthLayout-link mobile"
              rel="noreferrer"
              target="_blank"
              href="https://klaim.ai/terms-of-service"
            >
              TERMS OF SERVICE
            </a>
          </div>
          <div className="AuthLayout-copyRight mobile">
            {`Copyright © ${moment().format('YYYY')} Klaim All`}
            <br />
            All rights reserved
          </div>
        </Layout.Footer>
      </Layout>
    );
  }
  return (
    <Layout className="AuthLayout">
      <Layout.Content className="AuthLayout-content">
        <AuthSider />
        <React.Suspense
          fallback={<LoadingLayout><Spin spinning tip="Loading..." /></LoadingLayout>}
        >
          <div className="AuthLayout-contentContainer">
            <Outlet />
          </div>
        </React.Suspense>
      </Layout.Content>
    </Layout>
  );
};

export default AuthLayout;
