import React from 'react';
import { Card } from 'custom-test-antd';

import './LastTransaction.pcss';
import { Transaction } from '../../transaction';

interface Props {
  reference: string,
  date: string,
  detail: Array<{
    portal: string,
    purchasePrice: number
  }>
}

export const LastTransaction = ({ date, detail, reference }: Props) => (
  <Card>
    <div className="LastTransaction-header">
      Last Transaction
    </div>
    <Transaction
      reference={reference}
      date={date}
      detail={detail}
    />
  </Card>
);

export default LastTransaction;
