import { commonFetch, encodeQueryFilter, ApiError } from '../apiHelper';
import { Credentials, CredentialsListResponse, Otp } from './credentialsTypes';

export class CredentialsApi {
  static getList = async (filter: {
    search?: string,
    branchId?: string,
    portal?: string[],
  }, pagination: {
    pageSize: number,
    current: number,
  }): Promise<CredentialsListResponse> => {
    const requestFilter: any = {
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
    };
    if (Object.prototype.hasOwnProperty.call(filter, 'branchId')) {
      requestFilter.branchId = filter.branchId;
    }
    if (Object.prototype.hasOwnProperty.call(filter, 'portal')) {
      requestFilter.portal = filter.portal;
    }
    if (Object.prototype.hasOwnProperty.call(filter, 'search')) {
      requestFilter.search = filter.search;
    }
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/credentials${encodeQueryFilter(requestFilter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static createCredentials = async (credentials: Credentials, otp?: Otp) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/credentials`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify({ ...credentials, otp }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static createForceCredentials = async (credentials: Credentials) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/credentials/force`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static updateCredentials = async (id: string, credentials: Credentials, otp?: Otp) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/credentials/${id}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'PATCH',
      body: JSON.stringify({ ...credentials, otp }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static delete = async (id: string) => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/credentials/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getCredentials = async (id: string) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/credentials/${id}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static bulkAdd = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/credentials/upload`, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };
}

export default CredentialsApi;
