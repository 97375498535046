import React from 'react';
import { Button } from 'custom-test-antd';
import { CalendyButton } from 'components/common/calendy/button';

import './AuthorizeDocumentsStep.pcss';
import { DocumentsUploaderWrapper, WrapperProps } from '../documents-uploader';
import { ProposalState } from '../../../../api/financing/proposal/financingProposalTypes';

interface Props {
  onDelete: WrapperProps['onDelete'];
  onTypeChange: WrapperProps['onTypeChange'];
  onFileUpload: WrapperProps['onFileUpload'];
  onFileDownload: WrapperProps['onFileDownload'];
  onValidateButtonClick: () => void;
  onChangeButtonClick: () => void;
  proposalState?: ProposalState,
}

export const AuthorizeDocumentsStep = ({
  onDelete,
  onTypeChange,
  onFileUpload,
  onFileDownload,
  onValidateButtonClick,
  onChangeButtonClick,
  proposalState,
}: Props) => (
  <div className="AuthorizeDocumentsStep">
    <DocumentsUploaderWrapper
      onDelete={onDelete}
      onTypeChange={onTypeChange}
      onFileUpload={onFileUpload}
      onFileDownload={onFileDownload}
      disabled={proposalState !== ProposalState.KYCDocumentUploading}
    />
    <div className="AuthorizeDocumentsStep-help">
      {
        proposalState === ProposalState.KYCDocumentUploading
        && <Button onClick={onValidateButtonClick} size="large" type="primary">Validate documents</Button>
      }
      {
        proposalState === ProposalState.KYCDocumentUploaded
        && <Button onClick={onChangeButtonClick} size="large">Change documents</Button>
      }
      <span className="AuthorizeDocumentsStep-buttonSpace" />
      <span className="AuthorizeDocumentsStep-helpText">Having trouble with something?</span>
      <CalendyButton
        size="large"
        url={process.env.REACT_APP_CAPITAL_SUPPORT_CALENDY_URL}
      >
        Contact support
      </CalendyButton>
    </div>
  </div>
);

export default AuthorizeDocumentsStep;
