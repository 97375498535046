import React from 'react';
import { Formik } from 'formik';
import { Form, Button, Row } from 'custom-test-antd';
import { Link } from 'react-router-dom';

import './SignIn.pcss';

import { CheckboxField, InputWrapper, InputPasswordWrapper } from 'utils/form-helpers/antd-formik';
import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';

interface Props {
  onSubmit: any;
}

export const SignIn: React.FC<Props> = ({ onSubmit }) => (
  <AuthCard
    className="SignIn"
    header="Sign in"
    headerText="Login with your Klaim credentials."
  >
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <InputWrapper
            antFieldProps={{ label: 'Email' }}
            name="email"
            size="large"
            gray
          />
          <InputPasswordWrapper
            antFieldProps={{ label: 'Password' }}
            name="password"
            size="large"
            gray
          />
          <Link to={publicRoutes.forgotPassword} className="SignIn-forgotPassword">Forgot Password?</Link>
          <Row>
            <span className="SignIn-newUser">New user?</span>
            <Link to={publicRoutes.signUp} className="SignIn-newAccount">Create an account</Link>
          </Row>
          <div className="SignIn-loginRow">
            <CheckboxField name="rememberMe">
              Remember Login
            </CheckboxField>
            <Button
              htmlType="submit"
              loading={isSubmitting}
              type="primary"
              onClick={() => handleSubmit()}
              size="large"
            >
              Login
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </AuthCard>
);

export default SignIn;
