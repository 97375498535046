import React from 'react';
import { Button, Divider } from 'custom-test-antd';

import './SettingsCell.pcss';

interface Props {
  className?: string;
  label: string;
  value: string;
  onEdit?: React.MouseEventHandler<HTMLElement>;
}

export const SettingsCell: React.FC<Props> = ({ className, label, value, onEdit }) => (
  <div className={`SettingsCell ${className ?? ''}`}>
    <div className="SettingsCell-header">
      <span className="SettingsCell-label">{label}</span>
      {
        onEdit
        && (
          <Button
            type="link"
            onClick={onEdit}
            className="SettingsCell-button"
          >
            Edit
          </Button>
        )
      }
    </div>
    <Divider className="SettingsCell-divider" />
    <span className="SettingsCell-value">{value}</span>
  </div>
);

export default SettingsCell;
