import React from 'react';
import moment from 'moment-timezone';
import { Tooltip } from 'custom-test-antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import './Insurance.pcss';
import { ReactComponent as Download } from 'assets/download.svg';

import { EligibilityResponse } from '../../../../api/eligibility/eligibilityTypes';

interface TextSectionProps {
  label: React.ReactNode,
  value: React.ReactNode,
}

const TextSection: React.FC<TextSectionProps> = ({ label, value }) => (
  <>
    <div className="Insurance-label">{label}</div>
    <div className="Insurance-value">{value}</div>
  </>
);

interface Props {
  response: EligibilityResponse,
  createdAt?: string,
  portal?: string,
}

export const Insurance: React.FC<Props> = ({ response, createdAt, portal }) => {
  const responseStatus = response?.eligibilityStatus?.status;
  const statusMessage = response?.eligibilityStatus?.message;
  return (
    <div className="Insurance">
      <div className="Insurance-title">Insurance</div>
      <div className="Insurance-infoSection">
        <TextSection
          label="ELIGIBILITY STATUS"
          value={(
            <div className="Insurance-statusSection">
              <span className={`Insurance-status ${responseStatus ? 'eligible' : 'notEligible'}`} />
              <span className="Insurance-value Insurance-statusValue">
                {responseStatus ? 'Eligible' : 'Not Eligible'}
              </span>
              {
                statusMessage
                && <Tooltip title={statusMessage}><QuestionCircleOutlined /></Tooltip>
              }
            </div>
          )}
        />
        <TextSection label="INSURANCE NAME" value={portal} />
        <TextSection label="REFERENCE ID" value={response?.payerId ?? response?.referenceNumber} />
        {
          response?.patientId
          && <TextSection label="PATIENT ID" value={response?.patientId} />
        }
        <TextSection label="ORDERED" value={moment(createdAt).format('DD MMM YYYY')} />
        <TextSection label="VALIDITY" value={response?.effectiveDate} />
        <TextSection label="NETWORK" value={response?.network} />
        <TextSection label="PAYER NAME" value={response?.payerName} />
        {
          response?.payerCode
          && <TextSection label="PAYER NO" value={response?.payerCode} />
        }
        <TextSection label="POLICY NAME" value={response?.policyName} />
        <TextSection label="POLICY HOLDER" value={response?.policyHolder} />
        <TextSection label="POLICY NO" value={response?.policyNo} />
        {
          response?.clinicianName
          && <TextSection label="CLINICIAN NAME" value={response?.clinicianName} />
        }
        <TextSection label="CATEGORY" value={response?.category} />
        <TextSection label="AREA OF COVERAGE" value={response?.areaOfCoverage} />
        {
          response?.encounterType
          && <TextSection label="ENCOUNTER TYPE" value={response?.encounterType} />
        }
        {
          response?.limit
          && <TextSection label="LIMIT" value={response?.limit} />
        }
        {
          response?.billing
          && <TextSection label="BILLING" value={response?.billing} />
        }
        {
          response?.privilegeClass
          && <TextSection label="PRIVILEGE" value={response?.privilegeClass} />
        }
        {
          response?.dependency
          && <TextSection label="DEPENDENCY" value={response?.dependency} />
        }
        {
          response?.roomType
          && <TextSection label="ROOM TYPE" value={response?.roomType} />
        }
        {
          response?.dohPackageNo
          && <TextSection label="DOH PACKAGE NUMBER" value={response?.dohPackageNo} />
        }
        <TextSection
          label="ATTACHMENTS"
          value={
            response.documents
              ? (
                <a
                  href={response.documents}
                  className="Insurance-download"
                >
                  <Download className="Insurance-downloadIcon" />
                  <span> Download Files</span>
                </a>
              )
              : null
          }
        />
        <TextSection
          label="SCREENSHOT"
          value={
            response.screenshot
              ? (
                <a
                  href={response.screenshot}
                  className="Insurance-download"
                >
                  <Download className="Insurance-downloadIcon" />
                  <span> Get Screenshot</span>
                </a>
              )
              : null
          }
        />
      </div>
    </div>
  );
};

export default Insurance;
