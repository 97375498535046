import { Button, Modal, ModalProps, Statistic } from 'custom-test-antd';
import React, { FC, useEffect, useState } from 'react';
import { OrganizationApi } from 'api';
import UpgradeFullVersionWarning from '../common/warning/UpgradeFullVersionWarning';
import UpgradeFullVersionConfirmation from '../common/confirmation/UpgradeFullVersionConfirmation';

import './UpgradeFullVersionModal.pcss';

interface Props extends ModalProps {
  actionName?: string;
  actionAvailableFrom?: Date;
  onActionClick?: () => void;
}

export const UpgradeFullVersionModal: FC<Props> = React.memo(({
  actionName,
  actionAvailableFrom,
  onActionClick,
  ...props
}: Props) => {
  const [isUpgradeRequested, setIsUpgradeRequested] = useState(false);
  const [showTimer, setShowTimer] = useState(actionAvailableFrom ? actionAvailableFrom > new Date() : false);

  useEffect(() => {
    if (actionAvailableFrom) {
      setShowTimer(actionAvailableFrom > new Date());
    } else {
      setShowTimer(false);
    }
  }, [actionAvailableFrom]);

  const onGetCallback = async () => {
    await OrganizationApi.requestEligibilityUpgrade();
    setIsUpgradeRequested(true);
  };

  const onTimerFinish = () => {
    setShowTimer(false);
  };

  const warningWithAction = actionAvailableFrom ? (
    <>
      <div className="UpgradeFullVersionModal-title">Unlock the Full Version of Klaim Eligibility</div>
      <div className="UpgradeFullVersionModal-description">
        You are using a free version of Klaim Eligible.&nbsp;
        Free version users have to wait 25 minutes between actions.&nbsp;
        Upgrade to unlimited actions by contacting our sales team.

        { showTimer
          && (
          <Statistic.Countdown
            className="UpgradeFullVersionModal-timer"
            value={actionAvailableFrom.getTime()}
            onFinish={onTimerFinish}
          />
          ) }
        {
          !showTimer
          && (
            <Button
              className="UpgradeFullVersionModal-action"
              type="primary"
              onClick={onActionClick}
            >
              {actionName}
            </Button>
          )
        }

        <div className="UpgradeFullVersionModal-callback">
          <Button onClick={onGetCallback}>
            Unlock Full Version
          </Button>
        </div>
      </div>
    </>
  )
    : null;

  return (
    <Modal
      width={558}
      bodyStyle={{ textAlign: 'center', padding: '48px 0' }}
      {...props}
      footer={null}
      closable
    >
      {!isUpgradeRequested && actionAvailableFrom && warningWithAction}
      {!isUpgradeRequested && !actionAvailableFrom && <UpgradeFullVersionWarning onGetCallback={onGetCallback} />}
      {isUpgradeRequested && <UpgradeFullVersionConfirmation />}
    </Modal>
  );
});

export default UpgradeFullVersionModal;
