import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import * as Yup from 'yup';

import 'utils/form-helpers/yup-password';
import { ApiError, UserProfileApi } from 'api';

import { AccountPassword } from './AccountPassword';
import { submitValidate } from '../../../../utils/form-helpers/antd-formik';
import { privateRoutes } from '../../../application/routes/routesConstants';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().password().required('Password is required'),
  newPasswordRepeat: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const AccountPasswordContainer = () => {
  const navigate = useNavigate();
  return (
    <AccountPassword
      initialValues={{}}
      onSubmit={useCallback(async (values, formikHelpers) => {
        if (!submitValidate(values, validationSchema, formikHelpers)) {
          return false;
        }
        try {
          await UserProfileApi.changePassword({
            oldPassword: values.oldPassword,
            password: values.newPassword,
          });
          navigate(privateRoutes.accountSettings);
        } catch (error: any) {
          if (error instanceof ApiError && error.response.status === 400) {
            const response = await error.response.json();
            if (response.message) {
              formikHelpers.setErrors({ oldPassword: response.message });
              formikHelpers.setTouched({ oldPassword: true }, false);
            }
            return false;
          }
          notification.error({
            message: 'Error',
            description: 'Internal server error',
          });
          return false;
        }
        return true;
      }, [navigate])}
      onCancel={useCallback(() => {
        navigate(-1);
      }, [navigate])}
    />
  );
};

export default AccountPasswordContainer;
