import React from 'react';
import { Card, Divider, Spin, Empty } from 'custom-test-antd';
import { useMount } from 'react-use';

import './CapitalTransactions.pcss';

import { Pagination, PaginationValue } from './pagination';
import { Transaction } from '../transaction';
import { DealSummary } from '../../../api/financing/deals/financingDealsTypes';

interface Props {
  transactions: DealSummary[];
  pagination: PaginationValue;
  onFetch: () => void;
  loading: boolean;
  onPaginationChange: (pagination: PaginationValue) => void;
}

export const CapitalTransactions = ({ transactions, pagination, onFetch, loading, onPaginationChange }: Props) => {
  useMount(() => {
    onFetch();
  });
  const data = transactions.map((transaction) => ({
    reference: transaction.deal.reference,
    date: transaction.deal.date,
    transactionDetail: transaction.insurers.map((insurer) => ({
      portal: insurer.insurer.shortName.toLowerCase(),
      purchasePrice: insurer.purchasePrice,
      claims: insurer.count,
      netAmount: insurer.netAmount,
    })),
  }));

  return (
    <Card className="CapitalTransactions">
      <div className="CapitalTransactions-header">
        Last Transactions
      </div>
      <Spin spinning={loading}>
        {
          data.length === 0
          && <Empty />
        }
        {
          data.map((dataItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <Transaction
                reference={dataItem.reference}
                date={dataItem.date}
                detail={dataItem.transactionDetail}
                showClaims
                showNetAmount
              />
              <Divider className="CapitalTransactions-divider" />
            </React.Fragment>
          ))
        }
        {
          data.length !== 0
          && (
            <Pagination
              className="CapitalTransactions-pagination"
              pagination={pagination}
              onChange={onPaginationChange}
            />
          )
        }
      </Spin>
    </Card>
  );
};

export default CapitalTransactions;
