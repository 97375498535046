import React, { useCallback, useState } from 'react';
import { Button, Card, Checkbox, Divider } from 'custom-test-antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import './AcceptProposal.pcss';

import { formatPrice } from '../../utills/numberFormats';

interface Props {
  totalAmount: number;
  onAccept: (attachPdf: boolean) => void;
  className?: string;
}

export const AcceptProposal = ({ onAccept, totalAmount, className }: Props) => {
  const [attachPdf, setAttachPdf] = useState(false);
  return (
    <Card className={`AcceptProposal ${className ?? ''}`}>
      <span>Proposal Amount&nbsp;</span>
      <span className="AcceptProposal-currency">(AED)</span>
      <div>
        <span className="AcceptProposal-amount">{formatPrice(totalAmount, false)}</span>
      </div>
      <Button
        className="AcceptProposal-button"
        size="large"
        type="primary"
        onClick={useCallback(() => onAccept(attachPdf), [onAccept, attachPdf])}
      >
        Accept proposal
      </Button>
      <Divider />
      <Checkbox
        className="AcceptProposal-attachPdf"
        checked={attachPdf}
        onChange={useCallback((e: CheckboxChangeEvent) => (
          setAttachPdf(e.target.checked)
        ), [])}
      >
        Attach proposal pdf in mail
      </Checkbox>
    </Card>
  );
};

export default AcceptProposal;
