import produce from 'immer';
import { FormConfig } from '../portal-request/portalRequest';

export interface EligibilityRequestContextState {
  formConfig?: FormConfig;
  values: any;
  previousValues: any;
  initialValues: any;
  isInitialValuesLoading: boolean;
  validationErrors: any;
  touchedInputs: any;
}

export const initialState = (initValues?: Partial<EligibilityRequestContextState>): EligibilityRequestContextState => ({
  formConfig: initValues?.formConfig ?? undefined,
  values: {},
  previousValues: {},
  initialValues: {
    branchId: '',
  },
  isInitialValuesLoading: false,
  validationErrors: {},
  touchedInputs: {},
});

export const SET_FORM_CONFIG = 'SET_CONFIG';
export const setFormConfig = (formConfig?: FormConfig) => ({
  type: SET_FORM_CONFIG, payload: { formConfig },
});

export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const setFormValues = (values?: any) => ({
  type: SET_FORM_VALUES, payload: { values },
});

export const SET_INITIAL_FORM_VALUES = 'SET_INITIAL_FORM_VALUES';
export const setInitialFormValues = (initialValues?: any) => ({
  type: SET_INITIAL_FORM_VALUES, payload: { initialValues },
});

export const SET_IS_INITIAL_VALUES_LOADING = 'SET_IS_INITIAL_VALUES_LOADING';
export const setIsInitialValuesLoading = (isInitialValuesLoading: boolean) => ({
  type: SET_IS_INITIAL_VALUES_LOADING, payload: { isInitialValuesLoading },
});

export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const setValidationErrors = (validationErrors: any) => ({
  type: SET_VALIDATION_ERRORS, payload: { validationErrors },
});

export const SET_TOUCHED_INPUTS = 'SET_TOUCHED_INPUTS';
export const setTouchedInputs = (touchedInputs: any) => ({
  type: SET_TOUCHED_INPUTS, payload: { touchedInputs },
});

const eligibilityRequestReducer = (
  state: EligibilityRequestContextState,
  action: any,
) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_FORM_CONFIG: {
      const { formConfig } = (action as ReturnType<typeof setFormConfig>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.formConfig = formConfig;
      break;
    }
    case SET_FORM_VALUES: {
      const { values } = (action as ReturnType<typeof setFormValues>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.previousValues = draftState.values;

      // change branchId from undefined to ''
      const formattedValues = {
        ...values,
        branchId: !values.branchId ? '' : values.branchId,
      };
      // eslint-disable-next-line no-param-reassign
      draftState.values = formattedValues;
      break;
    }
    case SET_INITIAL_FORM_VALUES: {
      const { initialValues } = (action as ReturnType<typeof setInitialFormValues>).payload;
      // change branchId from undefined to ''
      const formattedValues = {
        ...initialValues,
        branchId: !initialValues.branchId ? '' : initialValues.branchId,
      };
      // eslint-disable-next-line no-param-reassign
      draftState.initialValues = formattedValues;
      break;
    }
    case SET_IS_INITIAL_VALUES_LOADING: {
      const { isInitialValuesLoading } = (action as ReturnType<typeof setIsInitialValuesLoading>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.isInitialValuesLoading = isInitialValuesLoading;
      break;
    }
    case SET_VALIDATION_ERRORS: {
      const { validationErrors } = (action as ReturnType<typeof setValidationErrors>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.validationErrors = validationErrors;
      break;
    }
    case SET_TOUCHED_INPUTS: {
      const { touchedInputs } = (action as ReturnType<typeof setTouchedInputs>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.touchedInputs = touchedInputs;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default eligibilityRequestReducer;
