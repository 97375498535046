import React from 'react';
import { Input, Menu, Popover, Table } from 'custom-test-antd';
import { DeleteOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import moment from 'moment-timezone';
import { PractitionerLicense } from 'api/practitioner-license/practitionerLicenseTypes';

import './LicenseList.pcss';
import { Card } from '../../eligibility/card';

interface Props {
  licenses: PractitionerLicense[];
  onOpenLicense: (id: string) => void;
  onDelete: (id: string) => void;
  loading: boolean;
  onSearch: (search: string) => void;
}

export const LicenseList = ({ licenses, onOpenLicense, loading, onDelete, onSearch }: Props) => {
  const columns: ColumnsType<PractitionerLicense> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'License',
      dataIndex: 'license',
      key: 'license',
    },
    {
      title: 'Entry Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => (createdAt ? moment(createdAt).format('DD MMM YYYY HH:mm') : ''),
    },
    {
      title: '',
      width: 54,
      onCell: () => ({
        onClick: (event) => event.stopPropagation(),
      }),
      render: (_, row) => (
        <Popover
          overlayClassName="LicenseList-moreActions"
          content={(
            <Menu
              className="LicenseList-menu"
              items={[
                {
                  label: 'View',
                  key: 'view',
                  icon: <EyeOutlined />,
                  onClick: () => {
                    onOpenLicense(row.id);
                  },
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    onDelete(row.id);
                  },
                },
              ]}
            />
          )}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  return (
    <Card className="LicenseList">
      <div className="LicenseList-actionPanel">
        <Input.Search
          className="LicenseList-search"
          placeholder="Search"
          onSearch={onSearch}
          size="large"
          gray
        />
      </div>
      <Table
        className="LicenseList"
        rowKey="id"
        loading={loading}
        rowClassName="LicenseList-tableRow"
        columns={columns}
        dataSource={licenses}
        onRow={(record) => ({
          onDoubleClick: () => {
            onOpenLicense(record.id);
          },
        })}
        rightAngleTopCorners
      />
    </Card>
  );
};

export default LicenseList;
