import React from 'react';
import { Formik } from 'formik';
import { Form, Button } from 'custom-test-antd';
import { Link } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';

import './UpdatePassword.pcss';

import { InputPasswordWrapper } from 'utils/form-helpers/antd-formik';
import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';
import { PasswordGuideTooltip } from '../password-guide-tooltip';

interface Props {
  onSubmit: any;
}

export const UpdatePassword: React.FC<Props> = ({ onSubmit }) => (
  <AuthCard
    className="UpdatePassword"
    header="Update your password"
    headerText="Type your new password here. Make sure that your password follows the requirements"
  >
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <div className="UpdatePassword-passwordRow">
            <InputPasswordWrapper
              antFieldProps={{ label: 'New Password', className: 'UpdatePassword-password' }}
              name="password"
              size="large"
              gray
            />
            <Form.Item>
              <PasswordGuideTooltip>
                <QuestionCircleFilled className="UpdatePassword-questionIcon" />
              </PasswordGuideTooltip>
            </Form.Item>
          </div>
          <div className="UpdatePassword-signInSection">
            <span>Remembered?&nbsp;</span>
            <Link to={publicRoutes.signIn} className="UpdatePassword-signIn">Sign In</Link>
          </div>
          <Button
            htmlType="submit"
            loading={isSubmitting}
            type="primary"
            onClick={() => handleSubmit()}
            size="large"
            className="UpdatePassword-submitButton"
          >
            Update password
          </Button>
        </Form>
      )}
    </Formik>
  </AuthCard>
);

export default UpdatePassword;
