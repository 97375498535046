import { Dispatch } from 'react';
import { ValidationError } from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import EligibilityDefaultApi from 'api/eligibility-default/EligibilityDefaultApi';
import { EligibilityDefaultGetResponse } from 'api/eligibility-default/eligibilityDefaultTypes';
import { FormConfig, IPortalRequest, PortalRequestFactory } from '../portalRequest';
import {
  InsurancePortalLabel,
  InsurancePortal,
} from '../../../../../constants/dropdownOptions';
import SinglePortalRequest from '../singlePortalRequest';
import { EligibilityApi } from '../../../../../api';
import { EligibilityRequestContextState } from '../../context/eligibilityRequestReducer';

export class MultipleRequest implements IPortalRequest {
  protected readonly portals: InsurancePortal[];

  protected readonly dispatch: Dispatch<any>;

  protected readonly getState: () => EligibilityRequestContextState;

  constructor(portals: InsurancePortal[], dispatch: Dispatch<any>, getState: () => EligibilityRequestContextState) {
    this.portals = portals;
    this.dispatch = dispatch;
    this.getState = getState;
  }

  getFormConfig(values: any = {}): FormConfig {
    const factory = new PortalRequestFactory();
    const portalFormConfigs: FormConfig[] = this.portals.map((portal: InsurancePortal) => {
      const portalRequest = factory.instantiate(portal, this.dispatch, this.getState) as SinglePortalRequest;
      const portalFormConfig = portalRequest.getFormConfig(values, false);
      if (portalFormConfig.treatmentBases) {
        portalFormConfig.treatmentBases = portalFormConfig.treatmentBases.map((basis) => ({
          ...basis,
          label: `${basis.label} (${InsurancePortalLabel[portal]})`,
        }));
      }
      return portalFormConfig;
    });

    return portalFormConfigs.reduce((acc, item) => ({
      ...acc,
      ...item,
      treatmentBases: [...acc.treatmentBases ?? [], ...item.treatmentBases ?? []],
      patientIdType: undefined,
    }), {} as FormConfig);
  }

  validate(values: any) {
    const factory = new PortalRequestFactory();
    let errors: ValidationError | undefined;
    this.portals.forEach((portal) => {
      const portalRequest = factory.instantiate(portal, this.dispatch, this.getState) as SinglePortalRequest;
      const schema = portalRequest.getValidationSchema();
      try {
        schema.validateSync({
          ...values,
          patientIdType: portalRequest.getEmiratesIdPatientType(),
        }, { abortEarly: false });
      } catch (error) {
        if (error instanceof ValidationError) {
          if (!errors) {
            errors = error;
          } else {
            errors.inner = [...errors.inner, ...error.inner];
          }
        } else {
          throw error;
        }
      }
    });
    if (errors) {
      throw errors;
    }
  }

  async getInitialValues(values: any, fromApi: boolean): Promise<any> {
    const { branchId } = values;
    let remoteDefaultValues: EligibilityDefaultGetResponse[];

    if (fromApi) {
      remoteDefaultValues = await EligibilityDefaultApi.search({ branchId, portals: this.portals });
    }

    const factory = new PortalRequestFactory();
    const portalInitValues: Promise<any>[] = this.portals.map((portal: InsurancePortal) => {
      const portalRequest = factory.instantiate(portal, this.dispatch, this.getState) as SinglePortalRequest;

      const remoteDefaultValue = remoteDefaultValues?.find((item) => item.portal === portal);
      if (remoteDefaultValue) {
        return portalRequest.getValuesForForm(remoteDefaultValue.payload);
      }

      return portalRequest.getInitialValues(values, false);
    });

    return (await Promise.all(portalInitValues)).reduce((acc, item) => ({
      ...acc,
      ...item,
      emiratesId: '784-____-_______-_',
      patientIdType: undefined,
    }), {} as any);
  }

  async getLastCheckId(values: any) {
    const factory = new PortalRequestFactory();
    for (let i = 0; i < this.portals.length; i += 1) {
      const portalRequest = factory.instantiate(this.portals[i], this.dispatch, this.getState) as SinglePortalRequest;
      // eslint-disable-next-line no-await-in-loop
      const lastCheckId = await portalRequest.getLastCheckId({
        ...values,
        patientIdType: portalRequest.getEmiratesIdPatientType(),
      });
      if (lastCheckId) {
        return lastCheckId;
      }
    }
    return undefined;
  }

  async createRequest(values: any) {
    const factory = new PortalRequestFactory();
    const submitValues = this.portals.map((portal) => {
      const portalRequest = factory.instantiate(portal, this.dispatch, this.getState) as SinglePortalRequest;
      return portalRequest.getValuesForSubmission({
        ...values,
        patientIdType: portalRequest.getEmiratesIdPatientType(),
      });
    });
    return EligibilityApi.createMultiPortalRequest(submitValues);
  }

  getPortal() {
    return this.portals;
  }
}

export default MultipleRequest;
