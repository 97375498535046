import React, { useState, useMemo } from 'react';
import produce from 'immer';
import { useMount } from 'react-use';
import ClientList, { User } from './ClientList';
import { UserProfileApi } from '../../../api';
import { OrganizationOwner } from '../../../api/user-profile/userProfileTypes';

type UserIsPortalPaid = { isPortalPaid: boolean; };
type UserIsEligibilityPaid = { isEligibilityPaid: boolean; };
type UserIsInspectorPaid = { isInspectorPaid: boolean; };
type UserUpdate = UserIsPortalPaid | UserIsEligibilityPaid | UserIsInspectorPaid;

export const mapOrganizationUserToUser = (user: OrganizationOwner): User => ({
  name: user.organization.owner.name,
  email: user.organization.owner.email,
  id: user.organization.owner.id,
  isPortalPaid: user.organization.isPortalPaid,
  isEligibilityPaid: user.organization.isEligibilityPaid,
  isInspectorPaid: user.organization.isInspectorPaid,
  phone: user.organization.owner.phone,
  organization: {
    id: user.organization.id,
    name: user.organization.name,
  },
});

export const ClientListContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  useMount(async () => {
    try {
      setLoading(true);
      const fetchedUsers: OrganizationOwner[] = await UserProfileApi.getOrganizationOwners();
      setUsers(fetchedUsers.map(mapOrganizationUserToUser));
    } finally {
      setLoading(false);
    }
  });

  const selectedUsers: User[] = useMemo(() => {
    const regExp = new RegExp(`.*${searchString}.*`, 'i');
    return users.filter((user) => (
      regExp.test(user.email)
      || regExp.test(user.name)
      || regExp.test(user.phone)
      || regExp.test(user.organization.name)
    ));
  }, [users, searchString]);

  const updateUser = (organizationId: number, userData: UserUpdate) => {
    setUsers((existingUsers) => {
      const userIndex = users.findIndex((user) => user.organization.id === organizationId);
      return produce(existingUsers, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[userIndex] = { ...draft[userIndex], ...userData };
      });
    });
  };

  return (
    <ClientList
      loading={loading}
      data={selectedUsers}
      onSetPortalPaid={async (organizationId, isPortalPaid) => {
        await UserProfileApi.setPortalPaid(organizationId, isPortalPaid);
        updateUser(organizationId, { isPortalPaid });
      }}
      onSetEligibilityPaid={async (organizationId, isEligibilityPaid) => {
        await UserProfileApi.setEligibilityPaid(organizationId, isEligibilityPaid);
        updateUser(organizationId, { isEligibilityPaid });
      }}
      onSetInspectorPaid={async (organizationId, isInspectorPaid) => {
        await UserProfileApi.setInspectorPaid(organizationId, isInspectorPaid);
        updateUser(organizationId, { isInspectorPaid });
      }}
      onSearch={setSearchString}
    />
  );
};

export default ClientListContainer;
