import React, { FC } from 'react';

import './UpgradeFullVersionWarning.pcss';
import { Button } from 'custom-test-antd';

type Props = {
  onGetCallback: () => void;
};

const upgradeFullVersionWarning: FC<Props> = ({ onGetCallback }: Props) => (
  <>
    <div className="UpgradeFullVersionWarning-title">Unlock the Full Version of Klaim Eligibility</div>
    <div className="UpgradeFullVersionWarning-description">
      You are using a free version of Klaim Eligible.&nbsp;
      To unlock the full version request a callback from our sales team
      <div className="UpgradeFullVersionWarning-callback">
        <Button onClick={() => onGetCallback()}>
          Get a Callback
        </Button>
      </div>
    </div>
  </>
);

export default React.memo(upgradeFullVersionWarning);
