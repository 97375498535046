import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useAuthState } from 'components/authentication/context/authContext';
import { userProfileSelector } from 'components/authentication/context/authSelectors';
import { UserOnboarding } from './UserOnboarding';
import { onboardStepSelector } from './context/userOnboardingSelectors';
import {
  UserOnboardingProvider,
  useUserOnboardingDispatch,
  useUserOnboardingState,
} from './context/userOnboardingContext';
import { initOnboardStep } from './context/userOnboardingThunks';
import { UserProfile } from '../../api/user-profile/userProfileTypes';

export const UserOnboardingContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const authState = useAuthState();
  const user = userProfileSelector(authState) as UserProfile;

  const state = useUserOnboardingState();
  const dispatch = useUserOnboardingDispatch();
  const onboardStep = onboardStepSelector(state);

  useEffect(() => {
    if (user) {
      dispatch(initOnboardStep(user));
    }
  }, [dispatch, user]);

  return (
    <UserOnboarding onboardStep={onboardStep}>
      {children}
    </UserOnboarding>
  );
};

interface WrapperProps {
  branchRequired?: boolean;
  callRequired?: boolean;
  children?: React.ReactNode;
}

export const UserOnboardingWrapper: FC<WrapperProps> = ({
  branchRequired = false,
  callRequired = false,
  children,
}: WrapperProps) => (
  <UserOnboardingProvider initState={{ branchRequired, callRequired }}>
    <UserOnboardingContainer>
      {children}
    </UserOnboardingContainer>
  </UserOnboardingProvider>
);

export default UserOnboardingWrapper;
