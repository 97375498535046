import React, { forwardRef } from 'react';
import { Select as _Select, SelectProps as _SelectProps, RefSelectProps } from 'custom-test-antd';
import { FieldProps } from 'formik';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { FormItemProps, makeField } from './makeField';

type SelectProps<T = any> = FieldProps & _SelectProps<T>;

export { _SelectProps as SelectProps };

type SelectFieldProps<T = any> = FormikFieldProps & _SelectProps<T>;

const InternalSelect = React.forwardRef((
  {
    children,
    field,
    form,
    meta,
    onChange,
    onBlur,
    ...restProps
  }: SelectProps,
  ref: React.Ref<RefSelectProps>,
) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;
  return (
    <_Select
      ref={ref}
      onChange={(newValue, option) => {
        setFieldValue(name, newValue);
        if (onChange) {
          onChange(newValue, option);
        }
      }}
      onBlur={(newValue) => {
        setFieldTouched(name);
        if (onBlur) {
          onBlur(newValue);
        }
      }}
      // setting undefined will show the placeholder
      value={value === '' || value === null ? undefined : value}
      {...restProps}
    >
      {children}
    </_Select>
  );
});

interface SelectComponent
  extends React.ForwardRefExoticComponent<SelectProps & React.RefAttributes<RefSelectProps>> {
  Option: typeof _Select.Option;
  OptGroup: typeof _Select.OptGroup;
}

export const Select = InternalSelect as SelectComponent;
Select.Option = _Select.Option;
Select.OptGroup = _Select.OptGroup;

const InternalSelectField = forwardRef((
  {
    name,
    validate,
    fast,
    ...restProps
  }: SelectFieldProps,
  ref: React.Ref<RefSelectProps>,
) => (
  <Field name={name} validate={validate} fast={fast}>
    {(fieldProps: any) => (
      <InternalSelect ref={ref} {...fieldProps} {...restProps} />
    )}
  </Field>
));

interface SelectFieldComponent
  extends React.ForwardRefExoticComponent<SelectFieldProps & React.RefAttributes<RefSelectProps>> {
  Option: typeof _Select.Option;
  OptGroup: typeof _Select.OptGroup;
}

export const SelectField = InternalSelectField as SelectFieldComponent;
SelectField.Option = _Select.Option;
SelectField.OptGroup = _Select.OptGroup;

interface SelectWrapperComponent
  extends React.ForwardRefExoticComponent<FormItemProps
    & _SelectProps
    & FormikFieldProps
    & React.RefAttributes<RefSelectProps>> {
  Option: typeof Select.Option;
  OptGroup: typeof Select.OptGroup;
}

export const SelectWrapper = makeField<_SelectProps>(Select) as SelectWrapperComponent;
SelectWrapper.Option = Select.Option;
SelectWrapper.OptGroup = Select.OptGroup;
