import React from 'react';
import { FormikHelpers, Formik } from 'formik';
import { Form, Button } from 'custom-test-antd';

import './AccountFullName.pcss';

import { InputWrapper } from '../../../../utils/form-helpers/antd-formik';
import { AccountLayoutCard } from '../account-layout-card';

interface Props {
  onCancel: () => void,
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any,
  initialValues: any
}

export const AccountFullName: React.FC<Props> = ({ onSubmit, initialValues, onCancel }) => (
  <AccountLayoutCard
    className="AccountFullName"
    title={(
      <AccountLayoutCard.Title
        title="Update Name"
        description="Changes to your name and surname will be reflected across your Klaim account."
      />
    )}
  >
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <InputWrapper
            size="large"
            antFieldProps={{ label: 'Full Name' }}
            gray
            name="fullName"
            placeholder="Full name"
          />
          <div className="AccountFullName-buttons">
            <Button
              size="large"
              type="link"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </AccountLayoutCard>
);

export default AccountFullName;
