import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProposalState } from 'api/financing/proposal/financingProposalTypes';

import { useProposalState } from '../../proposal/context/context/proposalContext';
import { proposalSelector } from '../../proposal/context/context/proposalSelectors';
import ProposalProcess from './ProposalProcess';
import { calcCurrentStep } from '../../accept-proposal-steps';
import { privateRoutes } from '../../../application/routes/routesConstants';

interface Props {
  className?: string;
}

export const ProposalProcessContainer = ({ className }: Props) => {
  const navigate = useNavigate();
  const state = useProposalState();
  const proposal = proposalSelector(state);
  return (
    <ProposalProcess
      className={className}
      step={calcCurrentStep(proposal?.state)}
      onContinue={useCallback(() => {
        switch (proposal?.state) {
          case ProposalState.Created:
          case ProposalState.WireTransferPrepared:
            navigate(privateRoutes.capitalProposal);
            break;
          case ProposalState.Accepted:
            navigate(privateRoutes.capitalGetStarted);
            break;
          default:
            navigate(privateRoutes.capitalProposalSteps);
        }
      }, [navigate, proposal])}
      onViewTutorial={useCallback(() => {
        navigate(privateRoutes.capitalGetStarted);
      }, [navigate])}
    />
  );
};

export default ProposalProcessContainer;
