import { commonFetch, encodeQueryFilter, ApiError } from '../apiHelper';
import { PractitionerLicense } from './practitionerLicenseTypes';

export class PractitionerLicenseApi {
  static upload = async (file: any, rewriteLicenses: boolean) => {
    const formData = new FormData();
    formData.append('file', file);
    if (rewriteLicenses) {
      formData.append('rewriteLicenses', 'true');
    }
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/practitioner-license/upload`, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      return response.blob();
    }
    throw new ApiError(response);
  };

  static getList = async (filter: {}, pagination: {
    pageSize?: number,
    current?: number,
  }): Promise<PractitionerLicense[]> => {
    const requestFilter: any = {};
    if (pagination.pageSize && pagination.current) {
      requestFilter.limit = pagination.pageSize;
      requestFilter.offset = (pagination.current - 1) * pagination.pageSize;
    }
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/practitioner-license${encodeQueryFilter(requestFilter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static delete = async (id: string): Promise<PractitionerLicense> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/practitioner-license/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static update = async (
    id: string,
    license: Partial<Omit<PractitionerLicense, 'id' | 'createdAt'>>,
  ): Promise<PractitionerLicense> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/practitioner-license/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(license),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static create = async (
    license: Omit<PractitionerLicense, 'id' | 'createdAt'>,
  ): Promise<PractitionerLicense> => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/practitioner-license`, {
      method: 'POST',
      body: JSON.stringify(license),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new ApiError(response);
  };

  static getLicense = async (id: string) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/practitioner-license/${id}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default PractitionerLicenseApi;
