import React from 'react';
import { Input, Menu, Popover, Table } from 'custom-test-antd';
import { DeleteOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';

import './EligibilityDefaultList.pcss';
import { EligibilityDefaultGetResponse } from 'api/eligibility-default/eligibilityDefaultTypes';
import { Card } from '../../eligibility/card';

interface Props {
  data: EligibilityDefaultGetResponse[];
  onOpen: (id: string) => void;
  onDelete: (id: string) => void;
  loading: boolean;
  onSearch: (search: string) => void;
}

export const EligibilityDefaultList = ({ data, onOpen, loading, onDelete, onSearch }: Props) => {
  const columns: ColumnsType<EligibilityDefaultGetResponse> = [
    {
      title: 'Branch',
      dataIndex: 'branchId',
      key: 'branchId',
    },
    {
      title: 'Insurance',
      dataIndex: 'portal',
      key: 'portal',
    },
    {
      title: '',
      width: 54,
      onCell: () => ({
        onClick: (event) => event.stopPropagation(),
      }),
      render: (_, row) => (
        <Popover
          overlayClassName="EligibilityDefaultList-moreActions"
          content={(
            <Menu
              className="EligibilityDefaultList-menu"
              items={[
                {
                  label: 'View',
                  key: 'view',
                  icon: <EyeOutlined />,
                  onClick: () => {
                    onOpen(row.id!);
                  },
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    onDelete(row.id!);
                  },
                },
              ]}
            />
          )}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  return (
    <Card className="EligibilityDefaultList">
      <div className="EligibilityDefaultList-actionPanel">
        <Input.Search
          className="EligibilityDefaultList-search"
          placeholder="Search"
          onSearch={onSearch}
          size="large"
          gray
        />
      </div>
      <Table
        className="EligibilityDefaultList"
        rowKey="id"
        loading={loading}
        rowClassName="EligibilityDefaultList-tableRow"
        columns={columns}
        dataSource={data}
        onRow={(record) => ({
          onDoubleClick: () => {
            onOpen(record.id!);
          },
        })}
        rightAngleTopCorners
      />
    </Card>
  );
};

export default EligibilityDefaultList;
