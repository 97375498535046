import React, { useState, useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import { useNavigate, generatePath } from 'react-router-dom';
import { privateRoutes } from 'components/application/routes/routesConstants';

import { EligibilityDefaultGetResponse } from 'api/eligibility-default/eligibilityDefaultTypes';
import EligibilityDefaultApi from 'api/eligibility-default/EligibilityDefaultApi';
import EligibilityDefaultList from './EligibilityDefaultList';

export const EligibilityDefaultListContainer = () => {
  const [data, setData] = useState<EligibilityDefaultGetResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await EligibilityDefaultApi.search();
      setData(result);
    } finally {
      setLoading(false);
    }
  }, []);

  useMount(async () => {
    await fetchList();
  });

  const filterData = useMemo(() => (
    data.filter(({ branchId, portal }) => {
      if (search) {
        return branchId?.toUpperCase().indexOf(search.toUpperCase()) !== -1
          || portal.toUpperCase().indexOf(search.toUpperCase()) !== -1;
      }
      return true;
    })
  ), [search, data]);

  return (
    <EligibilityDefaultList
      data={filterData}
      loading={loading}
      onOpen={(id) => {
        navigate(generatePath(`${privateRoutes.eligibilityDefaultEdit}`, { id }));
      }}
      onDelete={async (id) => {
        setLoading(true);
        try {
          await EligibilityDefaultApi.delete(id);
          await fetchList();
        } finally {
          setLoading(false);
        }
      }}
      onSearch={(userSearch) => {
        setSearch(userSearch);
      }}
    />
  );
};

export default EligibilityDefaultListContainer;
