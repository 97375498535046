import React from 'react';
import './InsuranceRow.pcss';

import Axa from 'assets/capital/insurers/axa.png';
import Nextcare from 'assets/capital/insurers/nextcare.png';
import Daman from 'assets/capital/insurers/daman.png';
import Almadallah from 'assets/capital/insurers/almadallah.png';
import Oman from 'assets/capital/insurers/oman.png';
import Nas from 'assets/capital/insurers/nas.png';
import Neuron from 'assets/capital/insurers/neuron.png';
import Adnic from 'assets/capital/insurers/adnic.png';

import { InsurancePortal } from '../../../constants/dropdownOptions';

interface Props {
  insurer: string;
  className?: string;
}

const insuranceConfig: { [key: string]: { icon?: string, label: string } } = {
  [InsurancePortal.Axa]: { icon: Axa, label: 'AXA' },
  [InsurancePortal.Nextcare]: { icon: Nextcare, label: 'Nextcare' },
  [InsurancePortal.Daman]: { icon: Daman, label: 'Daman' },
  [InsurancePortal.Almadallah]: { icon: Almadallah, label: 'Almadallah' },
  [InsurancePortal.Oman]: { icon: Oman, label: 'Oman' },
  [InsurancePortal.Nas]: { icon: Nas, label: 'Nas' },
  [InsurancePortal.Neuron]: { icon: Neuron, label: 'Neuron' },
  [InsurancePortal.Abnic]: { label: 'Abnic' },
  [InsurancePortal.Adnic]: { icon: Adnic, label: 'Adnic' },
};

export const InsuranceRow = ({ insurer, className }: Props) => {
  const config = insuranceConfig[insurer];
  return (
    <div className={`InsuranceRow ${className ?? ''}`}>
      <img
        src={config?.icon}
        alt=""
        className={`InsuranceRow-icon ${config ? '' : 'unknown'}`}
      />
      {config?.label ?? insurer}
    </div>
  );
};

export default InsuranceRow;
