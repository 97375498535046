import React from 'react';
import { FileListContainer } from './file-list';
import { EligibilityBulkRequestContainer } from './eligibility-bulk-request';

import './EligibilityBulk.pcss';

export const EligibilityBulk = () => (
  <div className="EligibilityBulk">
    <EligibilityBulkRequestContainer className="EligibilityBulk-upload" />
    <FileListContainer className="EligibilityBulk-fileList" />
  </div>
);

export default EligibilityBulk;
