import { Dispatch } from 'react';
import moment from 'moment-timezone';
import { BranchApi, FinancingProposalApi } from '../../../../../api';
import {
  setProposal,
  ProposalContextState,
  setCustomer,
  setLatestTransaction,
  setTransactionsItems, setTransactionsFetching, setTransactionsPagination, setBranches, setCustomers,
} from './proposalReducer';
import { branchesSelector, customersSelector, proposalSelector, transactionsSelector } from './proposalSelectors';
import FinancingCustomerApi from '../../../../../api/financing/customer/FinancingCustomerApi';
import FinancingDealsApi from '../../../../../api/financing/deals/FinancingDealsApi';

const mockProposal = {
  id: '62fbc66d5482e7323b1ee3e8',
  issuedDate: '2022-01-11',
  validDate: '2022-07-28',
  details: [{
    portal: 'axa',
    claims: 1654,
    netAmount: 49457,
    purchasePrice: 49457,
    discount: 8,
  }, {
    portal: 'nextcare',
    claims: 5648,
    netAmount: 74156,
    purchasePrice: 74156,
    discount: 3,
  }, {
    portal: 'daman',
    claims: 945,
    netAmount: 17625,
    purchasePrice: 17625,
    discount: 12,
  }, {
    portal: 'almadallah',
    claims: 4824,
    netAmount: 72213,
    purchasePrice: 72213,
    discount: 2,
  }],
  totalDiscount: 12,
  totalAmount: 249457,
  totalNetAmount: 261347,
  totalClaims: 0,
};

export const fetchBranches = () => async (dispatch: Dispatch<any>) => {
  const branches = await BranchApi.getUserBranches();
  dispatch(setBranches(branches));
};

export const fetchProposal = () => async (dispatch: Dispatch<any>, getState: () => ProposalContextState) => {
  const state = getState();
  const branches = branchesSelector(state);
  const response = await FinancingProposalApi.searchProposal(branches.map((branch) => branch.identifier));
  const proposal = response?.[0];
  if (!response) {
    return;
  }

  const detailsTotal = proposal.insurers.reduce((acc: any, insurer: any) => ({
    claimValue: acc.claimValue + insurer.claimValue,
    priceMin: acc.priceMin + insurer.claimValue * (1 - insurer.discountMax / 100),
    priceMax: acc.priceMax + insurer.claimValue * (1 - insurer.discountMin / 100),
    claimCount: acc.claimCount + (insurer.claimCount ?? 0),
  }), {
    claimValue: 0,
    priceMin: 0,
    priceMax: 0,
    claimCount: 0,
  });
  dispatch(setProposal({
    ...mockProposal,
    state: proposal.state,
    rawProposal: proposal,
    issuedDate: proposal.issueDate,
    validDate: moment(proposal.issueDate).add(proposal.daysValid, 'days').toISOString(),
    details: proposal.insurers.map((insurer: any) => ({
      portal: `${insurer.name.toLowerCase()}`,
      claims: insurer.claimCount,
      netAmount: insurer.claimValue,
      purchasePrice: insurer.claimValue * (1 - insurer.discountMax / 100),
      discount: insurer.discountMax,
    })),
    totalDiscount: ((1 - detailsTotal.priceMin / detailsTotal.claimValue) * 100).toFixed(1),
    totalAmount: detailsTotal.priceMin,
    totalNetAmount: detailsTotal.claimValue,
    totalClaims: detailsTotal.claimCount,
  }));
};

export const updateProposalState = (state: string) => async (
  dispatch: Dispatch<any>,
  getState: () => ProposalContextState,
) => {
  const contextState = getState();
  const currentProposal = proposalSelector(contextState);
  const proposal = await FinancingProposalApi.updateProposal(currentProposal.id, state);
  dispatch(setProposal({
    ...currentProposal,
    state: proposal.state,
  }));
};

export const fetchCustomers = () => async (dispatch: Dispatch<any>, getState: () => ProposalContextState) => {
  const state = getState();
  const branches = branchesSelector(state);
  const providerCodes = branches.map((branch) => branch.identifier);
  const customers = await FinancingCustomerApi.getCustomers({ providerCodes });
  dispatch(setCustomers(customers));
};

export const fetchCustomer = (providerCode: string) => async (dispatch: Dispatch<any>) => {
  const customer = await FinancingCustomerApi.getCustomers({ providerCodes: [providerCode] });
  dispatch(setCustomer(customer?.[0]));
};

export const fetchTransactions = () => (
  async (dispatch: Dispatch<any>, getState: () => ProposalContextState) => {
    const state = getState();
    const { pagination } = transactionsSelector(state);
    const customers = customersSelector(state);
    if (!customers.length) {
      return;
    }
    dispatch(setTransactionsFetching(true));
    try {
      const limit = pagination.pageSize + 1;
      const offset = (pagination.current - 1) * pagination.pageSize;
      const deals = await FinancingDealsApi.fetchDeals(
        // eslint-disable-next-line no-underscore-dangle
        customers.map((customer) => customer._id),
        limit,
        offset,
      );
      const transactions = [];
      for (let i = 0; i < deals.length && i < limit; i += 1) {
        // eslint-disable-next-line no-await-in-loop,no-underscore-dangle
        const transaction = await FinancingDealsApi.fetchDealSummary(deals[i]._id);
        transactions.push(transaction);
      }
      dispatch(setTransactionsItems(transactions));
      dispatch(setTransactionsPagination({
        ...pagination,
        hasNextPage: transactions.length > pagination.pageSize,
      }));
    } finally {
      dispatch(setTransactionsFetching(false));
    }
  }
);

export const fetchLatestTransaction = () => async (dispatch: Dispatch<any>, getState: () => ProposalContextState) => {
  const state = getState();
  const customers = customersSelector(state);
  if (!customers.length) {
    return;
  }
  // eslint-disable-next-line no-underscore-dangle
  const deals = await FinancingDealsApi.fetchDeals(customers.map((customer) => customer._id), 1);
  if (deals.length > 0) {
    // eslint-disable-next-line no-underscore-dangle
    const transaction = await FinancingDealsApi.fetchDealSummary(deals[0]._id);
    dispatch(setLatestTransaction(transaction));
  }
};
