import React from 'react';
import HyperlinkText from '../hyperlink-text/HyperlinkText';

interface Props {
  error: string;
  children?: React.ReactNode,
}

export const ErrorMessageText = ({ error, children }: Props) => (
  <div>
    <HyperlinkText>{error}</HyperlinkText>
    {children}
  </div>
);

export default ErrorMessageText;
