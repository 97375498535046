import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import {
  damanConsultationCategory,
  damanCountryCode, DamanPayerType, damanPayerType, DamanServiceCategory,
  damanServiceCategory, damanUltrasoundCategory,
  DamanUserType,
  damanUserType,
} from './types';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';

const initialValue = {
  patientIdType: DamanUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
  countryCode: '+971',
  payerType: DamanPayerType.Enhanced,
};

export class DamanRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    const config: PortalConfig = {
      patientIdType: damanUserType,
      patientId: undefined,
      clinicianLicense: true,
      serviceCategory: undefined,
      consultationCategory: undefined,
      ultrasoundCategory: undefined,
      countryCode: undefined,
      mobileNumber: true,
      emiratesId: true,
      payerType: damanPayerType,
    };

    const { patientIdType, serviceCategory } = values;
    if (patientIdType && patientIdType !== DamanUserType.EmiratesId) {
      config.emiratesId = false;
      config.patientId = calcPatientId(patientIdType, damanUserType);
    }
    config.countryCode = damanCountryCode;
    config.serviceCategory = damanServiceCategory;
    if (serviceCategory === DamanServiceCategory.NewConsultation) {
      config.consultationCategory = damanConsultationCategory;
    } else if (serviceCategory === DamanServiceCategory.Ultrasound) {
      config.ultrasoundCategory = damanUltrasoundCategory;
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== DamanUserType.EmiratesId
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === DamanUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
      clinicianLicense: Yup.string().required('Licensee is required'),
      countryCode: Yup.string().required('Country code is required'),
      mobileNumber: Yup.string().required('Mobile number is required'),
      serviceCategory: Yup.string().required('Mobile number is required'),
      consultationCategory: Yup.string().when(['serviceCategory'], (serviceCategory: any) => (
        serviceCategory === DamanServiceCategory.NewConsultation
          ? Yup.string().required('Category is required')
          : Yup.string()
      )),
      ultrasoundCategory: Yup.string().when(['serviceCategory'], (serviceCategory: any) => (
        serviceCategory === DamanServiceCategory.Ultrasound
          ? Yup.string().required('Category is required')
          : Yup.string()
      )),
      payerType: Yup.string().required('Payer is required'),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return DamanUserType.EmiratesId;
  }
}

export default DamanRequest;
