import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'custom-test-antd';

import { useAuthState } from 'components/authentication/context/authContext';
import { userProfileSelector } from 'components/authentication/context/authSelectors';
import { rbacManager } from 'services/rbac-manager/RbacManager';
import { RbacPermissionKeys } from 'constants/rbac';
import { privateRoutes } from '../application/routes/routesConstants';
import { EligibilityListProvider } from './eligibility-list/context/eligibilityListContext';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';

import './Eligibility.pcss';

enum EligibilityMenuKey {
  Eligibility = 'eligibility',
  EligibilityRequest = 'eligibilityRequest',
  BulkEligibilityRequest = 'bulkEligibilityRequest',
}

const MenuRoutes: { [key: string]: string } = {
  [EligibilityMenuKey.Eligibility]: privateRoutes.eligibilityList,
  [EligibilityMenuKey.EligibilityRequest]: privateRoutes.eligibilityRequest,
  [EligibilityMenuKey.BulkEligibilityRequest]: privateRoutes.eligibilityBulkRequest,
};

const Header = createPortal(PrivateLayout.titlePortalId);

export const Eligibility: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAuthState();
  const userProfile = userProfileSelector(authState);
  const menuItems = useMemo(() => {
    if (!userProfile) {
      return [];
    }

    const actor = { id: userProfile.id, roleId: userProfile.rbac_role_id };
    const result = [];
    if (rbacManager.checkPermission(actor, RbacPermissionKeys.ListEligibilityCheck)) {
      result.push({ label: 'Overview', key: EligibilityMenuKey.Eligibility });
    }
    if (rbacManager.checkPermission(actor, RbacPermissionKeys.CreateEligibilityCheck)) {
      result.push({ label: 'New Eligibility', key: EligibilityMenuKey.EligibilityRequest });
      result.push({ label: 'Bulk Eligibility', key: EligibilityMenuKey.BulkEligibilityRequest });
    }

    return result;
  }, [userProfile]);

  let selectedKey: string = EligibilityMenuKey.Eligibility;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header>Eligibility</Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="Eligibility-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <EligibilityListProvider
            initState={{ filter: undefined }}
          >
            <Outlet />
          </EligibilityListProvider>
        )}
      />
    </>
  );
};

export default Eligibility;
