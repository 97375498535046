import React from 'react';

import { Outlet } from 'react-router-dom';
import { isMobile } from '../../../utils/mobileDetector';

import './UserOnboardingLayout.pcss';

export const UserOnboardingLayout: React.FC = () => (
  <div className={`UserOnboardingLayout ${isMobile() ? 'mobile' : ''}`}>
    <Outlet />
  </div>
);

export default UserOnboardingLayout;
