import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notification } from 'custom-test-antd';

import ConfirmEmailSent from './ConfirmEmailSent';
import { useAuthDispatch } from '../context/authContext';
import { checkRegistrationIntention, resendRegistrationIntention } from '../context/authThunks';
import { IntentionCheck, IntentionStatus } from '../../../api/authentication/authenticationTypes';
import { publicRoutes } from '../../application/routes/routesConstants';

interface LocationState {
  email?: string;
  intentionId?: string;
}

export const ConfirmEmailSentContainer: React.FC = () => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: LocationState };
  const [emailResending, setEmailResending] = useState(false);
  const { intentionId } = state;
  return (
    <ConfirmEmailSent
      email={state?.email}
      onCheckIntention={useCallback(async () => {
        if (!intentionId) {
          return;
        }
        const result = (await dispatch(checkRegistrationIntention(intentionId))) as unknown as IntentionCheck;
        switch (result.status) {
          case IntentionStatus.Failed:
          case IntentionStatus.Completed: {
            navigate(publicRoutes.registrationCompleted, { state: { ...result } });
            break;
          }
          default:
            break;
        }
      }, [dispatch, navigate, intentionId])}
      emailResending={emailResending}
      onEmailResend={useCallback(async () => {
        if (!intentionId) {
          return;
        }
        setEmailResending(true);
        try {
          const result = await dispatch(resendRegistrationIntention(intentionId));
          if ((result as any).status === 'successful') {
            notification.success({ message: 'Success', description: 'Email successfully resent' });
          } else {
            notification.error({ message: 'Error', description: 'An error occurred while sending the email' });
          }
        } finally {
          setEmailResending(false);
        }
      }, [dispatch, setEmailResending, intentionId])}
    />
  );
};

export default ConfirmEmailSentContainer;
