// https://github.com/antoniopresto/antd-mask-input

import React, { useMemo } from 'react';
import { Input, InputProps, InputRef } from 'custom-test-antd';
import IMask from 'imask';

export type UnionToIntersection<T> = (
  T extends any ? (x: T) => any : never
  ) extends (x: infer R) => any
  ? {
    [K in keyof R]: R[K];
  }
  : never;

type OnChangeParam = Parameters<Exclude<InputProps['onChange'], undefined>>[0];

interface OnChangeEvent extends OnChangeParam {
  maskedValue: string;
  unmaskedValue: string;
}

interface IMaskOptionsBase
  extends UnionToIntersection<IMask.AnyMaskedOptions> {
}

export type InputMaskOptions = {
  [K in keyof IMaskOptionsBase]?: IMaskOptionsBase[K];
};

type MaskFieldType = string | RegExp | Function | Date | InputMaskOptions;

interface IMaskOptions extends Omit<InputMaskOptions, 'mask'> {
  mask: MaskFieldType;
}

interface MaskOptionsList extends Array<IMaskOptions> {
}

export type MaskType = MaskFieldType | MaskOptionsList;

export interface MaskedInputProps
  extends Omit<InputProps, 'onChange' | 'value' | 'defaultValue'> {
  mask: MaskType;
  definitions?: InputMaskOptions['definitions'];
  value?: string;
  defaultValue?: string;
  maskOptions?: InputMaskOptions;
  onChange?: (event: OnChangeEvent) => any;
}

function keyPressPropName() {
  if (typeof navigator !== 'undefined') {
    return navigator.userAgent.match(/Android/i)
      ? 'onBeforeInput'
      : 'onKeyPress';
  }
  return 'onKeyPress';
}

const KEY_PRESS_EVENT = keyPressPropName();

export { IMask };

export const MaskedInput = React.forwardRef<InputRef, MaskedInputProps>(
  (props: MaskedInputProps, antdRef) => {
    const {
      mask,
      maskOptions: _maskOptions,
      value: _value,
      defaultValue,
      definitions,
      ...antdProps
    } = props;

    const innerRef = React.useRef<HTMLInputElement | null>(null);

    const maskOptions = useMemo(() => ({
      mask,
      definitions: {
        0: /[0-9]/,
        ..._maskOptions?.definitions,
        ...definitions,
      },
      lazy: false, // make placeholder always visible
      ..._maskOptions,
    } as IMaskOptions), [mask]);

    const placeholder = useMemo(() => (
      IMask.createPipe({ ...maskOptions, lazy: false } as any)('')
    ), [maskOptions]);

    const imask = React.useRef<IMask.InputMask<any> | null>(null);

    const propValue = (typeof _value === 'string' ? _value : defaultValue) || '';

    const lastValue = React.useRef(propValue);

    const [value, setValue] = React.useState(propValue);

    // eslint-disable-next-line no-underscore-dangle
    const _onEvent = React.useCallback((ev: any, execOnChangeCallback = false) => {
      const masked = imask.current;
      if (!masked) return;

      if (ev.target) {
        if (ev.target.value !== masked.value) {
          masked.value = ev.target.value;
          // eslint-disable-next-line no-param-reassign
          ev.target.value = masked.value;
          lastValue.current = masked.value;
        }
      }

      Object.assign(ev, {
        maskedValue: masked.value,
        unmaskedValue: masked.unmaskedValue,
      });

      masked.updateValue();
      setValue(lastValue.current);

      if (execOnChangeCallback) {
        props.onChange?.(ev);
      }
    }, []);

    // eslint-disable-next-line no-underscore-dangle
    const _onAccept = React.useCallback((ev: any) => {
      if (!ev?.target) return;

      const input = innerRef.current;
      const masked = imask.current;
      if (!input || !masked) return;

      // eslint-disable-next-line no-param-reassign
      ev.target.value = masked.value;
      input.value = masked.value;
      lastValue.current = masked.value;

      _onEvent(ev, true);
    }, []);

    function updateMaskRef() {
      const input = innerRef.current;

      if (imask.current) {
        imask.current.updateOptions(maskOptions as any);
      }

      if (!imask.current && input) {
        imask.current = IMask<any>(input, maskOptions);
        imask.current.on('accept', _onAccept);
      }

      if (imask.current && imask.current.value !== lastValue.current) {
        imask.current.value = lastValue.current;
        imask.current.alignCursor();
      }
    }

    function updateValue(updValue: string) {
      lastValue.current = updValue;
      const input = innerRef.current;
      const masked = imask.current;
      if (!(input && masked)) return;
      masked.value = updValue;
      // updating value with the masked
      //   version (imask.value has a setter that triggers masking)
      input.value = masked.value;
      lastValue.current = masked.value;
      setValue(lastValue.current);
    }

    React.useEffect(() => {
      updateMaskRef();

      return () => {
        imask.current?.destroy();
        imask.current = null;
      };
    }, [mask]);

    React.useEffect(() => {
      updateValue(propValue);
    }, [propValue]);

    const eventHandlers = React.useMemo(() => ({
      onBlur(ev: any) {
        _onEvent(ev);
        // eslint-disable-next-line react/prop-types
        props.onBlur?.(ev);
      },

      onPaste(ev: React.ClipboardEvent<HTMLInputElement>) {
        lastValue.current = ev.clipboardData?.getData('text');

        if (ev.target) {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          ev.target.value = lastValue.current;
        }

        _onEvent(ev, true);
        // eslint-disable-next-line react/prop-types
        props.onPaste?.(ev);
      },

      onFocus(ev: any) {
        _onEvent(ev);
        // eslint-disable-next-line react/prop-types
        props.onFocus?.(ev);
      },

      [KEY_PRESS_EVENT]: (ev: any) => {
        _onEvent(ev, true);
        props[KEY_PRESS_EVENT]?.(ev);
      },
    }), []);

    return (
      <Input
        placeholder={placeholder}
        {...antdProps}
        {...eventHandlers}
        onChange={(ev) => _onEvent(ev, true)}
        value={value}
        ref={function handleInputMask(ref) {
          if (antdRef) {
            if (typeof antdRef === 'function') {
              antdRef(ref);
            } else {
              // eslint-disable-next-line no-param-reassign
              antdRef.current = ref;
            }
          }

          if (ref?.input) {
            innerRef.current = ref.input;
            if (!imask.current) {
              updateMaskRef();
            }
          }
        }}
      />
    );
  },
);

export default MaskedInput;
