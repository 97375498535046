import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';

const initialValue = {
  emiratesId: '784-____-_______-_',
};
export class EclaimRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(): PortalConfig {
    return {
      patientName: true,
      emiratesId: true,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      emiratesId: Yup.string().emiratesId().required('Field is required'),
      patientName: Yup.string(),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return undefined;
  }
}

export default EclaimRequest;
