import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum AxaUserType {
  EmiratesId = '1',
  DhaId = '2',
  PolicyNoAndMembershipCode = '3',
}

export const axaUserType: DropdownOptionString[] = [
  {
    value: AxaUserType.EmiratesId,
    label: 'Emirates ID',
  },
  {
    value: AxaUserType.DhaId,
    label: 'DHA ID',
  },
  {
    value: AxaUserType.PolicyNoAndMembershipCode,
    label: 'Policy No/Membership code',
  },
];
