import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'custom-test-antd';
import { privateRoutes } from '../application/routes/routesConstants';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';

import './PractitionerLicenses.pcss';

enum PractitionerLicensesMenuKey {
  List = 'list',
  NewLicense = 'newLicense',
  BulkUpload = 'bulkUpload',
}

const MenuRoutes: { [key: string]: string } = {
  [PractitionerLicensesMenuKey.BulkUpload]: privateRoutes.practitionerLicensesBulkUpload,
  [PractitionerLicensesMenuKey.NewLicense]: privateRoutes.practitionerLicensesNew,
  [PractitionerLicensesMenuKey.List]: privateRoutes.practitionerLicensesList,
};

const Header = createPortal(PrivateLayout.titlePortalId);

export const PractitionerLicenses: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems = useMemo(() => (
    [
      { label: 'Overview', key: PractitionerLicensesMenuKey.List },
      { label: 'New License', key: PractitionerLicensesMenuKey.NewLicense },
      { label: 'Bulk Licenses', key: PractitionerLicensesMenuKey.BulkUpload },
    ]
  ), []);

  let selectedKey: string = PractitionerLicensesMenuKey.List;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header>Practitioner Licenses</Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="Eligibility-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <Outlet />
        )}
      />
    </>
  );
};

export default PractitionerLicenses;
