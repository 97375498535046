import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum MetLifeUserType {
  EmiratesId = '1',
  InsuranceCard = '2',
}

export const metLifeUserType: DropdownOptionString[] = [
  {
    value: MetLifeUserType.EmiratesId,
    label: 'Emirates ID',
  },
  {
    value: MetLifeUserType.InsuranceCard,
    label: 'Insurance Card',
  },
];
