import React, { useState, useCallback } from 'react';
import { PopupModal } from 'react-calendly';
import { Button, ButtonProps } from 'custom-test-antd';

interface Props extends Omit<ButtonProps, 'onClick'> {
  url?: string;
}

export const CalendyButton = ({ url, ...other }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  if (!url || url === '') {
    return null;
  }

  return (
    <>
      <Button
        {...other}
        onClick={openModal}
      />
      <PopupModal
        open={isOpen}
        url={url}
        onModalClose={closeModal}
        rootElement={document.getElementById('root') as HTMLElement}
      />
    </>
  );
};

export default CalendyButton;
