import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, notification } from 'custom-test-antd';
import { FormikHelpers } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { submitValidate } from 'utils/form-helpers/antd-formik';

import CredentialsNew from './CredentialsNew';
import { BranchApi, CredentialsApi, ApiError } from '../../../api';
import { privateRoutes } from '../../application/routes/routesConstants';
import { BranchData } from '../../../api/branch/branchTypes';
import getValidationSchema from '../validation-schemas';
import { OTP_REQUIRED_ERROR } from '../constants/credentials';
import showOtpModal, { ModalInstance } from '../otp-form/OtpFormModal';

const confirmRecreateCredentials = () => (
  new Promise((resolve) => {
    Modal.confirm({
      title: 'Portal already in use',
      icon: <QuestionCircleOutlined />,
      content: 'Do you want to override credentials?',
      okText: 'Yes',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  })
);

export const CredentialsNewContainer = () => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<BranchData[]>([]);
  const otpRequired = useRef(false);
  const otpRequestId = useRef('');
  const otpModal = useRef<ModalInstance | undefined>(undefined);

  const onCredentialsSubmit = useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (!submitValidate(values, getValidationSchema(otpRequired.current), formikHelpers)) {
      return false;
    }
    let success = false;
    let errorMessage;
    try {
      const otp = values.otp ? { otp: values.otp, requestId: otpRequestId.current } : undefined;
      await CredentialsApi.createCredentials(values, otp);
      success = true;
    } catch (error: any) {
      errorMessage = error.toString();
      if (error instanceof ApiError && error.response?.status === 400) {
        const errorObject = await error.response.json();
        errorMessage = errorObject?.message;

        if (errorMessage === OTP_REQUIRED_ERROR) {
          otpRequestId.current = errorObject.requestId;

          if (!otpRequired.current) {
            otpRequired.current = true;
            otpModal.current = showOtpModal({
              onSubmit: async (otpValues: any, modalFormikHelpers: FormikHelpers<any>) => {
                const submitted = await onCredentialsSubmit({ ...values, ...otpValues }, modalFormikHelpers);
                if (submitted) {
                  otpModal.current?.destroy();
                }
              },
              onClose: () => {
                otpRequired.current = false;
              },
            });
          }

          return false;
        }
      }
      if (error instanceof ApiError && error.response?.status === 409) {
        const confirmation = await confirmRecreateCredentials();
        if (confirmation) {
          try {
            await CredentialsApi.createForceCredentials(values);
            success = true;
          } catch (recreateError: any) {
            errorMessage = recreateError.toString();
            if (recreateError instanceof ApiError && recreateError.response?.status === 400) {
              const errorObject = await recreateError.response.json();
              errorMessage = errorObject?.message;
            }
          }
        } else {
          return false;
        }
      }
    }
    if (success) {
      notification.success({
        message: 'Success',
        description: 'Credentials added successfully',
      });
      navigate(privateRoutes.credentialList);
      return true;
    }
    notification.error({
      message: 'Error',
      description: errorMessage,
    });
    return false;
  }, [navigate, otpRequired]);

  return (
    <CredentialsNew
      branches={branches}
      onFetchBranches={useCallback(async () => {
        const fetchedBranches = await BranchApi.getUserBranches();
        setBranches(fetchedBranches);
      }, [])}
      onSubmit={onCredentialsSubmit}
    />
  );
};

export default CredentialsNewContainer;
