import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'custom-test-antd';

import './ProposalDetailTable.pcss';
import { InsuranceRow } from '../../insurance-row';
import { formatClaims, formatDiscount, formatPrice } from '../../utills/numberFormats';

const tableColumns: ColumnsType<any> = [
  {
    title: 'INSURANCE',
    dataIndex: 'portal',
    key: 'portal',
    render: (portal) => <InsuranceRow insurer={portal} />,
  },
  {
    title: 'CLAIMS',
    dataIndex: 'claims',
    key: 'claims',
    align: 'right',
    className: 'ProposalDetailTable-claimsColumn',
    render: (claims) => formatClaims(claims),
  },
  {
    title: 'NET AMOUNT',
    dataIndex: 'netAmount',
    key: 'netAmount',
    align: 'right',
    className: 'ProposalDetailTable-netAmountColumn',
    render: (netAmount) => formatPrice(netAmount),
  },
  {
    title: 'DISCOUNT',
    dataIndex: 'discount',
    key: 'discount',
    align: 'right',
    className: 'ProposalDetailTable-discountColumn',
    render: (discount) => formatDiscount(discount),
  },
  {
    title: 'PURCHASE PRICE',
    dataIndex: 'purchasePrice',
    key: 'purchasePrice',
    align: 'right',
    className: 'ProposalDetailTable-purchasePriceColumn',
    render: (purchasePrice) => formatPrice(purchasePrice),
  },
];

interface Props {
  dataSource: any,
  showSummaryTableRows: boolean,
  totalClaims: number,
  totalNetAmount: number,
  totalDiscount: number,
  totalPurchasePrice: number,
  className?: string,
}

export const ProposalDetailTable = ({
  dataSource,
  showSummaryTableRows,
  totalClaims,
  totalNetAmount,
  totalDiscount,
  totalPurchasePrice,
  className,
}: Props) => (
  <Table
    rowKey="portal"
    className={`ProposalDetailTable ${className ?? ''}`}
    columns={tableColumns}
    dataSource={dataSource}
    rightAngleTopCorners
    firstColumnPadding={false}
    whiteHeader
    pagination={false}
    summary={() => {
      if (!showSummaryTableRows) {
        return null;
      }

      return (
        <Table.Summary.Row className="ProposalDetailTable-totalRow">
          <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {formatClaims(totalClaims)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right">
            {formatPrice(totalNetAmount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            {formatDiscount(totalDiscount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align="right">
            {formatPrice(totalPurchasePrice)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    }}
  />
);

export default ProposalDetailTable;
