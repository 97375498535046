import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'custom-test-antd';

import './Credentials.pcss';

import { privateRoutes } from '../application/routes/routesConstants';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import { CredentialsListProvider } from './credentials-list/context/credentialsListContext';
import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';

enum CredentialsMenuKey {
  Credentials = 'credentials',
  NewCredentials = 'newCredentials',
  BulkCredentialsRequest = 'bulkCredentialsRequest',
}

const MenuRoutes: { [key: string]: string } = {
  [CredentialsMenuKey.Credentials]: privateRoutes.credentialList,
  [CredentialsMenuKey.NewCredentials]: privateRoutes.credentialsNew,
  [CredentialsMenuKey.BulkCredentialsRequest]: privateRoutes.credentialsBulk,
};

const menuItems = [
  { label: 'Overview', key: CredentialsMenuKey.Credentials },
  { label: 'New Credentials', key: CredentialsMenuKey.NewCredentials },
  { label: 'Bulk Credentials', key: CredentialsMenuKey.BulkCredentialsRequest },
];

const Header = createPortal(PrivateLayout.titlePortalId);

export const Credentials: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let selectedKey: string = CredentialsMenuKey.Credentials;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header>Portal Credentials</Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="Credentials-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <CredentialsListProvider
            initState={{ filter: undefined }}
          >
            <Outlet />
          </CredentialsListProvider>
        )}
      />
    </>
  );
};

export default Credentials;
