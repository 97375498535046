import { ApiError, commonFetch } from '../apiHelper';
import { BranchData, Branch, BranchApiDto, BranchCredentialsValidationToken } from './branchTypes';

export class BranchApi {
  static getUserBranches = async (): Promise<BranchData[]> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/current/branches`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static checkBranch = async (branch: BranchApiDto): Promise<BranchCredentialsValidationToken> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/check`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify(branch),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static createBranchesFromTokens = async (tokens: string[]): Promise<void> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/upsert/from-tokens`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify({ validationTokens: tokens }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getAllBranch = async (): Promise<Omit<Branch, 'login' | 'password'>[]> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/all`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}
