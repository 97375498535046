import { RbacItemsService, RbacRuleFactory } from '.';

export interface Actor {
  id: number;
  roleId: number;
  organizationId?: number;
}

export class RbacManager {
  constructor(private rbacItemsService: RbacItemsService, private rbacRuleFactory: RbacRuleFactory) {}

  loadCache() {
    return this.rbacItemsService.loadCache();
  }

  checkPermission(actor: Actor, permissionId: number, payload?: any): boolean {
    const { roleId } = actor;
    const { permissions } = this.rbacItemsService.getRole(roleId);
    for (let i = 0; i < permissions.length; i++) {
      const { permission_id, rule } = permissions[i];

      if (permission_id === permissionId) {
        if (!rule) {
          return true;
        }

        return this.rbacRuleFactory.createRule(rule).execute({ actor, target: payload });
      }
    }

    return false;
  }

  getRolePermissions(roleId: number) {
    const role = this.rbacItemsService.getRole(roleId);
    return role;
  }

  getAllRoles() {
    return this.rbacItemsService.getRoles();
  }

  getAllPermissions() {
    return this.rbacItemsService.getPermissions();
  }
}
