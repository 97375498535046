import * as Yup from 'yup';

const generalValidationSchema = Yup.object({
  username: Yup.string().required(),
  portal: Yup.string().required(),
  license: Yup.string(),
  password: Yup.string().required(),
  branchId: Yup.string(),
  otp: Yup.string(),
});

const otpRequiredValidationSchema = Yup.object({
  username: Yup.string().required(),
  portal: Yup.string().required(),
  license: Yup.string(),
  password: Yup.string().required(),
  branchId: Yup.string(),
  otp: Yup.string().required(),
});

const getValidationSchema = (isOtpRequired: boolean) => (isOtpRequired
  ? otpRequiredValidationSchema
  : generalValidationSchema);

export default getValidationSchema;
