import React from 'react';

import './Note.pcss';

import { EligibilityResponse } from '../../../../api/eligibility/eligibilityTypes';

interface Props {
  className?: string;
  note?: EligibilityResponse['copay'];
  maxConsul?: EligibilityResponse['maxConsul'];
  deductable?: EligibilityResponse['deductable'];
  referral?: EligibilityResponse['referral']
  remarks?: EligibilityResponse['remarks']
}

const formatCopay = (note?: EligibilityResponse['copay']) => {
  if (!note) {
    return note;
  }
  if (typeof note === 'string' && note !== '') {
    if (note.includes('label')) {
      try {
        const parsedCopay = JSON.parse(note) as Array<{ label: string; value: string }>;
        return parsedCopay.map((c, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {`${c.label}: ${c.value ?? '-'}`}
            <br />
          </React.Fragment>
        ));
      } catch (e) {
        return note;
      }
    }
    return note;
  }
  if (Array.isArray(note)) {
    return note.map((c, index) => {
      const label = c.label?.slice(-1) === ':' ? c.label : `${c.label}:`;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {`${label} ${c.value ?? '-'}`}
          <br />
        </React.Fragment>
      );
    });
  }
  return note;
};

export const Note: React.FC<Props> = ({
  note,
  maxConsul,
  deductable,
  referral,
  className = '',
  remarks,
}) => (
  <div className={`Note ${className}`}>
    <div className="Note-title">NOTE:</div>
    {formatCopay(note)}
    {
      maxConsul && !Array.isArray(maxConsul) && maxConsul?.value
      && (
        <div className="Note-consultation">
          <div className="Note-title">{`${maxConsul.label?.toUpperCase()}:`}</div>
          {maxConsul?.value}
        </div>
      )
    }
    {
      remarks && remarks !== ''
      && (
        <div className="Note-consultation">
          <div className="Note-title">REMARKS:</div>
          {remarks}
        </div>
      )
    }
    {
      deductable && deductable !== ''
      && (
        <div className="Note-consultation">
          <div className="Note-title">DEDUCTIBLE:</div>
          {deductable}
        </div>
      )
    }
    {
      referral
      && (
        <div className="Note-consultation">
          <div className="Note-title">REFERRAL:</div>
          {formatCopay(referral)}
        </div>
      )
    }
  </div>
);

export default Note;
