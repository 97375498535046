import React, { FC } from 'react';
import CheckCircleFill from 'assets/check-circle-fill.svg';

import './UpgradeFullVersionConfirmation.pcss';

const upgradeFullVersionConfirmation: FC<any> = () => (
  <>
    <img className="UpgradeFullVersionConfirmation-icon" src={CheckCircleFill} alt="confirmed" />
    <div className="UpgradeFullVersionConfirmation-title">Request received</div>
    <div className="UpgradeFullVersionConfirmation-description">
      Thank you for reaching out! Our sales team will get back to you as soon as possible
    </div>
  </>
);

export default React.memo(upgradeFullVersionConfirmation);
