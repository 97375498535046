import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'custom-test-antd';
import { ReactComponent as Letter } from 'assets/letter.svg';

import './ConfirmEmailSent.pcss';

import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthCard } from '../auth-card';

interface Props {
  email?: string;
  emailResending: boolean;
  onCheckIntention: () => void;
  onEmailResend: () => void;
}

export const ConfirmEmailSent: React.FC<Props> = ({ email, onCheckIntention, emailResending, onEmailResend }) => {
  useEffect(() => {
    const id = setInterval(() => {
      onCheckIntention();
    }, 5000);
    return () => clearInterval(id);
  });

  return (
    <AuthCard
      className="ConfirmEmailSent"
      header="Validate Email"
      headerText={(
        <>
          <div>
            Please verify&nbsp;
            <span className="ConfirmEmailSent-email">{email}</span>
            <span>&nbsp;is your email by clicking on the confirmation link to continue</span>
          </div>
          <br />
          <div>
            You will receive an email within 1 minute. Make sure to check your spam folder too
          </div>
        </>
      )}
    >
      <Letter className="ConfirmEmailSent-icon" />
      <div className="ConfirmEmailSent-backSection">
        <div>Can’t find the confirmation email?&nbsp;</div>
      </div>
      <div className="ConfirmEmailSent-actionSection">
        <Button
          type="link"
          loading={emailResending}
          className="ConfirmEmailSent-resend"
          onClick={onEmailResend}
        >
          Resend Email
        </Button>
        <Link to={publicRoutes.signUp} className="ConfirmEmailSent-signIn">Correct Email</Link>
      </div>
    </AuthCard>
  );
};

export default ConfirmEmailSent;
