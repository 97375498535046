import React from 'react';
import { useNavigate } from 'react-router-dom';

import { UserProfile } from 'api/user-profile/userProfileTypes';
import { privateRoutes } from 'components/application/routes/routesConstants';
import { useAuthDispatch, useAuthState } from 'components/authentication/context/authContext';
import { userProfileSelector } from 'components/authentication/context/authSelectors';
import { OrganizationApi } from 'api';
import { fetchUserProfile } from 'components/authentication/context/authThunks';
import CallScheduler from './CallScheduler';

export const CallSchedulerContainer = () => {
  const dispatch = useAuthDispatch();
  const authState = useAuthState();
  const navigate = useNavigate();
  const { name, email } = userProfileSelector(authState) as UserProfile;

  if (!process.env.REACT_APP_ONBOARD_CALENDY_URL || process.env.REACT_APP_ONBOARD_CALENDY_URL === '') {
    return null;
  }

  return (
    <CallScheduler
      name={name}
      email={email}
      calendyUrl={process.env.REACT_APP_ONBOARD_CALENDY_URL as string}
      onSkip={async () => {
        await OrganizationApi.postSkipSalesCall();
        await dispatch(fetchUserProfile);
        navigate(privateRoutes.home);
      }}
    />
  );
};

export default CallSchedulerContainer;
