export enum ProposalState {
  Created = 'created',
  Accepted = 'accepted',
  KYCDocumentUploading = 'kycDocumentUploading',
  KYCDocumentUploaded = 'kycDocumentUploaded',
  EscrowAgreementGenerated = 'escrowAgreementGenerated',
  AuthoriseDocumentsPrepared = 'authoriseDocumentsPrepared',
  AuthoriseDocumentsUploading = 'authoriseDocumentsUploading',
  AuthoriseDocumentsUploaded = 'authoriseDocumentsUploaded',
  WireTransferPrepared = 'wireTransferPrepared',
  Completed = 'completed',
}

export interface Proposal {
  _id: string;
  name: string;
  klaimName: string;
  issueDate: string;
  creator: {
    _id: string;
    name: string;
  };
  facilitySize: number;
  legalFee: number;
  adminFee: number;
  daysValid: number;
  purchaseCooldown: number;
  insurers: Array<{
    name: string,
    claimValue: number;
    discountMin: number;
    discountMax: number;
    _id: string;
  }>;
  memoId: string;
  status: ProposalState;
  expiryDate: string;
}
