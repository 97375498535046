import React from 'react';
import { Spin } from 'custom-test-antd';
import { produce } from 'immer';
import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import EligibilityApi from 'api/eligibility/EligibilityApi';
import {
  MetLifeUserType,
  metLifeUserType,
} from './types';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';
import { setFormConfig } from '../../context/eligibilityRequestReducer';
import { formConfigSelector } from '../../context/eligibilityRequestSelectors';

const initialValue = {
  patientIdType: MetLifeUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
};

export class MetlifeRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    let config: PortalConfig = {
      patientIdType: metLifeUserType,
      portalPatientId: true,
      emiratesId: true,
      policyNumber: false,
      certificateNumber: false,
      dependentCode: undefined,
    };
    const { patientIdType, policyNumber, certificateNumber } = values;
    if (patientIdType === MetLifeUserType.InsuranceCard) {
      const state = this.getState();
      const currentConfig = formConfigSelector(state);
      config.portalPatientId = false;
      config.emiratesId = false;
      config.policyNumber = true;
      config.certificateNumber = true;
      config.dependentCode = {
        ...(currentConfig?.dependentCode ?? {}),
        onFocus: async () => {
          if (
            policyNumber === currentConfig?.dependentCode?.['data-policy']
            && certificateNumber === currentConfig?.dependentCode?.['data-certificate']
          ) {
            return;
          }
          config = produce(config, (draft) => {
            if (draft.dependentCode) {
              // eslint-disable-next-line no-param-reassign
              draft.dependentCode.loading = true;
              // eslint-disable-next-line no-param-reassign
              draft.dependentCode.dropdownRender = (menu) => (
                <Spin>{menu}</Spin>
              );
              // eslint-disable-next-line no-param-reassign
              (draft.dependentCode)['data-policy'] = policyNumber;
              // eslint-disable-next-line no-param-reassign
              (draft.dependentCode)['data-certificate'] = certificateNumber;
            }
          });
          this.dispatch(setFormConfig(config));
          try {
            const codes = await EligibilityApi.getMetlifeDependentCodes(
              values.branchId,
              policyNumber,
              certificateNumber,
            );
            config = produce(config, (draft) => {
              if (draft.dependentCode) {
                // eslint-disable-next-line no-param-reassign
                draft.dependentCode.options = codes;
              }
            });
            this.dispatch(setFormConfig(config));
          } finally {
            config = produce(config, (draft) => {
              if (draft.dependentCode) {
                // eslint-disable-next-line no-param-reassign
                draft.dependentCode.loading = false;
                // eslint-disable-next-line no-param-reassign
                draft.dependentCode.dropdownRender = undefined;
              }
            });
            this.dispatch(setFormConfig(config));
          }
        },
      };
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientIdType: Yup.string().required('Patient id type is required'),
      portalPatientId: Yup.string().when(['patientIdType'], (patientIdType, schema) => (
        (
          patientIdType === MetLifeUserType.EmiratesId
        )
          ? schema.required('Field is required')
          : schema
      )),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType, schema) => (
        (
          patientIdType === MetLifeUserType.EmiratesId
        )
          ? schema.emiratesId().required('Field is required')
          : schema
      )),
      policyNumber: Yup.string().when(['patientIdType'], (patientIdType, schema) => (
        (
          patientIdType === MetLifeUserType.InsuranceCard
        )
          ? schema.required('Field is required')
          : schema
      )),
      certificateNumber: Yup.string().when(['patientIdType'], (patientIdType, schema) => (
        (
          patientIdType === MetLifeUserType.InsuranceCard
        )
          ? schema.required('Field is required')
          : schema
      )),
      dependentCode: Yup.string().when(['patientIdType'], (patientIdType, schema) => (
        (
          patientIdType === MetLifeUserType.InsuranceCard
        )
          ? schema.required('Field is required')
          : schema
      )),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return MetLifeUserType.EmiratesId;
  }
}

export default MetlifeRequest;
