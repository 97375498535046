import React from 'react';
import { Spin, Button, Form, Card, Divider, Space } from 'custom-test-antd';
import type { FormikProps } from 'formik/dist/types';
import { v4 as uuid } from 'uuid';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  InputWrapper,
  SelectWrapper,
  MaskedInputWrapper,
  AutoCompleteWrapper,
  SwitchWrapper,
} from 'utils/form-helpers/antd-formik';
import Document from 'assets/document.svg';
import Patient from 'assets/patient.svg';

import './EligibilityRequest.pcss';

import {
  DropdownOptionString,
  InsurancePortalLabel,
  InsurancePortal,
} from 'constants/dropdownOptions';
import { BranchData } from '../../../api/branch/branchTypes';
import { FormConfig } from './portal-request/portalRequest';
import { PractitionerLicense } from '../../../api/practitioner-license/practitionerLicenseTypes';
import { ReadCardData, Initialize, EidServiceStatus } from '../../../services/EidToolkit';

export const insurancePortals: DropdownOptionString[] = [
  { label: InsurancePortalLabel[InsurancePortal.Axa], value: InsurancePortal.Axa },
  { label: InsurancePortalLabel[InsurancePortal.Daman], value: InsurancePortal.Daman },
  { label: InsurancePortalLabel[InsurancePortal.Nas], value: InsurancePortal.Nas },
  { label: InsurancePortalLabel[InsurancePortal.Neuron], value: InsurancePortal.Neuron },
  { label: InsurancePortalLabel[InsurancePortal.Nextcare], value: InsurancePortal.Nextcare },
  { label: InsurancePortalLabel[InsurancePortal.Almadallah], value: InsurancePortal.Almadallah },
  { label: InsurancePortalLabel[InsurancePortal.Oman], value: InsurancePortal.Oman },
  { label: InsurancePortalLabel[InsurancePortal.Mednet], value: InsurancePortal.Mednet },
  { label: InsurancePortalLabel[InsurancePortal.Adnic], value: InsurancePortal.Adnic },
  { label: InsurancePortalLabel[InsurancePortal.Metlife], value: InsurancePortal.Metlife },
];

export const eclaimInsurancePortals: DropdownOptionString[] = [
  { label: InsurancePortalLabel[InsurancePortal.Abnic], value: InsurancePortal.Abnic },
  { label: InsurancePortalLabel[InsurancePortal.Inayah], value: InsurancePortal.Inayah },
  { label: InsurancePortalLabel[InsurancePortal.MSH], value: InsurancePortal.MSH },
  { label: InsurancePortalLabel[InsurancePortal.NGI], value: InsurancePortal.NGI },
];

interface Props {
  branches: BranchData[];
  onFetchBranches: () => void;
  licenses: PractitionerLicense[];
  onFetchLicenses: () => void;
  formikProps: FormikProps<any>;
  selectBranchDisabled?: boolean;
  selectPortalDisabled?: boolean;
  submitBtnLabel?: string;
  eidDisabled?: boolean;
  portalSelectSingle?: boolean;
  config?: FormConfig;
  isInitialValueLoading?: boolean;
}

interface EIDServiceProps {
  eidServiceLoading: boolean;
  isEidServiceInitialized: boolean;
  readingCardData: boolean;
}

class EligibilityRequest extends React.PureComponent<Props, EIDServiceProps> {
  emirateIdKey: string;

  constructor(props: Props) {
    super(props);
    this.emirateIdKey = uuid();
    this.onPortalBranchChange = this.onPortalBranchChange.bind(this);
    this.state = {
      eidServiceLoading: false,
      isEidServiceInitialized: false,
      readingCardData: false,
    };
  }

  componentDidMount(): void {
    const { onFetchBranches, onFetchLicenses } = this.props;
    onFetchBranches();
    onFetchLicenses();
    this.checkEIDServiceStatus();
  }

  onPortalBranchChange() {
    // mask field get value from state and didn't change it on reset the value
    this.emirateIdKey = uuid();
  }

  readCardData(setFieldValue: any): void {
    this.setState({ readingCardData: true });
    ReadCardData((value?: string) => {
      if (value) {
        setFieldValue('emiratesId', value);
      }
      this.setState({ readingCardData: false });
    });
  }

  initializeEIDService(): void {
    Initialize((serviceLoading: boolean, isInitialized: boolean) => {
      this.setState({ eidServiceLoading: serviceLoading, isEidServiceInitialized: isInitialized });
    });
  }

  checkEIDServiceStatus(): void {
    EidServiceStatus((serviceLoading: boolean, isInitialized: boolean) => {
      this.setState({ eidServiceLoading: serviceLoading, isEidServiceInitialized: isInitialized });
    });
  }

  render() {
    const {
      branches,
      licenses,
      formikProps,
      selectBranchDisabled,
      selectPortalDisabled,
      submitBtnLabel,
      eidDisabled,
      portalSelectSingle,
      config,
      isInitialValueLoading,
    } = this.props;
    const {
      handleSubmit,
      values,
      isSubmitting,
      setFieldValue,
      isValid,
    } = formikProps;

    const { eidServiceLoading, isEidServiceInitialized, readingCardData } = this.state;
    return (
      <Spin spinning={isSubmitting || isInitialValueLoading}>
        <Form
          className="EligibilityRequest-form"
          layout="vertical"
        >
          <Card
            title={(
              <div className="EligibilityRequest-cardTitle">
                <img className="EligibilityRequest-documentIcon" src={Document} alt="valid" />
                Insurance Information
              </div>
            )}
          >
            <SelectWrapper
              disabled={selectBranchDisabled}
              size="large"
              gray
              antFieldProps={{ label: 'Branch' }}
              name="branchId"
              placeholder="Select branch"
              dropdownMatchSelectWidth={false}
              onChange={this.onPortalBranchChange}
              allowClear
            >
              {
                branches.map((branch) => (
                  <SelectWrapper.Option
                    key={branch.identifier}
                    value={branch.identifier}
                  >
                    {`[${branch.identifier}] ${branch.name}`}
                  </SelectWrapper.Option>
                ))
              }
            </SelectWrapper>
            <SelectWrapper
              disabled={selectPortalDisabled}
              size="large"
              gray
              antFieldProps={{ label: 'Insurance' }}
              name="portal"
              mode={portalSelectSingle ? undefined : 'multiple'}
              placeholder="Select insurance"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider className="EligibilityRequest-portalFooterDivider" />
                  {
                    !portalSelectSingle && (
                      <Space className="EligibilityRequest-portalFooterSpace">
                        <Button
                          type="text"
                          icon={<CheckCircleOutlined />}
                          onClick={async () => {
                            const allPortals = insurancePortals.map((portal) => portal.value);
                            const eclaimPortals = eclaimInsurancePortals.map((portal) => portal.value);
                            this.onPortalBranchChange();
                            setFieldValue('portal', allPortals.concat(eclaimPortals), true);
                          }}
                        >
                          Select all
                        </Button>
                        <Button
                          type="text"
                          icon={<CloseCircleOutlined />}
                          onClick={() => {
                            this.onPortalBranchChange();
                            setFieldValue('portal', [], true);
                          }}
                        >
                          Clear all
                        </Button>
                      </Space>
                    )
                  }
                </>
              )}
              onChange={this.onPortalBranchChange}
            >
              <SelectWrapper.OptGroup label="Common">
                {
                  insurancePortals.map((portal) => (
                    <SelectWrapper.Option
                      key={portal.value}
                      value={portal.value}
                    >
                      {portal.label}
                    </SelectWrapper.Option>
                  ))
                }
              </SelectWrapper.OptGroup>
              <SelectWrapper.OptGroup label="Eclaim">
                {
                  eclaimInsurancePortals.map((portal) => (
                    <SelectWrapper.Option
                      key={portal.value}
                      value={portal.value}
                    >
                      {portal.label}
                    </SelectWrapper.Option>
                  ))
                }
              </SelectWrapper.OptGroup>
            </SelectWrapper>
          </Card>
          {
            config
            && (
              <Card
                title={(
                  <div className="EligibilityRequest-cardTitle">
                    <img className="EligibilityRequest-patientIcon" src={Patient} alt="valid" />
                    Patient Information
                  </div>
                )}
              >
                <div className="EligibilityRequest-detailedForm">
                  {config.patientIdType && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Patient ID Type' }}
                      name="patientIdType"
                      placeholder="Select patient ID type"
                      value={values?.patientIdType}
                    >
                      {config.patientIdType.map((type) => (
                        <SelectWrapper.Option key={type.value} value={type.value}>{type.label}</SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.patientId && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: config.patientId.label }}
                      name="patientId"
                      placeholder={config.patientId.placeholder}
                    />
                  )}
                  {
                    config.emiratesId && (
                      <MaskedInputWrapper
                        key={this.emirateIdKey}
                        mask="000-0000-0000000-0"
                        size="large"
                        gray
                        name="emiratesId"
                        antFieldProps={{ label: 'Emirates ID' }}
                      />
                    )
                  }
                  {config.portalPatientId && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Patient ID' }}
                      name="portalPatientId"
                      placeholder="Enter Patient ID"
                    />
                  )}
                  {config.policyNo && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Policy No' }}
                      name="policyNo"
                      placeholder="Policy No"
                    />
                  )}
                  {config.membershipCode && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Membership Code' }}
                      name="membershipCode"
                      placeholder="Membership Code"
                    />
                  )}
                  {config.fetchAuthorizationId && (
                    <SwitchWrapper
                      antFieldProps={{ label: 'Authorization Id' }}
                      name="fetchAuthorizationId"
                    />
                  )}
                  {config.countryCode && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Country' }}
                      name="countryCode"
                      placeholder="Select country"
                      optionFilterProp="label"
                      showSearch
                    >
                      {config.countryCode.map((code) => (
                        <SelectWrapper.Option
                          key={`${code.value} ${code.label}`}
                          value={code.value}
                          label={`${code.value} ${code.label}`}
                        >
                          {code.label}
                        </SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.mobileNumber && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Mobile Number' }}
                      name="mobileNumber"
                      placeholder="Enter mobile number"
                    />
                  )}
                  {config.patientName && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Patient Name' }}
                      name="patientName"
                      placeholder="John Doe"
                    />
                  )}
                  {
                    config.treatmentBases
                    && config.treatmentBases.map((treatmentBasis) => (
                      <SelectWrapper
                        key={treatmentBasis.portal}
                        size="large"
                        gray
                        antFieldProps={{ label: treatmentBasis.label }}
                        name={`treatmentBasis-${treatmentBasis.portal}`}
                        placeholder="Select treatment type"
                      >
                        {treatmentBasis.options.map((option) => (
                          <SelectWrapper.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </SelectWrapper.Option>
                        ))}
                      </SelectWrapper>
                    ))
                  }
                  {config.clinicianLicense && (
                    <AutoCompleteWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Clinician License' }}
                      name="clinicianLicense"
                      placeholder="DHA-XXXX"
                      filterOption={(inputValue, option) => {
                        if (!option) {
                          return false;
                        }
                        return option?.label?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                      }}
                      options={licenses.map(({ license, name }, index) => ({
                        key: index,
                        value: license,
                        label: `${license} - ${name}`,
                      }))}
                      value={values?.clinicianLicense}
                    />
                  )}
                  {config.payerType && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Payer' }}
                      name="payerType"
                      placeholder="Select payer"
                    >
                      {config.payerType.map((type) => (
                        <SelectWrapper.Option
                          key={type.value}
                          value={type.value}
                        >
                          {type.label}
                        </SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.serviceCategory && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Service Category' }}
                      name="serviceCategory"
                      placeholder="Select service type"
                    >
                      {config.serviceCategory.map((category) => (
                        <SelectWrapper.Option
                          key={category.value}
                          value={category.value}
                        >
                          {category.label}
                        </SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.consultationCategory && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Consultation Category' }}
                      name="consultationCategory"
                      placeholder="Select consultation type"
                    >
                      {config.consultationCategory.map((category) => (
                        <SelectWrapper.Option
                          key={category.value}
                          value={category.value}
                        >
                          {category.label}
                        </SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.ultrasoundCategory && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Ultrasound Category' }}
                      name="ultrasoundCategory"
                      placeholder="Select ultrasound category"
                    >
                      {config.ultrasoundCategory.map((category) => (
                        <SelectWrapper.Option
                          key={category.value}
                          value={category.value}
                        >
                          {category.label}
                        </SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.visitCategory && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Visit Category' }}
                      name="visitCategory"
                      placeholder="Select visit category"
                    >
                      {config.visitCategory.map((category) => (
                        <SelectWrapper.Option
                          key={category.value}
                          value={category.value}
                        >
                          {category.label}
                        </SelectWrapper.Option>
                      ))}
                    </SelectWrapper>
                  )}
                  {config.policyNumber && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Policy Number' }}
                      name="policyNumber"
                      placeholder="0000000000"
                    />
                  )}
                  {config.certificateNumber && (
                    <InputWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Certificate Number' }}
                      name="certificateNumber"
                      placeholder="0000000000"
                    />
                  )}
                  {config.dependentCode && (
                    <SelectWrapper
                      size="large"
                      gray
                      antFieldProps={{ label: 'Dependent Code' }}
                      name="dependentCode"
                      placeholder="Select code"
                      {...config.dependentCode}
                    />
                  )}
                </div>
                <div className="EligibilityRequest-buttonsSection">
                  <Space>
                    {!isEidServiceInitialized && !eidDisabled
                      && (
                        <Button
                          onClick={() => this.initializeEIDService()}
                          type="primary"
                          size="large"
                          loading={eidServiceLoading}
                        >
                          Init Reader
                        </Button>
                      )}
                    {isEidServiceInitialized && !eidDisabled
                      && (
                        <Button
                          onClick={() => this.readCardData(setFieldValue)}
                          loading={readingCardData}
                          type="primary"
                          size="large"
                        >
                          Read EID
                        </Button>
                      )}
                    <Button
                      htmlType="submit"
                      onClick={() => handleSubmit()}
                      loading={isSubmitting}
                      type="primary"
                      size="large"
                      disabled={!isValid}
                    >
                      {submitBtnLabel ?? 'Check Eligibility'}
                    </Button>
                  </Space>
                </div>
              </Card>
            )
          }
        </Form>
      </Spin>
    );
  }
}

export default EligibilityRequest;
