import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import * as Yup from 'yup';

import { submitValidate } from 'utils/form-helpers/antd-formik';
import { ApiError, UserProfileApi } from 'api';

import { AccountEmail } from './AccountEmail';
import { useAuthDispatch, useAuthState } from '../../../authentication/context/authContext';
import { userProfileSelector } from '../../../authentication/context/authSelectors';
import { privateRoutes } from '../../../application/routes/routesConstants';
import { UserProfile } from '../../../../api/user-profile/userProfileTypes';
import { fetchUserProfile } from '../../../authentication/context/authThunks';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

export const AccountEmailContainer = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userProfileSelector(state) as UserProfile;
  const navigate = useNavigate();
  return (
    <AccountEmail
      initialValues={{ email: user.email }}
      onSubmit={useCallback(async (values, formikHelpers) => {
        if (!submitValidate(values, validationSchema, formikHelpers)) {
          return false;
        }
        try {
          await UserProfileApi.updateProfile({
            email: values.email,
            name: user.name,
            phone: user.phone,
          });
          await dispatch(fetchUserProfile);
          navigate(privateRoutes.accountSettings);
        } catch (error: any) {
          if (error instanceof ApiError && error.response.status === 400) {
            const response = await error.response.json();
            if (response?.stack?.code === '23505' && response?.stack?.table === 'users') {
              formikHelpers.setErrors({ email: 'Email already in use' });
              formikHelpers.setTouched({ email: true }, false);
              return false;
            }
          }
          notification.error({
            message: 'Error',
            description: 'Internal server error',
          });

          return false;
        }
        return true;
      }, [dispatch, navigate, user])}
      onCancel={useCallback(() => {
        navigate(-1);
      }, [navigate])}
    />
  );
};

export default AccountEmailContainer;
