import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'custom-test-antd';
import * as Yup from 'yup';

import 'utils/form-helpers/yup-phone';

import { AccountPhone } from './AccountPhone';

import { useAuthDispatch, useAuthState } from '../../../authentication/context/authContext';
import { userProfileSelector } from '../../../authentication/context/authSelectors';
import { UserProfile } from '../../../../api/user-profile/userProfileTypes';
import { submitValidate } from '../../../../utils/form-helpers/antd-formik';
import { UserProfileApi } from '../../../../api';
import { fetchUserProfile } from '../../../authentication/context/authThunks';
import { privateRoutes } from '../../../application/routes/routesConstants';

const validationSchema = Yup.object({
  phone: Yup.string().required('Phone is required').phone(undefined, false, 'Invalid phone'),
});

export const AccountPhoneContainer = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const user = userProfileSelector(state) as UserProfile;
  const navigate = useNavigate();
  return (
    <AccountPhone
      initialValues={{ phone: user.phone }}
      onSubmit={useCallback(async (values, formikHelpers) => {
        if (!submitValidate(values, validationSchema, formikHelpers)) {
          return false;
        }
        try {
          await UserProfileApi.updateProfile({
            name: user.name,
            email: user.email,
            phone: values.phone,
          });
          await dispatch(fetchUserProfile);
          navigate(privateRoutes.accountSettings);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Internal server error',
          });
          return false;
        }
        return true;
      }, [dispatch, navigate, user])}
      onCancel={useCallback(() => {
        navigate(-1);
      }, [navigate])}
    />
  );
};

export default AccountPhoneContainer;
