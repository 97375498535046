import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import eligibilityListReducer, { EligibilityListContextState, initialState, InitState } from './eligibilityListReducer';

const useThunkReducer = createReducer<any, EligibilityListContextState>(thunk);

const EligibilityListStateContext = React.createContext<EligibilityListContextState>(initialState());
export const EligibilityListDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState: InitState
}

const EligibilityListProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(eligibilityListReducer, initialState(initState));

  return (
    <EligibilityListStateContext.Provider value={state}>
      <EligibilityListDispatchContext.Provider value={dispatch}>
        {children}
      </EligibilityListDispatchContext.Provider>
    </EligibilityListStateContext.Provider>
  );
};

function useEligibilityListState() {
  const context = React.useContext(EligibilityListStateContext);
  if (context === undefined) {
    throw new Error('useEligibilityListState must be used within a EligibilityListStateContext');
  }
  return context;
}

function useEligibilityListDispatch() {
  const context = React.useContext(EligibilityListDispatchContext);
  if (context === undefined) {
    throw new Error('useEligibilityListDispatch must be used within a EligibilityListDispatchContext');
  }
  return context;
}

export {
  EligibilityListProvider,
  useEligibilityListState,
  useEligibilityListDispatch,
};
