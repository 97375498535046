import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountSettings } from './AccountSettings';
import { privateRoutes } from '../../application/routes/routesConstants';
import { useAuthState } from '../../authentication/context/authContext';
import { userProfileSelector } from '../../authentication/context/authSelectors';
import { UserProfile } from '../../../api/user-profile/userProfileTypes';

export const AccountSettingsContainer = () => {
  const navigate = useNavigate();
  const state = useAuthState();
  return (
    <AccountSettings
      user={userProfileSelector(state) as UserProfile}
      onFullNameEdit={useCallback(() => {
        navigate(privateRoutes.accountName);
      }, [navigate])}
      onPhoneEdit={useCallback(() => {
        navigate(privateRoutes.accountPhone);
      }, [navigate])}
      onEmailEdit={useCallback(() => {
        navigate(privateRoutes.accountEmail);
      }, [navigate])}
      onChangePassword={useCallback(() => {
        navigate(privateRoutes.accountPassword);
      }, [navigate])}
    />
  );
};

export default AccountSettingsContainer;
