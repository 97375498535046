import React from 'react';
import { Menu, Popover, Table, Upload, Select, UploadProps, Space } from 'custom-test-antd';
import moment from 'moment-timezone';

import './DocumentsUploader.pcss';
import cloudUpload from 'assets/cloud-upload.svg';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, CloudDownloadOutlined, MoreOutlined, LoadingOutlined } from '@ant-design/icons';
import { UploadFile } from './context/documentsUploaderReducer';

export interface Props {
  fileList: UploadFile[];
  className?: string,
  onDelete: (id: string) => Promise<void>;
  onTypeChange: (id: string, type: string) => void;
  onDraggerChange: Exclude<UploadProps['onChange'], undefined>;
  customRequest: UploadProps['customRequest'];
  onFileDownload: (fileName: string) => Promise<any>;
  disabled: boolean,
}

export enum KYCDocumentType {
  type1 = 'type1',
  type2 = 'type2',
}

const documentTypes = [
  { key: KYCDocumentType.type1, label: 'Type 1' },
  { key: KYCDocumentType.type2, label: 'Type 2' },
];

export class DocumentsUploader extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.getColumns = this.getColumns.bind(this);
  }

  getColumns(): ColumnsType<UploadFile> {
    const { disabled } = this.props;
    return [{
      title: 'FILE',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => (
        <Space className={`DocumentsUploader-fileName ${row.status === 'error' ? 'error' : ''}`}>
          {
            row.status === 'uploading'
            && <LoadingOutlined />
          }
          {name}
        </Space>
      ),
    }, {
      title: 'DOCUMENT DESCRIPTION',
      dataIndex: 'type',
      key: 'type',
      render: (description, row) => (
        <Select
          className="DocumentsUploader-typeSelect"
          gray
          value={description}
          onChange={(value) => {
            const { onTypeChange } = this.props;
            onTypeChange(row.id, value);
          }}
          dropdownMatchSelectWidth={false}
          disabled={disabled || row.status === 'uploading'}
        >
          {
            documentTypes.map((type) => (
              <Select.Option key={type.key} value={type.key}>{type.label}</Select.Option>
            ))
          }
        </Select>
      ),
    }, {
      title: 'UPLOAD DATE',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (date) => (date ? moment(date).format('DD MMM YYYY') : ''),
    }, {
      title: '',
      render: (row) => (
        <Popover
          overlayClassName="DocumentsUploader-moreActions"
          content={(
            <Menu
              className="DocumentsUploader-menu"
              disabled={disabled || row.status === 'uploading'}
              items={[
                {
                  label: 'Delete',
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    const { onDelete } = this.props;
                    onDelete(row.id);
                  },
                },
                {
                  label: 'Download',
                  key: 'download',
                  icon: <CloudDownloadOutlined />,
                  onClick: () => {
                    const { onFileDownload } = this.props;
                    onFileDownload(row.name);
                  },
                },
              ]}
            />
          )}
        >
          <MoreOutlined />
        </Popover>
      ),
    }];
  }

  render() {
    const { onDraggerChange, fileList, customRequest, className, disabled } = this.props;
    return (
      <div className={`DocumentsUploader ${className ?? ''}`}>
        <Upload.Dragger
          multiple
          name="file"
          customRequest={customRequest}
          className="DocumentsUploader-dragger"
          onChange={onDraggerChange}
          showUploadList={false}
          disabled={disabled}
        >
          <div>
            <img alt="" className="DocumentsUploader-icon" src={cloudUpload} />
          </div>
          <p>
            Upload your files here
            <br />
            by drag-and-drop or&nbsp;
            <span className="DocumentsUploader-linkText">click to select</span>
          </p>
        </Upload.Dragger>
        <Table
          rowKey="id"
          className="DocumentsUploader-fileList"
          columns={this.getColumns()}
          dataSource={fileList}
          pagination={false}
        />
      </div>
    );
  }
}

export default DocumentsUploader;
