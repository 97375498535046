import React from 'react';
import { Input, Table, Switch } from 'custom-test-antd';
import { ColumnsType } from 'antd/lib/table';

import './ClientList.pcss';

export interface User {
  name: string,
  email: string,
  id: number,
  isPortalPaid: boolean,
  isEligibilityPaid: boolean,
  isInspectorPaid: boolean,
  phone: string,
  organization: {
    id: number,
    name: string,
  },
}

export interface Props {
  loading: boolean,
  data: User[],
  onSetPortalPaid: (organizationId: number, activity: boolean) => void,
  onSetEligibilityPaid: (organizationId: number, activity: boolean) => void,
  onSetInspectorPaid: (organizationId: number, activity: boolean) => void,
  onSearch: (searchString: string) => void;
}

export class ClientList extends React.PureComponent<Props> {
  getColumnsConfig(): ColumnsType<User> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    const { onSetPortalPaid, onSetEligibilityPaid, onSetInspectorPaid } = this.props;
    return [
      {
        title: 'Organization',
        dataIndex: ['organization', 'name'],
      },
      {
        title: 'User Name',
        dataIndex: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
      },
      {
        title: 'Portal Paid',
        dataIndex: 'isPortalPaid',
        width: 200,
        render: (isPortalPaid, row) => (
          <Switch
            checked={isPortalPaid}
            onChange={(value) => {
              onSetPortalPaid(row.organization.id, value);
            }}
          />
        ),
      },
      {
        title: 'Eligibility Paid',
        dataIndex: 'isEligibilityPaid',
        width: 200,
        render: (isEligibilityPaid, row) => (
          <Switch
            checked={isEligibilityPaid}
            onChange={(value) => {
              onSetEligibilityPaid(row.organization.id, value);
            }}
          />
        ),
      },
      {
        title: 'Inspector Paid',
        dataIndex: 'isInspectorPaid',
        width: 200,
        render: (isInspectorPaid, row) => (
          <Switch
            checked={isInspectorPaid}
            onChange={(value) => {
              onSetInspectorPaid(row.organization.id, value);
            }}
          />
        ),
      },
    ];
  }

  render() {
    const { loading, data, onSearch } = this.props;
    return (
      <div className="ClientList">
        <div className="ClientList-actionPanel">
          <div>
            <Input.Search
              className="ClientList-search"
              placeholder="Search"
              onSearch={onSearch}
              size="large"
              gray
            />
          </div>
        </div>
        <Table
          loading={loading}
          className="ClientList-table"
          rowKey={(row) => row.organization.id}
          rowClassName="ClientList-tableRow"
          columns={this.getColumnsConfig()}
          dataSource={data}
          rightAngleTopCorners
        />
      </div>
    );
  }
}

export default ClientList;
