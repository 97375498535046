import React, { useState } from 'react';
import { Upload, Button, Spin } from 'custom-test-antd';
import type { UploadProps } from 'custom-test-antd';

import './BulkUpload.pcss';

import cloudUpload from 'assets/cloud-upload.svg';

export interface Props extends UploadProps {
  onSuccess?: (response?: any) => void;
  onError?: (error: any) => void;
  onStartUpload?: () => void;
  onTemplateDownload: () => void;
  uploadText: React.ReactNode;
  noteText: React.ReactNode;
  loading?: boolean;
}

export const BulkUpload: React.FC<Props> = (props) => {
  const {
    customRequest,
    onSuccess,
    onError,
    onStartUpload,
    onTemplateDownload,
    uploadText,
    noteText,
    loading,
    className,
    ...other
  } = props;
  const [internalLoading, setLoading] = useState(false);
  const spinLoading = Object.prototype.hasOwnProperty.call(props, 'loading') ? loading : internalLoading;
  return (
    <Spin spinning={spinLoading} wrapperClassName={className}>
      <Upload.Dragger
        {...other}
        className="BulkUpload-dragger"
        customRequest={customRequest}
        onChange={(info) => {
          const { status } = info.file;
          if (status === 'uploading') {
            setLoading(true);
            if (onStartUpload) {
              onStartUpload();
            }
          } else if (status === 'done') {
            setLoading(false);
            if (onSuccess) {
              onSuccess(info.file?.response);
            }
          } else if (status === 'error') {
            setLoading(false);
            if (onError) {
              onError(info.file?.error);
            }
          }
        }}
        showUploadList={false}
      >
        <div>
          <img alt="" className="BulkUpload-icon" src={cloudUpload} />
        </div>
        <p>
          {uploadText}
        </p>
      </Upload.Dragger>
      <div className="BulkUpload-footer">
        <div className="BulkUpload-noteText">
          {noteText}
        </div>
        <Button
          className="BulkUpload-downloadTemplate"
          type="link"
          onClick={onTemplateDownload}
        >
          Download CSV template
        </Button>
      </div>
    </Spin>
  );
};

export default BulkUpload;
