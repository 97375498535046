import { Button } from 'custom-test-antd';
import { Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { InputWrapper } from 'utils/form-helpers/antd-formik';

type Props = {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void
}

const OtpForm: FC<Props> = ({ onSubmit }: Props) => (
  <Formik
    initialValues={{}}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, isSubmitting }) => (
      <form>
        <InputWrapper
          size="large"
          gray
          antFieldProps={{ label: 'Please enter otp' }}
          name="otp"
        />
        <Button
          style={{ float: 'right' }}
          size="large"
          htmlType="button"
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          type="primary"
        >
          Verify
        </Button>
      </form>
    )}
  </Formik>
);

export default OtpForm;
