import React from 'react';
import { Spin, Tabs } from 'custom-test-antd';

import './EligibilityResponse.pcss';

import { Card } from '../card';
import {
  Eligibility,
  EligibilityRequestStatus,
} from '../../../api/eligibility/eligibilityTypes';
import { PatientInfo } from './patient-info';
import { Insurance } from './Insurance';
import { Benefits } from './benefits';
import { Note } from './note';
import { ApprovalRequirements } from './approval-requirements';
import Gatekeeper from './gatekeeper/Gatekeeper';
import { ErrorMessageContainer } from './error-message';

interface Props {
  loading: boolean;
  eligibility?: Eligibility;
  onResponseFetch: () => Promise<void>;
}

class EligibilityResponse extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.renderResponseForm = this.renderResponseForm.bind(this);
  }

  componentDidMount() {
    const { onResponseFetch } = this.props;
    onResponseFetch();
  }

  renderResponseForm(index: number) {
    const { eligibility } = this.props;

    if (!eligibility) {
      return null;
    }

    const approvalRequirements = eligibility.response?.[index]?.approvalRequirements;
    const gatekeeper = eligibility.response?.[index]?.gatekeeper;

    return (
      <div className="EligibilityResponse-info">
        <Card className="EligibilityResponse-card patient">
          <PatientInfo response={eligibility.response[index]} />
        </Card>
        <div className="EligibilityResponse-leftColumn">
          <Card className="EligibilityResponse-card">
            <Insurance
              response={eligibility.response?.[index]}
              createdAt={eligibility?.createdAt}
              portal={eligibility?.portal}
            />
          </Card>
          {
            gatekeeper && gatekeeper !== ''
            && (
              <Card className="EligibilityResponse-card">
                <Gatekeeper value={gatekeeper} />
              </Card>
            )
          }
        </div>
        <div className="EligibilityResponse-rightColumn">
          <Card>
            <Benefits benefits={eligibility.response?.[index].benefits} />
          </Card>
          <Card className="EligibilityResponse-card">
            <Note
              note={eligibility.response?.[index].copay}
              maxConsul={eligibility.response?.[index]?.maxConsul}
              deductable={eligibility.response?.[index]?.deductable}
              referral={eligibility.response?.[index]?.referral}
              remarks={eligibility.response?.[index]?.remarks}
            />
          </Card>
          {
            approvalRequirements && approvalRequirements.length > 0
            && (
              <Card>
                <ApprovalRequirements
                  approvalRequirements={approvalRequirements}
                />
              </Card>
            )
          }
        </div>
      </div>
    );
  }

  render() {
    const { eligibility, loading } = this.props;
    if (!eligibility) {
      return <Spin spinning wrapperClassName="EligibilityResponse-spin" tip="Loading..." />;
    }
    if (eligibility.status === EligibilityRequestStatus.Pending) {
      return (
        <Card className="EligibilityResponse-card">
          <div>Request in progress</div>
        </Card>
      );
    }
    if (
      eligibility.status === EligibilityRequestStatus.PortalError
      || eligibility.status === EligibilityRequestStatus.Error
    ) {
      return (
        <Card className="EligibilityResponse-card">
          <ErrorMessageContainer eligibility={eligibility} />
        </Card>
      );
    }

    if (eligibility.response?.length > 1) {
      return (
        <Spin spinning={loading} wrapperClassName="EligibilityResponse-spin">
          <Tabs className="EligibilityResponse-tabs">
            {
              eligibility.response.map((r, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tabs.TabPane tab={r.patientName || '-'} key={i}>
                  {this.renderResponseForm(i)}
                </Tabs.TabPane>
              ))
            }
          </Tabs>
        </Spin>
      );
    }

    return (
      <Spin wrapperClassName="EligibilityResponse-spin" spinning={loading}>
        {this.renderResponseForm(0)}
      </Spin>
    );
  }
}

export default EligibilityResponse;
