import React from 'react';
import { Table, Popover, Menu, Input } from 'custom-test-antd';
import { DeleteOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment-timezone';

import CloseCircleFill from 'assets/close-circle-fill.svg';
import CheckCircleFill from 'assets/check-circle-fill.svg';
import './CredentialsList.pcss';
import {
  CredentialsListItem, CredentialsWebSocketEvent,
} from '../../../api/credentials/credentialsTypes';
import { CredentialsListContextState } from './context/credentialsListReducer';
import { BranchData } from '../../../api/branch/branchTypes';
import container, { Service } from '../../../container';
import { IWebSocketClientService } from '../../../services';
import { Card } from '../../eligibility/card';

interface Props {
  onFetch: Function,
  items: CredentialsListItem[],
  pagination: CredentialsListContextState['pagination'],
  onSetPagination: (pagination: CredentialsListContextState['pagination']) => void,
  onItemUpdate: (item: CredentialsListItem) => void,
  onOpenCredentials: (id: string) => void,
  onDelete: (id: string) => void,
  onSearch: (search: string) => void;
}

export class CredentialsList extends React.PureComponent<Props> {
  columns: ColumnsType<CredentialsListItem> = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Portal',
      dataIndex: 'portal',
      key: 'portal',
    },
    {
      title: 'License',
      dataIndex: 'license',
      key: 'license',
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: BranchData) => (branch ? `[${branch.identifier}] ${branch.name}` : ''),
    },
    {
      title: 'Entry Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date?: string) => (date ? moment(date).format('DD MMM YYYY HH:mm') : ''),
    },
    {
      title: 'Authentication',
      dataIndex: 'validationResponse',
      key: 'validationResponse',
      render: (validationResponse, row) => {
        if (!Object.prototype.hasOwnProperty.call(row, 'validationResponse')) {
          return null;
        }
        if (validationResponse?.isValid) {
          return <img className="CredentialsList-validIcon" src={CheckCircleFill} alt="valid" />;
        }
        return <img className="CredentialsList-invalidIcon" src={CloseCircleFill} alt="invalid" />;
      },
    },
    {
      title: '',
      onCell: () => ({
        onClick: (event) => event.stopPropagation(),
      }),
      render: (_, row) => (
        <Popover
          overlayClassName="CredentialsList-moreActions"
          content={(
            <Menu
              className="CredentialsList-menu"
              items={[
                {
                  label: 'View',
                  key: 'view',
                  icon: <EyeOutlined />,
                  onClick: () => {
                    const { onOpenCredentials } = this.props;
                    onOpenCredentials(row.id);
                  },
                },
                {
                  label: 'Delete',
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    const { onDelete } = this.props;
                    onDelete(row.id);
                  },
                },
              ]}
            />
          )}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getList = this.getList.bind(this);
    this.updateCredentials = this.updateCredentials.bind(this);
  }

  componentDidMount() {
    const { onFetch } = this.props;
    const client = container.resolve(Service.websocketClient) as IWebSocketClientService;
    client.on(CredentialsWebSocketEvent.UpdateCredentials, this.updateCredentials);
    client.on(CredentialsWebSocketEvent.CreateCredentials, this.getList);
    onFetch();
  }

  componentWillUnmount() {
    const client = container.resolve(Service.websocketClient) as IWebSocketClientService;
    client.off(CredentialsWebSocketEvent.UpdateCredentials, this.updateCredentials);
    client.off(CredentialsWebSocketEvent.CreateCredentials, this.getList);
  }

  handleTableChange(pagination: TablePaginationConfig) {
    const { onSetPagination, onFetch } = this.props;
    onSetPagination(pagination as CredentialsListContextState['pagination']);
    onFetch();
  }

  getList() {
    const { onFetch } = this.props;
    onFetch();
  }

  updateCredentials(item: CredentialsListItem) {
    const { onItemUpdate } = this.props;
    onItemUpdate(item);
  }

  render() {
    const { items, pagination, onOpenCredentials, onSearch } = this.props;
    return (
      <Card className="CredentialsList">
        <div className="CredentialsList-actionPanel">
          <Input.Search
            className="CredentialsList-search"
            placeholder="Search"
            onSearch={onSearch}
            size="large"
            gray
          />
        </div>
        <Table
          rowKey="id"
          rowClassName="CredentialsList-tableRow"
          columns={this.columns}
          dataSource={items}
          pagination={pagination}
          onChange={this.handleTableChange}
          onRow={(record) => ({
            onDoubleClick: () => {
              onOpenCredentials(record.id);
            },
          })}
          rightAngleTopCorners
        />
      </Card>
    );
  }
}

export default CredentialsList;
