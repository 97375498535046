import React, { FC } from 'react';
import { PrivateLayoutProvider } from './context/privateLayoutContext';
import PrivateLayout from './PrivateLayout';

type Props = {
  showMenu: boolean;
  isMobileAdaptive: boolean;
};

export const PrivateLayoutContainer: FC<Props> = ({ showMenu = true, isMobileAdaptive = false }) => (
  <PrivateLayoutProvider state={{ showMenu, isMobileAdaptive }}>
    <PrivateLayout />
  </PrivateLayoutProvider>
);

export default PrivateLayoutContainer;
