import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum MednetUserType {
  EmiratesId = '1',
  CardNo = '2',
}

export const mednetUserType: DropdownOptionString[] = [
  {
    value: MednetUserType.EmiratesId,
    label: 'Emirates ID',
  },
  { value: MednetUserType.CardNo, label: 'Card No' },
];
