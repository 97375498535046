export interface DropdownOptionString {
  label: string;
  value: string;
}

export enum InsurancePortal {
  Axa = 'axa',
  Daman = 'daman',
  Nas = 'nas',
  Neuron = 'neuron',
  Nextcare = 'nextcare',
  Almadallah = 'almadallah',
  Oman = 'oman',
  Eclaim = 'eclaim',
  MSH = 'msh',
  Mednet = 'mednet',
  Abnic = 'abnic',
  Adnic = 'adnic',
  NGI = 'ngi',
  Inayah = 'inayah',
  Metlife = 'metlife',
}

export const InsurancePortalLabel = {
  [InsurancePortal.Axa]: 'AXA',
  [InsurancePortal.Daman]: 'Daman',
  [InsurancePortal.Nas]: 'NAS',
  [InsurancePortal.Neuron]: 'Neuron',
  [InsurancePortal.Nextcare]: 'Nextcare',
  [InsurancePortal.Almadallah]: 'Almadallah',
  [InsurancePortal.Oman]: 'Oman',
  [InsurancePortal.Eclaim]: 'Eclaim',
  [InsurancePortal.MSH]: 'MSH',
  [InsurancePortal.Mednet]: 'Mednet',
  [InsurancePortal.Abnic]: 'Abnic',
  [InsurancePortal.Adnic]: 'Adnic',
  [InsurancePortal.NGI]: 'NGI',
  [InsurancePortal.Inayah]: 'Inayah',
  [InsurancePortal.Metlife]: 'Metlife-Alico',
};

export const EclaimPortals = [
  InsurancePortal.MSH,
  InsurancePortal.Abnic,
  InsurancePortal.NGI,
  InsurancePortal.Inayah,
];
