import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum AlmadallahUserType {
  EmiratesId = '1',
  MemberIdOrCard = '2',
}

export const almadallahUserType: DropdownOptionString[] = [
  {
    value: AlmadallahUserType.EmiratesId,
    label: 'Emirates ID',
  },
  { value: AlmadallahUserType.MemberIdOrCard, label: 'Member ID/Card' },
];

export enum AlmadallahTreatmentType {
  OP = '1',
  IPDaycase = '2',
  Maternity = '3',
  Dental = '4',
  Optical = '5',
  Psychiatry = '6',
}

export const almadallahTreatmentType: DropdownOptionString[] = [
  { value: AlmadallahTreatmentType.OP, label: 'OP' },
  { value: AlmadallahTreatmentType.IPDaycase, label: 'IP/Daycase' },
  { value: AlmadallahTreatmentType.Maternity, label: 'Maternity' },
  { value: AlmadallahTreatmentType.Dental, label: 'Dental' },
  { value: AlmadallahTreatmentType.Optical, label: 'Optical' },
  { value: AlmadallahTreatmentType.Psychiatry, label: 'Psychiatry' },
];
