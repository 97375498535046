import React, { useCallback, useState } from 'react';
import { notification, Switch, Space } from 'custom-test-antd';
import type { UploadProps } from 'custom-test-antd';
import { BulkUpload } from 'components/common/bulk-upload';

import './LicenseBulk.pcss';

import { PractitionerLicenseApi, StaticApi } from '../../../api';

interface Props {
}

export const LicenseBulk: React.FC<Props> = () => {
  const [rewriteLicenses, setRewriteLicenses] = useState(false);
  const customRequest: UploadProps['customRequest'] = async ({ onSuccess, onError, file }) => {
    try {
      await PractitionerLicenseApi.upload(file, rewriteLicenses);
      if (onSuccess) {
        onSuccess('Ok');
      }
    } catch (error) {
      if (onError) {
        onError(error as Error);
      }
    }
  };
  return (
    <div>
      <div className="LicenseBulk-header">
        <Space>
          <span>Override existing licenses</span>
          <Switch
            checked={rewriteLicenses}
            onChange={setRewriteLicenses}
          />
        </Space>
      </div>
      <BulkUpload
        accept=".csv"
        customRequest={useCallback(customRequest, [rewriteLicenses])}
        onSuccess={useCallback(() => {
          notification.success({ message: 'Success', description: 'Licenses have been successfully added' });
        }, [])}
        onError={useCallback(() => {
          notification.error({
            message: 'Error',
            description: 'Error occurred during licenses upload',
          });
        }, [])}
        onTemplateDownload={useCallback(async () => {
          try {
            const data = await StaticApi.getEligibilityPractitionerLicensesTemplate();
            const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            const fileUrl = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'bulk_eligibility_practitioner_licenses.csv';
            link.click();
            link.remove();
          } catch (e) {
            notification.error({
              message: 'Error',
              description: 'Error getting practitioner licenses template',
            });
          }
        }, [])}
        uploadText={(
          <>
            Upload your practitioner licenses file
            <br />
            by drag-and-drop or&nbsp;
            <span className="LicenseBulk-link">click to select</span>
          </>
        )}
        noteText={(
          <>
            You can upload a .csv with your practitioner ID’s and other requirement’s. After
            <br />
            uploading the file will process your licenses. This may take some time.
          </>
        )}
      />
    </div>
  );
};

export default LicenseBulk;
