import React, { useState } from 'react';
import { Menu, Spin } from 'custom-test-antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';

import createPortal from '../../utils/createPortal';
import { PrivateLayout } from '../application/layouts/private-layout';
import { MenuContentLayout } from '../application/layouts/menu-content-layout';
import { privateRoutes } from '../application/routes/routesConstants';
import { ProposalProvider, useProposalDispatch } from './proposal/context/context/proposalContext';
import { fetchBranches, fetchCustomer, fetchCustomers, fetchProposal } from './proposal/context/context/proposalThunks';

enum CapitalMenuKey {
  Overview = 'Overview',
  Proposal = 'Proposal',
  Transactions = 'Transactions',
}

const MenuRoutes: { [key: string]: string } = {
  [CapitalMenuKey.Proposal]: privateRoutes.capitalProposal,
  [CapitalMenuKey.Transactions]: privateRoutes.capitalTransactions,
  [CapitalMenuKey.Overview]: privateRoutes.capital,
};

const menuItems = [
  { label: 'Overview', key: CapitalMenuKey.Overview },
  { label: 'Proposal', key: CapitalMenuKey.Proposal },
  { label: 'Transactions', key: CapitalMenuKey.Transactions },
];

const Header = createPortal(PrivateLayout.titlePortalId);

const Capital = () => {
  const [initializing, setInitializing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useProposalDispatch();
  useMount(async () => {
    await dispatch(fetchBranches());
    Promise.all([
      dispatch(fetchProposal()),
      dispatch(fetchCustomer('DHA-F-0000404')),
      dispatch(fetchCustomers()),
    ]).then(() => {
      setInitializing(true);
    });
  });

  let selectedKey: string = CapitalMenuKey.Overview;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <>
      <Header>Klaim Kapital</Header>
      <MenuContentLayout
        menu={(
          <Menu
            className="Credentials-menu"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
            }}
            items={menuItems}
          />
        )}
        content={(
          <>
            {
              initializing
              && <Outlet />
            }
            {
              !initializing
              && <Spin tip="Loading..." />
            }
          </>
        )}
      />
    </>
  );
};

export const CapitalWrapper = () => (
  <ProposalProvider>
    <Capital />
  </ProposalProvider>
);

export default CapitalWrapper;
