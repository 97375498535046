import React from 'react';
import { Layout, Spin } from 'custom-test-antd';
import { Outlet } from 'react-router-dom';
import klaim from 'assets/klaim.svg';

import './PrivateLayout.pcss';
import { isMobile } from 'utils/mobileDetector';
import { MenuButton } from './menu-button';
import { UserInfoContainer } from './user-info';
import { LoadingLayout } from '../loading-layout';
import { usePrivateLayoutState } from './context/privateLayoutContext';
import { isMobileAdaptiveSelector, showMenuSelector } from './context/privateLayoutSelector';

const titlePortalId = 'private-layout-title';

export const PrivateLayoutInternal: React.FC = () => {
  const state = usePrivateLayoutState();
  const showMenu = showMenuSelector(state);
  const isMobileAdaptive = isMobileAdaptiveSelector(state);

  return (
    <div className={`PrivateLayout ${isMobileAdaptive && isMobile() ? 'mobile' : ''}`}>
      <Layout className="PrivateLayout-container">
        <Layout.Header className="PrivateLayout-header">
          <img alt="" className="PrivateLayout-logo" src={klaim} />
          <div className="PrivateLayout-menuSection">
            {showMenu && (<MenuButton className="PrivateLayout-menuButton" />)}
            <div id={titlePortalId} />
          </div>
          <UserInfoContainer />
        </Layout.Header>
        <Layout.Content className="PrivateLayout-content">
          <React.Suspense fallback={<LoadingLayout><Spin spinning tip="Loading..." /></LoadingLayout>}>
            <Outlet />
          </React.Suspense>
        </Layout.Content>
      </Layout>
    </div>
  );
};

type PrivateLayoutInternalType = typeof PrivateLayoutInternal;

export interface PrivateLayoutType extends PrivateLayoutInternalType {
  titlePortalId: string;
}

export const PrivateLayout = PrivateLayoutInternal as PrivateLayoutType;
PrivateLayout.titlePortalId = titlePortalId;

export default PrivateLayout;
