import React from 'react';
import { Button } from 'custom-test-antd';
import LetterSuccess from 'assets/letter-success.svg';

import './RegistrationCompleted.pcss';
import { AuthCard } from '../auth-card';

interface Props {
  onContinue: () => void;
  showContinueOnPrevSessionMsg?: boolean;
}

export const RegistrationSuccess: React.FC<Props> = ({ onContinue, showContinueOnPrevSessionMsg }) => (
  <AuthCard
    className="ConfirmEmailSent"
    header="Email Authenticated"
    headerText={(
      <>
        Thank you for validating your email address
        {
          showContinueOnPrevSessionMsg ? (
            <>
              <br />
              <br />
              Please consider continuing your registration process on the previous browser session
            </>
          ) : null
        }
      </>
    )}
  >
    <img className="RegistrationCompleted-icon" src={LetterSuccess} alt="" />
    <div className="RegistrationCompleted-backSection">
      I want to&nbsp;
      <Button
        type="link"
        className="RegistrationCompleted-signIn"
        onClick={onContinue}
      >
        continue from here
      </Button>
    </div>
  </AuthCard>
);

export default RegistrationSuccess;
