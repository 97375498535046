import React from 'react';
import { Table } from 'custom-test-antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Benefit } from '../../../../api/eligibility/eligibilityTypes';
import { BenefitsTable } from './table';

import './Benefits.pcss';

interface Props {
  benefits: Benefit[];
  tableSize?: TableProps<any>['size'],
}

const columns: ColumnsType<{ group: string }> = [
  {
    dataIndex: 'group',
    key: 'group',
    title: 'Group',
  },
];

export const Benefits = ({ benefits, tableSize }: Props) => {
  const groups = benefits?.reduce((acc, benefit) => {
    if (benefit.group) {
      acc.add(benefit.group);
    }
    return acc;
  }, new Set<string>());
  const groupsArray: string[] = groups ? Array.from(groups) : [];
  if (groupsArray.length > 1) {
    return (
      <Table
        className="Benefits-groupTable"
        size={tableSize}
        dataSource={groupsArray.map((group) => ({ group }))}
        columns={columns}
        whiteHeader
        rowKey="group"
        expandable={{
          expandedRowRender: ({ group }) => {
            const groupBenefits = benefits.filter((benefit) => benefit.group === group);
            return (
              <BenefitsTable
                whiteHeader
                grayPagination={false}
                dataSource={groupBenefits}
                size={tableSize}
              />
            );
          },
        }}
      />
    );
  }
  return (
    <BenefitsTable
      whiteHeader
      dataSource={benefits}
      size={tableSize}
    />
  );
};

export default Benefits;
