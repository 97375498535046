import * as React from 'react';
import {
  AutoComplete as _AutoComplete,
  AutoCompleteProps as _AutoCompleteProps,
} from 'custom-test-antd';
import { FieldProps } from 'formik';

import Field from './Field';
import { DatePicker } from './DatePicker';
import { FormikFieldProps } from './FieldProps';
import { makeField } from './makeField';

type AutoCompleteProps = FieldProps & _AutoCompleteProps
type AutoCompleteFieldProps = FormikFieldProps & _AutoCompleteProps

export const AutoComplete = React.forwardRef(
  (
    {
      field: { value, name },
      form,
      onChange,
      onBlur,
      ...restProps
    }: AutoCompleteProps,
    ref: React.Ref<any>,
  ) => (
    <_AutoComplete
      ref={ref}
      defaultValue={value}
      onChange={(newValue, option) => {
        form.setFieldValue(name, newValue != null ? newValue.valueOf() : newValue);
        if (onChange) {
          onChange(newValue, option);
        }
      }}
      onBlur={(event) => {
        form.setFieldTouched(name);
        if (onBlur) {
          onBlur(event);
        }
      }}
      {...restProps}
    />
  ),
);

export const AutoCompleteField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: AutoCompleteFieldProps,
    ref: React.Ref<any>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <DatePicker.MonthPicker ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

export const AutoCompleteWrapper = makeField<_AutoCompleteProps>(AutoComplete);
