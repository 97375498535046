import { DropdownOptionString } from '../../../../../constants/dropdownOptions';

export enum NasUserType {
  EmiratesId = '1',
  CardNo = '2',
  DhaId = '3',
}

export const nasUserType: DropdownOptionString[] = [
  { value: NasUserType.EmiratesId, label: 'Emirates ID' },
  { value: NasUserType.CardNo, label: 'Card No' },
  { value: NasUserType.DhaId, label: 'DHA ID' },
];

export enum NasTreatmentType {
  InPatient = '1',
  OutPatient = '2',
  Dental = '7',
  Psychiatry = '8',
  Maternity = '17',
  Optical = '18',
  Wellness = '19',
}

export const nasTreatmentType: DropdownOptionString[] = [
  { value: NasTreatmentType.InPatient, label: 'In-Patient' },
  { value: NasTreatmentType.OutPatient, label: 'Out-Patient' },
  { value: NasTreatmentType.Maternity, label: 'Maternity' },
  { value: NasTreatmentType.Dental, label: 'Dental' },
  { value: NasTreatmentType.Optical, label: 'Optical' },
  { value: NasTreatmentType.Psychiatry, label: 'Psychiatry' },
  { value: NasTreatmentType.Wellness, label: 'Wellness' },
];
