import { DropdownOptionString } from '../../../../constants/dropdownOptions';

export const calcPatientId = (selectedPatientId: string, patientIdType: DropdownOptionString[]) => {
  const selectedOption = patientIdType.find((type) => type.value === selectedPatientId);
  if (!selectedOption) {
    return undefined;
  }
  return {
    placeholder: `Enter ${selectedOption.label}`,
    label: selectedOption.label,
  };
};
