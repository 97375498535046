import * as Yup from 'yup';
import 'utils/form-helpers/yup-emirates-id';
import produce from 'immer';
import {
  AlmadallahUserType,
  almadallahTreatmentType,
  almadallahUserType,
} from './types';
import { calcPatientId } from '../utils';
import { PortalConfig, SinglePortalRequest } from '../singlePortalRequest';

const initialValue = {
  patientIdType: AlmadallahUserType.EmiratesId,
  emiratesId: '784-____-_______-_',
};

export class AlmadallahRequest extends SinglePortalRequest {
  // eslint-disable-next-line class-methods-use-this
  getPortalConfig(values: any = {}): PortalConfig {
    const config: PortalConfig = {
      patientIdType: almadallahUserType,
      patientId: undefined,
      emiratesId: true,
      treatmentBasis: almadallahTreatmentType,
      patientName: false,
    };
    const { patientIdType } = values;
    if (patientIdType && patientIdType === AlmadallahUserType.MemberIdOrCard) {
      config.emiratesId = false;
      config.patientId = calcPatientId(AlmadallahUserType.MemberIdOrCard, almadallahUserType);
      config.patientName = true;
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationSchema() {
    return Yup.object({
      patientId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType !== AlmadallahUserType.EmiratesId
        )
          ? Yup.string().required('Field is required')
          : Yup.string()
      )),
      patientIdType: Yup.string().required('Patient id type is required'),
      emiratesId: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        patientIdType === AlmadallahUserType.EmiratesId || !patientIdType
          ? Yup.string().emiratesId().required('Field is required')
          : Yup.string()
      )),
      [`treatmentBasis-${this.portal}`]: Yup.string().required('Treatment basis is required'),
      patientName: Yup.string().when(['patientIdType'], (patientIdType: any) => (
        (
          patientIdType === AlmadallahUserType.MemberIdOrCard
        )
          ? Yup.string().required('Patient Name is required')
          : Yup.string()
      )),
    });
  }

  async getInitialValues(values: any, fromApi: boolean) {
    if (fromApi) {
      const remoteInitialValues = await super.getRemoteInitialValues(values);
      return remoteInitialValues ?? initialValue;
    }

    return initialValue;
  }

  // eslint-disable-next-line class-methods-use-this
  getEmiratesIdPatientType() {
    return AlmadallahUserType.EmiratesId;
  }

  getValuesForForm(values: any) {
    const valuesForForm = super.getValuesForForm(values);

    if (valuesForForm.treatmentBasis) {
      // eslint-disable-next-line no-param-reassign
      valuesForForm[`treatmentBasis-${this.portal}`] = valuesForForm.treatmentBasis;
      // eslint-disable-next-line no-param-reassign
      delete valuesForForm.treatmentBasis;
    }

    return valuesForForm;
  }

  getValuesForSubmission(values: any, enableValuesValidation: boolean = true) {
    const parentValues = super.getValuesForSubmission(values, enableValuesValidation);
    return produce(parentValues, (draft: any) => {
      if (values.patientIdType === AlmadallahUserType.EmiratesId) {
        // eslint-disable-next-line no-param-reassign
        draft.patientName = '';
      }
      return draft;
    });
  }
}

export default AlmadallahRequest;
