import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import proposalReducer, {
  ProposalContextState,
  initialState,
  InitState,
} from './proposalReducer';

const useThunkReducer = createReducer<any, ProposalContextState>(thunk);

const ProposalStateContext = React.createContext<ProposalContextState>(initialState());
export const ProposalDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState?: InitState
}

const ProposalProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(proposalReducer, initialState(initState));

  return (
    <ProposalStateContext.Provider value={state}>
      <ProposalDispatchContext.Provider value={dispatch}>
        {children}
      </ProposalDispatchContext.Provider>
    </ProposalStateContext.Provider>
  );
};

function useProposalState() {
  const context = React.useContext(ProposalStateContext);
  if (context === undefined) {
    throw new Error('useProposalState must be used within a ProposalStateContext');
  }
  return context;
}

function useProposalDispatch() {
  const context = React.useContext(ProposalDispatchContext);
  if (context === undefined) {
    throw new Error('useProposalDispatch must be used within a ProposalDispatchContext');
  }
  return context;
}

export {
  ProposalProvider,
  useProposalState,
  useProposalDispatch,
};
