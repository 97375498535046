import { commonFetch, ApiError } from '../apiHelper';

export class StaticApi {
  static getBulkEligibilityTemplate = async () => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/static/bulk-eligibility-template.csv`, {
      method: 'GET',
    });
    if (response.ok) {
      return response.blob();
    }
    throw new ApiError(response);
  };

  static getEligibilityPractitionerLicensesTemplate = async () => {
    const response = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/static/bulk-eligibility-practitioner-licenses.csv`,
      { method: 'GET' },
    );
    if (response.ok) {
      return response.blob();
    }
    throw new ApiError(response);
  };

  static getBulkPortalCredentialsTemplate = async () => {
    const response = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/static/bulk-credentials-template.csv`, {
      method: 'GET',
    });
    if (response.ok) {
      return response.blob();
    }
    throw new ApiError(response);
  };
}

export default StaticApi;
