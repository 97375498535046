import React from 'react';
import { Card, DatePicker, RangePickerProps, Space, Select } from 'custom-test-antd';
import ReactECharts from 'echarts-for-react';
import type { Moment } from 'moment-timezone';

import './Dashboard.pcss';

import { MenuContentLayout } from '../../application/layouts/menu-content-layout';
import createPortal from '../../../utils/createPortal';
import { PrivateLayout } from '../../application/layouts/private-layout';
import { UserUsageDashboardItem } from '../../../api/eligibility/eligibilityTypes';
import { BranchData } from '../../../api/branch/branchTypes';

const Header = createPortal(PrivateLayout.titlePortalId);

interface Props {
  dates: [Moment, Moment];
  onChangeDate: RangePickerProps['onChange'];
  userData: UserUsageDashboardItem[];
  loading: boolean;
  branches: BranchData[];
  selectedBranches?: string[],
  onBranchesChange: (branches: string[]) => void,
}

const prepareSeriesItem = (
  userData: UserUsageDashboardItem[],
  name: string,
  valueGetter: (user: UserUsageDashboardItem) => number,
  stack: string,
) => ({
  name,
  type: 'bar',
  stack,
  label: {
    show: true,
    formatter: (value: any) => (value.data === 0 ? '' : value.data),
  },
  data: userData.map((user) => valueGetter(user)),
});

export const Dashboard = (
  {
    userData, dates, onChangeDate, loading, branches, selectedBranches, onBranchesChange,
  }: Props,
) => {
  const headerAndFooterHeight = 120;
  const totalCount = userData.reduce((acc, data) => acc + data.total, 0);
  const successCount = userData.reduce((acc, data) => acc + data.success, 0);
  const errorCount = userData.reduce((acc, data) => acc + data.error + data.portalError, 0);
  const series = [
    prepareSeriesItem(userData, 'Portal Notice', (user) => user.portalError, 'status'),
    prepareSeriesItem(userData, 'Success', (user) => user.success, 'status'),
    prepareSeriesItem(userData, 'Pending', (user) => user.pending, 'status'),
    prepareSeriesItem(userData, 'Error', (user) => user.error, 'status'),

    prepareSeriesItem(userData, 'Axa', (user) => user.axa, 'portal'),
    prepareSeriesItem(userData, 'Daman', (user) => user.daman, 'portal'),
    prepareSeriesItem(userData, 'Nas', (user) => user.nas, 'portal'),
    prepareSeriesItem(userData, 'Neuron', (user) => user.neuron, 'portal'),
    prepareSeriesItem(userData, 'Nextcare', (user) => user.nextcare, 'portal'),
    prepareSeriesItem(userData, 'Almadallah', (user) => user.almadallah, 'portal'),
    prepareSeriesItem(userData, 'Oman', (user) => user.oman, 'portal'),
    prepareSeriesItem(userData, 'Adnic', (user) => user.adnic, 'portal'),
    prepareSeriesItem(userData, 'Metlife-Alico', (user) => user.metlife, 'portal'),
    prepareSeriesItem(userData, 'MSH', (user) => user.msh, 'portal'),
    prepareSeriesItem(userData, 'Mednet', (user) => user.mednet, 'portal'),
    prepareSeriesItem(userData, 'Abnic', (user) => user.abnic, 'portal'),
    prepareSeriesItem(userData, 'Inayah', (user) => user.inayah, 'portal'),
    prepareSeriesItem(userData, 'NGI', (user) => user.ngi, 'portal'),
  ];
  return (
    <>
      <Header>Dashboard</Header>
      <MenuContentLayout
        content={(
          <Card className="Dashboard-content">
            <div className="Dashboard-header">
              <div>User usage</div>
              <Space>
                <DatePicker.RangePicker
                  gray
                  value={dates}
                  onChange={onChangeDate}
                  allowClear={false}
                  className="Dashboard-datesSelect"
                />
                <Select
                  value={selectedBranches}
                  className="Dashboard-branchSelect"
                  gray
                  maxTagCount={1}
                  mode="multiple"
                  placeholder="Branch"
                  dropdownMatchSelectWidth={false}
                  optionFilterProp="label"
                  optionLabelProp="value"
                  onChange={onBranchesChange}
                >
                  {branches.map((branch) => (
                    <Select.Option
                      key={branch.identifier}
                      value={branch.identifier}
                      label={`${branch.identifier} ${branch.name}`}
                    >
                      {`[${branch.identifier}] ${branch.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
            </div>
            <div>
              {`Total count: ${totalCount}`}
            </div>
            <div>
              {`Successes: ${successCount}`}
            </div>
            <div>
              {`Errors: ${errorCount}`}
            </div>
            <ReactECharts
              showLoading={loading}
              style={{ height: (userData.length * 40 + headerAndFooterHeight) }}
              className="Dashboard-userChart"
              option={{
                tooltip: {},
                legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true,
                },
                xAxis: {
                  type: 'value',
                },
                yAxis: {
                  type: 'category',
                  data: userData.map((user) => user.userName),
                },
                series,
              }}
            />
          </Card>
        )}
      />
    </>
  );
};

export default Dashboard;
