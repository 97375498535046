import React, { Dispatch } from 'react';
import thunk from 'redux-thunk';
import { createReducer } from 'react-use';

import credentialsListReducer, { CredentialsListContextState, initialState, InitState } from './credentialsListReducer';

const useThunkReducer = createReducer<any, CredentialsListContextState>(thunk);

const CredentialsListStateContext = React.createContext<CredentialsListContextState>(initialState());
export const CredentialsListDispatchContext = React.createContext<Dispatch<any>>(() => {
});

interface Props {
  children: React.ReactNode,
  initState: InitState
}

const CredentialsListProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(credentialsListReducer, initialState(initState));

  return (
    <CredentialsListStateContext.Provider value={state}>
      <CredentialsListDispatchContext.Provider value={dispatch}>
        {children}
      </CredentialsListDispatchContext.Provider>
    </CredentialsListStateContext.Provider>
  );
};

function useCredentialsListState() {
  const context = React.useContext(CredentialsListStateContext);
  if (context === undefined) {
    throw new Error('useCredentialsListState must be used within a CredentialsListStateContext');
  }
  return context;
}

function useCredentialsListDispatch() {
  const context = React.useContext(CredentialsListDispatchContext);
  if (context === undefined) {
    throw new Error('useCredentialsListDispatch must be used within a CredentialsListDispatchContext');
  }
  return context;
}

export {
  CredentialsListProvider,
  useCredentialsListState,
  useCredentialsListDispatch,
};
