import React from 'react';
import { Modal } from 'custom-test-antd';
import { FormikHelpers } from 'formik';
import OtpForm from './OtpForm';

import './OtpFormModal.pcss';

type Props = {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => Promise<any>,
  onClose: () => void,
}

const showOtpModal = ({ onSubmit, onClose }: Props) => Modal.info({
  icon: null,
  className: 'OtpFormModal',
  width: 400,
  closable: true,
  content: <OtpForm onSubmit={onSubmit} />,
  onCancel: () => {
    onClose();
  },
});

export type ModalInstance = ReturnType<typeof showOtpModal>;

export default showOtpModal;
